define('vopay_app/components/inverite-page', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend({
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);
			this.set("WebHookURL", this.get("webhook.WebHookURL"));
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		actions: {
			update: function update() {
				var _this = this;

				var options = {};
				options.InveriteAPIKey = this.get("InveriteAPIKey");
				options.InveriteUrl = this.get("InveriteUrl");

				var self = this;
				this.get("api").updateInverite(options).then(function (data) {
					//re get webhook url
					self.set("isForm", false);
				}, function (error) {
					_this.errorMessage(error);
				});
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			showForm: function showForm() {
				this.set("isForm", true);
			},
			closeForm: function closeForm() {
				this.set("isForm", false);
			}
		}
	});
});