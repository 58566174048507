define('vopay_app/helpers/sufficieient-funds', ['exports', 'ember'], function (exports, _ember) {
	exports.sufficieientFunds = sufficieientFunds;

	function sufficieientFunds(params /*, hash*/) {
		var max = params[1];
		var current = Number(params[0].replace(/,/g, ''));
		var pass = params[2];
		if (current > max && !pass) {
			return true;
		} else {
			return false;
		}
		return params;
	}

	exports['default'] = _ember['default'].Helper.helper(sufficieientFunds);
});