define("vopay_app/components/recipient-input", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    recipient: null,
    recipient2: null,
    deliveryMethodName: "Choose the recipient",
    deliverySelectClass: "nothing",
    recipients: [],
    actions: {
      recipientChanged: function recipientChanged(item, id, first, last) {

        _ember["default"].$("#recipient").parsley().reset();

        var fullname = null;
        if (last === null) {
          fullname = first;
        } else {
          fullname = first + " " + last;
        }
        if (!item) {
          item = fullname;
        }
        this.set('recipient2', item);
        if (item !== "new") {
          this.set('recipient', item);
        } else {
          this.set('recipient', "Add New Recipient");
          this.set('deliveryMethodName', "+ Add New Recipient");
          _ember["default"].$('.dropdown-toggle.dropdownToggle--grey').removeClass('dropdownToggle--grey');
        }
        this.sendAction('recipientChanged', item, id);
      },
      recipientSelChanged: function recipientSelChanged(item) {

        var item2 = item.split(" ");

        var item3 = item2[0];
        item2.shift();

        this.set('recipient2', item2.join(" "));

        _ember["default"].$("#recipient").parsley().reset();

        if (item2 !== "new") {
          this.set('recipient', item2.join(" "));
        } else {
          this.set('recipient', "Add New Recipient");
        }
        this.sendAction('recipientChanged', item2.join(" "), item3);
      }
    }
  });
});
/***
* delevry dropdown
* @params availableDeliveryMethods (Object)
* shows correct dropdown for a delevery method
***/