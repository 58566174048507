define('vopay_app/services/memory-storage', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    getAndRemove: function getAndRemove(key) {
      var obj = this.get(key);
      if (obj !== undefined) {
        this.set(key, undefined);
      }
      return obj;
    }
  });
});