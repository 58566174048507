define('vopay_app/routes/dashboard/accounting-software/bills', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model() {
			this.get("userPermissions").HasPermissionRoute("ViewAccountingBillsAndInvoices");
			return _ember['default'].RSVP.hash({
				ConnectedAccounts: this.get('api').getRailzBusinessAccounts(),
				Bills: this._getBills(),
				PaidBills: this._getPaidBills()

			});
		},
		_getPaidBills: function _getPaidBills() {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_this.get('api').getRailzBusinessAccounts().then(function (data) {
					var account = data.Accounts[0];
					if (account) {
						var options = {
							BusinessName: account.BusinessName,
							ServiceName: account.ServiceName
						};
						_this.get("api").getRailzVopayBills(options).then(function (dataInvoices) {
							resolve(dataInvoices);
						}, function (error2) {
							reject(error2);
						});
					} else {
						resolve({
							Invoices: {}
						});
					}
				}, function (error) {
					reject(error);
				});
			});
		},
		_getBills: function _getBills() {
			var _this2 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_this2.get('api').getRailzBusinessAccounts().then(function (data) {
					var account = data.Accounts[0];
					if (account) {
						var options = {
							BusinessName: account.BusinessName,
							ServiceName: account.ServiceName
						};
						_this2.get("api").getRailzBills(options).then(function (dataInvoices) {
							resolve(dataInvoices);
						}, function (error2) {
							reject(error2);
						});
					} else {
						resolve({
							Invoices: {}
						});
					}
				}, function (error) {
					reject(error);
				});
			});
		}
	});
});