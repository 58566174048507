define("vopay_app/helpers/lower-case-no-space", ["exports", "ember"], function (exports, _ember) {
	exports.lowerCaseNoSpace = lowerCaseNoSpace;

	function lowerCaseNoSpace(input /*, hash*/) {
		if (input[0]) {
			var value = input[0];
			value = value.toLowerCase();
			return value.replace(" ", "");
		} else {
			return input[0];
		}
	}

	exports["default"] = _ember["default"].Helper.helper(lowerCaseNoSpace);
});