define('vopay_app/components/benefeficiary-list', ['exports', 'ember'], function (exports, _ember) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	exports['default'] = _ember['default'].Component.extend({
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		options: ["Update", "Fund", "Withdraw", "Unlink"],
		transactionType: ["All", "Bank Account", "Interac E-Transfer", "Visa Direct", "Credit Card"],
		keyword: "",
		currency: "All",
		type: null,
		pageSize: 20,
		pageNumber: 1,
		MoveToEdit: function MoveToEdit(key) {
			window.location.href = "/#/dashboard/link-accounts/update/" + key;
		},
		MoveToPay: function MoveToPay(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.add-account");
		},
		MoveToFund: function MoveToFund(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.fund");
		},
		MoveToWithdraw: function MoveToWithdraw(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.withdraw");
		},
		MoveToCollect: function MoveToCollect(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.collect");
		},
		RemoveAccount: function RemoveAccount(key) {
			var _this = this;

			this.get("firebase").deleteRecordUser("linkedAccount", key);
			this.get("firebase").getRecordUser(this.get("collection")).then(function (data) {
				_this.get("firebase").getRecordUser('linkedAccount').then(function (data2) {
					_this.set("beneficiaries", _this.mergeAccounts(data, data2));
				});
			});
		},
		deleteItem: function deleteItem(key) {
			var _this2 = this;

			this.get("firebase").deleteRecordUser(this.get("collection"), key);
			if (this.get("collection") === "accounts") {
				this.get("firebase").getRecordUser(this.get("collection")).then(function (data) {
					_this2.get("firebase").getRecordUser('linkedAccount').then(function (data2) {
						_this2.set("beneficiaries", _this2.mergeAccounts(data, data2));
						_this2.filterTransactions(_this2.get("keyword"), _this2.get("type"), _this2.get("currency"), _this2.get("bankAccounts"), 1);
					});
				});
			} else {
				this.get("firebase").getRecordUser(this.get("collection")).then(function (data) {
					_this2.set("beneficiaries", data);
					_this2.filterTransactions(_this2.get("keyword"), _this2.get("type"), _this2.get("currency"), _this2.get("bankAccounts"), 1);
				});
			}
		},
		mergeAccounts: function mergeAccounts(data1, data2) {
			var data = $.extend(true, {}, data1, data2);
			var keys = Object.keys(data).sort(function (a, b) {
				var aName = data[a].timestamp;
				var bName = data[b].timestamp;
				if (!aName) {
					aName = 0;
				}
				if (!bName) {
					bName = 0;
				}
				return aName < bName ? -1 : aName > bName ? 1 : 0;
			});
			var ordered = {};
			keys.forEach(function (key) {
				ordered[key] = data[key];
			});
			return ordered;
		},
		movetoDetails: function movetoDetails(key) {

			if (this.get("collection") === "beneficiary") {
				window.location.href = "/#/dashboard/beneficiaries/details/" + key;
			}
			if (this.get("collection") === "accounts") {
				window.location.href = "/#/dashboard/accounts/details/" + key;
			}
		},
		movetoEdit: function movetoEdit(key) {

			if (this.get("collection") === "beneficiary") {
				window.location.href = "/#/dashboard/beneficiaries/edit/" + key;
			}
			if (this.get("collection") === "accounts") {
				window.location.href = "/#/dashboard/accounts/edit/" + key;
			}
		},
		options2: ["Collect", "Details", "Edit", "Delete"],
		options3: ["Withdraw", "Details", "Edit", "Delete"],

		init: function init() {
			var _this3 = this;

			this._super.apply(this, arguments);
			var currencies = this.get("currencies.CurrenciesList");
			currencies.unshift("All");
			this.set("currencies2", currencies);
			this.set("Country", localStorage.getItem("Country"));
			var Country = localStorage.getItem("Country");
			var bankAccounts = this.get("bankAccounts.BankAccounts");
			var myAccounts = Object.values(bankAccounts);
			myAccounts = myAccounts.map(function (bankAccount) {
				return _extends({}, bankAccount, { Type: 'Bank Account', Country: Country });
			});

			var creditCards = this.get("creditCards.CreditCards");
			var myCards = Object.values(creditCards);
			myCards = myCards.map(function (creditCard) {
				return _extends({}, creditCard, { Type: 'Credit Card', Country: Country, Currency: 'CAD' });
			}).filter(function (card) {
				return card.CreditCardNumber != null;
			});

			var firebaseAccounts = $.extend(true, {}, this.get("firebaseBeneficiaries"), this.get("firebaseLinkedAccounts"));

			var nonBankAccounts = Object.values(firebaseAccounts);
			var accountKeys = Object.keys(firebaseAccounts);

			nonBankAccounts = nonBankAccounts.map(function (account, ind) {
				return _extends({}, account, { key: accountKeys[ind] });
			}).filter(function (email) {
				return email.Type != "Bank Account";
			});
			var linkedAccounts = myAccounts.concat(nonBankAccounts, myCards);
			this.set("bankAccounts", linkedAccounts);

			if (this.get("collection") === "accounts") {
				var data;
				var keys;

				(function () {
					data = linkedAccounts;
					keys = Object.keys(data).sort(function (a, b) {
						var aName = data[a].timestamp;
						var bName = data[b].timestamp;
						if (!aName) {
							aName = 0;
						}
						if (!bName) {
							bName = 0;
						}
						return aName < bName ? -1 : aName > bName ? 1 : 0;
					});

					var ordered = {};
					keys.forEach(function (key) {
						ordered[key] = data[key];
					});
					_this3.set("bankAccounts", ordered);
				})();
			}
			this.getTransactions(1, this.get("bankAccounts"));
		},
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};

			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {

					filteredList[keys[i]] = transactions[keys[i]];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(searchTerms, type, currency, transactions, page) {
			var filteredList = {};
			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.replace(/\$/g, '');
			searchTerms = searchTerms.replace(/\,/g, '');
			if (transactions) {
				var keys = Object.keys(transactions);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[keys[i]] !== 'undefined') {
						if ((type === null || type === "" || type === "All" || type === transactions[keys[i]].Type) && (currency === null || currency === "" || currency === "All" || currency === transactions[keys[i]].Currency)) {

							if (transactions[keys[i]].CompanyName && transactions[keys[i]].CompanyName.toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (transactions[keys[i]].AccountHolderName && transactions[keys[i]].AccountHolderName.toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (transactions[keys[i]].CreditCardHolderName && transactions[keys[i]].CreditCardHolderName.toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (transactions[keys[i]].RecipientName && transactions[keys[i]].RecipientName.toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (transactions[keys[i]].FirstName && (transactions[keys[i]].FirstName + " " + transactions[keys[i]].LastName).toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (transactions[keys[i]].FullName && transactions[keys[i]].FullName.toLowerCase().includes(searchTerms)) {
								next = next + 1;
								filteredList[keys[i]] = transactions[keys[i]];
							} else if (searchTerms === null || searchTerms === "") {
								next = next + 1;
								filteredList[next] = transactions[i];
							}
						}
					}
				}

				this.set("filteredList", filteredList);
				this.getTransactions(page, this.get("filteredList"));
			}
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		actions: {
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("currency"), this.get("bankAccounts"), 1);
			},
			filterType: function filterType(type) {
				this.set("type", type);
				this.set("keyword", "");
				this.filterTransactions(this.get("keyword"), type, this.get("currency"), this.get("bankAccounts"), 1);
			},
			filterCurrency: function filterCurrency(currency) {
				this.set("currency", currency);
				this.set("keyword", "");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("currency"), this.get("bankAccounts"), 1);
			},
			getPage: function getPage(pageNumber) {
				this.getTransactions(pageNumber, this.get("bankAccounts"));
			},

			MoveToFund: function MoveToFund(key) {
				sessionStorage.setItem("Benefiricary", key);
				this.get("routing").transitionTo("dashboard.fund");
			},
			MoveToPayment: function MoveToPayment(key) {
				sessionStorage.setItem("Benefiricary", key);
				this.get("routing").transitionTo("dashboard.add-account");
			},
			MoveToWithdraw: function MoveToWithdraw(key) {
				sessionStorage.setItem("Benefiricary", key);
				this.get("routing").transitionTo("dashboard.withdraw");
			},
			MoveToCollection: function MoveToCollection(key) {
				this.MoveToCollect(key);
			},
			movetoDetails: function movetoDetails(key, beneficiary) {
				key = !key ? 0 : key;

				var token = beneficiary.Type == 'Credit Card' ? beneficiary.CreditCardToken : beneficiary.Token;
				var type = beneficiary.Type.replace(/\s/g, '');

				if (token) {
					if ((beneficiary.FlinksLoginID || token) && this.get("collection") === "accounts") {

						window.location.href = "/#/dashboard/link-accounts/details/" + key + "/" + type + "/" + token;
					} else if (this.get("collection") === "beneficiary") {
						window.location.href = "/#/dashboard/beneficiaries/details/" + key + "/" + type + "/" + token;
					} else if (this.get("collection") === "accounts") {
						window.location.href = "/#/dashboard/accounts/details/" + key + "/" + type + "/" + token;
					}
				} else {
					if ((beneficiary.FlinksLoginID || beneficiary.Token) && this.get("collection") === "accounts") {

						window.location.href = "/#/dashboard/link-accounts/details/" + key;
					} else if (this.get("collection") === "beneficiary") {
						window.location.href = "/#/dashboard/beneficiaries/details/" + key;
					} else if (this.get("collection") === "accounts") {
						window.location.href = "/#/dashboard/accounts/details/" + key;
					}
				}
			},
			closeUnlinkModal: function closeUnlinkModal() {
				this.set("isUnlink", false);
			},
			closeDeleteModal: function closeDeleteModal() {
				this.set("isDeleteModal", false);
			},
			unlink: function unlink() {

				this.RemoveAccount(this.get("accountKey"));
				this.set("isUnlink", false);
			},
			deleteAccount: function deleteAccount() {
				this.deleteItem(this.get("accountKey"));
				this.set("isDeleteModal", false);
			},
			handleSelection: function handleSelection(value, key) {
				if (value === "Fund") {
					this.MoveToFund(key);
				} else if (value === "Pay") {
					this.MoveToPay(key);
				} else if (value === "Withdraw") {
					this.MoveToWithdraw(key);
				} else if (value === "Collect") {
					this.MoveToCollect(key);
				} else if (value === "Update") {
					this.MoveToEdit(key);
				} else if (value === "Unlink") {
					this.set("accountKey", key);
					this.set("isUnlink", true);
				}
			},
			handleSelection2: function handleSelection2(value, key) {
				if (value === "Details") {
					this.movetoDetails(key);
				} else if (value === "Pay") {
					this.MoveToPay(key);
				} else if (value === "Collect") {
					this.MoveToCollect(key);
				} else if (value === "Withdraw") {
					this.MoveToWithdraw(key);
				} else if (value === "Edit") {
					this.movetoEdit(key);
				} else if (value === "Delete") {
					this.set("accountKey", key);
					this.set("isDeleteModal", true);
				}
			},
			SetAsDefault: function SetAsDefault(key, beneficiary) {
				var _this4 = this;

				var options = {
					Token: beneficiary.Token,
					SetAsDefault: true
				};
				var bankAccounts = this.get("bankAccounts");
				this.get("api").setDefaultBankAccount(options).then(function (data) {
					for (var account in bankAccounts) {
						if (bankAccounts[account].IsDefault === "1") {
							_this4.set("bankAccounts." + account + ".IsDefault", "0");
							_this4.set("filteredList." + account + ".IsDefault", "0");
							break;
						}
					}
					_this4.set("bankAccounts." + key + ".IsDefault", "1");
					_this4.set("filteredList." + key + ".IsDefault", "1");
				}, function (error) {
					_this4.errorMessage(error);
				});
			}
		}
	});
});