define("vopay_app/utils/initializeLocationIq", ["exports"], function (exports) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

    exports["default"] = function (addressLine1Id, cityId, provinceId, postalcodeId) {
        var isoCode = arguments.length <= 4 || arguments[4] === undefined ? 'CA' : arguments[4];

        var autocomplete = new google.maps.places.Autocomplete(document.getElementById(addressLine1Id), { types: ["address"] });

        autocomplete.setComponentRestrictions({ country: isoCode });
        autocomplete.setFields(["address_component"]);

        autocomplete.addListener("place_changed", function () {
            var place = autocomplete.getPlace();
            var addressComponents = {};

            place.address_components.forEach(function (_ref) {
                var types = _ref.types;
                var long_name = _ref.long_name;
                var short_name = _ref.short_name;

                var _types = _slicedToArray(types, 1);

                var addressComponent = _types[0];

                var handlers = {
                    'street_number': function street_number() {
                        return addressComponents.streetNumber = long_name;
                    },
                    'route': function route() {
                        return addressComponents.street = long_name;
                    },
                    'locality': function locality() {
                        return addressComponents.city = long_name;
                    },
                    'sublocality_level_1': function sublocality_level_1() {
                        return addressComponents.city = long_name;
                    },
                    'administrative_area_level_1': function administrative_area_level_1() {
                        return addressComponents.province = short_name;
                    },
                    'administrative_area_level_3': function administrative_area_level_3() {
                        return addressComponents.city = long_name;
                    },
                    'postal_code': function postal_code() {
                        return addressComponents.postalcode = short_name;
                    },
                    'postal_code_prefix': function postal_code_prefix() {
                        return addressComponents.postalcode = short_name;
                    },
                    'country': function country() {
                        return addressComponents.country = short_name;
                    },
                    'default': function _default() {}
                };

                var handler = handlers[addressComponent] || handlers["default"];

                handler();
            });

            var streetNumber = addressComponents.streetNumber;
            var street = addressComponents.street;
            var city = addressComponents.city;
            var province = addressComponents.province;
            var postalcode = addressComponents.postalcode;

            $("#" + addressLine1Id).val((sanitize(streetNumber) + " " + sanitize(street)).trim()).trigger('input').trigger('change');
            $("#" + cityId).val(("" + sanitize(city)).trim()).trigger('input').trigger('change');
            $("#" + provinceId).val(("" + sanitize(province)).trim()).trigger('input').trigger('change');
            $("#" + postalcodeId).val(("" + sanitize(postalcode)).trim()).trigger('input').trigger('change');

            return addressComponents;

            function sanitize(val) {
                return [undefined, null].includes(val) ? '' : val;
            }
        });
    };
});