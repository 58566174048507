define('vopay_app/routes/dashboard/account/phonenumber', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),

    model: function model() {

      var authed = this.get('session.isAuthenticated');

      if (authed) {

        var model = this.modelFor("dashboard");
        return model;
      } else {

        this.transitionTo('login');
      }
    }
  });
});