define('vopay_app/helpers/bank-abbr-full', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports.bankAbbrFull = bankAbbrFull;

	function bankAbbrFull(params /*, hash*/) {
		var Banks = _vopay_appConstants['default'].flinksBanks.filter(function (f) {
			return f.abbr === params[0];
		}).map(function (c) {
			return c.name;
		});
		if (Banks[0]) {
			return Banks[0];
		} else {
			return false;
		}
	}

	exports['default'] = _ember['default'].Helper.helper(bankAbbrFull);
});