define('vopay_app/routes/dashboard/support/submit-ticket', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      api: _ember['default'].inject.service('api'),
      userPermissions: _ember['default'].inject.service('user-permissions'),
      model: function model() {
         this.get("userPermissions").HasPermissionRoute("ViewSupportTickets");
         this.get("userPermissions").HasPermissionRoute("CreateSupportTickets");
         return {};
      }
   });
});