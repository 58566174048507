define("vopay_app/helpers/fullname-short", ["exports", "ember"], function (exports, _ember) {
	exports.fullnameShort = fullnameShort;

	function fullnameShort(params /*, hash*/) {
		if (params[0]) {
			var fullname = params[0];
			var nameParts = fullname.split(" ");
			var firstname = nameParts[0];
			var lastname = nameParts[nameParts.length - 1];
			if (lastname) {
				lastname = " " + lastname.charAt(0) + ".";
			} else {
				lastname = "";
			}
			return firstname + lastname;
		} else {
			return "";
		}
	}

	exports["default"] = _ember["default"].Helper.helper(fullnameShort);
});