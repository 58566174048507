define("vopay_app/components/multi-hybrid-dropdown", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		actions: {
			selectedValue: function selectedValue(value) {
				if (value === "Other") {
					var selector = "#" + this.get("idNam3e") + "" + this.get("number");
					var selectorDd = "#" + this.get("idName") + "" + this.get("number") + "Dd";
					_ember["default"].$(selector).val("");
					_ember["default"].$(selector).attr('type', 'text');
					_ember["default"].$(selector).focus();
					_ember["default"].$(selectorDd).hide();
				} else {
					var selector2 = "#" + this.get("idName") + "" + this.get("number");
					_ember["default"].$(selector2).val(value);
					_ember["default"].$(selector2).parsley().validate();
				}

				this.sendAction('selected', value);
			}
		}
	});
});