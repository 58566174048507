define("vopay_app/components/reconciliation-description", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        Description: "",
        Id: "",
        Type: "",
        Service: "",
        IsBatchFile: false,
        HyperLinkText: "",
        Amount: ""
    });
});