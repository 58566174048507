define('vopay_app/components/template-info', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	exports['default'] = _ember['default'].Component.extend({
		firebase: _ember['default'].inject.service('firebase'),
		PaymentTypeOptions: ["Collect", "Payment"],
		PaymentType: "Collect",
		routing: _ember['default'].inject.service('-routing'),
		currencies2: _vopay_appConstants['default'].currencies,
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		country: "Canada",
		isoCountry: "CA",
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			if (localStorage.getItem("Country") == "US") {
				this.set("currency2", "USD");
				this.set("country", "United States");
				this.set("currencyName", "United States Dollar");
				this.set("isoCountry", "US");
			}
			var bankAccounts = this.get("bankAccounts");
			var myAccounts = Object.values(bankAccounts);
			myAccounts = myAccounts.map(function (bankAccount) {
				return _extends({}, bankAccount, { Type: 'Bank Account', Country: _this.get("country"), Currency: bankAccount.Currency || _this.get("currency2") });
			});
			this.set("beneficiaries", myAccounts);

			var templates = this.get("templates");
			var findTemplateId = this.get("key");
			if (templates) {
				var template = Object.values(templates).find(function (templateObj) {
					return templateObj.ID == findTemplateId;
				});

				if (template.ACTCurrencies_ID) {
					var Currency = "CAD";
					if (template.ACTCurrencies_ID === "1") {
						Currency = "USD";
					}
					template.Currency = Currency;
					this.set("currency2", Currency);
					this.set("currencyName", this.getCurrencyName(Currency));
				} else if (!template.Currency) {
					template.Currency = "CAD";
				}
				this.set("template", template);
			}
			//this.set("PaymentType", this.get("template.PaymentType"));
			this.set("currencyName", this.getCurrencyName(this.get("template.Currency")));
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			handlePaymentType: function handlePaymentType(value) {
				this.set("PaymentType", value);
			},
			moveToBulk: function moveToBulk() {
				if (this.get("PaymentType") == "Payment") {
					this.get("routing").transitionTo("dashboard.bulk-payment");
				} else {
					this.get("routing").transitionTo("dashboard.bulk-collect");
				}
				this.set("IsFinished", false);
			},
			FinishHandler: function FinishHandler() {
				this.set("IsFinished", true);
			}
		}
	});
});