define('vopay_app/helpers/mask-generator', ['exports', 'ember', 'vopay_app/helpers/spaces-between'], function (exports, _ember, _vopay_appHelpersSpacesBetween) {
  exports.maskGenerator = maskGenerator;

  function maskGenerator(params /*, hash*/) {

    if (!params[0]) {
      return params[0];
    }

    if (!params[1] || typeof params[1] !== 'number') {
      return params[0];
    }

    if (!params[2] || typeof params[2] !== 'number') {
      return params[0];
    }

    var delimiter = params[3];

    if (!params[3] || typeof params[3] !== 'string') {
      delimiter = ' ';
    }

    var value = params[0];
    var format = '';
    var mask = '';

    if (/^(?=.*[a-zA-Z])(?=.*[0-9])/g.test(value)) {
      format = '#';
    } else if (/^[a-zA-Z]+$/g.test(value)) {
      format = 'A';
    } else if (/^[0-9]+$/g.test(value)) {
      format = '1';
    }

    value = (0, _vopay_appHelpersSpacesBetween.spacesBetween)([].concat(params));

    for (var i = 0; i < value.length; i++) {
      if (value.charAt(i) === ' ') {
        mask += delimiter;
        continue;
      }
      mask += format;
    }

    return mask;
  }

  exports['default'] = _ember['default'].Helper.helper(maskGenerator);
});