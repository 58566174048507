define("vopay_app/components/tickets-list", ["exports", "ember"], function (exports, _ember) {
   exports["default"] = _ember["default"].Component.extend({
      init: function init() {
         var data = this.get("Tickets");
         this.set("Tickets", data);
         this._super.apply(this, arguments);
         this.showTickets();
      },
      showTickets: function showTickets(status) {
         var filteredList = {};
         var isActive = status === 0 ? false : true;
         var tickets = this.get("Tickets");

         isActive ? this.set("isActive", true) : this.set("isActive", false);

         if (tickets) {
            var keys = Object.keys(tickets);
            var next = -1;

            for (var i = 0; i < keys.length; i++) {

               if (typeof tickets[i] !== 'undefined' && isActive) {
                  if (tickets[i].status.toLowerCase() !== 'solved' && tickets[i].status.toLowerCase() !== 'closed') {
                     next = next + 1;
                     filteredList[next] = tickets[i];
                  }
               }

               if (typeof tickets[i] !== 'undefined' && !isActive) {
                  if (tickets[i].status.toLowerCase() === 'solved' || tickets[i].status.toLowerCase() === 'closed') {
                     next = next + 1;
                     filteredList[next] = tickets[i];
                  }
               }
            }
            this.set("filteredList", filteredList);
         }
      },
      actions: {
         toggleTicketTab: function toggleTicketTab(event) {
            this.showTickets(event.target.value);
         }
      }
   });
});