define('vopay_app/routes/dashboard/account-billing', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model() {
			this.get("userPermissions").HasPermissionRoute("ManageDefaultPayments");
			return _ember['default'].RSVP.hash({
				bankAccounts: this.get('api').getBankAccounts(true, null, true),
				defaultPaymentMethod: this.get('api').getDefaultPaymentMethod(true)
			});
		}
	});
});