define('vopay_app/routes/dashboard/recipients/new', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		model: function model() {
			var authed = this.get('session.isAuthenticated');
			//if authenticated, grab some info about the user
			if (authed) {

				return _ember['default'].RSVP.hash({
					countries: this.get('api').getCountries()
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});