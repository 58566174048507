define("vopay_app/templates/components/integrations-page", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 15,
                "column": 4
              }
            },
            "moduleName": "vopay_app/templates/components/integrations-page.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "integrations-item", [], ["type", "integration", "title", "accounting-software", "IntegrationTitle", "Accounting Software Configuration", "image", "Accounting-Software.png"], ["loc", [null, [14, 5], [14, 159]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 3
            },
            "end": {
              "line": 16,
              "column": 3
            }
          },
          "moduleName": "vopay_app/templates/components/integrations-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "check-permission", ["ViewAccountingBillsAndInvoices"], [], ["loc", [null, [13, 10], [13, 61]]], 0, 0]], [], 0, null, ["loc", [null, [13, 4], [15, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/integrations-page.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "dashboard__section dashboard__section--grey dashboard__section--first dashboard__section--heading");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "dashboard__header");
        var el4 = dom.createTextNode("Integrations");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "dashboard__section dashboard__section--body dashboard__section--fullWidth");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "integration__section");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4, "class", "integration__sectionHeading");
        var el5 = dom.createTextNode("Financial Data");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("	\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 3, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createMorphAt(element0, 7, 7);
        morphs[3] = dom.createMorphAt(element0, 9, 9);
        morphs[4] = dom.createMorphAt(element0, 11, 11);
        return morphs;
      },
      statements: [["inline", "integrations-item", [], ["type", "integration", "title", "Flinks", "IntegrationTitle", "Flinks Configuration", "image", "flinks.jpeg"], ["loc", [null, [8, 3], [8, 119]]], 0, 0], ["inline", "integrations-item", [], ["type", "integration", "title", "Plaid", "IntegrationTitle", "Plaid Configuration", "image", "Plaid.svg"], ["loc", [null, [9, 3], [9, 115]]], 0, 0], ["inline", "integrations-item", [], ["type", "integration", "title", "Inverite", "IntegrationTitle", "Inverite Configuration", "image", "Inverite.svg"], ["loc", [null, [10, 3], [10, 124]]], 0, 0], ["inline", "integrations-item", [], ["type", "integration", "title", "global-pay", "IntegrationTitle", "Global Payments Configuration", "image", "global-payments.svg"], ["loc", [null, [11, 3], [11, 140]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "AccountingSoftwareIntegrationEnabled", ["loc", [null, [12, 13], [12, 49]]], 0, 0, 0, 0], ["get", "AccountingSystemIntegrationEnabled", ["loc", [null, [12, 50], [12, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 9], [12, 85]]], 0, 0]], [], 0, null, ["loc", [null, [12, 3], [16, 10]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});