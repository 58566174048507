define("vopay_app/templates/components/province-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 160
          }
        },
        "moduleName": "vopay_app/templates/components/province-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "hidden", "value", ["subexpr", "@mut", [["get", "Selection", ["loc", [null, [1, 29], [1, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "Name", ["loc", [null, [1, 44], [1, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "id", ["subexpr", "@mut", [["get", "DropdownId", ["loc", [null, [1, 52], [1, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "errorID", ["subexpr", "@mut", [["get", "errorID", ["loc", [null, [1, 71], [1, 78]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 80]]], 0, 0], ["inline", "hybrid-dropdown", [], ["data", ["subexpr", "@mut", [["get", "CanadaProvincesOptions", ["loc", [null, [2, 23], [2, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "default", ["subexpr", "@mut", [["get", "Default", ["loc", [null, [2, 54], [2, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "currentSelection", ["subexpr", "@mut", [["get", "currentSelection", ["loc", [null, [2, 79], [2, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "SelectedProvince", "selected", ["subexpr", "@mut", [["get", "selectedVal", ["loc", [null, [2, 131], [2, 142]]], 0, 0, 0, 0]], [], [], 0, 0], "errorID", ["subexpr", "@mut", [["get", "errorID", ["loc", [null, [2, 151], [2, 158]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 160]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});