define('vopay_app/components/birthdate-input', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: '',
		months: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"],
		years: null,
		days: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
		monthsOriginal: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"],
		daysOriginal: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
		selDay: null,
		selMonth: null,
		selYear: null,
		DateToday: new Date(),
		watchbirthDate: _ember['default'].observer('birthDate', function () {

			var selector = "#" + this.get("customId");
			_ember['default'].$(selector).val("" + this.get("birthDate"));
			_ember['default'].$(selector).parsley().validate();

			if (this.get("isFutur")) {
				this.sendAction("date", this.get("birthDate"), this.isValidDate());
			} else {
				this.sendAction("date", this.get("birthDate"));
			}
		}),
		didUpdateAttrs: function didUpdateAttrs(options) {
			this._super.apply(this, arguments);

			if (!options.newAttrs.labelDescription || !options.newAttrs.labelDescription.value || options.newAttrs.labelDescription == options.oldAttrs.labelDescription) {
				var self = this;
				var selector = "#" + this.get("customId");
				var d = moment(this.get("lastDate")).toDate();
				this.set("DateToday", d);
				var months = self.get("monthsOriginal");

				var n = months[d.getMonth()];

				var r = ("0" + d.getDate()).slice(-2);
				var s = d.getFullYear();
				//var days = this.getDays();
				months = self.getMonths();
				var days = self.getDays();
				self.set("months", months);
				self.set("days", days);
				self.set("selMonth", n);
				self.set("selDay", r);
				self.set("selYear", s);
				var list = [];
				for (var i = s; i <= s + 103; i++) {
					list.push(i);
				}
				this.set('years', list);
				_ember['default'].$(selector).val("" + self.get("birthDate"));
				_ember['default'].$(selector).parsley().validate();
				if (this.get("isFutur")) {
					self.sendAction("date", self.get("birthDate"), self.isValidDate());
				} else {
					self.sendAction("date", self.get("birthDate"));
				}
			}
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			_ember['default'].$(".birthdayInput__input").keyup(function () {
				if (_ember['default'].$(this).val() !== '') {
					_ember['default'].$(".birthdayInput__placeholder").addClass("birthdayInput__invisible");
				} else {
					_ember['default'].$(".birthdayInput__placeholder").removeClass("birthdayInput__invisible");
				}
			});
			_ember['default'].$(".birthdayInput__input").change(function () {
				if (_ember['default'].$(this).val() !== '') {
					_ember['default'].$(".birthdayInput__placeholder").addClass("birthdayInput__invisible");
				} else {
					_ember['default'].$(".birthdayInput__placeholder").removeClass("birthdayInput__invisible");
				}
			});
			var selector = "#" + this.get("customId");
			var self = this;
			setTimeout(function () {

				if (self.get("prefill") == "True") {
					_ember['default'].$(".birthdayInput__placeholder").addClass("birthdayInput__invisible");
					var d = new Date();

					var months = self.get("months");
					var n = months[d.getMonth()];
					var r = ("0" + d.getDate()).slice(-2);
					if (self.get("isNot") == "True") {
						d.setDate(new Date().getDate() + 1);
						n = months[d.getMonth()];
						r = ("0" + d.getDate()).slice(-2);
						var date2 = moment().add(1, 'days').utc().toDate();
						self.set("DateToday", date2);
					}

					var s = d.getFullYear();
					//var days = this.getDays();
					months = self.getMonths();
					var days = self.getDays();
					self.set("months", months);
					self.set("days", days);
					self.set("selMonth", n);
					self.set("selDay", r);
					self.set("selYear", s);

					_ember['default'].$(selector).val("" + self.get("birthDate"));
					_ember['default'].$(selector).parsley().validate();
					if (self.get("isFutur")) {

						self.sendAction("date", self.get("birthDate"), self.isValidDate());
					} else {
						self.sendAction("date", self.get("birthDate"));
					}
				}
				if (self.get("prefillEnd") == "True") {
					_ember['default'].$(".birthdayInput__placeholder").addClass("birthdayInput__invisible");
					var d2 = new Date();
					var d = new Date(d2);

					d.setDate(d.getDate() + 1);
					var months = self.get("months");
					var n = months[d.getMonth()];
					var r = d.getDate();
					var s = d.getFullYear();
					months = self.getMonths();
					var days = self.getDays();
					self.set("months", months);
					self.set("days", days);
					self.set("selMonth", n);
					self.set("selDay", r);
					self.set("selYear", s);
					_ember['default'].$(selector).val("" + self.get("birthDate"));
					_ember['default'].$(selector).parsley().validate();
					if (self.get("isFutur")) {
						delf.sendAction("date", self.get("birthDate"), self.isValidDate());
					} else {
						self.sendAction("date", self.get("birthDate"));
					}
				}
			}, 100);
		},
		getMonths: function getMonths() {
			var date = this.get("DateToday");
			var monthsOriginal = this.get("monthsOriginal").slice(0);
			var month = date.getMonth();
			while (month > 0) {
				monthsOriginal.splice(0, 1);
				month = month - 1;
			}
			if (this.get("isNotBack") == "true") {
				return monthsOriginal;
			} else {
				return this.get("monthsOriginal");
			}
		},
		getDaysInMonth: function getDaysInMonth(month, year) {
			// Here January is 1 based
			//Day 0 is the last day in the previous month

			return new Date(year, month + 1, 0).getDate();
			// Here January is 0 based
			// return new Date(year, month+1, 0).getDate();
		},
		getDays: function getDays() {
			var date = this.get("DateToday");
			var daysOriginal = this.get("daysOriginal").slice(0);
			var day = date.getDate() - 1;

			while (day > 0) {
				daysOriginal.splice(0, 1);
				day = day - 1;
			}
			var daysInaMonth = this.getDaysInMonth(date.getMonth(), date.getFullYear());
			var removal = 31 - daysInaMonth;

			while (removal > 0) {
				daysOriginal.splice(daysOriginal.length - 1, 1);
				removal = removal - 1;
			}
			if (this.get("isNotBack") == "true") {
				return daysOriginal;
			} else {
				return this.get("daysOriginal");
			}
		},

		birthDate: _ember['default'].computed('selDay', 'selMonth', 'selYear', function () {
			var selDay = this.get('selDay');
			var selMonth = this.get('selMonth');
			var selYear = this.get('selYear');
			var selMonth2 = null;
			if (selDay && selMonth && selYear) {
				var selector = "#" + this.get("customId");
				_ember['default'].$(selector).parsley().reset();
				if (selMonth === "JAN") {
					selMonth2 = "01";
				} else if (selMonth === "FEB") {
					selMonth2 = "02";
				} else if (selMonth === "MAR") {
					selMonth2 = "03";
				} else if (selMonth === "APR") {
					selMonth2 = "04";
				} else if (selMonth === "MAY") {
					selMonth2 = "05";
				} else if (selMonth === "JUN") {
					selMonth2 = "06";
				} else if (selMonth === "JUL") {
					selMonth2 = "07";
				} else if (selMonth === "AUG") {
					selMonth2 = "08";
				} else if (selMonth === "SEPT") {
					selMonth2 = "09";
				} else if (selMonth === "OCT") {
					selMonth2 = "10";
				} else if (selMonth === "NOV") {
					selMonth2 = "11";
				} else if (selMonth === "DEC") {
					selMonth2 = "12";
				} else {
					selMonth2 = selMonth;
				}

				return "" + selYear + '-' + selMonth2 + '-' + selDay;
			} else {
				var selector2 = "#" + this.get("customId");
				_ember['default'].$(selector2).val("");

				return "";
			}
		}),
		init: function init() {
			this._super.apply(this, arguments);
			if (this.get("isFutur")) {
				var d = new Date();
				var n = d.getFullYear();
				var list = [];
				for (var i = n; i <= n + 103; i++) {
					list.push(i);
				}
				this.set('years', list);
			} else {
				var d2 = new Date();
				var n2 = d2.getFullYear() - 18;
				var list2 = [];
				for (var i2 = n2; i2 >= n2 - 103; i2--) {
					list2.push(i2);
				}
				this.set('years', list2);
			}
		},
		isValidDate: function isValidDate() {
			var value = this.get("birthDate");
			var test = this.get("DateToday");

			var amount = moment(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			var min = moment(test).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			if (amount.isAfter(min) || amount.isSame(min)) {
				return true;
			} else {
				return false;
			}
		},
		actions: {
			birthOnchange: function birthOnchange() {},
			setBirthDate: function setBirthDate(date) {
				this.set('selDay', date);
				if (this.get("birthDate")) {
					var selector = "#" + this.get("customId");
					_ember['default'].$(selector).val("" + this.get("birthDate"));
					_ember['default'].$(selector).parsley().validate();
					if (this.get("isFutur")) {
						this.sendAction("date", this.get("birthDate"), this.isValidDate());
					} else {
						this.sendAction("date", this.get("birthDate"));
					}
				}
			},
			setBirthMonth: function setBirthMonth(month) {
				this.set('selMonth', month);
				if (this.get("prefill") == "True") {
					var days = this.get("daysOriginal");
					var months = this.get("monthsOriginal");

					var date = new Date();
					if (months[date.getMonth()] == month) {
						var days2 = this.getDays();
						this.set("days", days2);

						if (days.indexOf(this.get("selDay")) < date.getDate() - 1) {
							this.set("selDay", days[date.getDate() - 1]);
						}
					} else {
						this.set("days", days);
					}
					if (this.get("selDay") > this.getDaysInMonth(date.getMonth(), date.getFullYear())) {
						var days3 = this.get("days");
						this.set("selDay", days3[days3.length - 1]);
					}
				}
				if (this.get("birthDate")) {
					var selector = "#" + this.get("customId");
					_ember['default'].$(selector).val("" + this.get("birthDate"));
					_ember['default'].$(selector).parsley().validate();
					if (this.get("isFutur")) {
						this.sendAction("date", this.get("birthDate"), this.isValidDate());
					} else {
						this.sendAction("date", this.get("birthDate"));
					}
				}
			},
			setBirthYear: function setBirthYear(year) {
				this.set('selYear', year);
				if (this.get("prefill") == "True") {
					var months = this.get("monthsOriginal");
					var date = new Date();
					if (date.getFullYear() == year) {
						var months2 = this.getMonths();

						this.set("months", months2);
						if (date.getMonth() > months.indexOf(this.get('selMonth'))) {
							this.set('selMonth', months2[0]);
							var days2 = this.getDays();
							this.set("days", days2);
						} else if (date.getMonth() == months.indexOf(this.get('selMonth'))) {
							var days2 = this.getDays();
							this.set("days", days2);
						}
					} else {
						this.set("months", months);
					}
				}
				if (this.get("birthDate")) {
					var selector = "#" + this.get("customId");
					_ember['default'].$(selector).val("" + this.get("birthDate"));
					_ember['default'].$(selector).parsley().validate();
					if (this.get("isFutur")) {
						this.sendAction("date", this.get("birthDate"), this.isValidDate());
					} else {
						this.sendAction("date", this.get("birthDate"));
					}
				}
			}

		}
	});
});