define('vopay_app/routes/dashboard/reconciliation', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model() {
			this.get("userPermissions").HasPermissionRoute("ViewReconciliation");
			var authed = this.get('session.isAuthenticated');
			var options = {};
			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					ForReconciliation: true
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					ForReconciliation: true
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					ForReconciliation: true
				};
			}

			if (authed) {

				return _ember['default'].RSVP.hash({
					transactions: this.get('api').getReconciliationTransactions(),
					Iframe: this.get('api').getApiURL(options)
				});
			} else {

				this.transitionTo('login');
			}
		}
	});
});