define('vopay_app/routes/dashboard/users/index', ['exports', 'ember'], function (exports, _ember) {
				exports['default'] = _ember['default'].Route.extend({
								api: _ember['default'].inject.service('api'),
								firebase: _ember['default'].inject.service('firebase'),
								userPermissions: _ember['default'].inject.service('user-permissions'),
								model: function model() {
												this.get("userPermissions").HasPermissionRoute("ViewUsers");
												return _ember['default'].RSVP.hash({
																UserList: this.get('api').getUserAccounts()
												});
								}

				});
});