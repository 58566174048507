define('vopay_app/components/faq-page', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Component.extend({
      didRender: function didRender() {
         var accordion = document.getElementsByClassName('container');

         for (var i = 0; i < accordion.length; i++) {
            accordion[i].addEventListener('click', function () {
               this.classList.toggle('active');
            });
         }
      },
      actions: {
         toggleFaq: function toggleFaq(event) {
            var icon = event.target.parentElement.getElementsByTagName('i');
            var initialIconValue = icon[0].classList && icon[0].classList.value === 'fas fa-chevron-down' ? true : false;

            if (initialIconValue) {
               icon[0].classList.remove('fa-chevron-down');
               icon[0].classList.add('fa-chevron-up');
            } else {
               icon[0].classList.remove('fa-chevron-up');
               icon[0].classList.add('fa-chevron-down');
            }
         }
      }
   });
});