define('vopay_app/routes/signup', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		beforeModel: function beforeModel(params) {
			if (this.get('session.isAuthenticated')) {
				if (params.queryParams._ga) {
					this.transitionTo("dashboard.transactions", { queryParams: { _ga: params.queryParams._ga } });
				} else {
					this.transitionTo("dashboard.transactions", { queryParams: { _ga: null } });
				}
			}
		}

	});
});