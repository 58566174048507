define("vopay_app/templates/components/currency-converter", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 1
            },
            "end": {
              "line": 27,
              "column": 1
            }
          },
          "moduleName": "vopay_app/templates/components/currency-converter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "recipientInput");
          dom.setAttribute(el1, "class", "form__toolTip form__toolTip--inline form__element currencyConverter__element--shrink form__element--noPadding");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "id", "recipientLabel");
          dom.setAttribute(el2, "class", "form__label currencyInput__label");
          var el3 = dom.createTextNode("Buy");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "currency-input", [], ["currencyChanged", "recipientCurrencyChanged", "senderAmountChanged", "recipientAmountChanged", "country", ["subexpr", "@mut", [["get", "recipient", ["loc", [null, [20, 11], [20, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "amount", ["subexpr", "@mut", [["get", "recipientAmount", ["loc", [null, [21, 10], [21, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "currency", ["subexpr", "@mut", [["get", "recipientCurrency", ["loc", [null, [22, 12], [22, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "countries", ["subexpr", "@mut", [["get", "countries", ["loc", [null, [23, 13], [23, 22]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 2], [24, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 1
            },
            "end": {
              "line": 40,
              "column": 1
            }
          },
          "moduleName": "vopay_app/templates/components/currency-converter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "recipientInput");
          dom.setAttribute(el1, "class", "form__toolTip form__toolTip--inline form__element currencyConverter__element--shrink form__element--noPadding");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "id", "recipientLabel");
          dom.setAttribute(el2, "class", "form__label currencyInput__label");
          var el3 = dom.createTextNode("Buy");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "currency-input", [], ["currencyChanged", "recipientCurrencyChanged", "senderAmountChanged", "recipientAmountChanged", "country", ["subexpr", "@mut", [["get", "recipient", ["loc", [null, [32, 11], [32, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "amount", ["subexpr", "@mut", [["get", "recipientAmount", ["loc", [null, [33, 10], [33, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "currency", ["subexpr", "@mut", [["get", "currency", ["loc", [null, [34, 12], [34, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "countries", ["subexpr", "@mut", [["get", "countries", ["loc", [null, [35, 13], [35, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "lock", "true"], ["loc", [null, [29, 2], [37, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "vopay_app/templates/components/currency-converter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "currencyConverter__element currencyConverter__element--last currencyConverter__element--outside form__element--60");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "currencyConverter__item currencyConverter__item--orange");
          var el3 = dom.createTextNode("\n			Exchange Rate ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "currencyConverter__item--featured currencyConverter__item--highlight");
          var el4 = dom.createTextNode("1 CAD = ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "currencyConverter__dot");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["inline", "decimal-points", [["get", "exchangeRate", ["loc", [null, [50, 125], [50, 137]]], 0, 0, 0, 0], 4], [], ["loc", [null, [50, 108], [50, 141]]], 0, 0], ["content", "currency", ["loc", [null, [50, 142], [50, 154]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 0
            },
            "end": {
              "line": 75,
              "column": 0
            }
          },
          "moduleName": "vopay_app/templates/components/currency-converter.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "currencyConverter__section currencyConverter__section--grey form__element--60");
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "id", "currencyConverterLoader");
          dom.setAttribute(el2, "class", "currencyConverter__loading");
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "currencyConverter__filter");
          var el4 = dom.createTextNode("\n		");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "currencyConverter__spinner");
          var el4 = dom.createTextNode("\n		");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "currencyConverter__element currencyConverter__element--last");
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "currencyConverter__item currencyConverter__item--orange");
          var el4 = dom.createTextNode("Exchange rate ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "currencyConverter__item--featured currencyConverter__item--highlight");
          var el5 = dom.createTextNode("1 CAD = ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "currencyConverter__dot");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" TODO - Change date ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "currencyConverter__item");
          var el4 = dom.createTextNode("Service Fee ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "currencyConverter__item--featured currencyConverter__item--highlight");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" CAD");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "currencyConverter__item");
          var el4 = dom.createTextNode("Total To pay ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "currencyConverter__item--featured currencyConverter__item--highlight");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" CAD");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "currencyConverter__section currencyConverter__section--grey form__element--60");
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "currencyConverter__element currencyConverter__element--last");
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "currencyConverter__item");
          var el4 = dom.createTextNode("Your quote will expire in ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("strong");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "id", "quoteRefresh");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" seconds");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" TODO - Change date ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0, 3]);
          var element1 = dom.childAt(element0, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "decimal-points", [["get", "exchangeRate", ["loc", [null, [63, 191], [63, 203]]], 0, 0, 0, 0], 4], [], ["loc", [null, [63, 174], [63, 207]]], 0, 0], ["content", "currency", ["loc", [null, [63, 208], [63, 220]]], 0, 0, 0, 0], ["content", "fee", ["loc", [null, [65, 133], [65, 142]]], 0, 0, 0, 0], ["content", "totalToPay", ["loc", [null, [66, 133], [66, 149]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 76,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/currency-converter.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "currencyConverter__section form__element--60");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "sendingInput");
        dom.setAttribute(el2, "class", "form__toolTip form__toolTip--inline form__element form__element--noPadding");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "id", "sendingLabel");
        dom.setAttribute(el3, "class", "form__label currencyInput__label");
        var el4 = dom.createTextNode("Sell");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "errorbox-converter");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "currency-input", [], ["senderAmountChanged", "senderAmountChanged", "amount", ["subexpr", "@mut", [["get", "senderAmount", ["loc", [null, [5, 10], [5, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "country", ["subexpr", "@mut", [["get", "senderCountry", ["loc", [null, [6, 11], [6, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "currency", ["subexpr", "@mut", [["get", "senderCurrency", ["loc", [null, [7, 12], [7, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "countries", ["subexpr", "@mut", [["get", "countries", ["loc", [null, [8, 13], [8, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "fee", ["subexpr", "@mut", [["get", "fee", ["loc", [null, [9, 7], [9, 10]]], 0, 0, 0, 0]], [], [], 0, 0], "minNumber", ["subexpr", "@mut", [["get", "minNumber", ["loc", [null, [10, 13], [10, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "maxNumber", ["subexpr", "@mut", [["get", "maxNumber", ["loc", [null, [11, 13], [11, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "lock", "true"], ["loc", [null, [3, 2], [12, 17]]], 0, 0], ["block", "if", [["get", "preview", ["loc", [null, [15, 7], [15, 14]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [15, 1], [40, 8]]]], ["block", "if", [["get", "preview", ["loc", [null, [47, 6], [47, 13]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [47, 0], [75, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});