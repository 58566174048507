define('vopay_app/components/account-standing', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		actions: {
			currencyChanged: function currencyChanged(value) {
				var _this = this;

				this.get("api").getAccountBalance({
					Currency: value
				}).then(function (data) {
					_this.set("accountBalance", data);
					_this.sendAction("currencyChanged", value);
				});
			}
		}
	});
});