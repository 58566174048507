define('vopay_app/helpers/local-date', ['exports', 'ember'], function (exports, _ember) {
  exports.localDate = localDate;

  function localDate(params /*, hash*/) {

    var a = window.moment.utc(params[0]);
    var newDate = a.utc().format('YYYY-MM-DD HH:mm:ss');
    return newDate;
  }

  exports['default'] = _ember['default'].Helper.helper(localDate);
});