define('vopay_app/routes/dashboard/bulk-payment/templates/edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			this.get("userPermissions").HasPermissionRoute("CreatePayments");
			var authed = true;
			if (authed) {

				return _ember['default'].RSVP.hash({
					key: id["template"],
					template: this.get("firebase").getSingleRecordUser("templates", id["template"])
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});