define('vopay_app/routes/dashboard/request-accounts/details', ['exports', 'ember', 'vopay_app/utils/date-util'], function (exports, _ember, _vopay_appUtilsDateUtil) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    userPermissions: _ember['default'].inject.service('user-permissions'),
    datetime: _vopay_appUtilsDateUtil['default'].create().dateStringMin(new Date(), true),
    dateinfuture: _vopay_appUtilsDateUtil['default'].create().dateStringMin(_vopay_appUtilsDateUtil['default'].create().future(new Date(), 2), true),
    noSendNow: "true",
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    hideSendNow: (function () {
      _ember['default'].$("#sendNow").hide();
      _ember['default'].$(".dashboardDd--sendNow").css("margin-right", "0px");
    }).on('activate'),
    model: function model(id) {
      this.get("userPermissions").HasPermissionRoute("ViewTransactionHistory");
      var authed = true;
      var Currency = "CAD";
      if (id['currency']) {
        Currency = id['currency'];
      }
      var options = {};
      var options2 = {};
      options.TransactionID = id['transactionId'];
      options.Currency = Currency;
      var options3 = {};
      options3.TransactionID = id['transactionId'];
      options3.StartDateTime = "2016-01-01";
      options3.Currency = Currency;
      var link = window.localStorage.getItem("link");
      window.localStorage.removeItem("link");
      options3.Currency = Currency;
      if (authed) {
        if (id['type'] === "Transaction" && id["transactionId"]) {
          if (id['secondaryType'] === "EFT Withdrawal") {
            id['secondaryType'] = "Withdraw";
          }
          if (id['secondaryType'] === "EFT Funding") {
            id['secondaryType'] = "Fund";
          }

          if (id['secondaryType'] === "Fund") {
            return _ember['default'].RSVP.hash({
              transaction: this.get("api").getFundTransaction(options),
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: id['secondaryType'],
              errors: this.get("api").getFailedTransactions(options3),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          }
          if (id['secondaryType'] === "Withdraw") {
            return _ember['default'].RSVP.hash({
              transaction: this.get("api").getWithdrawTransaction(options),
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: id['secondaryType'],
              errors: this.get("api").getFailedTransactions(options3),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          } else if (id['secondaryType'] === "Reversal") {
            return _ember['default'].RSVP.hash({
              transactionNote: this.get("api").getAccountTransactions(options3),
              transaction: this._retrieveTransactionData(id['transaction']),
              type2: "Reversal",
              errors: this.get("api").getFailedTransactions(options3),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          } else if (id['secondaryType'] === "Other") {
            return _ember['default'].RSVP.hash({
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: id['secondaryType'],
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          } else if (id['secondaryType'] === "Conversion") {
            return _ember['default'].RSVP.hash({
              transaction: this.get("api").getGCMTransaction(options),
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: "Currency Conversion",
              errors: this.get("api").getFailedTransactions(options2),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          } else if (id['secondaryType'] === "eTransfer") {
            return _ember['default'].RSVP.hash({
              transaction: this.get("api").getInteracBulkTransaction(options),
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: "Interac Bulk Payout",
              errors: this.get("api").getFailedTransactions(options2),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          } else if (id['secondaryType'] === "Interac") {
            return _ember['default'].RSVP.hash({
              transaction: this.get("api").getInteracTransaction(options),
              transactionNote: this.get("api").getAccountTransactions(options3),
              type: "Interac Money Request",
              errors: this.get("api").getFailedTransactions(options2),
              noSendNow: this.get('noSendNow'),
              LinkPage: link
            });
          }
        } else if (id['type'] === "Schedule" && id["transactionId"]) {
          var optionsSched = {
            ScheduledTransactionID: id["transactionId"]
          };
          var optionsPaylink = {
            PaylinkRequestID: id["paylinkId"]
          };
          return _ember['default'].RSVP.hash({
            transaction: id['paylinkId'],
            Paylinks: this.get("api").getPaylinkHistory(optionsPaylink),
            type: "Schedule",
            ScheduledTransaction: this.get('api').getScheduleTransactions(optionsSched),
            noSendNow: this.get('noSendNow'),
            LinkPage: link
          });
        } else if (id['type'] === "Beneficiary") {
          var optionsPaylink = {
            PaylinkRequestID: id["paylinkId"]
          };
          return _ember['default'].RSVP.hash({
            transaction: id['paylinkId'],
            Paylinks: this.get("api").getPaylinkHistory(optionsPaylink),
            type: "Beneficiary",
            noSendNow: this.get('noSendNow'),
            LinkPage: link
          });
        } else {
          var optionsPaylink = {
            PaylinkRequestID: id["paylinkId"]
          };
          return _ember['default'].RSVP.hash({
            transaction: id['paylinkId'],
            Paylinks: this.get("api").getPaylinkHistory(optionsPaylink),
            type: "Paylink",
            errors: this.get("api").getFailedTransactions(options3),
            noSendNow: this.get('noSendNow'),
            LinkPage: link
          });
        }
      } else {
        this.transitionTo('login');
      }
    },
    _retrieveTransactionType: function _retrieveTransactionType(id) {
      var _this = this;

      var options3 = {};

      options3.TransactionID = id;
      options3.StartDateTime = "2016-01-01";
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get("api").getAccountTransactions(options3).then(function (data) {
          var options4 = {};

          options4.TransactionID = data.Transactions[0].ParentTransactionID;
          options4.StartDateTime = "2016-01-01";
          _this.get("api").getAccountTransactions(options4).then(function (data2) {
            var transaction = null;
            if (data2.Transactions[0].TransactionID == data.Transactions[0].ParentTransactionID) {
              transaction = data2.Transactions[0];
            } else {
              transaction = data2.Transactions[1];
            }
            var type = "";

            if (transaction.TransactionType == "EFT Withdrawal") {
              type = "Withdraw";
            } else if (transaction.TransactionType == "EFT Funding") {
              type = "Fund";
            } else if (transaction.TransactionType == "Currency Conversion") {
              type = "Conversion";
            } else if (transaction.TransactionType == "Interac Money Request") {
              type = "Interac Money Request";
            } else if (transaction.TransactionType == "Interac Bulk Payout") {
              type = "Interac Bulk Payout";
            } else {
              type = "";
            }
            resolve(type);
          });
        });
      });
    },
    _retrieveTransactionData: function _retrieveTransactionData(id) {
      var _this2 = this;

      var options3 = {};
      options3.TransactionID = id;
      options3.StartDateTime = "2016-01-01";
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this2.get("api").getAccountTransactions(options3).then(function (data) {
          var options4 = {};
          options4.TransactionID = data.Transactions[0].ParentTransactionID;
          options4.StartDateTime = "2016-01-01";
          _this2.get("api").getAccountTransactions(options4).then(function (data2) {
            var transaction = null;
            if (data2.Transactions[0].TransactionID == data.Transactions[0].ParentTransactionID) {
              transaction = data2.Transactions[0];
            } else {
              transaction = data2.Transactions[Object.keys(data2.Transactions).length - 1];
            }
            var type = "";
            if (transaction.TransactionType == "EFT Withdrawal") {
              type = "Withdraw";
              var options5 = {};
              options5.TransactionID = transaction.TransactionID;
              _this2.get("api").getWithdrawTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "EFT Funding") {
              type = "Fund";
              var options5 = {};
              options5.TransactionID = transaction.TransactionID;
              _this2.get("api").getFundTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Currency Conversion") {
              type = "Conversion";
              var options5 = {};
              options5.TransactionID = transaction.TransactionID;
              _this2.get("api").getGCMTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Interac Money Request") {
              type = "Interac Money Request";
              var options5 = {};
              options5.TransactionID = transaction.TransactionID;
              _this2.get("api").getInteracTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Interac Bulk Payout") {
              type = "Interac Bulk Payout";
              var options5 = {};
              options5.TransactionID = transaction.TransactionID;
              _this2.get("api").getInteracBulkTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else {
              reject();
            }
          });
        });
      });
    },

    _parsePaymentData: function _parsePaymentData(payment) {

      var paymentObject = {};
      var parsedPayment = {};

      paymentObject = JSON.parse(payment);

      for (var key in paymentObject) {
        var iKey = key.replace(/_+/g, "");
        parsedPayment[iKey] = paymentObject[key];
      }

      return parsedPayment;
    },

    _retrievePayment: function _retrievePayment(trans) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this3.get('api').getPaymentMethods().then(function (data) {

          var payment = {};
          var payments = trans.PaymentMethod === 'Bank' ? data.BankAccounts : data.CreditCards;

          for (var key in payments) {

            if (trans.PaymentMethodID === payments[key].BankAccountID || trans.PaymentMethodID === payments[key].CreditCardID) {

              payment = payments[key];
              break;
            }
          }

          trans.payment = payment;
          resolve(trans);
        });
      });
    },

    _retrieveRecipient: function _retrieveRecipient(trans) {
      var _this4 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (!trans) {
          location.href = "/#/dashboard/transactions";
        }
        _this4.get('api').listRecipients().then(function (data) {

          var recipient = {};

          for (var key in data) {
            if (trans.RemittanceRecipientID === data[key].RemittanceRecipientID) {

              data[key].BankAccount = _this4._parsePaymentData(data[key].BankAccount);
              recipient = data[key];
              break;
            }
          }
          trans.recipient = recipient;
          resolve(trans);
        });
      });
    },

    _setupTransaction: function _setupTransaction(trans) {
      var _this5 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {

        // Status transaction
        switch (trans.RemittanceStatus) {//pending, cancelled or processed
          case 'pending':
            trans.StatusTrans = 'in progress';
            break;
          case 'cancelled':
            trans.StatusTrans = 'cancelled';
            break;
          case 'failed':
            trans.StatusTrans = 'failed';
            break;
          case 'successful':
            trans.StatusTrans = 'completed';
            break;
          default:
            trans.StatusTrans = 'in progress';
            break;
        }

        // Payment methods
        switch (trans.PaymentMethod) {//pending, cancelled or processed
          case 'bank':
            trans.PaymentMethodClass = 'bankDeposit';
            trans.PaymentMethodLabel = 'bank deposit';
            break;
          case 'CreditCard':
            trans.PaymentMethodClass = 'creditCard';
            trans.PaymentMethodLabel = 'credit card';
            break;
          default:
            trans.PaymentMethodClass = 'bankDeposit';
            trans.PaymentMethodLabel = 'bank deposit';
            break;
        }
        var params = {
          SendingAmount: trans.AmountSendCAD,
          SendingCurrency: 'CAD',
          RecipientCurrency: trans.ReceiveCurrency,
          PaymentMethod: 'Bank', // TODO - Fixed payment method
          DeliveryMethod: trans.recipient.DeliveryMethod
        };
        _this5.get('api').getQuotes(params).then(function (data) {
          trans.EstimatedAmount = data.StandardQuote.RecipientAmount;
          resolve(trans);
        });
      });
    },

    _retrieveTransaction: function _retrieveTransaction(id) {
      var _this6 = this;

      return this.get('api').getTransactions(id).then(function (trans) {
        return _this6._retrieveRecipient(trans);
      }).then(function (trans) {
        return _this6._setupTransaction(trans);
      }).then(function (trans) {
        return _this6._retrievePayment(trans);
      });
    }

  });
});