define('vopay_app/components/order-status', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		CurrentCurrency: "CAD",
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.set("CurrentCurrency", this.get("DefaultCurrency"));
			var options = {};
			options.Currency = this.get("DefaultCurrency");
			var Country = localStorage.getItem("Country");
			this.get("api").getAccountTransactions(options).then(function (data) {
				_this.set("transactions", data.Transactions);
				var transactions = _this.get("transactions");

				if (transactions) {
					var transactions3 = {};
					var keys = Object.keys(transactions);
					for (var i = 0; i < keys.length && i < _this.get("limit"); i++) {
						if (Country == "US") {
							if (transactions[i].TransactionType == "EFT Withdrawal") {
								_this.set("transactions." + i + ".TransactionType", "ACH Withdrawal");
							} else if (transactions[i].TransactionType == "EFT Funding") {
								_this.set("transactions." + i + ".TransactionType", "ACH Funding");
							}
						}
						var next = i + 1;
						transactions3[next] = transactions[i];
					}
					_this.set("transactions", transactions3);
				}
			});
			this.get("api").getGCMCurriencies().then(function (data) {

				_this.set("currencies", data.CurrenciesList);
			});
		},
		actions: {
			currencyChanged: function currencyChanged(value) {
				var _this2 = this;

				this.set("CurrentCurrency", value);
				var options = {};
				options.Currency = value;
				this.get("api").getAccountTransactions(options).then(function (data) {
					_this2.set("transactions", data.Transactions);
					var transactions = _this2.get("transactions");

					if (transactions) {
						var transactions3 = {};
						var keys = Object.keys(transactions);
						for (var i = 0; i < keys.length && i < _this2.get("limit"); i++) {
							var next = i + 1;
							transactions3[next] = transactions[i];
						}
						_this2.set("transactions", transactions3);
					}
				});
			}
		}
	});
});