define('vopay_app/components/accounting-software-page', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
    var authorizationUrl = _vopay_appConfigEnvironment['default'].vopay360.authorizationUrl;
    exports['default'] = _ember['default'].Component.extend({
        routing: _ember['default'].inject.service('-routing'),
        api: _ember['default'].inject.service('api'),
        hasNoAccountingSoftware: false,
        AccountingSystemIntegrationEnabled: false,
        AccountingSoftwareIntegrationEnabled: false,
        AuthorizationUrl: authorizationUrl,
        ConnectionStatus: "Pending",
        ApiKey: "",
        init: function init() {
            this._super.apply(this, arguments);
            this.set('AccountingSoftwareIntegrationEnabled', localStorage.getItem("AccountingSoftwareIntegrationEnabled") === '1');
            this.set('AccountingSystemIntegrationEnabled', localStorage.getItem("AccountingSystemIntegrationEnabled") === '1');
            if (Object.keys(this.get("ConnectedAccounts.Accounts")).length === 0) {
                this.set("hasNoAccountingSoftware", true);
            }
            this.set("ApiKey", localStorage.getItem("key"));
            if (this.get("AccountingSystemIntegrationEnabled") && this.get("AccountingSystemUser")) {
                var AccountingSystemUser = this.get("AccountingSystemUser");
                var ConnectedAccountingSystemUser = AccountingSystemUser.erp_organisation_details.find(function (org) {
                    return org.status != 0;
                });
                this.set("ExeroOrganizationName", "");

                if (AccountingSystemUser.status == 1 && ConnectedAccountingSystemUser == null) {
                    this.set("ConnectionStatus", "Disconnected");
                } else if (AccountingSystemUser.status == 0 && ConnectedAccountingSystemUser == null) {
                    this.set("ConnectionStatus", "Disconnected");
                } else if (AccountingSystemUser.status == 1 && ConnectedAccountingSystemUser.status == 1) {
                    this.set("ConnectionStatus", "Connected");
                    this.set("ExeroOrganizationName", ConnectedAccountingSystemUser.organisation_name);
                } else if (AccountingSystemUser.status == 1 && (typeof ConnectedAccountingSystemUser.status === 'undefined' || ConnectedAccountingSystemUser.status === null) || ConnectedAccountingSystemUser.status == 3) {
                    this.set("ConnectionStatus", "Pending");
                } else if (AccountingSystemUser.status == 0 && AccountingSystemUser.status == 1) {
                    this.set("ConnectionStatus", "NotAvailable");
                } else {
                    this.set("ConnectionStatus", "Disconnected");
                }

                this.set("ConnectedAccountingSystemUser", ConnectedAccountingSystemUser);
            } else {
                this.set("ConnectionStatus", "NotAvailable");
            }
        },
        actions: {
            showRailz: function showRailz() {
                this.get("routing").transitionTo("dashboard.integrations.accounting-software.connect");
            },
            connectAccountingSoftware: function connectAccountingSoftware(code) {
                window.open(this.get("AuthorizationUrl") + '/' + code + '/' + this.get("ApiKey"), "_blank");
            },
            showDisconnectPopup: function showDisconnectPopup(code) {
                this.set("code", code);
                this.set("isDisconnectModal", true);
            },
            disconnectAccountingSoftware: function disconnectAccountingSoftware() {
                var _this = this;

                var options = {
                    account: this.get("code")
                };
                this.set("isDisconnectModal", false);
                this.get("api").disconnectAccountingSystemVoPay360(options).then(function () {
                    _this.get('api').getUserDetialsVoPay360().then(function (data) {
                        _this.set("AccountingSystemUser", data.data);
                    }, function (error) {});
                }, function (error) {
                    _this.set("isError", true);
                    _this.set("ErrorMessage", error);
                });
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            closeDisconnectModal: function closeDisconnectModal() {
                this.set("isDisconnectModal", false);
            }
        }
    });
});