define('vopay_app/components/payment-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		isSend: true,
		date: _ember['default'].computed(function () {
			var date = new Date();
			return date;
		}),
		init: function init() {
			this._super.apply(this, arguments);
			// get Countries from the API
		},
		didInsertElement: function didInsertElement() {
			var date = new Date();
			if (date.getUTCHours() >= 19 || date.getUTCHours() < 7) {
				date.setTime(date.getTime() + 43200000);
			}
			this.$('input[name="date"]').datepicker({
				startDate: date
			});
		},
		actions: {
			changeRecieve: function changeRecieve() {
				this.set("isSend", false);
			},
			changeSend: function changeSend() {
				this.set("isSend", true);
			},
			addAccount: function addAccount() {
				if (this.get("isSend") === true) {
					sessionStorage.setItem("isDeposit", this.get("isSend"));
					sessionStorage.setItem("returnUrl", window.location.href);
				}
				this.get("routing").transitionTo("dashboard.add-beneficiary");
			}
		}
	});
});