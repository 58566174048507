define('vopay_app/helpers/check-array', ['exports', 'ember'], function (exports, _ember) {
  exports.checkArray = checkArray;

  function checkArray(params /*, hash*/) {
    if (Object.keys(params[0]).length == 0) {
      return true;
    } else {
      return false;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(checkArray);
});