define('vopay_app/helpers/currency-fullname', ['exports', 'ember'], function (exports, _ember) {
		exports.currencyFullname = currencyFullname;

		function currencyFullname(params /*, hash*/) {
				var currencies = {

						CAD: {
								name: 'Canadian Dollar',
								iso3: 'CAD'
						},

						EUR: {
								name: 'Euro',
								iso3: 'EUR'
						},

						GBP: {
								name: 'Pound Sterling',
								iso3: 'GBP'
						},

						USD: {
								name: 'United States Dollar',
								iso3: 'USD'
						}
				};
				return currencies[params[0]].name;
		}

		exports['default'] = _ember['default'].Helper.helper(currencyFullname);
});