define('vopay_app/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'vopay_app/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsBase, _vopay_appConfigEnvironment) {

  // let endpoint = 'http://dev1.vopay.com/api';
  var earthnode = _vopay_appConfigEnvironment['default'].earthnode;

  /***
  * setCookie
  * @param token (String) - Token value
  *
  * Sets the token cookie
  */

  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    //default restore function
    api: Ember.inject.service('api'),
    userPermissions: Ember.inject.service('user-permissions'),
    datadog: Ember.inject.service('datadog'),
    userAccounts: Ember.inject.service('user-accounts'),
    restore: function restore() {
      var _this = this;

      //checks if the user is already authed by trying to access the /user endpoint
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // jshint ignore:line

        var url = earthnode + '/account/balance';

        Ember.$.ajax({
          url: url,
          method: 'POST',
          headers: { "Authorization": 'Bearer ' + localStorage.getItem("JWT") }
        }).done(function (data) {
          if (data.Success === true) {
            _this.get("datadog").setUser(localStorage.getItem("Username"), localStorage.getItem("key"), localStorage.getItem("signature"), localStorage.getItem("accountID"), localStorage.getItem("AccountName"));
            resolve(data);
          } else {
            _this.get("datadog").clearUser();
            reject(data.ErrorMessage);
          }
        }).fail(function (err) {
          _this.get("datadog").clearUser();
          reject(err);
        });
      });
    },
    //default authenticate function
    authenticate: function authenticate(options) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // jshint ignore:line

        var url = earthnode + '/portal/login';

        Ember.$.ajax({
          url: url,
          method: 'POST',
          data: {
            Username: options.Username,
            Password: options.Password,
            JWToken: options.JWToken
          }
        }).done(function (data) {
          if (data.Success === true) {
            (function () {
              localStorage.setItem("signature", data.Signature);
              if (options.JWToken) {
                localStorage.setItem("key", data.Key);
                localStorage.setItem("accountID", data.AccountID);
                localStorage.setItem("AccountName", data.AccountName);
                localStorage.setItem("JWT", data.JWT);
              }
              localStorage.setItem("EFTCollectEnabled", data.EFTCollectEnabled);
              localStorage.setItem("EFTSendEnabled", data.EFTSendEnabled);
              localStorage.setItem("ACHFundEnabled", data.ACHFundEnabled);
              localStorage.setItem("ACHWithdrawEnabled", data.ACHWithdrawEnabled);
              localStorage.setItem("GCMEnabled", data.GCMEnabled);
              localStorage.setItem("UserManagementEnabled", data.UserManagementEnabled);
              localStorage.setItem("InteracMoneyRequestEnabled", data.InteracMoneyRequestEnabled);
              localStorage.setItem("USDEFTCollectEnabled", data.USDEFTCollectEnabled);
              localStorage.setItem("USDEFTSendEnabled", data.USDEFTSendEnabled);
              localStorage.setItem("InteracBulkPayoutEnabled ", data.InteracBulkPayoutEnabled);
              localStorage.setItem("PortalViewOnly", data.PortalViewOnly);
              localStorage.setItem("SubAccountPermissions", data.SubAccountPermissions);
              localStorage.setItem("ClientAccountsEnabled", data.ClientAccountsEnabled);
              localStorage.setItem("TransactionLabelOverrideEnabled", data.TransactionLabelOverrideEnabled);
              localStorage.setItem("VisaDirectEnabled", data.VisaDirectEnabled);
              localStorage.setItem("CreditCardEnabled", data.CreditCardEnabled);
              localStorage.setItem("AccountingSoftwareIntegrationEnabled", data.AccountingSoftwareIntegrationEnabled);
              localStorage.setItem("Country", data.Country);
              localStorage.setItem("AccountType", data.AccountType);
              localStorage.setItem("RTRWithdrawEnabled", data.RTRWithdrawEnabled);
              localStorage.setItem("Domain", data.Domain);
              _this2.get("userAccounts").SetAccounts(data.AdditionalAccounts);
              var self = _this2;
              window.firebase.auth().signInWithCustomToken(data.FirebaseToken).then(function (data1) {
                // Handle Errors here.
                localStorage.setItem("user", window.firebase.auth().currentUser.uid);
                if (options.JWToken) {
                  self.get("api").getAccountPermissions().then(function (AccountPermission) {
                    self.get("userPermissions").SetPermissions(AccountPermission.Permissions);
                    resolve(data);
                  }, function (error) {
                    reject(error);
                  });
                } else {
                  resolve(data);
                }
                // ...
              });
            })();
          } else {

              reject(data.ErrorMessage);
            }
        }).fail(function (err) {

          reject(err);
        });
      });
    },

    //default invalidate method
    invalidate: function invalidate() {
      window.sift_id = null;
      window.session_id = null;
    }
  });
});
/* global Ember */