define('vopay_app/controllers/change-password', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		oldPassword: "",
		newPassword: "",
		actions: {
			changePassword: function changePassword() {
				var self = this;
				var options = {};
				options.oldPassword = this.get("oldPassword");
				options.password1 = this.get("newPassword");
				options.password2 = this.get("newPassword");
				this.get('api').changePassword(options).then(function (data) {

					self.get("routing").transitionTo("login");
				});
			}
		}
	});
});