define('vopay_app/routes/dashboard/support/tickets-list', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Route.extend({
      api: _ember['default'].inject.service('api'),
      userPermissions: _ember['default'].inject.service('user-permissions'),
      model: function model() {
         this.get("userPermissions").HasPermissionRoute("ViewSupportTickets");
         return _ember['default'].RSVP.hash({
            TicketList: this.get('api').getTickets()
         });
      }
   });
});