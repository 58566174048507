define('vopay_app/routes/dashboard/file-upload/details', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    userPermissions: _ember['default'].inject.service('user-permissions'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model(id) {
      this.get("userPermissions").HasPermissionRoute("ViewBulkFileUpload");
      var authed = true;
      if (authed) {
        return _ember['default'].RSVP.hash({
          fileUploadDetails: this.get('api').getUploadedFileDetails(id["BatchTransactionRequestID"]),
          batches: this.get('api').getUploadedFiles(),
          BatchTransactionRequestID: id["BatchTransactionRequestID"]
        });
      } else {
        this.transitionTo('login');
      }
    }

  });
});