define('vopay_app/routes/dashboard/batch-file', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        session: _ember['default'].inject.service('session'),
        api: _ember['default'].inject.service('api'),
        userPermissions: _ember['default'].inject.service('user-permissions'),
        model: function model(params) {
            this.get("userPermissions").HasPermissionRoute("ViewReconciliation");
            var authed = this.get('session.isAuthenticated');
            if (authed) {
                var batchOptions = {
                    BatchID: params["BatchID"],
                    TransactionType: params["TransactionType"],
                    PaymentType: params["PaymentType"]
                };
                return _ember['default'].RSVP.hash({
                    transactions: this.get('api').getBatchTransactions(batchOptions),
                    BatchID: params["BatchID"],
                    TransactionType: params["TransactionType"],
                    PaymentType: params["PaymentType"],
                    Amount: params["Amount"]
                });
            } else {
                this.transitionTo('login');
            }
        }
    });
});