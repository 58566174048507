define('vopay_app/components/payment-methods', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		init: function init() {
			this._super.apply(this, arguments);
			var count = 0;
			var paymethods = this.get("paymethods.BankAccounts");

			if (paymethods) {
				var paymethods2 = {};
				for (var k in paymethods) {
					if (paymethods.hasOwnProperty(k)) {
						++count;
					}
				}
				for (var j in paymethods) {
					if (paymethods.hasOwnProperty(j)) {
						var next = count - 1;
						paymethods2[next] = paymethods[j];
						--count;
					}
				}
				this.set("paymethods.BankAccounts", paymethods2);
			}
		},
		actions: {
			addBank: function addBank() {
				this.get("routing").transitionTo("dashboard.account.payment.new");
			},
			deleteBank: function deleteBank(id) {
				var _this = this;

				var options = {};
				options.BankAccountID = id;
				this.get("api").deletePaymentMethod(options).then(function (data) {
					if (data.error) {
						_this.handleErrors(null, data.error);
					}
				}, function (error) {
					_this.handleErrors(null, error);
				});
				var paymethods2 = {};
				var paymethods = this.get("paymethods.BankAccounts");
				var count = 0;
				for (var j in paymethods) {
					if (paymethods[j].BankAccountID === id) {} else {
						paymethods2[count] = paymethods[j];
						count++;
					}
				}
				this.set("paymethods.BankAccounts", paymethods2);
			}
		}
	});
});