define("vopay_app/helpers/batch-status", ["exports", "ember"], function (exports, _ember) {
  exports.batchStatus = batchStatus;

  function batchStatus(params /*, hash*/) {
    var NumRecords = parseFloat(params[0]);
    var TotalCreated = parseFloat(params[1]);
    var TotalFailed = parseFloat(params[2]);
    var TotalSubmitted = parseFloat(params[3]);
    if (TotalSubmitted === 0) {
      return "Processed";
    } else {
      return "Submitted";
    }
  }

  exports["default"] = _ember["default"].Helper.helper(batchStatus);
});