define("vopay_app/templates/dashboard/convert-pay", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 140
          }
        },
        "moduleName": "vopay_app/templates/dashboard/convert-pay.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "convert-pay-overview", [], ["currencies", ["subexpr", "@mut", [["get", "model.currencies", ["loc", [null, [1, 34], [1, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "accountBalance", ["subexpr", "@mut", [["get", "model.accountBalance", ["loc", [null, [1, 66], [1, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "model.errors", ["loc", [null, [1, 94], [1, 106]]], 0, 0, 0, 0]], [], [], 0, 0], "transactions", ["subexpr", "@mut", [["get", "model.transactions", ["loc", [null, [1, 120], [1, 138]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 140]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});