define('vopay_app/routes/dashboard/confirmation', ['exports', 'ember', 'vopay_app/utils/date-util'], function (exports, _ember, _vopay_appUtilsDateUtil) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    datetime: _vopay_appUtilsDateUtil['default'].create().dateStringMin(new Date(), true),
    dateinfuture: _vopay_appUtilsDateUtil['default'].create().dateStringMin(_vopay_appUtilsDateUtil['default'].create().future(new Date(), 2), true),
    noSendNow: "true",
    userPermissions: _ember['default'].inject.service('user-permissions'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    hideSendNow: (function () {
      _ember['default'].$("#sendNow").hide();
      _ember['default'].$(".dashboardDd--sendNow").css("margin-right", "0px");
    }).on('activate'),
    loader: _ember['default'].inject.service('loader'),
    beforeModel: function beforeModel() {
      this.get("loader").UpdateLoading(true);
    },
    afterModel: function afterModel() {
      this.get("loader").UpdateLoading(false);
    },
    model: function model(id, _ref) {
      var queryParams = _ref.queryParams;

      //this.get("userPermissions").HasPermissionRoute("ViewTransactionHistory");

      var Country = localStorage.getItem("Country");
      var authed = true;
      var Currency = "CAD";
      if (id['currency']) {
        Currency = id['currency'];
      }

      if (id['currency'] === "undefined") {
        if (Country == "US") {
          Currency = "USD";
        } else {
          Currency = "CAD";
        }
      }
      var BearerToken = localStorage.getItem("subAccount");
      var subaccountsURL = localStorage.getItem("currentUrlSubAccount");

      var options = {};
      var options2 = {};
      options.TransactionID = id['transaction'];
      options.Currency = Currency;
      if (queryParams.isJWT) {
        options.BearerToken = BearerToken;
      }
      var options3 = {};
      options3.TransactionID = id['transaction'];
      options3.StartDateTime = "2016-01-01";
      options3.Currency = Currency;
      if (queryParams.isJWT) {
        options3.BearerToken = BearerToken;
      }
      var link = window.localStorage.getItem("link");
      window.localStorage.removeItem("link");
      options3.Currency = Currency;
      if (authed) {

        if (id['type'] === "Fund") {

          options3.TransactionType = "EFT Funding";
          if (localStorage.getItem("Country") === "US") {
            options3.TransactionType = "ACH Funding";
          }
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getFundTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: id['type'],
            errors: this.get("api").getFailedTransactions(options3),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "VoPayInstantWithdraw") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getRTRWithdrawTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "VoPayInstant Withdraw",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "CreditCard") {
          return _ember['default'].RSVP.hash({
            transaction: this._retrieveCreditCardData(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Credit Card",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Reversal") {
          return _ember['default'].RSVP.hash({
            transactionNote: this.get("api").getAccountTransactions(options3),
            transaction: this._retrieveTransactionData(id['transaction'], queryParams.isJWT),
            type2: "Reversal",
            errors: this.get("api").getFailedTransactions(options3),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Other") {
          return _ember['default'].RSVP.hash({
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: id['type'],
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Refund" || id['type'] === "Withdraw" && Country == "US") {

          return _ember['default'].RSVP.hash({
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Refund",
            secondaryType: id['type'] === "Withdraw" ? "Withdraw" : null,
            transaction: this._retrieveTransactionRefund(id['transaction'], queryParams.isJWT),
            errors: this.get("api").getFailedTransactions(options3),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Conversion") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getGCMTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Currency Conversion",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "VisaDirect") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").visaDirectTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Visa Direct",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "eTransfer") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getInteracBulkTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Interac Bulk Payout",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Interac") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getInteracTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: "Interac Money Request",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else if (id['type'] === "Inbound_e-Transfer") {
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getInteracInbound(options),
            transactionNote: this.get("api").getAccountTransactions(options3, queryParams.isJWT),
            type: "Inbound e-Transfer",
            errors: this.get("api").getFailedTransactions(options2),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        } else {
          if (localStorage.getItem("Country") === "US") {
            options3.TransactionType = "ACH Withdrawal";
          }
          return _ember['default'].RSVP.hash({
            transaction: this.get("api").getWithdrawTransaction(options),
            transactionNote: this.get("api").getAccountTransactions(options3),
            type: id['type'],
            errors: this.get("api").getFailedTransactions(options3),
            noSendNow: this.get('noSendNow'),
            Id: id['transaction'],
            isJWT: queryParams.isJWT,
            subaccountsURL: subaccountsURL,
            LinkPage: link
          });
        }
      } else {
        this.transitionTo('login');
      }
    },
    _retrieveCreditCardData: function _retrieveCreditCardData(options) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get("api").GetCreditCardTransaction(options).then(function (data) {
          data.TransactionType = "Credit Card";

          _this.get("api").getCreditCards(false, data.CreditCardToken).then(function (data2) {
            var CreditCard = data2.CreditCards[0];
            if (CreditCard) {
              data.CreditCardBrand = CreditCard.CreditCardBrand;
              data.CreditCardExpiryMonth = CreditCard.CreditCardExpiryMonth;
              data.CreditCardExpiryYear = CreditCard.CreditCardExpiryYear;
              data.CreditCardHolderName = CreditCard.CreditCardHolderName;
              data.CreditCardNumber = CreditCard.CreditCardNumber;
              resolve(data);
            } else {
              _this.get("api").getCreditCards(true, data.CreditCardToken).then(function (data3) {
                var CreditCard2 = data3.CreditCards[0];
                if (CreditCard2) {
                  data.CreditCardBrand = CreditCard2.CreditCardBrand;
                  data.CreditCardExpiryMonth = CreditCard2.CreditCardExpiryMonth;
                  data.CreditCardExpiryYear = CreditCard2.CreditCardExpiryYear;
                  data.CreditCardHolderName = CreditCard2.CreditCardHolderName;
                  data.CreditCardNumber = CreditCard2.CreditCardNumber;
                }
                resolve(data);
              }, function (error2) {
                reject(error2);
              });
            }
          }, function (error) {
            _this.get("api").getCreditCards(true, data.CreditCardToken).then(function (data3) {
              var CreditCard2 = data3.CreditCards[0];
              if (CreditCard2) {
                data.CreditCardBrand = CreditCard2.CreditCardBrand;
                data.CreditCardExpiryMonth = CreditCard2.CreditCardExpiryMonth;
                data.CreditCardExpiryYear = CreditCard2.CreditCardExpiryYear;
                data.CreditCardHolderName = CreditCard2.CreditCardHolderName;
                data.CreditCardNumber = CreditCard2.CreditCardNumber;
              }
              resolve(data);
            }, function (error2) {
              reject(error2);
            });
          });
        }, function (error) {

          reject(error);
        });
      });
    },
    _retrieveTransactionType: function _retrieveTransactionType(id, isJWT) {
      var _this2 = this;

      var options3 = {};

      options3.TransactionID = id;
      options3.StartDateTime = "2016-01-01";
      var BearerToken = localStorage.getItem("subAccount");
      if (isJWT) {
        options3.BearerToken = BearerToken;
      }
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this2.get("api").getAccountTransactions(options3).then(function (data) {
          var options4 = {};

          options4.TransactionID = data.Transactions[0].ParentTransactionID;
          options4.StartDateTime = "2016-01-01";
          _this2.get("api").getAccountTransactions(options4).then(function (data2) {
            var transaction = null;
            if (data2.Transactions[0].TransactionID == data.Transactions[0].ParentTransactionID) {
              transaction = data2.Transactions[0];
            } else {
              transaction = data2.Transactions[1];
            }
            var type = "";

            if (transaction.TransactionType == "EFT Withdrawal") {
              type = "Withdraw";
            } else if (transaction.TransactionType == "EFT Funding") {
              type = "Fund";
            } else if (transaction.TransactionType == "Currency Conversion") {
              type = "Conversion";
            } else if (transaction.TransactionType == "Interac Money Request") {
              type = "Interac Money Request";
            } else if (transaction.TransactionType == "Interac Bulk Payout") {
              type = "Interac Bulk Payout";
            } else {
              type = "";
            }
            resolve(type);
          });
        });
      });
    },
    _retrieveTransactionRefund: function _retrieveTransactionRefund(id, isJWT) {
      var _this3 = this;

      var options3 = {};
      options3.TransactionID = id;
      var BearerToken = localStorage.getItem("subAccount");
      if (isJWT) {
        options3.BearerToken = BearerToken;
      }
      var self = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this3.get("api").getWithdrawTransaction(options3).then(function (data3) {
          data3.TransactionType = "EFT Withdrawal";
          resolve(data3);
        }, function (error) {
          _this3.get("api").getFundTransaction(options3).then(function (data4) {
            data4.TransactionType = "EFT Funding";
            resolve(data4);
          }, function (error2) {

            _this3._retrieveCreditCardData(options3).then(function (data5) {
              data5.TransactionType = "Credit Card";
              resolve(data5);
            }, function (error3) {

              _this3.get("api").getAccountTransactions(options3).then(function (data6) {
                var Keys = Object.keys(data6.Transactions);

                for (var i = 0; i < Keys.length; i++) {

                  if (data6.Transactions[i].TransactionID == id) {
                    data6.Transactions[i].TransactionType = "Other Refund";
                    resolve(data6.Transactions[i]);
                  }
                }
                reject(error3);
              });
            });
          });
        });
      });
    },
    _retrieveTransactionData: function _retrieveTransactionData(id, isJWT) {
      var _this4 = this;

      var options3 = {};
      options3.TransactionID = id;
      options3.StartDateTime = "2016-01-01";
      var BearerToken = localStorage.getItem("subAccount");
      if (isJWT) {
        options3.BearerToken = BearerToken;
      }
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this4.get("api").getAccountTransactions(options3).then(function (data) {
          var options4 = {};

          options4.TransactionID = data.Transactions[0].ParentTransactionID;
          options4.StartDateTime = "2016-01-01";
          if (isJWT) {
            options4.BearerToken = BearerToken;
          }
          _this4.get("api").getAccountTransactions(options4).then(function (data2) {
            var transaction = null;
            if (data2.Transactions[0].TransactionID == data.Transactions[0].ParentTransactionID) {
              transaction = data2.Transactions[0];
            } else {
              transaction = data2.Transactions[Object.keys(data2.Transactions).length - 1];
            }
            var type = "";
            if (transaction.TransactionType == "EFT Withdrawal" || transaction.TransactionType == "ACH Withdrawal") {
              type = "Withdraw";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getWithdrawTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "EFT Funding" || transaction.TransactionType == "ACH Funding") {
              type = "Fund";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getFundTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Currency Conversion") {
              type = "Conversion";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getGCMTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Interac Money Request") {
              type = "Interac Money Request";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getInteracTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Interac Bulk Payout") {
              type = "Interac Bulk Payout";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getInteracBulkTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Inbound e-Transfer") {
              type = "Inbound e-Transfer";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getInteracInbound(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "Credit Card") {
              type = "Credit Card";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4._retrieveCreditCardData(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                data3.Type = transaction.TransactionType;
                resolve(data3);
              });
            } else if (transaction.TransactionType == "VoPayInstant Withdraw") {
              type = "VoPayInstant Withdraw";
              var options5 = {};
              if (isJWT) {
                options5.BearerToken = BearerToken;
              }
              options5.TransactionID = transaction.TransactionID;
              _this4.get("api").getRTRWithdrawTransaction(options5).then(function (data3) {
                data3.TransactionType = transaction.TransactionType;
                resolve(data3);
              });
            } else {
              reject();
            }
          });
        });
      });
    },

    _parsePaymentData: function _parsePaymentData(payment) {

      var paymentObject = {};
      var parsedPayment = {};

      paymentObject = JSON.parse(payment);

      for (var key in paymentObject) {
        var iKey = key.replace(/_+/g, "");
        parsedPayment[iKey] = paymentObject[key];
      }

      return parsedPayment;
    },

    _retrievePayment: function _retrievePayment(trans) {
      var _this5 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this5.get('api').getPaymentMethods().then(function (data) {

          var payment = {};
          var payments = trans.PaymentMethod === 'Bank' ? data.BankAccounts : data.CreditCards;

          for (var key in payments) {

            if (trans.PaymentMethodID === payments[key].BankAccountID || trans.PaymentMethodID === payments[key].CreditCardID) {

              payment = payments[key];
              break;
            }
          }

          trans.payment = payment;
          resolve(trans);
        });
      });
    },

    _retrieveRecipient: function _retrieveRecipient(trans) {
      var _this6 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (!trans) {
          location.href = "/#/dashboard/transactions";
        }
        _this6.get('api').listRecipients().then(function (data) {

          var recipient = {};

          for (var key in data) {
            if (trans.RemittanceRecipientID === data[key].RemittanceRecipientID) {

              data[key].BankAccount = _this6._parsePaymentData(data[key].BankAccount);
              recipient = data[key];
              break;
            }
          }
          trans.recipient = recipient;
          resolve(trans);
        });
      });
    },

    _setupTransaction: function _setupTransaction(trans) {
      var _this7 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {

        // Status transaction
        switch (trans.RemittanceStatus) {//pending, cancelled or processed
          case 'pending':
            trans.StatusTrans = 'in progress';
            break;
          case 'cancelled':
            trans.StatusTrans = 'cancelled';
            break;
          case 'failed':
            trans.StatusTrans = 'failed';
            break;
          case 'successful':
            trans.StatusTrans = 'completed';
            break;
          default:
            trans.StatusTrans = 'in progress';
            break;
        }

        // Payment methods
        switch (trans.PaymentMethod) {//pending, cancelled or processed
          case 'bank':
            trans.PaymentMethodClass = 'bankDeposit';
            trans.PaymentMethodLabel = 'bank deposit';
            break;
          case 'CreditCard':
            trans.PaymentMethodClass = 'creditCard';
            trans.PaymentMethodLabel = 'credit card';
            break;
          default:
            trans.PaymentMethodClass = 'bankDeposit';
            trans.PaymentMethodLabel = 'bank deposit';
            break;
        }
        var params = {
          SendingAmount: trans.AmountSendCAD,
          SendingCurrency: 'CAD',
          RecipientCurrency: trans.ReceiveCurrency,
          PaymentMethod: 'Bank', // TODO - Fixed payment method
          DeliveryMethod: trans.recipient.DeliveryMethod
        };
        _this7.get('api').getQuotes(params).then(function (data) {
          trans.EstimatedAmount = data.StandardQuote.RecipientAmount;
          resolve(trans);
        });
      });
    },

    _retrieveTransaction: function _retrieveTransaction(id) {
      var _this8 = this;

      return this.get('api').getTransactions(id).then(function (trans) {
        return _this8._retrieveRecipient(trans);
      }).then(function (trans) {
        return _this8._setupTransaction(trans);
      }).then(function (trans) {
        return _this8._retrievePayment(trans);
      });
    }

  });
});