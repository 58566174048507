define("vopay_app/templates/components/faq-page", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/faq-page.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "FileInput");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "dashboard__section dashboard__section--grey dashboard__section--first dashboard__section--heading");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4, "class", "dashboard__header");
        var el5 = dom.createTextNode("FAQ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form dashboard__section  dashboard__section--body");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "dashboard__form accordion");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5, "id", "templateForm");
        var el6 = dom.createTextNode("\n					");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form__element form__element--60 form__element--block");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("How do I reset my password?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Log in to the VoPay client dashboard > Open the little arrow on the top right side under your account name > Open Change Password\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        If you have forgotten your password, please reach out to the account owner to reset your password using the steps above or send an email to ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "mailto:helpdesk@copay.com");
        var el10 = dom.createTextNode("helpdesk@vopay.com");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" and someone from VoPay will reset it for you.\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("How do I add another user?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Log in to the VoPay client dashboard > Open the little arrow on the top right side under your account name > Open 'User Management' and you can add/delete or change a user's password here by clicking on the associated buttons or links. If you do not see this menu, please contact support at ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "mailto:helpdesk@copay.com");
        var el10 = dom.createTextNode("helpdesk@vopay.com");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("Why is my transaction still in progress?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Please refer to the link below for EFT processing and cut-off times.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "target", "_blank");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "https://docs.vopay.com/docs/eft#processing-times");
        var el10 = dom.createTextNode("EFT / ACH - VoPay");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("How do I cancel an Interac e-Transfer transaction?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Once an e-Transfer request has been processed and the specified transfers have been created, the sender can cancel them up until they're picked up by the recipient, through the submission of a cancel transfer request.  Follow the steps below to cancel.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        Log into the VoPay client dashboard > Open 'Statements and Reports' on the left pane > Open 'Transaction History' and locate the transaction you would like to cancel.  Furthest to the right, click on the 'Details' button.  Next, click on the 'Cancel' button.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        If a transaction is already accepted and the deposit has been made there is no way to cancel the transaction.\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("What will happen if the recipient doesn't accept the funds via Interac e-Transfer?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("The transaction expires in 30 days. Upon expiration, funds that were sent will automatically be redeposited into the sender's account.\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("What's the funding time for Bulk e-Transfer?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Clients can initiate and receive payments 24/7/365. Transactions are processed in real-time and funds are available immediately so that businesses can access these funds when they need them.\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("What does this error mean, 'This user is invalid or inactive', when trying to log into the Client dashboard?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Please make sure you are using the correct environment and log in credentials. Sandbox environment credentials cannot be used for the Production environment and vice-versa.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        Production - ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "target", "_blank");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "https://dashboard.vopay.com/#/login");
        var el10 = dom.createTextNode("https://dashboard.vopay.com");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        Sandbox  - ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "target", "_blank");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "https://dashboard-dev.vopay.com/#/login");
        var el10 = dom.createTextNode("https://dashboard-dev.vopay.com");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("What does it mean by 'Failed by auto process cron job'?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("In the Sandbox environment, transactions are processed based on the amount entered.  The transaction status will change based on the decimal portion of its amount (cents).  If you want your transactions to fall in the next statuses, you can refer to the link below for the list.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "target", "_blank");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "https://docs.vopay.com/docs/sandbox");
        var el10 = dom.createTextNode("Sandbox - VoPay");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "container");
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "accordion-title");
        var el9 = dom.createElement("b");
        var el10 = dom.createTextNode("Where can I find a list of error codes if a transaction fails?");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9, "class", "fas fa-chevron-down");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "content");
        var el9 = dom.createTextNode("Please refer to the link below and locate the 'Error Codes' section.\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9, "target", "_blank");
        dom.setAttribute(el9, "onclick", "event.stopPropagation()");
        dom.setAttribute(el9, "href", "https://docs.vopay.com/docs/eft#processing-times");
        var el10 = dom.createTextNode("EFT / ACH - VoPay");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                     ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                     ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("hr");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n               ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n				");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3, 1, 1, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var element4 = dom.childAt(element0, [7]);
        var element5 = dom.childAt(element0, [9]);
        var element6 = dom.childAt(element0, [11]);
        var element7 = dom.childAt(element0, [13]);
        var element8 = dom.childAt(element0, [15]);
        var element9 = dom.childAt(element0, [17]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element1, 'onclick');
        morphs[1] = dom.createAttrMorph(element2, 'onclick');
        morphs[2] = dom.createAttrMorph(element3, 'onclick');
        morphs[3] = dom.createAttrMorph(element4, 'onclick');
        morphs[4] = dom.createAttrMorph(element5, 'onclick');
        morphs[5] = dom.createAttrMorph(element6, 'onclick');
        morphs[6] = dom.createAttrMorph(element7, 'onclick');
        morphs[7] = dom.createAttrMorph(element8, 'onclick');
        morphs[8] = dom.createAttrMorph(element9, 'onclick');
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [10, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [21, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [26, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [34, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [44, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [50, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [57, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [67, 71]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleFaq"], [], ["loc", [null, [null, null], [75, 71]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});