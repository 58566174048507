define('vopay_app/controllers/dashboard/transactions', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['_ga'],
		_ga: null,
		resetController: function resetController(controller) {
			var queryParams = controller.get('queryParams');
			queryParams.forEach(function (param) {
				controller.set(param, null);
			});
		}

	});
});