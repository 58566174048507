define('vopay_app/components/voucher-history', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		keyword: "",
		type: null,
		date: null,
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,
		isShowingModal: false,
		watchdate: _ember['default'].observer('date', function () {
			var start = localStorage.getItem("startDate");
			var end = localStorage.getItem("endDate");
			localStorage.setItem("startDate", "");
			localStorage.setItem("endDate", "");
			this.refreshTransactions(start, end);
		}),
		didInsertElement: function didInsertElement() {
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				startDate: window.moment().subtract(6, 'days'),
				endDate: window.moment(),
				ranges: {
					'Today': [window.moment(), window.moment()],
					'Yesterday': [window.moment().subtract(1, 'days'), window.moment().subtract(1, 'days')],
					'Last 7 Days': [window.moment().subtract(6, 'days'), window.moment()],
					'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
					'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
					'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});
			localStorage.setItem("startDate", window.moment().subtract(6, 'days').format('YYYY-MM-DD'));
			localStorage.setItem("endDate", window.moment().format('YYYY-MM-DD'));
			_ember['default'].$('input[name="daterange"]').val(window.moment().subtract(6, 'days').format('MM/DD/YYYY') + " - " + window.moment().format('MM/DD/YYYY'));
			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
				localStorage.setItem("startDate", picker.startDate.format('YYYY-MM-DD'));
				localStorage.setItem("endDate", picker.endDate.format('YYYY-MM-DD'));
				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY'));
				_ember['default'].$('input[name="daterange"]').trigger("change");
			});
		},
		init: function init() {

			this._super.apply(this, arguments);
			var count = 0;
			var transactions = this.get("transactions");

			if (transactions) {
				var transactions2 = {};
				for (var k in transactions) {
					if (transactions.hasOwnProperty(k)) {
						++count;
					}
				}
				if (count === 0) {
					this.set("isShowingModal", true);
				} else {
					this.set("isShowingModal", false);
				}
				for (var j in transactions) {
					if (transactions.hasOwnProperty(j)) {
						var next = count - 1;
						transactions2[next] = transactions[j];
						--count;
					}
				}
				this.set("transactions", transactions2);
			}
			this.getTransactions(1, this.get("transactions"));
		},
		refreshTransactions: function refreshTransactions(startDate, endDate) {
			var _this = this;

			var options = {};
			options.StartDateTime = startDate;
			options.EndDateTime = endDate;
			this.get("api").getVopinTransactions(options).then(function (data) {
				var count = 0;
				var transactions = data.Transactions;

				if (transactions) {
					var transactions2 = {};
					for (var k in transactions) {
						if (transactions.hasOwnProperty(k)) {
							++count;
						}
					}
					for (var j in transactions) {
						if (transactions.hasOwnProperty(j)) {
							var next = count - 1;
							transactions2[next] = transactions[j];
							--count;
						}
					}
					_this.set("transactions", transactions2);
				}
				_this.filterTransactions(_this.get("keyword"), _this.get("type"), _this.get("transactions"), 1);
			});
		},
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
					var next = i + 1;
					filteredList[next] = transactions[i];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(searchTerms, type, transactions, page) {
			var filteredList = {};
			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.replace(/\$/g, '');
			searchTerms = searchTerms.replace(/\,/g, '');
			if (transactions) {
				var keys = Object.keys(transactions);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[i] !== 'undefined') {
						if (type === null || type === "" || type === "All" || type === transactions[i].TransactionType) {

							if (transactions[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms.toLowerCase())) {

								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].ClientReferenceNumber !== null && transactions[i].ClientReferenceNumber.toLowerCase().replace(/\s|_/g, "").includes(searchTerms.toLowerCase())) {

								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].PIN.toLowerCase().replace(/\s|_/g, "").includes(searchTerms.toLowerCase())) {

								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].Amount !== null && transactions[i].Amount.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (searchTerms === null || searchTerms === "") {
								next = next + 1;
								filteredList[next] = transactions[i];
							}
						}
					}
				}
				_ember['default'].$('#searchTransactions').addClass('searchHide');
				this.set("filteredList", filteredList);
				this.getTransactions(page, this.get("filteredList"));
			}
		},
		actions: {
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			getPage: function getPage(pageNumber) {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("transactions"), pageNumber);
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("transactions"), 1);
			}
		}
	});
});