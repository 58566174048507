define('vopay_app/components/signing-authority-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		ownerNumbers: ["1"],
		ownerCurrNum: 1,
		ownerTypeSelected: false,
		ownerTypes: ["Individual", "Corperate"],

		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updateSigningAuthority: function updateSigningAuthority() {
				this.get("routing").transitionTo("dashboard.account.business-profile");
			},
			ownerTypeSelected: function ownerTypeSelected(value) {
				_ember['default'].$("#ownerType").val(value);
				_ember['default'].$("#ownerType").parsley().validate();

				this.set('ownerTypeSelected', true);
				if (value === "Individual") {
					this.set('isIndividual', true);
				} else {
					this.set('isIndividual', false);
				}
			},
			addOwner: function addOwner() {
				this.set("ownerCurrNum", this.get("ownerCurrNum") + 1);
				this.get('ownerNumbers').pushObject(this.get("ownerCurrNum"));
			},
			removeOwner: function removeOwner() {
				this.set('ownerNumbers', this.get('ownerNumbers').slice(0, -1));
				this.set("ownerCurrNum", this.get("ownerCurrNum") - 1);
			}
		}
	});
});