define('vopay_app/routes/dashboard/settings/webhooks', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		firebase: _ember['default'].inject.service('firebase'),
		model: function model() {
			this.get("userPermissions").HasPermissionRoute("ViewSettings");
			return _ember['default'].RSVP.hash({
				webhook: this.get('api').getWebhook()
			});
		}
	});
});