define('vopay_app/components/view-credentials', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
    var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
    var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

    exports['default'] = _ember['default'].Component.extend({
        SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
        LongName: businessLongName,
        ShortName: businessShortName,
        firebase: _ember['default'].inject.service('firebase'),
        routing: _ember['default'].inject.service('-routing'),
        api: _ember['default'].inject.service('api'),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("api").getAutoBankTransfer().then(function (data) {
                if (data.Status == "cancelled") {
                    _this.set("NoTransfer", true);
                    _this.set("loaded", true);
                } else {
                    _this.set("transaction", data);
                    _this.set("loaded", true);
                }
            }, function (error) {
                _this.set("NoTransfer", true);
                _this.set("loaded", true);
            });
        },
        actions: {
            deleteItem: function deleteItem() {
                this.set("isDeleteModal", true);
            },
            moveToEdit: function moveToEdit() {
                this.get("routing").transitionTo("dashboard.balance-transfer.update");
            },
            moveToCreate: function moveToCreate() {
                this.get("routing").transitionTo("dashboard.balance-transfer.create");
            },
            deleteAccount: function deleteAccount() {
                var _this2 = this;

                this.get("api").cancelAutoBankTransfer().then(function (data) {
                    _this2.set("isDeleteModal", false);
                    window.location.reload();
                });
            },
            closeDeleteModal: function closeDeleteModal() {
                this.set("isDeleteModal", false);
            }

        }
    });
});