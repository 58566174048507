define("vopay_app/helpers/single-params-builder", ["exports", "ember"], function (exports, _ember) {
  exports.singleParamsBuilder = singleParamsBuilder;

  function singleParamsBuilder(params /*, hash*/) {
    var name = params[0];
    var value = params[1];
    if (value) {
      return "?" + name + "=" + value;
    } else {
      return "";
    }
  }

  exports["default"] = _ember["default"].Helper.helper(singleParamsBuilder);
});