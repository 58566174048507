define('vopay_app/components/reset-password-form', ['exports', 'ember'], function (exports, _ember) {
				exports['default'] = _ember['default'].Component.extend({
								routing: _ember['default'].inject.service('-routing'),
								api: _ember['default'].inject.service('api'),
								loader: _ember['default'].inject.service('loader'),
								errorMsg: "",
								validPassword: false,
								didInsertElement: function didInsertElement() {
												_ember['default'].$('form').parsley({
																successClass: " ",
																errorClass: "form__input--error",
																errorsWrapper: "<p></p>",
																errorTemplate: "<span class='form__message'></span>"
												});
												_ember['default'].$("input").keyup(function () {
																_ember['default'].$(this).addClass("form__input--active");
												});
												_ember['default'].$("input").focusout(function () {
																_ember['default'].$(this).removeClass("form__input--active");
												});
								},
								actions: {
												resetPassword: function resetPassword() {
																var _this = this;

																var TwoFAData = _ember['default'].$('#ResetPasswordForm').serializeArray();
																var arrLength = TwoFAData.length;
																var options = {};
																var form = _ember['default'].$('#ResetPasswordForm');
																form.parsley().validate();

																if (form.parsley().isValid() && this.get("validPassword")) {
																				this.get("loader").UpdateLoading(true);
																				//set all the form data

																				for (var i = 0; i < arrLength; i++) {
																								options[TwoFAData[i].name] = TwoFAData[i].value;
																				}
																				options.PasswordToken = this.get("PasswordToken");
																				this.get("api").resetPassword(options).then(function () {
																								_this.get("loader").UpdateLoading(false);
																								_this.get("routing").transitionTo("login");
																				}, function (error) {
																								_this.get("loader").UpdateLoading(false);
																								_this.set('errorMsg', error);
																				});
																}
												},
												isValidHandler: function isValidHandler(isValid) {
																this.set("validPassword", isValid);
												}

								}
				});
});