define('vopay_app/components/change-phone', ['exports', 'ember', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler) {
  exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
    routing: _ember['default'].inject.service('-routing'),
    phoneNumber: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
      _ember['default'].$("input").keyup(function () {
        _ember['default'].$(this).addClass("form__input--blueActive");
      });
      _ember['default'].$("input").focusout(function () {
        _ember['default'].$(this).removeClass("form__input--blueActive");
      });
    },

    watchPhoneNumber: _ember['default'].observer('phoneNumber', function () {

      this.set('phoneNumber', this.get('entryUtil').onlyNumbers(this.get('phoneNumber')));
    }),

    actions: {

      changeNumber: function changeNumber() {
        var _this = this;

        var self = this;
        var options = {
          phonenumber: self.get('phoneNumber')
        };

        var form = _ember['default'].$('#phoneNumberForm');
        form.parsley().validate();

        if (form.parsley().isValid()) {
          this.get('api').updateUser(options).then(function () {

            self.get('routing').transitionTo('dashboard.account.contact');
          }, function (err) {

            _this.handleErrors(null, err);
          });
        }

        return false;
      }
    }
  });
});