define('vopay_app/router', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {

  var siftApiKey = _vopay_appConfigEnvironment['default'].siftApiKey;

  _ember['default'].Router.reopen({

    notifySiftScience: (function () {
      var _user_id = '';
      // Set to the user's ID, username, or email address, or '' if not yet known.

      if (window.sift_id) {
        _user_id = window.sift_id;
      }

      var _session_id = ''; // Set to a unique session ID for the visitor's current browsing session.

      if (window.session_id) {
        _session_id = window.session_id;
      }

      var _sift = window._sift = window._sift || [];
      _sift.push(['_setAccount', siftApiKey]);
      _sift.push(['_setUserId', _user_id]);
      _sift.push(['_setSessionId', _session_id]);
      _sift.push(['_trackPageview']);

      return;
    }).on('didTransition')
  });

  var Router = _ember['default'].Router.extend({
    location: _vopay_appConfigEnvironment['default'].locationType,
    rootURL: _vopay_appConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('transfer', { path: '/signup' });
    this.route('login');
    // this.route('transfer-complete');
    //this.route('reset');
    //this.route('forgot-password');
    // this.route('signup');
    //this.route('change-password');
    this.route('home', { path: '/' });
    this.route('dashboard', { path: '/dashboard' }, function () {
      this.route('transactions');
      this.route('confirmation', { path: '/transaction/:type/:transaction' });
      this.route('confirmation', { path: '/transaction/:type/:transaction/:currency' });
      // this.route('account', {path: '/account'}, function() {
      //   this.route('contact');
      //   this.route('create');
      //   this.route('password', {path: '/change/password'});
      //   this.route('address', {path: '/change/address'});
      //   this.route('phonenumber', {path: '/change/phonenumber'});
      //   this.route('payment', function() {
      //     this.route('new');
      //   });
      // });
      // this.route('overview'); //To be added once flow is sorted out later
      // this.route('recipientUpdateInfo', {path: '/transactions/:transaction/recipient'});
      // this.route('recipients', function() {
      //   this.route('new');
      // });
      // this.route('payment-update-info', {path: '/transactions/:transaction/payment'});
      this.route('info', { path: '/' });
      //this.route('user-transactions');
      this.route('add-account', { path: '/payment' });
      //this.route('payments');
      //this.route('bank');
      //this.route('home');

      // this.route('account',{path:'/profile'}, function() {
      //   this.route('business-profile');
      //   this.route('primary-contact');
      //   this.route('documents');
      //   this.route('directors');
      //   this.route('change-primary-contact', {path:'/primary-contact/edit'});
      //   this.route('change-directors', {path:'/directors/edit'});
      //   this.route('change-business-details' , {path:'/business-details/edit'});
      //   this.route('change-signing-authorties' , {path:'/signing-authorties/edit'});
      //   this.route('change-mailing-address' , {path:'/mailing-address/edit'});
      //   this.route('change-product-and-services', {path:'/product-and-services/edit'});
      // });
      this.route('convert-pay');
      this.route('conversions');
      //this.route('e-voucher');
      // this.route('add-beneficiary');
      this.route('change-password');
      this.route('balances');
      this.route('fund');
      this.route('beneficiaries', function () {
        this.route('details', { path: '/details/:user' });
        this.route('details', { path: '/details/:user/:type/:token' });
        this.route('create');
        this.route('edit', { path: '/edit/:user' });
        this.route('file');
        this.route('template');
      });

      this.route('accounts', function () {
        this.route('details', { path: '/details/:user' });
        this.route('details', { path: '/details/:user/:type/:token' });
        this.route('edit', { path: '/edit/:user' });
        this.route('create');
      });
      this.route('benefactor');
      this.route('collect');
      this.route('bulk-payment', function () {
        this.route('templates', function () {
          this.route('create');
          this.route('edit', { path: '/edit/:template' });
          this.route('template', { path: '/template/:template' });
        });
        this.route('file');
        this.route('create');
        this.route('edit', { path: '/edit/:template' });
        this.route('group', { path: '/group/:template' });
      });
      this.route('bulk-collect', function () {
        this.route('create');
        this.route('edit', { path: '/edit/:template' });
        this.route('group', { path: '/group/:template' });
      });
      this.route('link-accounts', function () {
        this.route('link');
        this.route('update', { path: '/update/:user' });
        this.route('details', { path: '/details/:user' });
        this.route('details', { path: '/details/:user/:type/:token' });
      });
      this.route('file-upload', function () {
        this.route('upload-file');
        this.route('file-history');
        this.route('details', { path: '/details/:BatchTransactionRequestID' });
        this.route('file-template');
      });

      this.route('integrations', function () {
        this.route('Plaid');
        this.route('Flinks');
        this.route('inverite', { path: '/Inverite' });
        this.route('global-pay');
        this.route('accounting-software', function () {
          this.route('connect');
        });
      });
      this.route('settings', function () {
        this.route('webhooks');
        this.route('paylink');
        this.route('authorized-ips');
        this.route('paylink');
      });
      this.route('support', function () {
        this.route('submit-ticket');
        this.route('tickets-list');
        this.route('ticket-details', { path: '/ticket-details/:ticketID' });
        this.route('faq');
      });
      this.route('sub-accounts', function () {
        this.route('create');
        this.route('view', { path: '/view/:accountID/:currency' });
      });
      this.route('client-accounts', function () {
        this.route('create');
        this.route('view');
        this.route('transfer-funds');
      });
      this.route('transfer');
      this.route('subaccount-info');
      this.route('permissions', function () {
        this.route('edit', { path: '/edit/:accountID' });
      });

      this.route('sub-account', function () {});
      this.route('withdraw');

      this.route('schedule', function () {
        this.route('details', { path: '/details/:scheduleId' });
      });
      this.route('balance-transfer', function () {
        this.route('update');
        this.route('create');
      });

      this.route('request-accounts', { path: "/paylink-accounts" }, function () {
        this.route('details', { path: '/details/:type/:paylinkId' });
        this.route('details', { path: '/details/:type/:paylinkId/:transactionId/' });
        this.route('details', { path: '/details/:type/:paylinkId/:transactionId/:secondaryType' });
      });
      this.route('bank-transfer');
      this.route('users', function () {
        this.route('permissions', { path: "/permissions/:Id" });
      });
      this.route('account-billing');
      this.route('reconciliation');
      this.route('accounting-software', function () {
        this.route('invoices', { path: "/receivables" });
        this.route('bills', { path: "/payables" });
      });
      this.route('batch-file', { path: '/batch-file/:TransactionType/:BatchID/:PaymentType/:Amount' });
    });
    this.route('error', { path: '/*path' });
    this.route('accounts');
    this.route('password-reset', { path: '/password-reset/:PasswordToken' });
    this.route('railz-close');
    this.route('railz-thank-you');

    this.route('account-renewal', { path: '/account-renewal/:Token' });

    this.route('auth-user');
  });

  exports['default'] = Router;
});