define('vopay_app/mixins/error-handler', ['exports', 'ember'], function (exports, _ember) {

  /**
  * Mixin that handle errors form the server to the fornt-end application
  * Has to be part of a component that contains route service injected and a modal template setup.
  *
  */
  exports['default'] = _ember['default'].Mixin.create({
    isShowingModal: false,
    actionName: 'close',

    _extractNameFromElement: function _extractNameFromElement(form, error) {

      var arrError = error.msg.split(' ');
      var fieldName = '';
      var last = '';

      form.forEach(function (field) {

        arrError.map(function (err) {

          var rawstr = err.replace(/\W/g, '');
          var patt = new RegExp(rawstr.toLowerCase() || 'null');
          var patt2 = new RegExp(last.toLowerCase() || 'null');
          if (patt.test(field.name.toLowerCase()) && (patt2.test(field.name.toLowerCase()) || !last)) {
            last = last + "" + rawstr;
            fieldName = field.name;
          }
        });
      });
      return fieldName;
    },

    _loginRedirection: function _loginRedirection() {

      location.reload(false);
    },

    /***
    * handleErrors
    *
    * Responsable to retrieve error messages that comes from the Components
    * @param error - Object that contain error info
    *
    */
    handleErrors: function handleErrors(form, error) {
      var _this = this;

      var self = this;

      if (error.Authentication) {

        this._loginRedirection(error.Authentication[0]);
        return;
      }

      if (error.msg) {
        var _ret = (function () {
          if (error.msg === "Error : BIC/SWIFT is missing") {

            error.msg = "Error: Invalid IBAN";
          }

          var fieldName = _this._extractNameFromElement(form, error);

          // Include error msg  below the input field
          if (fieldName) {

            if (_ember['default'].$('input[name=\'' + fieldName + '\']')) {

              var fieldError = _ember['default'].$('input[name=\'' + fieldName + '\']').parsley();
              fieldError.reset();
              fieldError.addError(fieldName, { message: error.msg });
              _ember['default'].$('input[name=\'' + fieldName + '\']').addClass("form__input--error2");
              _ember['default'].$('input[name=\'' + fieldName + '\']').focus();
              //append error class

              _ember['default'].$('input[name=\'' + fieldName + '\']').change(function () {
                _ember['default'].$('input[name=\'' + fieldName + '\']').parsley().reset();
                _ember['default'].$('input[name=\'' + fieldName + '\']').removeClass("form__input--error2");
                //error class
              });
            } else {

                _this.set('message', error.msg);
                _this.set('request', error.request);
                _this.set('isShowingModal', true);
              }
          } else {
            // Display modal error message if doesn't find the field

            _this.set('message', error.msg);
            _this.set('request', error.request);
            _this.set('actionName', 'Try again');
            _this.set('isShowingModal', true);
            setTimeout(function () {
              _ember['default'].$('.ember-modal-dialog__btn').on('click', function () {
                self.get('routing').transitionTo('transfer'); // TODO -- Send to appropriate route
              });
            }, 1000);
          }

          return {
            v: undefined
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }

      this.set('message', error);
      this.set('isShowingModal', true);
      _ember['default'].$("#stepFourBtn").removeAttr('disabled');
    }
  });
});