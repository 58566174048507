define('vopay_app/routes/dashboard/link-accounts/details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			this.get("userPermissions").HasPermissionRoute("ViewBeneficiaries");
			if (id["token"]) {
				var options = {
					Token: id["token"]
				};
				if (id["type"] == 'CreditCard') {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getCreditCards(true, id["token"]),
						Type: id["type"],
						key: id["user"]
					});
				} else {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getBankAccounts(true, id["token"]),
						//bankTransactions: this.get("api").getIQ11Transactions({Token: id["token"]}),
						Type: id["type"],
						key: id["user"]
					});
				}
			} else {
				return _ember['default'].RSVP.hash({
					currencies: this.get('api').getGCMCurriencies(),
					beneficiary: this.get("firebase").getSingleRecordUser("linkedAccount", id["user"]),
					Type: "etransfer",
					key: id["user"]
				});
			}
		}
	});
});