define('vopay_app/components/account-overview', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		currency: "CAD",
		actions: {
			currencyChanged: function currencyChanged(value) {
				var _this = this;

				var options = {};
				options.Currency = value;
				this.get("api").getAccountTransactions(options).then(function (data) {
					_this.set("transactions", data);
				});
			}
		}
	});
});