define('vopay_app/helpers/camel-case', ['exports', 'ember'], function (exports, _ember) {
	exports.camelCase = camelCase;

	function camelCase(params /*, hash*/) {
		var str = params[0];
		if (str !== undefined) {
			return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
				return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
			}).replace(/\s|\-+/g, '');
		}
	}

	exports['default'] = _ember['default'].Helper.helper(camelCase);
});