define('vopay_app/components/transfer-summary', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    notMenuActive: true,
    isDisabled: false,
    newPromo: true,
    displayAmount: _ember['default'].computed('lastAmount', function () {

      return this.get('lastAmount');
    }),
    toggleMenu: function toggleMenu() {
      if (!this.get('notMenuActive')) {
        this.set('notMenuActive', true);
      } else {
        this.set('notMenuActive', false);
      }
    },
    togglePromo: function togglePromo() {
      if (!this.get('hasPromo')) {
        this.set('hasPromo', true);
      } else {
        this.set('hasPromo', false);
      }
    },
    toggleNewPromo: function toggleNewPromo() {
      if (!this.get('newPromo')) {
        this.set('newPromo', true);
      } else {
        this.set('newPromo', false);
      }
    },
    toggleDetails: function toggleDetails() {
      _ember['default'].$(event.target).toggleClass('open');
      if (_ember['default'].$(event.target).hasClass('open')) {
        _ember['default'].$(event.target).html('<span>-</span>Show Less Details');
      } else {
        _ember['default'].$(event.target).html('<span>+</span>More Details');
      }
      _ember['default'].$('.transferSummary__section--animate').toggleClass('open');
      _ember['default'].$('.transferSummary__promo').toggleClass('open');
      _ember['default'].$('#transferSummary').toggleClass('open');
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (window.innerWidth <= 890) {
        this.set('notMenuActive', true);
        this.set('isDisabled', false);
      } else {
        this.set('notMenuActive', false);
        this.set('isDisabled', true);
      }
    },
    /***
     * handles screen resize
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).scroll(function () {
        if (_ember['default'].$(window).scrollTop() <= 0) {

          _ember['default'].$("#transferSummary").addClass("transferSummary--noScroll");
        } else {
          _ember['default'].$("#transferSummary").removeClass("transferSummary--noScroll");
        }
      });
    },

    watchDiscount: _ember['default'].observer('discount', function () {
      if (this.get("discount") <= 0 || !this.get("discount")) {} else {
        this.togglePromo();
        this.toggleNewPromo();
      }
    }),

    boundResizeHandler: null,

    handleResize: function handleResize() {
      if (window.innerWidth <= 890) {
        this.set('isDisabled', false);
        this.set('notMenuActive', true);
      } else {
        this.set('isDisabled', true);
        this.set('notMenuActive', false);
      }
    },

    bindResizeEvent: (function () {
      this.set('boundResizeHandler', _ember['default'].run.bind(this, this.handleResize));
      _ember['default'].$(window).on('resize', this.get('boundResizeHandler'));
    }).on('init'),

    watchOverflow: _ember['default'].observer('isDisabled', 'notMenuActive', function () {
      if (!this.get("isDisabled") && !this.get("notMenuActive")) {
        _ember['default'].$("body").addClass("transferSummary__body--overflowHidden");
        _ember['default'].$("html").addClass("transferSummary__body--overflowHidden");
      } else {
        _ember['default'].$("body").removeClass("transferSummary__body--overflowHidden");
        _ember['default'].$("html").removeClass("transferSummary__body--overflowHidden");
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).off('resize', this.get('boundResizeHandler'));
    },

    actions: {
      /***
       * toggleMenu
       *
       * Toggles the dropdown menu
       */
      activateMenu: function activateMenu() {
        if (window.innerWidth <= 890) {
          this.toggleMenu();
        }
      },
      activateNewPromo: function activateNewPromo() {
        this.toggleNewPromo();
      },
      activatePromo: function activatePromo() {

        this.sendAction("promoCodeApplied", this.get("promocode"));
      },
      removePromo: function removePromo() {
        this.togglePromo();
        this.toggleNewPromo();
        this.sendAction("promoCodeRemoved");
        this.set("promocode", "");
      },
      changeDetails: function changeDetails() {
        this.toggleDetails();
      }
    }
  });
});