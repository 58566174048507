define('vopay_app/components/payment-bank-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		actions: {
			addAccount: function addAccount() {
				sessionStorage.setItem("isDeposit", true);
				sessionStorage.setItem("returnUrl", window.location.href);
				this.get("routing").transitionTo("dashboard.add-account");
			},
			deleteAccount: function deleteAccount(id) {
				return false;
			}
		}
	});
});