define('vopay_app/components/payment-item-desktop', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
	exports['default'] = _ember['default'].Component.extend({
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		tagName: "",
		isPay: false,
		currencies: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},

			EUR: {
				name: 'Euro',
				iso3: 'EUR'
			},

			GBP: {
				name: 'Pound Sterling',
				iso3: 'GBP'
			},

			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		wachValue: _ember['default'].observer('amount', function () {
			var amount = this.get('amount');
			if (amount) {
				if (this.get("disable")) {
					this.set("amount", this.get('entryUtil').onlyCurrencySimple(amount).toLocaleString());
				} else {
					this.set('amount', this.get('entryUtil').onlyCurrency(amount));
				}
				this.set("isPay", true);
			} else {
				this.set("isPay", false);
			}

			var selector = ".dateDesktop" + this.get("payMethod.BankAccountID");
			if (!_ember['default'].$(selector).datepicker("getUTCDate")) {
				var date = new Date();
				if (date.getUTCHours() >= 19 || date.getUTCHours() < 7) {
					date.setTime(date.getTime() + 43200000);
				}
				_ember['default'].$(selector).datepicker("update", date);
			}
		})
	});
});