define('vopay_app/routes/dashboard/integrations/accounting-software/index', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		model: function model() {
			return _ember['default'].RSVP.hash({
				ConnectedAccounts: this.get('api').getRailzBusinessAccounts(),
				AccountingSystem: this.get('api').getAccountingSystemVoPay360(),
				AccountingSystemUser: this.get('api').getUserDetialsVoPay360()
			});
		}
	});
});