define('vopay_app/components/switch-banner', ['exports', 'ember', 'vopay_app/utils/cookies-manager', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appUtilsCookiesManager, _vopay_appConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        userAccounts: _ember['default'].inject.service('user-accounts'),
        cookiesmanager: _vopay_appUtilsCookiesManager['default'].create(),
        routing: _ember['default'].inject.service('-routing'),

        actions: {
            'switch': function _switch() {
                var accounts = this.get("userAccounts").GetAccounts();
                this.get("cookiesmanager").setCookie("dashboardToken", accounts[localStorage.getItem("AccountName")]);
                this.get("cookiesmanager").setCookie("dashboardUsername", localStorage.getItem("Username"));
                localStorage.removeItem("JWT");
                localStorage.removeItem("key");
                localStorage.removeItem("accountID");
                localStorage.removeItem("signature");
                window.location.href = _vopay_appConfigEnvironment['default'].switchPortalURL;
                // this.get("routing").transitionTo("auth-user")
            }
        }
    });
});