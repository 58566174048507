define('vopay_app/components/account-transfer', ['exports', 'ember', 'vopay_app/utils/entry-filter', 'vopay_app/constants'], function (exports, _ember, _vopay_appUtilsEntryFilter, _vopay_appConstants) {
	exports['default'] = _ember['default'].Component.extend({
		amount: "0.00",
		Currency: "CAD",
		CurrencyName: "Canadian Dollar",
		currencies2: _vopay_appConstants['default'].currencies,
		api: _ember['default'].inject.service('api'),
		currentTo: "",
		currentFrom: "",
		selected: false,
		routing: _ember['default'].inject.service('-routing'),

		wachValue: _ember['default'].observer('amount', function () {
			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("input")[2];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString("en-CA") + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString("en-CA") + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString("en-CA");
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		init: function init() {
			this._super.apply(this, arguments);

			var currencies2 = this.get("currencies2");
			var currencies = this.get("currencies.CurrenciesList");
			if (sessionStorage.getItem("transferCurrency") != null && sessionStorage.getItem("transferCurrency") != "") {
				this.set("selected", true);
				var currencyList = this.get("currencies2");
				this.set("Currency", sessionStorage.getItem("transferCurrency"));
				this.set("CurrencyName", currencyList[sessionStorage.getItem("transferCurrency")].name);
				sessionStorage.removeItem("transferCurrency");
			}
			this.set("subAccounts", this.get("data"));
			//this.set("ErrorMessage2", "You currently do not have any subaccount please");
			//this.set("isError2", true);
			this.setOptions();

			var currencies3 = [];
			for (var key in currencies) {
				if (currencies2[currencies[key]]) {
					currencies3[currencies[key]] = {};
					currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
					currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
				}
			}

			this.set("currencies3", currencies3);
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		setOptions: function setOptions() {
			var _this = this;

			var fromOptions = [];
			var toOptions = [];
			var subAccountList = this.get("subAccounts.Subaccounts");

			var Currency = this.get("Currency");
			var Currency2 = this.get("Currency");
			if (!Currency || Currency === "CAD" || Currency === "AUD" || Currency === "SGD" || Currency === "HKD" || Currency === "NZD") {
				Currency2 = "USD";
			}
			var formatter = new Intl.NumberFormat('en-US', {
				style: "currency",
				currency: Currency2,
				minimumFractionDigits: 2
			});
			var optionsBalance = {};
			optionsBalance.Currency = Currency;
			this.get('api').getAccountBalance(optionsBalance).then(function (data) {
				var account = {
					LegalBusinessName: localStorage.getItem("AccountName"),
					AccountID: localStorage.getItem("accountID")
				};
				if (Number(data.AvailableFunds) <= 0) {

					_this.set("max", 0);
					_this.set("amount", "0.00");
					account.AvailableBalance = 0;
					account.Label = account.LegalBusinessName + " (" + formatter.format(data.AvailableFunds) + ")";
				} else {
					_this.set("max", data.AvailableFunds);
					_this.set("amount", "0.00");
					account.AvailableBalance = data.AvailableFunds;
					account.Label = account.LegalBusinessName + " (" + formatter.format(data.AvailableFunds) + ")";
				}
				fromOptions.push(account.Label);
				toOptions.push(account.Label);
				_this.set("mainAccount", account);
				for (var key in subAccountList) {
					if (_this.hasCurrency(Currency, subAccountList[key].Balances)) {
						if (subAccountList[key] != 0 && subAccountList[key].IsActive) {
							var currencyBalance = _this.getCurrencyBalance(Currency, subAccountList[key].Balances);
							subAccountList[key].Label = subAccountList[key].LegalBusinessName + " (" + currencyBalance + ")";
							subAccountList[key].AvailableBalance = currencyBalance;
							fromOptions.push(subAccountList[key].LegalBusinessName + " (" + formatter.format(currencyBalance) + ")");
							toOptions.push(subAccountList[key].Label = subAccountList[key].LegalBusinessName + " (" + formatter.format(currencyBalance) + ")");
						}
					} else {
						var currencyBalance = 0;
						if (subAccountList[key] != 0 && subAccountList[key].IsActive) {
							subAccountList[key].Label = subAccountList[key].LegalBusinessName + " (" + formatter.format(currencyBalance) + ")";
							subAccountList[key].AvailableBalance = currencyBalance;
							fromOptions.push(subAccountList[key].LegalBusinessName + " (" + formatter.format(currencyBalance) + ")");
							toOptions.push(subAccountList[key].Label = subAccountList[key].LegalBusinessName + " (" + formatter.format(currencyBalance) + ")");
						}
					}
				}

				_this.set("fromOptions", fromOptions);
				_this.set("toOptions", toOptions);
				_this.set("fromOptions2", fromOptions);
				_this.set("toOptions2", toOptions);
				_this.set("subAccountList", subAccountList);

				if (sessionStorage.getItem("transferAccount") != null && sessionStorage.getItem("transferAccount") != "") {
					var fromArray;
					var filtered;
					var toArray;
					var filtered2;

					(function () {

						var value1 = _this.getBuisnessLabel(localStorage.getItem("accountID"));
						var value2 = _this.getBuisnessLabel(sessionStorage.getItem("transferAccount"));

						_this.set("toAccountId", sessionStorage.getItem("transferAccount"));
						_this.set("fromAccountId", localStorage.getItem("accountID"));

						fromArray = _this.get("fromOptions");
						filtered = fromArray.filter(function (val) {
							return val !== value1;
						});

						_this.set("ToOptions2", filtered);
						toArray = _this.get("toOptions");
						filtered2 = toArray.filter(function (val) {
							return val !== value2;
						});

						_this.set("fromOptions2", filtered2);

						_this.set("currentTo", value2);
						_this.set("currentFrom", value1);

						sessionStorage.removeItem("transferAccount");
					})();
				}
			});
		},
		findOption: function findOption(name) {
			var subAccountList = this.get("subAccountList");
			var mainAccount = this.get("mainAccount");
			if (mainAccount.Label == name) {
				return mainAccount.AccountID;
			}
			for (var key in subAccountList) {
				if (subAccountList[key].Label == name) {
					return subAccountList[key].AccountID;
				}
			}
		},
		findBalance: function findBalance(name) {
			var subAccountList = this.get("subAccountList");
			var mainAccount = this.get("mainAccount");
			if (mainAccount.Label == name) {
				return mainAccount.AvailableBalance;
			}
			for (var key in subAccountList) {
				if (subAccountList[key].Label == name) {
					return subAccountList[key].AvailableBalance;
				}
			}
		},
		hasCurrency: function hasCurrency(currency, balances) {
			for (var key in balances) {
				if (balances[key].Currency == currency) {
					return true;
				}
			}
		},
		getCurrencyBalance: function getCurrencyBalance(currency, balances) {
			var ctr = balances;
			for (var key in balances) {
				if (balances[key].Currency == currency) {
					return balances[key].AvailableBalance;
				}
			}
		},
		getBuisnessName: function getBuisnessName(accountID) {
			var subAccountList = this.get("subAccountList");
			var mainAccount = this.get("mainAccount");
			if (mainAccount.AccountID == accountID) {
				return mainAccount.LegalBusinessName;
			}
			for (var key in subAccountList) {
				if (subAccountList[key].AccountID == accountID) {
					return subAccountList[key].LegalBusinessName;
				}
			}
		},
		getBuisnessLabel: function getBuisnessLabel(accountID) {
			var subAccountList = this.get("subAccountList");
			var mainAccount = this.get("mainAccount");
			if (mainAccount.AccountID == accountID) {
				return mainAccount.Label;
			}
			for (var key in subAccountList) {
				if (subAccountList[key].AccountID == accountID) {
					return subAccountList[key].Label;
				}
			}
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		getFormObject: function getFormObject(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},

		actions: {
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			handleTo: function handleTo(value) {
				this.set("toAccountId", this.findOption(value));
				var fromArray = this.get("fromOptions");
				var filtered = fromArray.filter(function (val) {
					return val !== value;
				});
				this.set("fromOptions2", filtered);
				this.set("currentTo", value);
				if (value == this.get("currentFrom")) {
					this.set("currentFrom", "");
				}
			},
			handleFrom: function handleFrom(value) {
				this.set("currentFrom", value);
				this.set("fromAccountId", this.findOption(value));
				var toArray = this.get("toOptions");
				var filtered = toArray.filter(function (val) {
					return val !== value;
				});
				this.set("toOptions2", filtered);
				this.set("max", this.findBalance(value));
				if (value == this.get("currentTo")) {
					this.set("currentTo", "");
				}
			},
			CurrencyChanged: function CurrencyChanged(currency) {
				var currencyList = this.get("currencies2");
				this.set("Currency", currency);
				this.set("CurrencyName", currencyList[currency].name);
				this.set("currentTo", "");
				this.set("currentFrom", "");
				this.setOptions();
			},
			moveToPayment: function moveToPayment() {
				$("#Overview").hide();
				$("#paymentInfo").show();
			},
			overview: function overview() {
				var formData = this.getFormObject(_ember['default'].$('#paymentForm').serializeArray());
				formData.DollarAmount = _ember['default'].$("#amount").val().replace(/\,/g, "");

				if (this.validateForm("#paymentForm") && parseFloat(this.get("max")) >= parseFloat(formData.DollarAmount)) {
					var payment = {
						From: this.getBuisnessName(this.get("fromAccountId")),
						To: this.getBuisnessName(this.get("toAccountId")),
						DollarAmount: formData.DollarAmount
					};
					this.set("paymentToAdd", payment);
					$("#Overview").show();
					$("#paymentInfo").hide();
				}
			},
			transferAccount: function transferAccount() {
				var _this2 = this;

				var formData = this.getFormObject(_ember['default'].$('#paymentForm').serializeArray());
				formData.DollarAmount = _ember['default'].$("#amount").val().replace(/\,/g, "");

				if (this.validateForm("#paymentForm") && parseFloat(this.get("max")) >= parseFloat(formData.DollarAmount)) {
					var fromAccountId = this.get("fromAccountId");
					var toAccountId = this.get("toAccountId");
					var currentAccountId = localStorage.getItem("accountID");
					$('.complete').prop('disabled', true);
					if (fromAccountId == currentAccountId) {
						var options = {
							RecipientAccountID: toAccountId,
							Amount: formData.DollarAmount,
							Currency: this.get("Currency")
						};
						this.get("api").transferTo(options).then(function (data) {
							localStorage.setItem("link", window.location.href);
							var self = _this2;
							setTimeout(function () {
								window.location.href = "#/dashboard/transaction/Other/" + data.TransactionID + "/" + self.get("Currency");
							}, 1000);
						}, function (error) {
							_ember['default'].$('.complete').removeAttr('disabled');
							_this2.errorMessage(error);
						});
					} else if (toAccountId == currentAccountId) {
						var options = {
							DebitorAccountID: fromAccountId,
							Amount: formData.DollarAmount,
							Currency: this.get("Currency")
						};
						this.get("api").transferFrom(options).then(function (data) {
							localStorage.setItem("link", window.location.href);
							var self = _this2;
							setTimeout(function () {
								window.location.href = "#/dashboard/transaction/Other/" + data.TransactionID + "/" + self.get("Currency");
							}, 1000);
						}, function (error) {
							_ember['default'].$('.complete').removeAttr('disabled');
							_this2.errorMessage(error);
						});
					} else {
						(function () {
							var options = {
								DebitorAccountID: fromAccountId,
								Amount: formData.DollarAmount,
								Currency: _this2.get("Currency")
							};
							var options2 = {
								RecipientAccountID: toAccountId,
								Amount: formData.DollarAmount,
								Currency: _this2.get("Currency")
							};
							var self = _this2;
							self.get("api").transferFrom(options).then(function (data) {
								self.get("api").transferTo(options2).then(function (data) {
									localStorage.setItem("link", window.location.href);
									setTimeout(function () {
										window.location.href = "#/dashboard/transaction/Other/" + data.TransactionID + "/" + self.get("Currency");
									}, 1000);
								}, function (error) {
									_ember['default'].$('.complete').removeAttr('disabled');
									self.errorMessage(error);
								});
							}, function (error) {
								_ember['default'].$('.complete').removeAttr('disabled');
								self.errorMessage(error);
							});
						})();
					}
				}
			}
		}
	});
});