define('vopay_app/helpers/decimal-points', ['exports', 'ember'], function (exports, _ember) {
  exports.decimalPoints = decimalPoints;

  function decimalPoints(params /*, hash*/) {

    if (!params[0] || params[0] !== params[0]) {

      return 0;
    }

    if (!params[1] || params[1] !== params[1]) {

      return params[0];
    }

    var paramNumber = parseFloat(params[0]);
    var value = paramNumber.toFixed(params[1]);
    return value;
  }

  exports['default'] = _ember['default'].Helper.helper(decimalPoints);
});