define("vopay_app/templates/dashboard/add-account", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/dashboard/add-account.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "outlet", ["loc", [null, [1, 0], [1, 10]]], 0, 0, 0, 0], ["inline", "add-payment-method", [], ["paymentMethod", "Bank", "clientAccounts", ["subexpr", "@mut", [["get", "model.clientAccounts", ["loc", [null, [2, 57], [2, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "PaylinkSettings", ["subexpr", "@mut", [["get", "model.PaylinkSettings", ["loc", [null, [2, 94], [2, 115]]], 0, 0, 0, 0]], [], [], 0, 0], "userInfo", ["subexpr", "@mut", [["get", "model.userInfo", ["loc", [null, [2, 125], [2, 139]]], 0, 0, 0, 0]], [], [], 0, 0], "currencies", ["subexpr", "@mut", [["get", "model.currencies", ["loc", [null, [2, 151], [2, 167]]], 0, 0, 0, 0]], [], [], 0, 0], "bankAccounts", ["subexpr", "@mut", [["get", "model.bankAccounts", ["loc", [null, [2, 181], [2, 199]]], 0, 0, 0, 0]], [], [], 0, 0], "firebaseBeneficiaries", ["subexpr", "@mut", [["get", "model.firebaseBeneficiaries", ["loc", [null, [2, 222], [2, 249]]], 0, 0, 0, 0]], [], [], 0, 0], "firebaseLinkedAccounts", ["subexpr", "@mut", [["get", "model.firebaseLinkedAccounts", ["loc", [null, [2, 273], [2, 301]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 303]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});