define("vopay_app/helpers/iso3-country", ["exports", "ember"], function (exports, _ember) {
	exports.iso3Country = iso3Country;

	function iso3Country(params /*, hash*/) {
		var countries = params[0];
		var iso = params[1];
		if (iso === "CA") {
			return "canada";
		} else {
			var ctr = countries.filter(function (f) {
				return f.Abbreviation === iso;
			}).map(function (c) {
				return c.Country;
			});
			return ctr[0];
		}
	}

	exports["default"] = _ember["default"].Helper.helper(iso3Country);
});