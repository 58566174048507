define('vopay_app/components/export-links', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: '',

		actions: {
			pdf: function pdf() {

				var pdf = new jspdf.jsPDF('p', 'pt', 'A4');
				$("#confirmation .btn").hide();
				$("#confirmation .confirmation__btn").hide();
				$("#confirmation .dashboard__btn").hide();
				$("#confirmation #PaylinkBock").hide();
				$('.dashboardDd').hide();
				var width = pdf.internal.pageSize.width;
				var height = pdf.internal.pageSize.height;
				var node = document.getElementById('confirmation');
				//var node = document.body
				var w = node.clientWidth;
				var h = node.clientHeight;
				var scale = width / w;

				var newCanvas = document.createElement('canvas');
				//pdf.internal.scaleFactor = scale;
				newCanvas.width = w * scale;
				newCanvas.height = h * scale;
				newCanvas.style.width = w + 'px';
				newCanvas.style.height = h + 'px';
				var context = newCanvas.getContext('2d');
				pdf.html(node, {
					callback: function callback(pdf) {
						pdf.save("output.pdf");
						$("#confirmation .btn").show();
						$("#confirmation .confirmation__btn").show();
						$("#confirmation .dashboard__btn").show();
						$("#confirmation #PaylinkBock").show();
						$('.dashboardDd').show();
					},
					html2canvas: {
						backgroundColor: '#fafafd',
						windowWidth: width,
						windowHeight: height,
						width: w * scale,
						height: h * scale,
						scale: scale

					}

				});

				return false;
			}
		}
	});
});