define('vopay_app/components/payment-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //default values
    tagName: '',
    paymentMethodName: "Choose your payee",
    paymentMethod: null,
    hasDefault: _ember['default'].computed('availablePaymentMethods', 'paymentMethodName', 'paymentMethod', 'dpStateClass', 'selStateClass', function () {
      var listBanks = this.get('availablePaymentMethods.BankAccounts');
      var hasDefault = false;
      for (var key in listBanks) {

        if (listBanks[key]['IsDefault'] === "1") {
          hasDefault = false;
          break;
        }
      }
      return hasDefault;
    }),
    selStateClass: "customSelDd--grey",
    dpStateClass: "dropdownToggle--grey",
    paymentSelectClass: "globe",
    init: function init() {

      this._super.apply(this, arguments);
      if (this.get("hasDefault")) {
        var listBanks = this.get('availablePaymentMethods.BankAccounts');
        for (var key in listBanks) {
          if (listBanks[key]['IsDefault'] === "1") {
            this.set("selStateClass", "");
            this.set("dpStateClass", "");
            this.set('paymentMethodName', listBanks[key]['MaskedAccount'] + " Bank");
            this.set('paymentMethod', "Bank");
            this.set('paymentSelectClass', "bankAccount");
            this.sendAction('paymentChanged', listBanks[key]['BankAccountID'], "Bank");
            break;
          }
        }
      }
    },

    actions: {
      /***
      * paymentChanged
      * @params item (String)
      * Sets payment method
      */
      addActiveClass: function addActiveClass() {},

      paymentChanged: function paymentChanged(paymentID, accMessage, paymentType) {

        _ember['default'].$("#proof").parsley().reset();
        this.sendAction('paymentChanged', paymentID, paymentType, accMessage);
        if (accMessage === "Add New Bank Account") {
          this.set('paymentMethodName', accMessage);
        } else {
          var listBanks = this.get('availablePaymentMethods.BankAccounts');
          for (var key in listBanks) {
            if (listBanks[key]['BankAccountID'] === paymentID) {
              this.set('paymentMethodName', listBanks[key]["AccountHolderName"] + " - " + accMessage);
            }
          }
        }

        this.set('dpStateClass', "bankAccount");
        this.set('paymentSelectClass', "bankAccount");
        this.set('selStateClass', "bankAccount");
        this.set('paymentMethod', paymentType);
      },

      paymentSelChanged: function paymentSelChanged(item) {
        var listBanks = this.get('availablePaymentMethods.BankAccounts');
        var item2 = item.split(" ");
        var paymentID = item2[0];
        var paymentType = item2[1];
        var accMessage = item2[2];
        item2.shift();
        item2.shift();

        _ember['default'].$("#proof").parsley().reset();
        this.sendAction('paymentChanged', paymentID, paymentType);
        if (paymentID === "new") {
          this.set('paymentMethodName', "Add New Bank Account");
        } else {
          for (var key in listBanks) {
            if (listBanks[key]['BankAccountID'] === paymentID) {
              this.set('paymentMethodName', listBanks[key]["AccountHolderName"] + " - " + accMessage);
            }
          }
        }

        this.set('paymentSelectClass', "bankAccount");
        this.set('paymentMethod', paymentType);
        this.set('dpStateClass', "bankAccount");
        this.set('selStateClass', "bankAccount");
      }
    }
  });
});