define('vopay_app/routes/dashboard/schedule/details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			var authed = true;
			if (authed) {
				var link = window.localStorage.getItem("link");
				window.localStorage.removeItem("link");
				var options = {
					ScheduledTransactionID: id["scheduleId"]
				};
				var Currency = "CAD";
				if (localStorage.getItem("Country") == "US") {
					Currency = "USD";
				}
				var options2 = {};
				return _ember['default'].RSVP.hash({
					LinkPage: link,
					Id: id["scheduleId"],
					transactions: this.get('api').getScheduleTransactions(options),
					Country: localStorage.getItem("Country"),
					Currency: Currency
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});