define("vopay_app/components/recipient-dropdown", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    menuActive: false,
    dropDownClass: "form__dropdownToggle--grey",
    dropDownSelClass: "form__selDropdown--grey",
    dropDownStateClass: "",
    selDropDownStateClass: "",
    currentSelection: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentSelection', this.get('default'));
      if (this.get('isSelected')) {
        this.set("dropDownClass", "");
        this.set("dropDownSelClass", "");
      }
      if (window.innerWidth <= 670) {
        this.set("desktop", false);
        this.set("mobile", true);
      } else {
        this.set("desktop", true);
        this.set("mobile", false);
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      /** 
       var lazyLoadInstance = new LazyLoad({
           // Your custom settings go here
         });
         self.set("lazy", lazyLoadInstance);
       
      **/
      $(window).on('resize', this.handleResize.bind(this));
    },
    toggleMenu: function toggleMenu() {
      if (!this.get('menuActive')) {
        this.set('menuActive', true);
        this.set('dropDownStateClass', 'form__dropdownToggle--active');
        this.set('selDropDownStateClass', 'form__selDropdown--active');
      } else {
        this.set('menuActive', false);
        this.set('dropDownStateClass', '');
        this.set('selDropDownStateClass', '');
      }
    },

    handleResize: function handleResize(e) {
      if (window.innerWidth <= 670) {
        this.set("desktop", false);
        this.set("mobile", true);
      } else {

        this.set("desktop", true);
        this.set("mobile", false);
      }
      /**
      let self = this;
      setTimeout(function(){
          $(".loaded").removeClass("loaded").removeAttr("data-ll-status");
          self.get("lazy").update();
      },100);
      **/
    },
    actions: {
      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },
      /***
      * delevry dropdown
      * @params item (String)
      * Sets current selected item the dropdown
      ***/
      selectItem: function selectItem(item) {
        this.sendAction('value', item);
        var name = "";
        if (item === "New") {
          name = "New";
        } else {
          var data = this.get("data");
          var chosen = data[item];
          if (chosen.CompanyName) {
            name = chosen.CompanyName;
          } else {
            name = chosen.FirstName + " " + chosen.LastName;
          }
        }
        this.set('currentSelection', name);
        this.set('dropDownClass', '');
        this.set('dropDownSelClass', '');
        this.toggleMenu();
      }
    }
  });
});