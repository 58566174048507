define("vopay_app/mixins/manage-cookies", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports["default"] = _ember["default"].Mixin.create({

    /***
     * getCookie
     * @param name (String) - Name of the target cookie
     *
     * Returns a cookie based on the name
     */
    getCookie: function getCookie(name) {

      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) {

        return parts.pop().split(";").shift();
      }
    },
    getRootDomain: function getRootDomain(hostname) {
      var parts = hostname.split('.');
      if (parts.length > 2) {
        return parts.slice(1).join('.'); // Remove the first part (subdomain)
      } else {
          return hostname; // No subdomain, return as-is
        }
    },
    /***
     * setCookie
     * @param name (String) - name key for the cookie
     * @param value (String) - value to store in the cookie
     *
     * Sets the token cookie
     */
    setCookie: function setCookie(name, value) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      if (this.getCookie(name)) {
        this.removeCookie(name);
      }
      var rootDomain = this.getRootDomain(window.location.hostname);
      // Set default options
      var defaultOptions = {
        path: '/',
        domain: '.' + rootDomain,
        expires: new Date(Date.now() + 5 * 60 * 1000).toUTCString(), // 5 minutes from now
        secure: window.location.protocol === 'https:', // Set to true for secure cookies
        sameSite: 'Lax' };

      // Merge user-provided options with defaults
      // Adjust as needed (Strict, Lax, None)
      var mergedOptions = _extends({}, defaultOptions, options);

      var cookie = name + "=" + value;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(mergedOptions)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _step$value = _slicedToArray(_step.value, 2);

          var key = _step$value[0];
          var optValue = _step$value[1];

          if (optValue === true) {
            cookie += ";" + key;
          } else if (optValue !== false) {
            cookie += ";" + key + "=" + optValue;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"]) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      document.cookie = cookie;
    },

    /***
     * removeCookie
     * @param name (String) - name key for the cookie
     *
     * Remove cookie
     */
    removeCookie: function removeCookie(name) {

      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  });
});