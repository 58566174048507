define('vopay_app/routes/dashboard/sub-accounts/view', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			this.get("userPermissions").HasPermissionRoute("ViewSubaccounts");

			return _ember['default'].RSVP.hash({
				accountID: id["accountID"],
				currency: id["currency"],
				data: this._transactionsSubAccount(id["accountID"], id["currency"]),
				currencies: this.get('api').getGCMCurriencies()
			});
		},
		_transactionsSubAccount: function _transactionsSubAccount(accountID, currency) {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_this.get('api').getSubAccounts().then(function (data) {
					var subAccount = _this.findSubAccount(data.Subaccounts, accountID);
					var options = {
						BearerToken: subAccount.Token,
						Currency: currency
					};
					_this.get('api').getAccountTransactions(options).then(function (data2) {
						data.NumberOfRecords = data2.NumberOfRecords;
						data.Transactions = data2.Transactions;
						data.BearerToken = subAccount.Token;

						resolve(data);
					}, function (error) {
						reject(error);
					});
				}, function (error) {
					reject(error);
				});
			});
		},
		findSubAccount: function findSubAccount(subAccountList, name) {

			for (var key in subAccountList) {
				if (subAccountList[key].AccountID == name) {
					return subAccountList[key];
				}
			}
		}
	});
});