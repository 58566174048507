define('vopay_app/routes/account-renewal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        api: _ember['default'].inject.service('api'),
        model: function model(RouteParamters) {

            return _ember['default'].RSVP.hash({
                Token: this.get('api').AccountRenewal({
                    Token: RouteParamters["Token"]
                })
            });
        }
    });
});