define('vopay_app/components/top-bar', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		image: _vopay_appConfigEnvironment['default'].imageSmall,
		didInsertElement: function didInsertElement() {
			//var self = this;
			var self = this;
			var d = new Date();
			sessionStorage.setItem("SessionTime", d.getTime());
			clearInterval(window.sessionTimeout);
			window.sessionTimeout = setInterval(function () {
				var d2 = new Date();
				if (3600000 < d2.getTime() - sessionStorage.getItem("SessionTime")) {
					clearInterval(window.sessionTimeout);
					localStorage.removeItem("key");
					localStorage.removeItem("accountID");
					localStorage.removeItem("signature");
					localStorage.removeItem("subAccount");
					localStorage.removeItem("JWT");
					localStorage.removeItem("currentUrlSubAccount");
					localStorage.setItem("logout", true);
					location.reload(true);
				}
			}, 1000);
			_ember['default'].$("body").click(function () {
				var d = new Date();
				sessionStorage.setItem("SessionTime", d.getTime());
			});
		}
	});
});