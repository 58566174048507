define('vopay_app/utils/pooler', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    _interval: 1000,
    _currentlyExecutedFunction: null,

    start: function start(context, pollingFunction) {
      this.set('_currentlyExecutedFunction', this._schedule(context, pollingFunction, [].slice.call(arguments, 2)));
    },

    stop: function stop() {
      _ember['default'].run.cancel(this.get('_currentlyExecutedFunction'));
    },

    _schedule: function _schedule(context, func, args) {
      return _ember['default'].run.later(this, function () {
        this.set('_currentlyExecutedFunction', this._schedule(context, func, args));
        func.apply(context, args);
      }, this.get('_interval'));
    },

    setInterval: function setInterval(interval) {
      this.set('_interval', interval);
    }
  });
});