define('vopay_app/components/client-account-list', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/utils/query-param-builder'], function (exports, _ember, _vopay_appConstants, _vopay_appUtilsQueryParamBuilder) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		accountStatus: ['All', 'Active', 'Not Active'],
		clientType: ['All', 'Individual', 'Business', 'Receive-only', 'Standard'],
		keywords: '',
		currency: '',
		isActive: '',
		type: '',
		init: function init() {
			this._super.apply(this, arguments);

			//const balanceList = this.createBalanceList();

			this.set('ClientAccounts', Object.values(this.get('data').ClientAccounts));

			this.set('currencies', ['All'].concat(_toConsumableArray(Object.keys(_vopay_appConstants['default'].currencies))));
			//this.set('allBalances', balanceList);
			this.set('filteredClientAccounts', Object.values(this.get('data').ClientAccounts));
		},
		createBalanceList: function createBalanceList() {
			var _this = this;

			var accountList = Object.values(this.get('data').ClientAccounts);
			var balanceList = [];

			return accountList.reduce(function (currentList, currentAccount) {
				return [].concat(_toConsumableArray(currentList), _toConsumableArray(Object.values(currentAccount.Balances).map(function (_balance) {
					var balance = _extends({}, currentAccount, _balance);

					delete balance.Balances;

					balance.AccountBalance = _this.sanitize(balance.AccountBalance) || 0;
					balance.PendingBalance = _this.sanitize(balance.PendingBalance) || 0;
					balance.AvailableBalance = _this.sanitize(balance.AvailableBalance) || 0;

					balance.queryParams = _this.createQueryParams(balance);

					return balance;
				})));
			}, balanceList);
		},
		filterBalances: function filterBalances(name, type, status) {
			var _this2 = this;

			return this.get('ClientAccounts').filter(function (_ref) {
				var ClientName = _ref.ClientName;
				var ClientType = _ref.ClientType;
				var IsActive = _ref.IsActive;

				return [_this2.hasMatchingName(ClientName, name), _this2.hasMatchingType(ClientType, type), _this2.hasMatchingStatus(IsActive, status)].every(function (condition) {
					return condition;
				});
			});
		},
		runFilter: function runFilter() {
			this.set('filteredClientAccounts', this.filterBalances(this.get('keyword'), this.get('type'), this.get('isActive')));
		},
		createQueryParams: function createQueryParams(transaction) {
			return Object.entries(transaction).reduce(_vopay_appUtilsQueryParamBuilder['default'], '');
		},
		sanitize: function sanitize() {
			var input = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

			if (input != null) {
				return input.toLowerCase().replace(/\$/g, '').replace(/\,/g, '').replace(/\s|_/g, '', '');
			}
		},
		mapInput: function mapInput(input) {
			var map = { 'All': '', 'Active': '1', 'Not Active': '0' };
			return map[input] !== undefined ? map[input] : input;
		},
		hasMatchingName: function hasMatchingName(name, searchTerms) {
			return searchTerms ? this.sanitize(name).includes(this.sanitize(searchTerms)) : true;
		},
		hasMatchingType: function hasMatchingType(type, searchType) {
			return searchType ? this.sanitize(searchType) === this.sanitize(type) : true;
		},
		hasMatchingCurrency: function hasMatchingCurrency(currency, searchCurrency) {
			return searchCurrency ? searchCurrency === currency : true;
		},
		hasMatchingStatus: function hasMatchingStatus(status, searchStatus) {
			return searchStatus !== '' ? searchStatus === status : true;
		},
		actions: {
			filterByKeyword: function filterByKeyword() {
				this.runFilter();
			},
			filterByType: function filterByType(type) {
				this.set('type', this.mapInput(type));
				this.runFilter();
			},
			filterByCurrency: function filterByCurrency(currency) {
				this.set('currency', this.mapInput(currency));
				this.runFilter();
			},
			filterByStatus: function filterByStatus(status) {
				this.set('isActive', this.mapInput(status));
				this.runFilter();
			}
		}
	});
});