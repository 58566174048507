define('vopay_app/controllers/forgot-password', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		username: "",
		reset: false,
		actions: {
			sendResetEmail: function sendResetEmail() {
				_ember['default'].$('form').parsley({
					successClass: " ",
					errorClass: "form__input--error",
					errorsWrapper: "<p></p>",
					errorTemplate: "<span class='form__message'></span>"
				});
				var form = _ember['default'].$('#forgotForm');
				form.parsley().validate();
				form.parsley().isValid();
				if (form.parsley().isValid()) {

					var options = {};
					options.username = _ember['default'].$("input[name=email]").val();
					this.get('api').getResetEmail(options);
					//this.get("routing").transitionTo("login");
					this.set("reset", true);
				}
			}
		}
	});
});