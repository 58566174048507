define('vopay_app/components/add-beneficiary-method', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		userName: null,
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		accountOptions: ["Send Fund", "Collect Fund", "Both"],
		isDeposit: false,
		country: "Canada",
		currency: "CAD",
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		countryID: 2,
		currencies: {
			AUD: {
				name: 'Australian Dollar',
				iso3: 'AUD'
			},

			BHD: {
				name: 'Bahrain Dinar',
				iso3: 'BHD'
			},

			BGN: {
				name: 'Bulgarian Lev',
				iso3: 'BGN'
			},

			CNY: {
				name: 'Chinese Yuan',
				iso3: 'CNY'
			},

			HRK: {
				name: 'Croatian Kuna',
				iso3: 'HRK'
			},

			CZK: {
				name: 'Czech Koruna',
				iso3: 'CZK'
			},

			DKK: {
				name: 'Danish Krone',
				iso3: 'DKK'
			},

			EUR: {
				name: 'Euro',
				iso3: 'EUR'
			},

			HKD: {
				name: 'Hong Kong Dollar',
				iso3: 'HKD'
			},

			HUF: {
				name: 'Hungarian Forint',
				iso3: 'HUF'
			},

			INR: {
				name: 'Indian Rupee',
				iso3: 'INR'
			},

			ILS: {
				name: 'Israeli New Shekel',
				iso3: 'ILS'
			},

			JMD: {
				name: 'Jamaican Dollar',
				iso3: 'JMD'
			},

			JPY: {
				name: 'Japanese Yen',
				iso3: 'JPY'
			},

			KES: {
				name: 'Kenyan Shilling',
				iso3: 'KES'
			},

			KWD: {
				name: 'Kuwaiti Dinar ',
				iso3: 'KED'
			},

			MXN: {
				name: 'Mexican Peso ',
				iso3: 'MXN'
			},

			NZD: {
				name: 'New Zealand Dollar',
				iso3: 'NZD'
			},

			NOK: {
				name: 'Norwegian Krone',
				iso3: 'NOK'
			},

			OMR: {
				name: 'Omani Rial',
				iso3: 'OMR'
			},

			PLN: {
				name: 'Polish Zloty',
				iso3: 'PLN'
			},

			GBP: {
				name: 'Pound Sterling',
				iso3: 'GBP'
			},

			QAR: {
				name: 'Qatari Riyal',
				iso3: 'QAR'
			},

			RON: {
				name: 'Romanian New Leu',
				iso3: 'RON'
			},

			RUB: {
				name: 'Russian Rouble',
				iso3: 'RUB'
			},

			SAR: {
				name: 'Saudi Riyal',
				iso3: 'SAR'
			},

			SGD: {
				name: 'Singapore Dollar',
				iso3: 'SGD'
			},

			ZAR: {
				name: 'South African Rand',
				iso3: 'ZAR'
			},

			SEK: {
				name: 'Swedish Krona',
				iso3: 'SEK'
			},

			CHF: {
				name: 'Swiss Franc',
				iso3: 'CHF'
			},

			THB: {
				name: 'Thai Baht',
				iso3: 'THB'
			},

			TRY: {
				name: 'Turkish Lira',
				iso3: 'TRY'
			},

			UGX: {
				name: 'Ugandan Shiling ',
				iso3: 'UGX'
			},

			AED: {
				name: 'UAE Dirham',
				iso3: 'AED'
			},

			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		// Avoiding wrong entry data

		watchfIsStore: _ember['default'].observer('isStore', function () {

			if (this.get("isStore") === false) {
				this.set("isDefault", false);
				_ember['default'].$("#isDefault").attr("disabled", true);
			} else {
				_ember['default'].$("#isDefault").removeAttr("disabled");
			}
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			//let name = this.get("userInfo.FirstName")+" "+this.get("userInfo.LastName");
			this.get('api').getCountries().then(function (data) {
				_this.set('countries', data);
			});
		},
		didInitAttrs: function didInitAttrs() {
			this.set("signatureNumbers", []);
			this.set("isDeposit", false);
			var returnUrl = sessionStorage.getItem("returnUrl");
			if (returnUrl === "http://localhost:4200/#/dashboard/bank" || returnUrl === "http://dev2.vopay.com/#/dashboard/bank" || returnUrl === "https://account.vopay.com/#/dashboard/bank") {
				this.set("isCurrency", true);
			} else {
				this.set("isCurrency", false);
			}
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});
			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			if (payment.IsDefault === "on") {
				payment.IsDefault = true;
			} else {
				payment.IsDefault = false;
			}

			if (this.get('paymentMethod') === 'CreditCard') {
				payment.type = 'creditcard';
			} else if (this.get('paymentMethod') === 'Bank') {
				payment.type = 'bankaccount';
			}

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			recipientCountryChanged: function recipientCountryChanged(country) {
				this.set("showRecipient", false);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('currency', this.getCurrency(country));
				this.set('countryID', this.getCountryID(country));
				var cts = this.get('countries');
				var self = this;
				var DeliveryMethods = cts.filter(function (f) {
					return f.Country.toUpperCase() === self.get('country').toUpperCase();
				}).map(function (c) {
					return c.DeliveryMethods;
				});

				// Reset recipients rules
				var methods = DeliveryMethods[0];
				this.set("DeliveryMethods", methods);
				_ember['default'].$('#delivery-input select').prop('selectedIndex', 0);
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				this.set("currency2", currency);
				this.set("currencyName", this.getCurrencyName(currency));
			},
			addSignature: function addSignature() {
				this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
				this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
			},
			removeSignature: function removeSignature() {
				this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
				this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
			},
			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			cancel: function cancel() {
				if (sessionStorage.getItem("returnUrl")) {
					var url = sessionStorage.getItem("returnUrl");
					sessionStorage.removeItem("returnUrl");
					window.location.href = url;
				} else {
					this.get("routing").transitionTo("dashboard.info");
				}
			},
			handleAccountOptions: function handleAccountOptions(value) {

				if (value === "Collect Fund") {
					this.set("isDeposit", true);
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
					this.set('signatureNumDel', 1);
				} else if (value === "Both") {
					this.set("isDeposit", true);
					this.set('signatureNumDel', 0);
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
				} else {
					this.set("isDeposit", false);
				}
			},
			create: function create() {
				var _this2 = this;

				if (this.get("currency") === "CAD") {
					if (this.validateForm('#paymentForm')) {
						(function () {
							// Add payment
							_ember['default'].$('#paymentForm button').attr('disabled', 'disabled');
							var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
							_this2.set('paymentToAdd', _this2.addPaymentMethod(paymentFormArr));
							_this2.get('api').addPaymentMethod(_this2.get('paymentToAdd')).then(function (bank) {
								// Resolve payment method
								if (_this2.get("paymentToAdd.IsDefault")) {
									_this2.get('api').setDefault(bank.ID).then(function () {
										_ember['default'].$('#paymentForm button').removeAttr('disabled');
										if (sessionStorage.getItem("returnUrl")) {
											var url = sessionStorage.getItem("returnUrl");
											sessionStorage.removeItem("returnUrl");
											window.location.href = url;
										} else {
											_this2.get("routing").transitionTo("dashboard.info");
										}
									}, function (err) {
										_ember['default'].$('#paymentForm button').removeAttr('disabled');
										_this2.handleErrors(paymentFormArr, err);
									});
								} else {
									_ember['default'].$('#paymentForm button').removeAttr('disabled');
									if (sessionStorage.getItem("returnUrl")) {
										var url = sessionStorage.getItem("returnUrl");
										sessionStorage.removeItem("returnUrl");
										window.location.href = url;
									} else {
										_this2.get("routing").transitionTo("dashboard.info");
									}
								}
							}, function (err2) {
								_ember['default'].$('#paymentForm button').removeAttr('disabled');
								_this2.handleErrors(paymentFormArr, err2);
							});
						})();
					}
				} else {
					this.get("routing").transitionTo("dashboard.info");
				}
			}

		}
	});
});