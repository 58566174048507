define('vopay_app/routes/dashboard/bank-transfer', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model() {
			this.get("userPermissions").HasPermissionRoute("CreatePayments");
			this.get("userPermissions").HasPermissionRoute("CreateCollections");
			var authed = this.get('session.isAuthenticated');

			if (authed) {

				return _ember['default'].RSVP.hash({
					clientAccounts: this.get("api").getClientAccounts(),
					bankAccounts: this.get('api').getBankAccounts(true),
					firebaseBeneficiaries: this.get("firebase").getRecordUser("accounts"),
					firebaseLinkedAccounts: this.get("firebase").getRecordUser("linkedAccount")
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});