define("vopay_app/components/province-dropdown", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		CanadaProvincesOptions: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"],
		CanadianProvinces: {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon"

		},
		track: null,
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			var Provinces = this.get("CanadianProvinces");
			var self = this;
			var Id = this.get("DropdownId");

			var oldValue = this.$("#" + Id).val();
			var somethingChanged = false;

			this.set("track", setInterval(function () {

				if (self.$("#" + Id) && self.$("#" + Id).val() != oldValue) {

					oldValue = self.$("#" + Id).val();
					somethingChanged = true;
					if (oldValue.length == 2) {

						self.set("currentSelection", Provinces[oldValue]);
						self.$(".dropdown select option").removeProp('selected');
						self.$(".dropdown select option[value='" + Provinces[oldValue] + "']").first().prop('selected', 'selected');
						self.set("selectedVal", true);
						self.$(".dropdown input").first().parsley().reset();
					} else {
						self.set("currentSelection", oldValue);
						self.$(".dropdown select option").removeProp('selected');
						self.$(".dropdown select option[value='" + oldValue + "']").first().prop('selected', 'selected');
						self.set("selectedVal", true);
						self.$(".dropdown input").first().parsley().reset();
					}
				}
			}, 200));
		},

		willDestroyElement: function willDestroyElement() {
			clearInterval(this.get("track"));
		},
		init: function init() {
			this._super.apply(this, arguments);
			var Provinces = this.get("CanadianProvinces");
			if (this.get("Selection")) {
				this.set("Default", Provinces[this.get("Selection")]);
				this.set("currentSelection", Provinces[this.get("Selection")]);
				this.set("selectedVal", true);
			} else {
				this.set("Default", "Province");
			}
		},
		getKeyByValue: function getKeyByValue(object, value) {
			return Object.keys(object).find(function (key) {
				return object[key] === value;
			});
		},

		actions: {
			SelectedProvince: function SelectedProvince(value) {
				var ProvinceCode = this.getKeyByValue(this.get("CanadianProvinces"), value);
				var Id = this.get("DropdownId");
				_ember["default"].$("#" + Id).parsley().reset();
				this.set("Selection", ProvinceCode);
			}
		}
	});
});