define("vopay_app/components/beneficiary-template-download", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		ServiceOptions: ["EFT", "e-Transfer"],
		DocumentOptions: ["Debit (Collect Funds)", "Credit (Send Funds)"],
		Service: "EFT",
		Document: "Debit",
		api: _ember["default"].inject.service('api'),
		routing: _ember["default"].inject.service('-routing'),
		progress: [],
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var self = this;
		},
		CanadianProvinces: {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon"

		},
		currencies: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		currencyName: "Canadian Dollar",
		currency: "CAD",
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('api').getCountries().then(function (data) {
				_this.set('countries', data);
			});
		},
		actions: {
			closeModalProgress: function closeModalProgress() {
				this.set("isProgress", false);
			},
			handleService: function handleService(value) {
				this.set("Service", value);
			},
			handleDocument: function handleDocument(value) {
				if (value == "Credit (Send Funds)") {
					this.set("Document", "Credit");
				} else {
					this.set("Document", "Debit");
				}
			},
			moveToUploadFile: function moveToUploadFile() {
				$("#FileSummary").hide();
				$("#FileInput").show();
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				this.set("currency", currency);
				this.set("currencyName", this.getCurrencyName(currency));
			}
		}
	});
});