define('vopay_app/components/reconciliation-transactions', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		pageSize: 20,
		keyword: "",
		showBatch: true,
		disableDateSelector: false,
		init: function init() {
			this._super.apply(this, arguments);
		},
		watchdate: _ember['default'].observer('date', function () {
			var start = localStorage.getItem("startDate");
			var end = localStorage.getItem("endDate");
			if (start && end && start != "" && end != "") {
				this.set("startDate", start);
				this.set("endDate", end);

				localStorage.setItem("startDate", "");
				localStorage.setItem("endDate", "");

				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
			}
		}),
		didInsertElement: function didInsertElement() {
			this.set("startDate", window.moment().utc().subtract(29, 'days').format('YYYY-MM-DD'));
			this.set("endDate", window.moment().utc().format('YYYY-MM-DD'));
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				startDate: window.moment().subtract(29, 'days'),
				endDate: window.moment(),
				ranges: {
					'Today': [window.moment().utc(), window.moment().utc()],
					'Yesterday': [window.moment().utc().subtract(1, 'days'), window.moment().utc().subtract(1, 'days')],
					'Last 7 Days': [window.moment().utc().subtract(6, 'days'), window.moment().utc()],
					'Last 30 Days': [window.moment().utc().subtract(29, 'days'), window.moment().utc()],
					'This Month': [window.moment().utc().startOf('month'), window.moment().utc().endOf('month')],
					'Last Month': [window.moment().utc().subtract(1, 'month').startOf('month'), window.moment().utc().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});

			localStorage.setItem("startDate", "");
			localStorage.setItem("endDate", "");
			_ember['default'].$('input[name="daterange"]').val(window.moment().utc().subtract(29, 'days').format('YYYY-MM-DD') + " - " + window.moment().utc().format('YYYY-MM-DD'));
			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {

				localStorage.setItem("startDate", picker.startDate.format('YYYY-MM-DD'));
				localStorage.setItem("endDate", picker.endDate.format('YYYY-MM-DD'));
				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('YYYY-MM-DD') + " - " + picker.endDate.format('YYYY-MM-DD'));
				_ember['default'].$('input[name="daterange"]').trigger("change");
			});
			this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
		},
		filterTransactions: function filterTransactions(searchTerms, transactions, page) {
			var filteredList = {};
			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.trim();
			var startDate = this.get("startDate");
			var endDate = this.get("endDate");

			if (transactions) {
				var keys = Object.keys(transactions);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {
					if (typeof transactions[i] !== 'undefined') {
						if ((moment(startDate).isBefore(transactions[i].Date) || moment(startDate).isSame(transactions[i].Date)) && (moment(endDate).isAfter(transactions[i].Date) || moment(endDate).isSame(transactions[i].Date)) || this.get("disableDateSelector")) {
							if (transactions[i].Description.toLowerCase().trim().includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].Balance && transactions[i].Balance.toString().toLowerCase().trim().includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].Debit && transactions[i].Debit.toString().toLowerCase().trim().includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (transactions[i].Credit && transactions[i].Credit.toString().toLowerCase().trim().includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = transactions[i];
							} else if (searchTerms === null || searchTerms === "") {
								next = next + 1;
								filteredList[next] = transactions[i];
							}
						}
					}
				}
				_ember['default'].$('#searchTransactions').addClass('searchHide');
				this.set("filteredList", filteredList);
				this.getTransactions(page, this.get("filteredList"));
			}
		},

		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
					var next = i + 1;
					filteredList[next] = transactions[i];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", $.extend(true, {}, filteredList));
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		actions: {
			getPage: function getPage(pageNumber) {
				this.filterTransactions(this.get("keyword"), this.get("transactions"), pageNumber);
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
			},
			pdf: function pdf() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;

				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			csv: function csv() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			xls: function xls() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xlsx', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			doc: function doc() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			text: function text() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
					_ember['default'].$('#tableID').css("display", "");
				}, 200);
				return false;
			},
			json: function json() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').css("display", "block !important");
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			xml: function xml() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
				return false;
			},
			printPage: function printPage() {

				var pageNumberCurrent = this.get("pageNumber");
				this.set("pageSize", this.get("recordCount"));
				this.filterTransactions(this.get("keyword"), this.get("transactions"), 1);
				var self = this;

				setTimeout(function () {
					window.print();
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("transactions"), pageNumberCurrent);
				}, 200);
			}
		}
	});
});