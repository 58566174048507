define("vopay_app/templates/components/profile-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 16,
                  "column": 4
                }
              },
              "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					Account & Billing\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 3
              },
              "end": {
                "line": 18,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("			");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(element7, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["dashboardDd__menuItem ", ["subexpr", "if", [["get", "isUser", ["loc", [null, [13, 41], [13, 47]]], 0, 0, 0, 0], "header__links--active"], [], ["loc", [null, [13, 36], [13, 73]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["dashboard.account-billing"], ["class", "primary", "name", "account-billing"], 0, null, ["loc", [null, [14, 4], [16, 16]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 6
                  },
                  "end": {
                    "line": 25,
                    "column": 6
                  }
                },
                "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("							User Management\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 28,
                  "column": 4
                }
              },
              "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("				\n					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("					");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n					\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'class');
              morphs[1] = dom.createMorphAt(element6, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["dashboardDd__menuItem ", ["subexpr", "if", [["get", "isUser", ["loc", [null, [22, 43], [22, 49]]], 0, 0, 0, 0], "header__links--active"], [], ["loc", [null, [22, 38], [22, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["dashboard.users"], ["class", "primary", "name", "UserManagment"], 0, null, ["loc", [null, [23, 6], [25, 18]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 3
              },
              "end": {
                "line": 29,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isUserManagement", ["loc", [null, [20, 10], [20, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 4], [28, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 8
                  },
                  "end": {
                    "line": 37,
                    "column": 8
                  }
                },
                "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("									");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "dashboardDd__menuItem");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "class", "primary disabled-link");
                var el3 = dom.createElement("img");
                dom.setAttribute(el3, "class", "dashboardDd__Icon");
                dom.setAttribute(el3, "src", "/images/checkmark-grey.png");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 1, 1);
                return morphs;
              },
              statements: [["content", "key", ["loc", [null, [36, 142], [36, 149]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 8
                  },
                  "end": {
                    "line": 39,
                    "column": 8
                  }
                },
                "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("									");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "dashboardDd__menuItem");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "class", "primary");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1, 0]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element4);
                morphs[1] = dom.createMorphAt(element4, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["swichAccounts", ["get", "token", ["loc", [null, [38, 87], [38, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 62], [38, 94]]], 0, 0], ["content", "key", ["loc", [null, [38, 95], [38, 102]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 7
                },
                "end": {
                  "line": 40,
                  "column": 7
                }
              },
              "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "key", ["loc", [null, [35, 18], [35, 21]]], 0, 0, 0, 0], ["get", "AccountName", ["loc", [null, [35, 22], [35, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 14], [35, 34]]], 0, 0]], [], 0, 1, ["loc", [null, [35, 8], [39, 15]]]]],
            locals: ["key", "token"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 3
              },
              "end": {
                "line": 44,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n					");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "primary");
            var el3 = dom.createTextNode(" ◄  Switch Account\n						");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3, "class", "dashboardDd__subMenu dashboardDd__menu dropdown-menu");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("						");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n					");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["dashboardDd__menuItem ", ["subexpr", "if", [["get", "isUser", ["loc", [null, [31, 42], [31, 48]]], 0, 0, 0, 0], "header__links--active"], [], ["loc", [null, [31, 37], [31, 74]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each-in", [["get", "UserAccounts", ["loc", [null, [34, 18], [34, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 7], [40, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 40
              },
              "end": {
                "line": 45,
                "column": 95
              }
            },
            "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Change Password");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dashboardDd");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-default dropdown-toggle dashboardDd__toggle dashboardDd__toggle--grey dashboardDd__toggle--profile");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "data-toggle", "dropdown");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "icon-chevron-down");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dropdown-menu dashboardDd__menu");
          dom.setAttribute(el2, "aria-labelledby", "dropdownMenu2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("		    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "dashboardDd__menuItem");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "dashboardDd__menuItem dashboardDd__menuItem--last");
          var el4 = dom.createElement("a");
          var el5 = dom.createTextNode("Log Out");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element8, [3]);
          var element11 = dom.childAt(element10, [7, 0]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createMorphAt(element9, 3, 3);
          morphs[2] = dom.createMorphAt(element10, 1, 1);
          morphs[3] = dom.createMorphAt(element10, 2, 2);
          morphs[4] = dom.createMorphAt(element10, 3, 3);
          morphs[5] = dom.createMorphAt(dom.childAt(element10, [5]), 0, 0);
          morphs[6] = dom.createAttrMorph(element11, 'onclick');
          return morphs;
        },
        statements: [["content", "AccountName", ["loc", [null, [5, 3], [5, 18]]], 0, 0, 0, 0], ["content", "accountID", ["loc", [null, [5, 20], [5, 33]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "check-permission", ["ManageDefaultPayments"], [], ["loc", [null, [12, 9], [12, 51]]], 0, 0]], [], 0, null, ["loc", [null, [12, 3], [18, 10]]]], ["block", "if", [["subexpr", "check-permission", ["ViewUsers"], [], ["loc", [null, [19, 9], [19, 39]]], 0, 0]], [], 1, null, ["loc", [null, [19, 3], [29, 10]]]], ["block", "if", [["get", "HasAdditionalUserAccounts", ["loc", [null, [30, 9], [30, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [30, 3], [44, 10]]]], ["block", "link-to", ["dashboard.change-password"], [], 3, null, ["loc", [null, [45, 40], [45, 107]]]], ["attribute", "onclick", ["subexpr", "action", ["logout"], [], ["loc", [null, [null, null], [46, 98]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 40
              },
              "end": {
                "line": 56,
                "column": 95
              }
            },
            "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Change Password");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 0
            },
            "end": {
              "line": 60,
              "column": 0
            }
          },
          "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dashboardDd dashboardDd--sendNow");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-default dropdown-toggle dashboardDd__toggle dashboardDd__toggle--grey");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "data-toggle", "dropdown");
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "icon-chevron-down");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dropdown-menu dashboardDd__menu");
          dom.setAttribute(el2, "aria-labelledby", "dropdownMenu2");
          var el3 = dom.createTextNode("\n		    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "dashboardDd__menuItem");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "dashboardDd__menuItem dashboardDd__menuItem--last");
          var el4 = dom.createElement("a");
          var el5 = dom.createTextNode("Log Out");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element2, [3, 0]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[3] = dom.createAttrMorph(element3, 'onclick');
          return morphs;
        },
        statements: [["content", "userInfo.FirstName", ["loc", [null, [52, 3], [52, 25]]], 0, 0, 0, 0], ["inline", "short-last-name", [["get", "userInfo.LastName", ["loc", [null, [52, 44], [52, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 26], [52, 63]]], 0, 0], ["block", "link-to", ["dashboard.change-password"], [], 0, null, ["loc", [null, [56, 40], [56, 107]]]], ["attribute", "onclick", ["subexpr", "action", ["logout"], [], ["loc", [null, [null, null], [57, 99]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/profile-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "noSendNow", ["loc", [null, [2, 6], [2, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 0], [60, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});