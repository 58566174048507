define("vopay_app/components/subaccount-details", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    api: _ember["default"].inject.service("api"),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var data = this.get("data");
      this.set("SubAccounts", data.Subaccounts);
      this.get("api").getSubAccountTypes().then(function (data) {
        var subAccountTypes = Object.values(data.SubAccountTypes);
        _this.set("subAccountTypes", subAccountTypes);
      })["catch"](this.set("subAccountTypes", [{ Type: "member", Description: "Members" }, { Type: "operating_name", Description: "Operating Name (DBA)" }, { Type: "other", Description: "Other" }]));
      var subaaccount = this.findSubAccount(data.Subaccounts, this.get("accountID"));
      subaaccount.SubAccountType = this.getSubAccountLabel(subaaccount.SubAccountType);
      this.set("SubAccount", subaaccount);

      var Balance = this.findSubAccountBalance(subaaccount, this.get("currency"));
      this.set("Balance", Balance);
      localStorage.setItem("subAccount", this.get("BearerToken"));
      localStorage.setItem("currentUrlSubAccount", location.href);
    },
    findSubAccount: function findSubAccount(subAccountList, name) {
      for (var key in subAccountList) {
        if (subAccountList[key].AccountID == name) {
          return subAccountList[key];
        }
      }
    },
    findSubAccountBalance: function findSubAccountBalance(subAccount, currency) {
      for (var key in subAccount.Balances) {
        if (subAccount.Balances[key].Currency == currency) {
          return subAccount.Balances[key];
        }
      }
    },
    getSubAccountLabel: function getSubAccountLabel(SubAccountType) {
      var subAccountTypes = this.get("subAccountTypes");
      return subAccountTypes.find(function (x) {
        return x.Type === SubAccountType;
      }) ? subAccountTypes.find(function (x) {
        return x.Type === SubAccountType;
      }).Description : SubAccountType;
    }
  });
});