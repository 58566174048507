define("vopay_app/templates/components/change-address", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 4
            },
            "end": {
              "line": 28,
              "column": 77
            }
          },
          "moduleName": "vopay_app/templates/components/change-address.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Cancel");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/change-address.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "name", "addressForm");
        dom.setAttribute(el2, "id", "addressForm");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__section form__section--noPadding dashboard__section--form");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4, "class", "form__header dashboard__header--sub");
        var el5 = dom.createTextNode("Change Address");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50 form__element--block");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form__input form__input--blue");
        dom.setAttribute(el5, "id", "street-address");
        dom.setAttribute(el5, "name", "streetaddress");
        dom.setAttribute(el5, "placeholder", "First Line of Your Address");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form__label");
        var el6 = dom.createTextNode("Address");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form__input form__input--blue");
        dom.setAttribute(el5, "id", "state");
        dom.setAttribute(el5, "name", "state");
        dom.setAttribute(el5, "placeholder", "Province/State");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form__label");
        var el6 = dom.createTextNode("Province");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form__input form__input--blue");
        dom.setAttribute(el5, "id", "city");
        dom.setAttribute(el5, "name", "city");
        dom.setAttribute(el5, "placeholder", "Enter City Name");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form__label");
        var el6 = dom.createTextNode("City");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form__input form__input--blue");
        dom.setAttribute(el5, "id", "postcode");
        dom.setAttribute(el5, "name", "postcode");
        dom.setAttribute(el5, "placeholder", "A1A 1A1");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form__label");
        var el6 = dom.createTextNode("Postal Code");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50 form__input--blue");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "hidden");
        dom.setAttribute(el5, "class", "form__input");
        dom.setAttribute(el5, "name", "country");
        dom.setAttribute(el5, "placeholder", "Country");
        dom.setAttribute(el5, "value", "Canada");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form__input");
        dom.setAttribute(el5, "id", "country");
        dom.setAttribute(el5, "name", "country2");
        dom.setAttribute(el5, "placeholder", "Country");
        dom.setAttribute(el5, "value", "Canada");
        dom.setAttribute(el5, "disabled", "true");
        dom.setAttribute(el5, "data-parsley-trigger", "focusout");
        dom.setAttribute(el5, "data-parsley-required", "true");
        dom.setAttribute(el5, "required", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form__label");
        var el6 = dom.createTextNode("Country");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form__element form__element--50 form__element--block");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5, "class", "form__btn form__btn--inline");
        var el6 = dom.createTextNode("Change Address");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "dashboard__description");
        var el5 = dom.createTextNode("Why change of address is important?");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "dashboard__description dashboard__description--secondary");
        var el5 = dom.createTextNode("\n				An accurate and up-to-date address is essential for differentiating and proctecting your account. Take a moment to make sure the address you have associated with your account is up to date");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element0, [5, 1]);
        var element3 = dom.childAt(element0, [7, 1]);
        var element4 = dom.childAt(element0, [9, 1]);
        var element5 = dom.childAt(element0, [13]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'value');
        morphs[1] = dom.createAttrMorph(element2, 'value');
        morphs[2] = dom.createAttrMorph(element3, 'value');
        morphs[3] = dom.createAttrMorph(element4, 'value');
        morphs[4] = dom.createElementMorph(element6);
        morphs[5] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["attribute", "value", ["concat", [["get", "userInfo.Address1", ["loc", [null, [6, 111], [6, 128]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "userInfo.Province", ["loc", [null, [10, 94], [10, 111]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "userInfo.City", ["loc", [null, [14, 92], [14, 105]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "userInfo.PostalCode", ["loc", [null, [18, 100], [18, 119]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeAddress"], [], ["loc", [null, [27, 12], [27, 38]]], 0, 0], ["block", "link-to", ["dashboard.account.contact"], ["class", "dashboard__cancel"], 0, null, ["loc", [null, [28, 4], [28, 89]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});