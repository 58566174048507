define('vopay_app/components/transaction-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get("api").getGCMCurriencies().then(function (data) {

				_this.set("currencies", data.CurrenciesList);
			});
		},
		convertDate: function convertDate(days) {
			var today = new Date();
			today.setDate(today.getDate() - days);
			var DD = this.pad(today.getDate(), 2);
			var MM = this.pad(today.getMonth() + 1, 2);
			var YYYY = today.getFullYear();
			var date = YYYY + "-" + MM + "-" + DD;
			return date;
		},
		pad: function pad(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		},
		didInsertElement: function didInsertElement() {
			var _this2 = this;

			var options = {};

			options.Currency = this.get("DefaultCurrency");

			this.get("api").getAccountTransactions(options).then(function (data) {
				var transactions = data.Transactions;
				var keys = Object.keys(transactions);
				var data2 = [];

				var count = 0;
				var days = 0;
				var last = _this2.convertDate(0);

				var i = 0;
				while (i < keys.length && days <= 6) {
					var current = transactions[keys[i]].TransactionDateTime.split(" ")[0];

					if (last !== current) {
						var dataPoint = {
							date: last,
							value: count
						};
						data2.push(dataPoint);

						days = days + 1;
						last = _this2.convertDate(days);
						count = 0;
					} else {
						last = current;
						count = count + 1;
						i = i + 1;
					}
				}
				if (count >= 1) {
					var dataPoint2 = {
						date: last,
						value: count
					};
					data2.push(dataPoint2);

					days = days + 1;
					last = _this2.convertDate(days);
					count = 0;
				}
				while (days <= 6) {
					var dataPoint3 = {
						date: last,
						value: 0
					};
					data2.push(dataPoint3);
					days = days + 1;
					last = _this2.convertDate(days);
				}

				window.line = new window.Morris.Line({
					// ID of the element in which to draw the chart.
					element: 'transactionchart',
					// Chart data records -- each entry in this array corresponds to a point on
					// the chart.
					data: data2,
					// The name of the data record attribute that contains x-values.
					xkey: 'date',
					// A list of names of data record attributes that contain y-values.
					ykeys: ['value'],
					resize: true,
					// Labels for the ykeys -- will be displayed when you hover over the
					// chart.
					labels: ['Transactions'],
					lineColors: ['#6f42c1']
				});
				_this2.set("transactions", data.Transactions);
			});
		},
		actions: {
			currencyChanged: function currencyChanged(value) {
				var _this3 = this;

				var options = {};
				options.Currency = value;
				this.get("api").getAccountTransactions(options).then(function (data2) {
					var transactions = data2.Transactions;
					var keys = Object.keys(transactions);
					var data = [];

					var count = 0;
					var days = 0;
					var last = _this3.convertDate(0);

					var i = 0;
					while (i < keys.length && days <= 6) {
						var current = transactions[keys[i]].TransactionDateTime.split(" ")[0];

						if (last !== current) {
							var dataPoint = {
								date: last,
								value: count
							};
							data.push(dataPoint);

							days = days + 1;
							last = _this3.convertDate(days);
							count = 0;
						} else {
							last = current;
							count = count + 1;
							i = i + 1;
						}
					}
					if (count >= 1) {
						var dataPoint2 = {
							date: last,
							value: count
						};
						data.push(dataPoint2);

						days = days + 1;
						last = _this3.convertDate(days);
						count = 0;
					}
					while (days <= 6) {
						var dataPoint3 = {
							date: last,
							value: 0
						};
						data.push(dataPoint3);
						days = days + 1;
						last = _this3.convertDate(days);
					}

					window.line.setData(data);
					_this3.set("transactions", data.Transactions);
				});
			}
		}
	});
});