define('vopay_app/helpers/error-code-message', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports.errorCodeMessage = errorCodeMessage;

	function errorCodeMessage(params /*, hash*/) {
		var EFTErrorCodes = _vopay_appConstants['default'].ErrorCodes;
		if (EFTErrorCodes[params[0]]) {
			return EFTErrorCodes[params[0]].description;
		} else {
			return false;
		}
	}

	exports['default'] = _ember['default'].Helper.helper(errorCodeMessage);
});