define("vopay_app/components/bluk-listing", ["exports", "ember"], function (exports, _ember) {
   exports["default"] = _ember["default"].Component.extend({
      tagName: "",
      list: ["Pay", "Collect"],
      firebase: _ember["default"].inject.service('firebase'),
      service: "Pay",
      actions: {
         selectBeneficiary: function selectBeneficiary(value) {
            var _this = this;

            this.set('key', value);
            this.get("firebase").getSingleRecordUser("beneficiary", this.get("key")).then(function (user) {
               _this.set('beneficiary', user);
            });
         },
         toggleFavourite: function toggleFavourite() {
            var data = this.get("beneficiary.IsFavourite");
            if (data === true) {
               this.set("beneficiary.IsFavourite", false);
            } else {
               this.set("beneficiary.IsFavourite", true);
            }
            var data2 = this.get("beneficiary");
            this.get("firebase").updateRecordUser("beneficiary", this.get("key"), data2);
         },

         ChangeService: function ChangeService(value) {
            this.set("service", value);
         }
      }
   });
});