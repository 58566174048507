define('vopay_app/components/template-accounts', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		beneficiary: "",
		firebase: _ember['default'].inject.service('firebase'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		ServiceUsed: "Credit",
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			$(window).off("resize");
		},

		handleResize: function handleResize(e) {
			if (window.innerWidth <= 768) {
				this.set("desktop", false);
				this.set("mobile", true);
			} else {

				this.set("desktop", true);
				this.set("mobile", false);
			}
		},
		init: function init() {
			this._super.apply(this, arguments);
			if (this.get("PaymentType") == "Collect") {
				this.set("ServiceUsed", "Debit");
			}
		},
		didInsertElement: function didInsertElement() {
			this.handleResize(this);
			$(window).on('resize', this.handleResize.bind(this));
		},
		payment: function payment(listing, index, length, PaymentType, Service) {
			var _this = this;

			this.set("isProgress", true);
			var batch = {};
			var template = this.get("template");
			var listing3 = Object.values(listing);
			batch.BatchData = listing3;
			batch.TransactionType = Service;
			batch.PaymentType = PaymentType;
			batch.Length = this.get("number");
			batch.TotalAmount = this.get("CurrencyAmount")[template.Currency];
			batch.BatchName = template.GroupName;
			if (batch.TransactionType == "e-Transfer") {
				batch.TransactionType = "Interac";
			} else if (batch.TransactionType == "Pay Link") {
				batch.TransactionType = "Paylink";
			}
			this.payload(batch).then(function (data) {

				_this.finish(listing);
			}, function (error) {
				_this.set("isProgress", false);
				_this.errorMessage(error);
			});
		},
		payload: function payload(batch) {
			var self = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				self.get("api").acceptPayloadSendServer(batch).then(function (data) {
					batch.Message = "Successfully submitted the file to the server!";
					resolve(batch);
				}, function (error) {
					reject(error);
				});
			});
		},
		finish: function finish(listing) {

			this.set("listing2", listing);
			_ember['default'].$("#FileSummary").show();
			_ember['default'].$("#FileOverview").hide();
			this.sendAction('FinishHandler', "");
			_ember['default'].$('.complete').prop('disabled', false);
			this.set("isProgress", false);
		},
		errorMessage: function errorMessage(message) {
			this.set("ErrorMessage", message);
			this.set("isError", true);
		},
		actions: {
			selectBeneficiary: function selectBeneficiary(value) {
				var PaymentType = this.get("PaymentType");
				if (value == "New") {
					localStorage.setItem('location', window.location.href);
					if (PaymentType == "Payment") {
						localStorage.setItem('isPayment', true);
					}
					this.get("routing").transitionTo("dashboard.beneficiaries.create");
				} else {
					this.set("beneficiary", value);
				}
			},
			addPayee: function addPayee() {
				var _this2 = this;

				if (this.get("beneficiary") != '') {
					var Beneficiaries = this.get("beneficiaries");
					var beneficiary = Beneficiaries[this.get("beneficiary")];
					var data2 = {};
					data2.GroupID = this.get("key");
					data2.Token = beneficiary.Token;
					this.get("api").addContact(data2).then(function (contact) {
						_this2.get("api").getContacts(data2).then(function (contacts) {
							_this2.set("contacts", contacts.Contacts);
						});
					}, function (error) {
						_this2.errorMessage(error);
					});
				}
			},
			refreshbeneficiaries: function refreshbeneficiaries(value) {
				var _this3 = this;

				var data2 = {};
				data2.GroupID = this.get("key");
				this.get("api").getContacts(data2).then(function (contacts) {
					_this3.set("contacts", contacts.Contacts);
				});
			},
			review: function review() {
				var formArray = $("#bulk").serializeArray();
				var listing = [];
				var beneficiaries = this.get("contacts");
				var amount = 0;
				var number = 0;
				var CurrencyAmount = [];
				var template = this.get("template");
				for (var i = 0; i < formArray.length; i++) {
					if (formArray[i]['name'] == "Key") {
						var key = formArray[i]['value'];

						if (beneficiaries[key]) {
							var listingObject = Object.assign({}, beneficiaries[key]);
							var IsActive = false;
							var IsAmount = false;
							for (var j = i; j < formArray.length; j++) {
								if (IsActive && IsAmount) {
									break;
								}
								if (formArray[j]['name'] == "Activated-" + key) {
									listingObject.IsActive = formArray[j]['value'];
									IsActive = true;
								}
								if (formArray[j]['name'] == "Amount-" + key) {
									listingObject.DollarAmount = formArray[j]['value'].replace(/,/g, '');
									listingObject.Amount = formArray[j]['value'].replace(/,/g, '');
									listingObject.DollarAmount2 = formArray[j]['value'].replace(/,/g, '');
									IsAmount = true;
								}
							}

							if (listingObject.DollarAmount2 == "" || listingObject.DollarAmount2 == null || listingObject.IsActive == false || listingObject.IsActive == "false") {
								listingObject.DollarAmount = 0;
								listingObject.Amount = 0;
								listingObject.DollarAmount2 = "0";
							}
							if (!CurrencyAmount[template.Currency]) {
								CurrencyAmount[template.Currency] = 0;
							}
							if (!listingObject.Currency) {
								listingObject.Currency = template.Currency;
							}
							if (listingObject.AccountHolderName || listingObject.CreditCardHolderName) {
								var name = listingObject.AccountHolderName ? listingObject.AccountHolderName : listingObject.CreditCardHolderName;
								var nameParts = name.split(" ");
								if (nameParts.length > 2 || nameParts.length < 2) {
									listingObject["CompanyName"] = name;
								} else {
									listingObject["FirstName"] = nameParts[0];
									listingObject["LastName"] = nameParts[1];
								}
							}
							listingObject.Message = "Successfully submitted";
							CurrencyAmount[template.Currency] = parseFloat(CurrencyAmount[template.Currency]) + parseFloat(listingObject.DollarAmount2.replace(/,/g, ''));
							if (parseFloat(listingObject.DollarAmount2.replace(/,/g, '')) > 0 && listingObject.DollarAmount != 0) {
								delete listingObject.Status;
								delete listingObject.ContactID;
								delete listingObject.DollarAmount;
								delete listingObject.DollarAmount2;
								delete listingObject.AccountHolderName;
								listing.push(listingObject);
								number = number + 1;
							}
						}
					}
				}
				var listing2 = Object.assign({}, listing);
				this.set("listing", listing2);
				var CurrencyAmount2 = Object.assign({}, CurrencyAmount);
				this.set("CurrencyAmount", CurrencyAmount2);

				this.set("number", number);

				_ember['default'].$("#FileOverview").show();
				_ember['default'].$("#bulk").hide();
			},
			moveToBulk: function moveToBulk() {
				_ember['default'].$("#FileOverview").hide();
				_ember['default'].$("#bulk").show();
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			pdf: function pdf() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			csv: function csv() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xls: function xls() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			doc: function doc() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			text: function text() {

				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			json: function json() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xml: function xml() {
				setTimeout(function () {
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			printPage: function printPage() {
				setTimeout(function () {
					window.print();
				}, 200);
			},
			pay: function pay() {
				var _this4 = this;

				var listing = this.get("listing");
				var template = this.get("template");
				var PaymentType = this.get("PaymentType");
				_ember['default'].$('.complete').prop('disabled', true);

				if (Object.keys(listing).length == 0) {
					this.errorMessage("No transactions found");
					_ember['default'].$('.complete').prop('disabled', false);
				} else {

					if (PaymentType == "Payment") {
						var currency = null;
						var options = {};
						options.Currency = template.Currency;
						this.get("api").getAccountBalance(options).then(function (data) {
							if (parseFloat(data.AvailableFunds) >= _this4.get("CurrencyAmount")[template.Currency]) {
								_this4.payment(listing, 0, Object.keys(listing).length, "credit", template.Service);
							} else {
								_this4.errorMessage("Insufficient Funds");
								_ember['default'].$('.complete').prop('disabled', false);
							}
						}, function (error) {
							_this4.errorMessage("Unsupported Currency");
							_ember['default'].$('.complete').prop('disabled', false);
						});
					} else {
						this.payment(listing, 0, Object.keys(listing).length, "debit", template.Service);
					}
				}

				//    	for (var key in listing) {
				//    		var beneficiary = listing[key];
				// 		if(PaymentType == "Collect"){
				// 			this.get("api").fundTransaction(beneficiary).then(
				// 				transaction => {

				// 				},
				// 				() =>{

				// 		  		}
				// 			);
				// 		}
				// 		if(PaymentType == "Payment"){
				// 			this.get("api").withdrawTransaction(beneficiary).then(
				// 				transaction => {

				// 				},
				// 				() =>{

				// 		  		}
				// 			);
				// 		}
				// 	}
				// 	let self = this;
				//    	setTimeout(function(){ self.get("routing").transitionTo("dashboard.transactions"); }, 3000);
			}
		}
	});
});