define('vopay_app/routes/dashboard/balances', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		moveToTop: (function () {
			window.scrollTo(0, 0);
		}).on('activate'),
		model: function model() {

			var authed = this.get('session.isAuthenticated');
			var options = {};
			options.TransactionType = "Currency Conversion";
			if (authed) {

				return _ember['default'].RSVP.hash({
					currencies: this.get('api').getGCMCurriencies(),
					accountBalances: this._retrieveCurrency()
				});
			} else {
				this.transitionTo('login');
			}
		},
		_retrieveCurrency: function _retrieveCurrency() {
			var _this = this;

			var obj = {};
			return this.get('api').getGCMCurriencies().then(function () {
				return _this._retrieveInfo(obj, ["CAD", "USD", "GBP", "EUR"], 0);
			});
		},
		_retrieveInfo: function _retrieveInfo(obj, supportedCurrencies, index) {
			var _this2 = this;

			var self = this;
			return new _ember['default'].RSVP.Promise(function (resolve) {

				if (supportedCurrencies[index]) {
					var options = {};
					options.Currency = supportedCurrencies[index];
					_this2.get('api').getAccountBalance(options).then(function (data) {
						obj[supportedCurrencies[index]] = {};
						obj[supportedCurrencies[index]].Balance = data.AccountBalance;
						obj[supportedCurrencies[index]].SecurityDeposit = data.SecurityDeposit;
						obj[supportedCurrencies[index]].AvailableFunds = data.AvailableFunds;
						obj[supportedCurrencies[index]].PendingFunds = data.PendingFunds;

						if (obj[supportedCurrencies[index]].Balance === "0.00" || obj[supportedCurrencies[index]].Balance === "0") {
							obj[supportedCurrencies[index]].isActive = false;
							resolve(self._retrieveInfo(obj, supportedCurrencies, index + 1));
						} else {
							obj[supportedCurrencies[index]].isActive = true;
							resolve(self._retrieveInfo(obj, supportedCurrencies, index + 1));
						}
					});
				} else {
					obj["DKK"] = {
						isActive: false
					};
					obj["SEK"] = {
						isActive: false
					};
					obj["NOK"] = {
						isActive: false
					};
					obj["PLN"] = {
						isActive: false
					};
					obj["AUD"] = {
						isActive: false
					};
					resolve(obj);
				}
			});
		}

	});
});