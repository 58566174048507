define('vopay_app/utils/date-util', ['exports', 'ember'], function (exports, _ember) {

  // const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var monthsMin = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  // const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // const weekDaysMin = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  exports['default'] = _ember['default'].Object.extend({

    /**
    * ex: Oct 03, 2016
    *
    **/
    dateStringMin: function dateStringMin(date, time) {
      var day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
      var month = monthsMin[date.getMonth()];
      var year = date.getFullYear();
      var hasTime = time ? date.getHours() + ':' + date.getMinutes() + ' PST' : '';
      return month + ' ' + day + ', ' + year + ' ' + hasTime;
    },

    future: function future(date, days) {

      return new Date(date.setDate(date.getDate() + days || 0));
    },

    formatStringDate: function formatStringDate(date, strDelimiter) {

      strDelimiter = strDelimiter || '/';
      var dateFormated = new Date(date);

      if (!date || dateFormated.toString === 'Invalid Date') {
        return date;
      }

      var month = '' + (dateFormated.getMonth() + 1);
      month = month.length === 1 ? '0' + month : month;
      var day = dateFormated.getDate().toString().length === 1 ? '0' + dateFormated.getDate() : dateFormated.getDate();
      var year = dateFormated.getFullYear();
      return '' + month + strDelimiter + day + strDelimiter + year;
    }

  });
});