define('vopay_app/components/reconciliation-page', ['exports', 'ember'], function (exports, _ember) {
				exports['default'] = _ember['default'].Component.extend({
								Token: null,
								isIQ11: false,
								api: _ember['default'].inject.service('api'),
								transactions: null,
								didInsertElement: function didInsertElement() {

												this._super.apply(this, arguments);

												window.addEventListener('message', function (e) {
																if (e.data.step == "ACCOUNT_SELECTED") {
																				localStorage.setItem("accountId", e.data.accountId);
																}
																if (e.data.step == "REDIRECT") {
																				localStorage.setItem("loginId", e.data.loginId);
																				localStorage.setItem("institution", e.data.institution);
																				//location.href = e.data.url;
																}
																if (e.data.Step == "LINK") {
																				localStorage.setItem("institution", e.data.Institution);
																				localStorage.setItem("AccountNumber", e.data.AccountNumber);
																				localStorage.setItem("BankId", e.data.BankId);
																				localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
																				localStorage.setItem("FlinksToken", e.data.Token);
																}
												});
								},
								actions: {

												closeModaliQ11: function closeModaliQ11() {
																this.set("isIQ11", false);
																clearInterval(window.Iq11Interval);
												},
												showiq11Frame: function showiq11Frame() {
																this.set("isIQ11", true);
																var self = this;
																window.Iq11Interval = setInterval(function () {

																				if (localStorage.getItem("FlinksToken") != null) {

																								self.set("Token", localStorage.getItem("FlinksToken"));
																								localStorage.removeItem("FlinksToken");
																								self.set("isIQ11", false);

																								self.get('api').getReconciliationTransactions().then(function (data) {
																												self.set("transactions", data);
																												clearInterval(window.Iq11Interval);
																								});
																				}
																}, 100);
												}

								}
				});
});