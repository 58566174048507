define('vopay_app/components/client-account-details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);

			var data = this.get('data');
			data.Status = this.getStatus();

			this.set('ClientAccount', data);
		},
		getStatus: function getStatus() {
			var map = { '1': 'Active', '0': 'Not Active' };
			return map[this.get('data').IsActive];
		}
	});
});