define("vopay_app/components/claim-funds", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		isComplete: false,
		actions: {
			Confirm: function Confirm() {
				this.set("isComplete", true);
			}
		}

	});
});