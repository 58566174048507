define('vopay_app/components/currency-widget', ['exports', 'ember', 'vopay_app/utils/pooler'], function (exports, _ember, _vopay_appUtilsPooler) {
  exports['default'] = _ember['default'].Component.extend({
    api: _ember['default'].inject.service('api'),
    poolerUtil: _vopay_appUtilsPooler['default'].create(),
    countries: [],
    rates: [],
    availableDeliveryMethods: [],
    sell: {
      amount: "0",
      currency: "CAD",
      country: "Canada"
    },
    buy: {
      amount: "100,000.00",
      currency: "USD",
      country: "United States"
    },
    isSell: true,
    sameamount: true,
    exchangeRate: 0,
    exchangeRateNo: 0,
    currencies2: {

      CAD: {
        name: 'Canadian Dollar',
        iso3: 'CAD'
      },

      EUR: {
        name: 'Euro',
        iso3: 'EUR'
      },

      GBP: {
        name: 'Pound Sterling',
        iso3: 'GBP'
      },

      USD: {
        name: 'United States Dollar',
        iso3: 'USD'
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var currencies2 = this.get("currencies2");
      var currencies = this.get("currencies.CurrenciesList");
      var currencies3 = {};
      for (var key in currencies) {
        if (currencies2[currencies[key]]) {
          currencies3[currencies[key]] = {};
          currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
          currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
        }
      }

      this.set("currencies3", currencies3);
      //let country = this.get('buy.country');
      this.updateExchangeRate();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$("#businessWidget input").first().focus();
      _ember['default'].$('.loader').hide();
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
    },
    getCountry: function getCountry(country) {
      return this.get('countries').filter(function (ctr) {
        return ctr.Country.toUpperCase() === country.toUpperCase();
      })[0];
    },
    getDeliveryMethods: function getDeliveryMethods(country) {
      var ctr = this.getCountry(country);
      return {
        Airtime: !!parseInt(ctr.Airtime),
        MoneyTransfer: !!parseInt(ctr.MoneyTransfer),
        BillPayment: !!parseInt(ctr.BillPayment),
        Voucher: !!parseInt(ctr.Voucher)
      };
    },
    updateSellAmountValue: function updateSellAmountValue(amount) {
      var _this = this;

      this.get("api").getGCMQuote({
        SellCurrency: this.get('sell.currency'),
        SellAmount: amount,
        BuyCurrency: this.get('buy.currency')
      }).then(function (quote) {
        _this.set("buy.amount", quote.BuyAmount.toLocaleString());
      });
    },
    updateBuyAmountValue: function updateBuyAmountValue(amount) {
      var _this2 = this;

      this.get("api").getGCMQuote({
        SellCurrency: this.get('sell.currency'),
        BuyAmount: amount,
        BuyCurrency: this.get('buy.currency')
      }).then(function (quote) {
        _this2.set("sell.amount", quote.SellAmount.toLocaleString());
      });
    },
    updateExchangeRate: function updateExchangeRate() {

      this.updateBuyAmountValue(parseFloat(this.get("buy.amount").replace(/\,/g, "")));
    },
    actions: {
      sellAmountChanged: function sellAmountChanged(amount) {
        this.set("isSell", true);
        var amount2 = parseFloat(amount.replace(/\,/g, ""));

        this.updateSellAmountValue(amount2);
      },
      buyAmountChanged: function buyAmountChanged(amount) {
        this.set("isSell", false);
        var amount2 = parseFloat(amount.replace(/\,/g, ""));

        this.updateBuyAmountValue(amount2);
      },
      buyCurrencyChanged: function buyCurrencyChanged(currency) {

        if (currency === "Other") {
          _ember['default'].$("#suggestion").show();
          _ember['default'].$(".popup__bg").show();
        } else {
          var _self = this;
          //get the currency and delivery methods from our countrie list

          //set currency and delivery methods
          this.set('buy.currency', currency);

          this.updateExchangeRate(this.get('sell.amount'));
          //TODO need promise here

          _ember['default'].$.event.trigger({
            type: "updateRateUrls",
            currency: _self.get('buy.currency')
          });
        }
      },
      completeTransaction: function completeTransaction() {
        var _this3 = this;

        if (this.get("isSell")) {
          var amount = parseFloat(this.get('sell.amount').replace(/\,/g, ""));
          this.get("api").getGCMQuote({
            SellCurrency: this.get('sell.currency'),
            SellAmount: amount,
            BuyCurrency: this.get('buy.currency')
          }).then(function (quote) {
            _this3.set("buy.amount", quote.BuyAmount);
          });
        } else {
          var amount2 = parseFloat(this.get('buy.amount').replace(/\,/g, ""));
          this.get("api").getGCMQuote({
            SellCurrency: this.get('sell.currency'),
            BuyAmount: amount2,
            BuyCurrency: this.get('buy.currency')
          }).then(function (quote) {
            _this3.set("sell.amount", quote.SellAmount);
          });
        }
        _ember['default'].$("#overviewTransactionForm").hide();
        _ember['default'].$("#transactionConfirmation").show();
      },
      moveToWidget: function moveToWidget() {
        _ember['default'].$("#transactionConfirmation").hide();
        _ember['default'].$("#overviewTransactionForm").show();
      },
      moveToTransactionResponse: function moveToTransactionResponse() {
        var _this4 = this;

        if (this.get("isSell")) {
          var amount = parseFloat(this.get('sell.amount').replace(/\,/g, ""));
          this.get("api").getGCMConversion({
            SellCurrency: this.get('sell.currency'),
            SellAmount: amount,
            BuyCurrency: this.get('buy.currency')
          }).then(function (quote) {
            _this4.set("buy.amount", quote.BuyAmount);
            _ember['default'].$("#transactionConfirmation").hide();
            _ember['default'].$("#transactionResponse").show();
          });
        } else {
          var amount2 = parseFloat(this.get('buy.amount').replace(/\,/g, ""));
          this.get("api").getGCMConversion({
            SellCurrency: this.get('sell.currency'),
            BuyAmount: amount2,
            BuyCurrency: this.get('buy.currency')
          }).then(function (quote) {
            _this4.set("sell.amount", quote.SellAmount);
            _ember['default'].$("#transactionConfirmation").hide();
            _ember['default'].$("#transactionResponse").show();
          });
        }
      },
      redisplayWidget: function redisplayWidget() {
        this.sendAction("transactionSumbitted");
        _ember['default'].$("#transactionResponse").hide();
        _ember['default'].$("#overviewTransactionForm").show();
      }
    }
  });
});