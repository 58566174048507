define("vopay_app/components/batch-transactions-list", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        pageSize: 20,
        keyword: "",
        statuses: ["All", "Requested", "Pending", "In Progress", "Sent", "Successful", "Settled", "Failed", "Cancelled", "Cancellation Requested", "Waiting"],
        status: "all",
        init: function init() {
            this._super.apply(this, arguments);
            this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
        },
        filterTransactions: function filterTransactions(searchTerms, status, transactions, page) {
            var filteredList = {};
            searchTerms = searchTerms.toLowerCase();
            searchTerms = searchTerms.trim();
            if (transactions) {
                var keys = Object.keys(transactions);
                var next = -1;
                for (var i = 0; i < keys.length; i++) {
                    if (typeof transactions[i] !== 'undefined') {
                        if (status === null || status === "" || status === "all" || status === transactions[i].Status) {

                            if (((transactions[i].FirstName + "").toLowerCase().trim() + " " + (transactions[i].LastName + "").toLowerCase().trim()).includes(searchTerms)) {
                                next = next + 1;
                                filteredList[next] = transactions[i];
                            } else if (transactions[i].ID && transactions[i].ID.toString().toLowerCase().trim().includes(searchTerms)) {
                                next = next + 1;
                                filteredList[next] = transactions[i];
                            } else if (transactions[i].Amount && transactions[i].Amount.toString().toLowerCase().trim().includes(searchTerms)) {
                                next = next + 1;
                                filteredList[next] = transactions[i];
                            } else if (searchTerms === null || searchTerms === "") {
                                next = next + 1;
                                filteredList[next] = transactions[i];
                            }
                        }
                    }
                }

                _ember["default"].$('#searchTransactions').addClass('searchHide');
                this.set("filteredList", filteredList);
                this.getTransactions(page, this.get("filteredList"));
            }
        },

        getTransactions: function getTransactions(pageNumber, transactions) {
            var pageSize = this.get("pageSize");
            var filteredList = {};
            if (transactions) {
                var keys = Object.keys(transactions);
                for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
                    var next = i + 1;
                    filteredList[next] = transactions[i];
                }
                this.set("recordCount", keys.length);
                this.set("pageNumber", pageNumber);
                this.set("filteredList", filteredList);
            } else {
                this.set("recordCount", 0);
                this.set("pageNumber", 1);
            }
        },
        actions: {
            getPage: function getPage(pageNumber) {
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), pageNumber);
            },
            filterKeyword: function filterKeyword() {
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
            },
            filterStatus: function filterStatus(status) {
                this.set("status", status.toLowerCase());
                this.filterTransactions(this.get("keyword"), status.toLowerCase(), this.get("transactions"), 1);
            },
            pdf: function pdf() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;

                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            csv: function csv() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            xls: function xls() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'xlsx', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            doc: function doc() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            text: function text() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                    _ember["default"].$('#tableID').css("display", "");
                }, 200);
                return false;
            },
            json: function json() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').css("display", "block !important");
                    _ember["default"].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            xml: function xml() {
                this.set("pageSize", this.get("recordCount"));
                var pageNumberCurrent = this.get("pageNumber");
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;
                setTimeout(function () {
                    //your code to be executed after 1 second
                    _ember["default"].$('#tableID').addClass("importantShow");
                    _ember["default"].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
                    _ember["default"].$('#tableID').removeClass("importantShow");
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
                return false;
            },
            printPage: function printPage() {

                var pageNumberCurrent = this.get("pageNumber");
                this.set("pageSize", this.get("recordCount"));
                this.filterTransactions(this.get("keyword"), this.get("status"), this.get("transactions"), 1);
                var self = this;

                setTimeout(function () {
                    window.print();
                    self.set("pageSize", 20);
                    self.filterTransactions(self.get("keyword"), self.get("status"), self.get("transactions"), pageNumberCurrent);
                }, 200);
            }
        }
    });
});