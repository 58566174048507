define('vopay_app/components/overview-transaction', ['exports', 'ember', 'vopay_app/utils/pooler'], function (exports, _ember, _vopay_appUtilsPooler) {
  exports['default'] = _ember['default'].Component.extend({
    api: _ember['default'].inject.service('api'),
    poolerUtil: _vopay_appUtilsPooler['default'].create(),
    countries: [],
    availableDeliveryMethods: [],
    watchSenderAmount: _ember['default'].observer('sender.amount', function () {
      if (this.get('sender.amount') > 999) {
        this.set('sender.amount', 999);
      }
      if (!this.get('sender.amount')) {
        this.set('totalToPay', "N/A");
        this.set('recipient.amount', "N/A");
        this.set('fee', "N/A");
      }
    }),
    watchSameAmount: _ember['default'].observer('sameamount', function () {
      var amount = this.get("sender.amount");
      this.updateSendAmountValue(amount);
    }),
    sender: {
      amount: 300,
      currency: "CAD",
      country: "Canada"
    },
    recipient: {
      amount: 0,
      currency: "USD",
      country: "United States"
    },
    sameamount: true,
    lastAmount: 100,
    maxAmout: 999,
    minAmout: 1,
    arrivalDate: _ember['default'].computed('rates', function () {
      var arrivalDate = this.get('rates.StandardQuote.EstimatedArrivalDate');
      return arrivalDate;
    }),
    deliverySelectClass: null,
    paymentSelectClass: null,
    transaction: {}, //RemittanceRecipientID, BankAccountID, AmountSendCAD, Purpose
    //exchangeRate: null,
    exchangeRate: _ember['default'].computed('rates', function () {

      var rates = this.get('rates.StandardQuote.ExchangeRate');
      return parseFloat(rates).toFixed(4);
    }),
    fee: _ember['default'].computed('rates', function () {

      var fee = this.get('rates.StandardQuote.TransactionFee');
      return parseFloat(fee).toFixed(2);
    }),

    totalPrice: _ember['default'].computed('rates', function () {
      var total = this.get('rates.StandardQuote.RecipientAmount');
      return parseFloat(total);
    }),

    savings: _ember['default'].computed('recipient.amount', 'recipient.currency', 'totalToPay', function () {
      var bankExchange = {
        USD: 1.37510,
        EUR: 1.47831,
        DKK: 0.20020,
        SEK: 0.15270,
        GBP: 1.72019,
        NOK: 0.16450,
        PLN: 0.35080,
        SGD: 0.96824,
        CNY: 0.20620,
        PHP: 0.02902,
        INR: 0.01979
      };
      if (this.get("totalToPay") && this.get("totalToPay") !== "N/A") {
        var bankAmount = this.get("recipient.amount") * bankExchange[this.get("recipient.currency")] + 13.50;
        if (parseFloat(bankAmount - this.get("totalToPay")).toFixed(2) > 0 && parseFloat(bankAmount - this.get("totalToPay")).toFixed(2) < 200) {
          return parseFloat(bankAmount - this.get("totalToPay")).toFixed(2);
        } else {
          return "N/A";
        }
      } else {
        return "N/A";
      }
    }),
    rates: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var country = this.get('recipient.country');
      // get Countries from the API
      this.get('api').getCountries().then(function (data) {
        _this.set('countries', data);
        var currency = _this.getCountry(country).DefaultCurrency;
        var deliveryMethods = _this.getDeliveryMethods(country);
        //set currency and delivery methods
        _this.set('availableDeliveryMethods', deliveryMethods);
        _this.set('recipient.currency', currency);
        localStorage.setItem('recipientCurrency', _this.get('recipient.currency'));
      });
      this.updateSendAmountValue(this.get('sender.amount'));
      this.filterRecipients();
    },
    /***
       * filterRecipients
       *
       * Filter Recipients by Country and Currency
       */
    filterRecipients: function filterRecipients() {
      var _this2 = this;

      var ct = this.get('countries').filter(function (fl) {
        return fl.Country.toUpperCase() === _this2.get('recipient.country').toUpperCase();
      });
      var arrUserRecipients = [].concat(this.get('userRecipients')).filter(function (f) {
        var jsonBank = JSON.parse(f.BankAccount);
        return f.Currency === _this2.get('recipient.currency') && jsonBank['bank_country'] === ct[0].Abbreviation;
      });

      this.set('recipientsFiltered', arrUserRecipients);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('.loader').hide();
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
    },

    getCountry: function getCountry(country) {
      return this.get('countries').filter(function (ctr) {
        return ctr.Country.toUpperCase() === country.toUpperCase();
      })[0];
    },

    getDeliveryMethods: function getDeliveryMethods(country) {
      var ctr = this.getCountry(country);
      return {
        Airtime: !!parseInt(ctr.Airtime),
        MoneyTransfer: !!parseInt(ctr.MoneyTransfer),
        BillPayment: !!parseInt(ctr.BillPayment),
        Voucher: !!parseInt(ctr.Voucher)
      };
    },

    /***
    * getDeliveryPrice
    * @param method (String) - The delivery method
    *
    * Returns the price based on the delivery method
    */
    getDeliveryPrice: function getDeliveryPrice(method) {
      var price = null;

      switch (method) {
        case "Bank Transfer":
          price = 0;
          break;
        case "Pickup":
          price = 0;
          break;
        default:
          price = 0;
      }

      return price;
    },

    /***
    * getPaymentPrice
    * @param method (String) - The payment method
    *
    * Returns the price based on the payment method
    */
    getPaymentPrice: function getPaymentPrice(method) {
      var price = null;

      switch (method) {
        case "Debit Card":
          price = 0;
          break;
        case "Credit Card":
          price = 0.03;
          break;
        default:
          price = 0;
      }

      return price;
    },

    /***
    * updateExchangeRate
    *
    * Gets and updates the exchange rate
    */
    updateExchangeRate: function updateExchangeRate() {
      var _this3 = this;

      var self = this;

      this.get('api').getQuotes({
        SendingAmount: this.get('sender.amount'),
        SendingCurrency: this.get('sender.currency'),
        RecipientCurrency: this.get('recipient.currency'),
        PaymentMethod: 'Bank'
      }).then(function (quote) {

        var newRate = parseFloat(quote.StandardQuote.ExchangeRate).toFixed(4);
        var fee = parseFloat(quote.StandardQuote.TransactionFee).toFixed(2);
        self.set('exchangeRate', newRate);

        _ember['default'].$(".exchange-rate__price").html("1 CAD = " + _this3.get('exchangeRate') + " " + _this3.get('recipient.currency'));
        _ember['default'].$(".exchange-rate__subtitle a:nth-child(1)").attr("href", "https://www.google.ca/finance?q=CAD" + _this3.get('recipient.currency'));
        _ember['default'].$(".exchange-rate__subtitle a:nth-child(2)").attr("href", "https://ca.finance.yahoo.com/q?s=CAD" + _this3.get('recipient.currency') + "=X");

        self.set('fee', fee);
      });
    },
    /***
    * updateSendAmountValue
    * @param amount (String) - The delivery method
    *
    * Updates the sender value amount
    */
    updateSendAmountValue: function updateSendAmountValue(amount) {

      var self = this;
      var pool = this.get('poolerUtil');
      pool.setInterval(500);
      pool.stop();

      this.set("sender.amount", amount);
      //validate
      if (amount) {

        pool.start(this, function () {
          var _this4 = this;

          // Get fee based amount send TODO - Component is not place to call data (it should come from Model - Ember Data)
          var getQuotes = new _ember['default'].RSVP.Promise(function (resolve, reject) {
            pool.stop();
            return _this4.get('api').getQuotes({
              SendingAmount: amount,
              SendingCurrency: self.get('sender.currency'),
              RecipientCurrency: self.get('recipient.currency'),
              PaymentMethod: 'Bank'
            }).then(function (quote) {

              resolve(quote);
            }, function (err) {

              reject(err);
            });
          });
          _ember['default'].$("#currencyConverterLoader").show();
          getQuotes.then(function (quote) {

            var fee = quote.StandardQuote.TransactionFee;
            var exchangeRate = quote.StandardQuote.ExchangeRate;
            var arrivalDate = quote.StandardQuote.EstimatedArrivalDate;
            var totalPay = 0;
            var ckd = _this4.get('sameamount');

            _this4.set('arrivalDate', arrivalDate);

            _this4.set('fee', fee);

            if (_this4.get('exchangeRate') !== exchangeRate) {

              _this4.set('exchangeRate', parseFloat(exchangeRate).toFixed(4));
              _ember['default'].$(".exchange-rate__price").html("1 CAD = " + _this4.get('exchangeRate') + " " + _this4.get('recipient.currency'));
              _ember['default'].$(".exchange-rate__subtitle a:nth-child(1)").attr("href", "https://www.google.ca/finance?q=CAD" + _this4.get('recipient.currency'));
              _ember['default'].$(".exchange-rate__subtitle a:nth-child(2)").attr("href", "https://ca.finance.yahoo.com/q?s=CAD" + _this4.get('recipient.currency') + "=X");
            }

            if (ckd) {

              if (amount && amount >= fee) {

                var amountNumber = amount !== amount ? 0 : parseFloat(amount);
                var difference = 0;

                amountNumber = amountNumber < fee ? fee : amountNumber;
                difference = amountNumber - fee;
                totalPay = parseFloat(fee) + parseFloat(difference);

                _this4.set('recipient.amount', quote.FeeIncludedQuote.RecipientAmount.toLocaleString());
                _this4.set('lastAmount', amountNumber);
                _this4.set('totalToPay', totalPay.toFixed(2));
              }

              _this4.set('minAmout', fee);
              if (amount < fee) {
                //fee validation
                setTimeout(function () {
                  _ember['default'].$("#overviewTransactionForm").parsley().validate();
                }, 0);
                _this4.set('totalToPay', "N/A");
                _this4.set('recipient.amount', "N/A");
                _this4.set('fee', "N/A");
              }
            } else {
              // not checked

              totalPay = parseFloat(fee) + parseFloat(amount);
              _this4.set('recipient.amount', quote.StandardQuote.RecipientAmount.toLocaleString());
              _this4.set('lastAmount', amount ? amount : 0);
              _this4.set('totalToPay', totalPay.toFixed(2));
              _this4.set('minAmout', 1);
            }

            _ember['default'].$("#currencyConverterLoader").hide();
            setTimeout(function () {
              //Ember.$("#senderAmountForm").parsley().validate();
            }, 500);
          });
        });
      }
    },

    /***
    * resetMethods
    *
    * resets the states and values for delivery and payment method
    */
    resetMethods: function resetMethods() {
      //reset delivery and payment methods
      this.set('deliveryMethod', "Choose Delivery Method");
      this.set('paymentMethod', "Choose Payment Method");
      _ember['default'].$(".customSelDd select").prop('selectedIndex', 0);
      this.set('deliverySelectClass', '');
      this.set('paymentSelectClass', '');
    },

    /***
    * showPopup
    *
    * Triggers event to trigger popup
    */
    showPopup: function showPopup() {
      _ember['default'].$.event.trigger({
        type: "triggerPopup",
        popup: "beta"
      });
    },

    actions: {
      /***
        * CompleteTransaction
        *
        * Processes the transaction
        */
      completeTransaction: function completeTransaction() {

        if (_ember['default'].$('#sender__confirmation input').is(':checked')) {
          var bankAccounts = this.get('availablePaymentMethods.BankAccounts');
          var trans = {
            RemittanceRecipientID: this.get('transaction.RemittanceRecipientID'),
            PaymentMethod: 'Bank',
            BankAccountID: bankAccounts[0].BankAccountID,
            AmountSendCAD: this.get('sender.amount'),
            AmountRecive: this.get('recipient.amount'),
            AmountRecieveCurrency: this.get('recipient.currency')
          };
          this.get('api').performTransaction(trans).then(location.href = '#/dashboard/transactions');
        }
      },
      /***
      * recipientCurrencyChanged
      * @param currency (String) - Currency to set
      *
      * Sets the currency
      */
      recipientCurrencyChanged: function recipientCurrencyChanged(country) {
        var _this5 = this;

        if (country === "Other") {
          _ember['default'].$("#suggestion").show();
        } else {
          var _self = this;
          //get the currency and delivery methods from our countrie list
          var ctr = this.getCountry(country);
          var currency = ctr.DefaultCurrency;
          var deliveryMethods = this.getDeliveryMethods(country);

          this.set('recipient.country', country);

          //set currency and delivery methods
          this.set('availableDeliveryMethods', deliveryMethods);
          this.set('recipient.currency', currency);

          this.updateExchangeRate();

          //TODO need promise here
          this.updateSendAmountValue(this.get('sender.amount'));

          _ember['default'].$.event.trigger({
            type: "updateRateUrls",
            currency: _self.get('recipient.currency')
          });
          var params = {
            SendingAmount: this.get('sender.amount'),
            SendingCurrency: 'CAD',
            RecipientCurrency: currency,
            PaymentMethod: 'Bank' // TODO - Fixed payment method
          };
          this.get('api').getQuotes(params).then(function (data) {
            var arrivalDate = data.StandardQuote.EstimatedArrivalDate;
            _this5.set('arrivalDate', arrivalDate);
          });
          this.resetMethods();
        }
      },

      /***
      * senderAmountChanged
      * @param amount (Int) - Amount to set
      *
      * Sets the sender amount
      */
      senderAmountChanged: function senderAmountChanged(amount) {
        this.updateSendAmountValue(amount);
      },

      /***
      * recipientAmountChanged
      * @param amount (Int) - Amount to set
      *
      * Sets the recipient amount
      */
      recipientAmountChanged: function recipientAmountChanged(amount) {
        this.updateRecipientAmountValue(amount);
      },

      /***
      * deliveryChanged
      * @param method (String) - The delivery method
      *
      * Sets the delivery method
      */
      deliveryChanged: function deliveryChanged(method) {
        this.set('deliveryMethod', method);

        var styleClass = method;
        styleClass = styleClass.replace(/\s/g, '');
        styleClass = styleClass.toLowerCase();

        this.set('deliverySelectClass', "customDd--active");
      },
      /***
       * recipientChanged
       * @param option (String) - Flag for showing the recipient form
       *
       * The step that we want to edit
       */
      recipientChanged: function recipientChanged(name, id) {
        if (name === 'new') {
          location.href = '#/transfer';
        } else {
          this.set('recipient.name', name);
          this.set('transaction.RemittanceRecipientID', id);
        }
      },
      /***
      * paymentChanged
      * @param method (String) - The payment method
      *
      * Sets the payment method
      */
      paymentChanged: function paymentChanged(method) {

        if (method === null) {
          location.href = '#/transfer';
        } else {
          this.set('paymentMethod', method);

          var styleClass = method;
          styleClass = styleClass.replace(/\s/g, '');
          styleClass = styleClass.toLowerCase();

          this.set('paymentSelectClass', "customDd--active");
        }
      },

      selectDeliveryChanged: function selectDeliveryChanged(method) {
        this.set('deliverySelectClass', "customDd--active");
        this.send('deliveryChanged', method);
      },

      selectPaymentChanged: function selectPaymentChanged(method) {
        this.set('paymentSelectClass', "customDd--active");
        this.send('paymentChanged', method);
      }

    }
  });
});