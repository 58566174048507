define('vopay_app/routes/dashboard/users/permissions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        api: _ember['default'].inject.service('api'),
        userPermissions: _ember['default'].inject.service('user-permissions'),
        model: function model(params) {
            this.get("userPermissions").HasPermissionRoute("ManageUserPermissions");
            var permissionsOption = {
                EmailAddress: params.Id
            };

            return _ember['default'].RSVP.hash({
                Permissions: this.get('api').getUserPermissions(permissionsOption)
            });
        }
    });
});