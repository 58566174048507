define('vopay_app/components/currencies-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selectedCurrency: null,
    menuActive: false,
    tagName: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(".currencyInput_content option[value=\"" + this.get("country") + "\"]").prop('selected', true);
    },
    toggleMenu: function toggleMenu() {

      if (!this.get('menuActive')) {
        this.set('menuActive', true);
      } else {
        this.set('menuActive', false);
      }
    },
    actions: {
      /***
      * currencyChanged
      * @param country (String) - Country to set
      * @param currency (String) - Currency to set
      *
      * Sets the country and currency, sends to top level component
      */
      currencyChanged: function currencyChanged(currency) {
        this.sendAction('currencyChanged', currency);
        _ember['default'].$(".currencyInput_content option[value=\"" + currency + "\"]").prop('selected', true);
        this.toggleMenu();
      },

      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },

      /***
      * showPopup
      *
      * Triggers event to trigger popup
      */
      showPopup: function showPopup() {
        _ember['default'].$.event.trigger({
          type: "triggerPopup",
          popup: "suggestion"
        });

        this.toggleMenu();
      },

      /***
        Activate proper action on element selection
      */
      selectChanged: function selectChanged(currency) {
        if (currency === "other") {

          this.send('showPopup');
        } else {

          this.send('currencyChanged', currency);
        }
      },

      senderAmountChanged: function senderAmountChanged(amount) {
        var amount2 = parseFloat(amount.replace(/,/g, ''));
        this.set("amount", amount2.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        this.sendAction('senderAmountChanged', amount2.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      }

    }
  });
});