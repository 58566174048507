define('vopay_app/initializers/html5-attributes', ['exports', 'ember'], function (exports, _ember) {
		exports.initialize = initialize;

		function initialize() {
				// application.inject('route', 'foo', 'service:foo');
				_ember['default'].TextField.reopen({
						attributeBindings: ['required', 'data-toggle', 'aria-expanded', 'autocomplete', 'readonly', 'disabled']
				});
				_ember['default'].LinkComponent.reopen({
						attributeBindings: ['required', 'data-toggle', 'aria-expanded', 'autocomplete', 'readonly', 'name', 'disabled']
				});
		}

		exports['default'] = {
				name: 'html5-attributes',
				initialize: initialize
		};
});