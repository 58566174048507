define('vopay_app/components/overview-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		init: function init() {

			this._super.apply(this, arguments);
			var count = 0;
			var transactions = this.get("transactions");
			if (transactions) {
				var transactionsList = {};
				var keys = Object.keys(transactions).reverse();

				for (var i = 0; i < keys.length; i++) {
					var next = count + 1;
					transactionsList[next] = transactions[keys[i]];
					if (count < 5) {
						++count;
					}
				}
				this.set("transactions", transactionsList);
			}
		},
		actions: {
			moveToTransfer: function moveToTransfer() {
				this.get("routing").transitionTo("transfer");
			}
		}
	});
});