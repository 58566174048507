define("vopay_app/routes/claim", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Route.extend({
		amount: 1,
		beforeModel: function beforeModel(transition) {

			this.set("amount", transition.queryParams.amount);
		},
		model: function model() {
			return _ember["default"].RSVP.hash({
				Amount: this.get("amount")
			});
		}
	});
});