define("vopay_app/helpers/has-description", ["exports", "ember"], function (exports, _ember) {
  exports.hasDescription = hasDescription;

  function hasDescription(params /*, hash*/) {

    if (params[0]) {

      if (params[1]) {

        if (moment().format("YYYY-MM-DD") >= params[1]) {
          // Date equals today's date
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(hasDescription);
});