define('vopay_app/models/transaction', ['exports', 'ember-data'], function (exports, _emberData) {

  /**
  * TODO - Convert all API calls to Ember DATA
  *
  */
  exports['default'] = _emberData['default'].Model.extend({
    RemittanceTransactionID: _emberData['default'].attr('string'),
    PaymentMethodID: _emberData['default'].attr('string'),
    PaymentMethod: _emberData['default'].attr('string'),
    AmountSendCAD: _emberData['default'].attr('string'),
    AmountReceive: _emberData['default'].attr('string'),
    ReceiveCurrency: _emberData['default'].attr('string'),
    HasBeenProcessed: _emberData['default'].attr('string'),
    Purpose: _emberData['default'].attr('string'),
    RemittanceRecipientID: _emberData['default'].attr('string'),
    RemittanceRecipient: _emberData['default'].attr('string'),
    DepositStatus: _emberData['default'].attr('string')
  });
});