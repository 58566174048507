define('vopay_app/helpers/spaces-between', ['exports', 'ember'], function (exports, _ember) {
  exports.spacesBetween = spacesBetween;

  function spacesBetween(params /*, hash*/) {

    if (!params[0]) {
      return params[0];
    }

    if (!params[1] || typeof params[1] !== 'number') {
      return params[0];
    }

    if (!params[2] || typeof params[2] !== 'number') {
      return params[0];
    }

    var value = '';
    var qty = params[1];
    var startsWith = params[2];

    for (var v = 0; v <= params[0].length; v += qty) {
      if (v <= 0) {
        value += params[0].substring(v, v + startsWith) + " ";
        continue;
      }
      value += params[0].substring(v - startsWith, v - startsWith + qty) + " ";
    }

    return value.trim();
  }

  exports['default'] = _ember['default'].Helper.helper(spacesBetween);
});