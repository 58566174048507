define('vopay_app/components/authorized-ips-page', ['exports', 'ember'], function (exports, _ember) {

	var WILDCARD = '*';

	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);

			this.set('authorizedIPs', this.splitList(this.get('authorizedIPs.AuthorizedIPs')));
			this.set('isWildCard', this.get('userInputValue') === WILDCARD);
			this.set('hasNoIPs', this.isEmptyList());
		},
		updateErrorMessage: function updateErrorMessage(message) {
			this.set('ErrorMessage', message);
			this.set('isError', true);
		},
		updateAuthorizedIPsList: function updateAuthorizedIPsList(authorizedIPs) {
			this.set('authorizedIPs', this.splitList(authorizedIPs));
		},
		splitList: function splitList(list) {
			return list ? list.split(',') : '';
		},
		joinList: function joinList(list) {
			return list ? list.join(', ') : '';
		},
		isEmptyList: function isEmptyList() {
			return this.get('authorizedIPs').length === 0;
		},
		nullifyEmptyList: function nullifyEmptyList(list) {
			return list === '' ? null : list;
		},
		actions: {
			checkForWildCard: function checkForWildCard() {
				this.set('isWildCard', this.get('userInputValue') === WILDCARD);
			},
			closeModalError: function closeModalError() {
				this.set('isError', false);
			},
			updateAuthorizedIPs: function updateAuthorizedIPs() {
				var _this = this;

				this.get('api').postAuthorizedIPs(this.nullifyEmptyList(this.get('userInputValue'))).then(function (data) {
					_this.updateAuthorizedIPsList(data.AuthorizedIPs);
					_this.set('hasNoIPs', _this.isEmptyList());
					_this.set('isForm', false);
				}, function (error) {
					_this.updateErrorMessage(error);
				});
			},
			showForm: function showForm() {
				this.set('userInputValue', this.joinList(this.get('authorizedIPs')));
				this.set('isForm', true);
			},
			closeForm: function closeForm() {
				this.set('isForm', false);
			}
		}
	});
});