define('vopay_app/routes/dashboard', ['exports', 'ember', 'vopay_app/mixins/manage-cookies'], function (exports, _ember, _vopay_appMixinsManageCookies) {
  exports['default'] = _ember['default'].Route.extend(_vopay_appMixinsManageCookies['default'], {
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    isProfileRoute: false,
    isTransRoute: false,
    isOverviewRoute: false,
    isTransInfoRoute: false,
    isRecipientRoute: false,
    isInfoRoute: false,
    isPaymentRoute: false,
    isBankRoute: false,
    isEvoucher: false,
    isChangePassword: false,
    isDashboard: true,
    isPay: false,
    isConvert: false,
    isBalances: false,
    listRecipients: {},
    _setActualTransition: function _setActualTransition(transition) {
      this.set("isDashboard", !!transition.targetName.match(/info/g));
      this.set('isBalances', !!transition.targetName.match(/balances/g));
      this.set('isEvoucher', !!transition.targetName.match(/e-voucher/g));
      this.set("isPayment", !!transition.targetName.match(/add-account/g));
      this.set("isCollect", !!transition.targetName.match(/dashboard.collect/));
      this.set('isConvert', !!transition.targetName.match(/convert-pay/g));
      this.set('isClientAccounts', !!transition.targetName.match(/dashboard.beneficiaries.index/g));
      this.set('isTransaction', !!transition.targetName.match(/transactions/g));
      this.set('isBulk', !!transition.targetName.match(/bulk-payment/g));
      this.set('isBulkCollect', !!transition.targetName.match(/bulk-collect/g));
      this.set('isTemplate', !!transition.targetName.match(/template/g));
      this.set('isFile', !!transition.targetName.match(/file-upload/g));
      if (!!transition.targetName.match(/sub-accounts/g)) {
        this.set('isAccounts', false);
      } else {
        this.set('isAccounts', !!transition.targetName.match(/accounts/g));
      }
      this.set('isTransfer', !!transition.targetName.match(/transfer/g));
      this.set('isSubAccounts', !!transition.targetName.match(/sub-accounts/g));
      this.set('isLinkedAccounts', !!transition.targetName.match(/link-accounts/g));
      this.set('isFund', !!transition.targetName.match(/fund/g));

      this.set('isTransRoute', !!transition.targetName.match(/transactions/g));
      // this.set('isOverviewRoute', !!transition.targetName.match(/overview/g));//To be added later in life when we re-think the flow
      this.set('isTransInfoRoute', !!transition.targetName.match(/confirmation/g));

      this.set('isRecipientRoute', !!transition.targetName.match(/recipients/g));
      this.set('isBankRoute', !!transition.targetName.match(/bank/g));

      this.set('isChangePassword', !!transition.targetName.match(/change-password/g));

      this.set("isPermissions", !!transition.targetName.match(/permissions/g));

      this.set('isPay', !!transition.targetName.match(/pay/g));
    },
    beforeModel: function beforeModel(transition) {

      this._setActualTransition(transition);
    },
    model: function model() {
      var self = this;
      if (self.get('session.isAuthenticated')) {} else {
        self.transitionTo('login');
      }

      return _ember['default'].RSVP.hash({
        isDashboard: self.get('isDashboard'),
        isBalances: self.get('isBalances'),
        isConvert: self.get('isConvert'),
        isPayment: self.get('isPayment'),
        isCollect: self.get('isCollect'),
        isClientAccounts: self.get('isClientAccounts'),
        isEvoucher: self.get('isEvoucher'),
        isTransaction: self.get('isTransaction'),
        isBulk: self.get('isBulk'),
        isBulkCollect: self.get('isBulkCollect'),
        isTemplate: self.get('isTemplate'),
        isFile: self.get('isFile'),
        isLinkedAccounts: self.get('isLinkedAccounts'),
        isAccounts: self.get('isAccounts'),
        isFund: self.get('isFund'),
        isSubAccounts: self.get('isSubAccounts'),
        isTransfer: self.get("isTransfer"),
        isPermissions: self.get("isPermissions"),
        // isOverviewRoute: self.get('isOverviewRoute'),  //To be added later in life when we re-think the flow
        isTransInfoRoute: self.get('isTransInfoRoute')
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        //show message when ready

        this._setActualTransition(transition);
        this.refresh();
      }
    }

  });
});