define('vopay_app/components/nav-bar', ['exports', 'ember', 'vopay_app/mixins/manage-cookies'], function (exports, _ember, _vopay_appMixinsManageCookies) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsManageCookies['default'], {
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		router: _ember['default'].inject.service('-routing'),
		"currentRouteNameChanged": function currentRouteNameChanged(router, propertyName) {
			$(".header_links--sub.header__links--active").removeClass("header__links--active");

			this._setActualTransition(router.get("currentRouteName"));
		},
		//   "isDashboard": false,
		// 'isBalances': false,
		// 'isEvoucher': false,
		// "isPayment": false,
		// "isCollect": false,
		// 'isConvert': false,
		// 'isClientAccounts': false,
		// 'isTransaction': false,
		// 'isBulk': false,
		// 'isBulkCollect': false,
		// 'isTemplate': false,
		// 'isFile': false,

		// 'isAccounts': false,

		// 'isAccounts': false,

		// 'isTransfer': false,
		// 'isSubAccounts': false,
		// 'isLinkedAccounts': false,
		// 'isFund': false,

		// 'isTransRoute': false,

		// 'isTransInfoRoute': false,
		// 'isRecipientRoute': false,
		// 'isBankRoute': false,

		// 'isChangePassword': false,
		// "isPermissions": false,

		// 'isPay': false,
		init: function init() {
			this._super.apply(this, arguments);

			this.set('isSubAccount', localStorage.getItem("SubAccountPermissions") === '1');
			this.set('isClientAccount', localStorage.getItem("ClientAccountsEnabled") === '1');
			this.set('AccountingSoftwareIntegrationEnabled', localStorage.getItem("AccountingSoftwareIntegrationEnabled") === '1');
			this.set('AccountingSystemIntegrationEnabled', localStorage.getItem("AccountingSystemIntegrationEnabled") === '1');
			this.set('Country', localStorage.getItem("Country"));
			this.set("isPaaS", localStorage.getItem("AccountType") === "PaaS");
		},
		_setActualTransition: function _setActualTransition(targetName) {

			// do your destroying code setting stuff

			this.set("isDashboard", !!targetName.match(/info/g));
			this.set('isBalances', !!targetName.match(/balances/g));
			this.set('isEvoucher', !!targetName.match(/e-voucher/g));
			this.set("isPayment", !!targetName.match(/add-account/g));
			this.set("isCollect", !!targetName.match(/dashboard.collect/));
			this.set('isConvert', !!targetName.match(/convert-pay/g));

			this.set('isClientAccounts', !!targetName.match(/dashboard.beneficiaries.index/g));
			this.set('isClientAccountsFile', !!targetName.match(/dashboard.beneficiaries.file/g));
			this.set('isClientAccountsTemplate', !!targetName.match(/dashboard.beneficiaries.template/g));

			this.set('isTransaction', !!targetName.match(/transactions/g));
			this.set('isBulk', !!targetName.match(/bulk-payment/g));
			this.set('isBulkCollect', !!targetName.match(/bulk-collect/g));
			this.set('isTemplate', !!targetName.match(/template/g));

			this.set('isAccountingSoftware', !!targetName.match(/dashboard.accounting-software/g));
			this.set('isInvoices', !!targetName.match(/accounting-software.invoices/g));
			this.set('isBills', !!targetName.match(/accounting-software.bills/g));

			this.set('isFile', !!targetName.match(/file-upload/g));
			this.set('isFileTeplate', !!targetName.match(/file-template/g));
			this.set('isFileHistory', !!targetName.match(/file-history/g));

			this.set('isFileUpload', !!targetName.match(/upload-file/g));
			this.set('isSettings', !!targetName.match(/settings/g));
			this.set('isSchedule', !!targetName.match(/schedule/g));
			this.set('isReconciliation', !!targetName.match(/reconciliation/g));
			this.set('isSubmitTicket', !!targetName.match(/submit-ticket/g));
			this.set('isViewTickets', !!targetName.match(/tickets-list/g));
			this.set('isFaq', !!targetName.match(/faq/g));
			if (!!targetName.match(/sub-accounts/g) || !!targetName.match(/request-accounts/g)) {
				this.set('isAccounts', false);
			} else {
				this.set('isAccounts', targetName.split('.')[1] === 'accounts');
			}

			if (!!targetName.match(/balance-transfer/g)) {

				this.set('isBlanceTransfer', !!targetName.match(/balance-transfer/g));
			} else {
				if (!!targetName.match(/bank-transfer/g)) {
					this.set('isTransfer', false);
				} else {
					if (targetName !== 'dashboard.client-accounts.transfer-funds') {
						this.set('isTransfer', !!targetName.match(/transfer/g));
					}
				}
			}
			this.set('isBankTransfer', !!targetName.match(/bank-transfer/g));
			this.set('isSubAccounts', !!targetName.match(/sub-accounts/g));

			this.set('isClientAccountMenu', targetName.split('.')[1] === 'client-accounts');
			if (targetName === 'dashboard.client-accounts.transfer-funds') {
				this.set('isClientAccountFunds', true);
			} else {
				this.set('isClientAccountList', targetName.split('.')[1] === 'client-accounts');
			}

			this.set('isLinkedAccounts', !!targetName.match(/link-accounts/g));
			if (targetName !== 'dashboard.client-accounts.transfer-funds') {
				this.set('isFund', !!targetName.match(/fund/g));
			}

			this.set('isUser', !!targetName.match(/users/g));
			this.set('isTransRoute', !!targetName.match(/transactions/g));
			// this.set('isOverviewRoute', !!transition.targetName.match(/overview/g));//To be added later in life when we re-think the flow
			this.set('isTransInfoRoute', !!targetName.match(/confirmation/g));
			this.set('isRecipientRoute', !!targetName.match(/recipients/g));
			this.set('isBankRoute', !!targetName.match(/bank/g));

			this.set('isChangePassword', !!targetName.match(/change-password/g));
			this.set("isPermissions", !!targetName.match(/permissions/g));

			this.set('isPay', !!targetName.match(/pay/g));
			this.set("isWithdraw", !!targetName.match(/withdraw/g));
			this.set("isRequestInfo", !!targetName.match(/request-accounts/g));
		},
		_setFalseActualTransition: function _setFalseActualTransition() {
			this.set("isDashboard", false);
			this.set('isBalances', false);
			this.set('isEvoucher', false);
			this.set("isPayment", false);
			this.set("isCollect", false);
			this.set('isConvert', false);
			this.set('isClientAccounts', false);
			this.set('isTransaction', false);
			this.set('isBulk', false);
			this.set('isBulkCollect', false);
			this.set('isTemplate', false);
			this.set('isFile', false);
			this.set('isBlanceTransfer', false);

			this.set('isAccounts', false);

			this.set('isAccounts', false);

			this.set('isTransfer', false);
			this.set('isSubAccounts', false);
			this.set('isLinkedAccounts', false);
			this.set('isFund', false);

			this.set('isTransRoute', false);
			// this.set('isOverviewRoute', !!transition.targetName.match(/overview/g));//To be added later in life when we re-think the flow
			this.set('isTransInfoRoute', false);
			this.set('isRecipientRoute', false);
			this.set('isBankRoute', false);

			this.set('isChangePassword', false);
			this.set("isPermissions", false);
			this.set("isWithdraw", false);
			this.set('isPay', false);
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);
			_ember['default'].$("#crossRight").removeClass("cross--show");
			_ember['default'].$("#crossLeft").removeClass("cross--show");
			_ember['default'].$("#filter").removeClass("filter--show");
			_ember['default'].$("body").removeClass("isOpen--left");
			_ember['default'].$("#sidebarLeft").removeClass("sidebar__open--left");
			_ember['default'].$("body").removeClass("isOpen--right");
			_ember['default'].$("#sidebarRight").removeClass("sidebar__open--right");
			_ember['default'].$("#navbar").removeClass("header2--right");
			_ember['default'].$("#navbar").removeClass("header2--left");
		},
		willRender: function willRender() {},
		didUpdateAttrs: function didUpdateAttrs() {
			$(".active").removeClass("active");
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var self = this;
			this.$(".header_links--sub").click(function () {
				//$(".header__links--active").removeClass("header__links--active");
				self.$(".active").removeClass("active");
				//.$(".header__links--active").removeClass("header__links--active");
				if (self.$(this).hasClass("header__links--active") || self.$(this).children(".header__linksHeader").first().hasClass("header__links--active")) {
					//self.$(this).removeClass("header__links--active");
				} else {
						//self.$(".header__links--active").removeClass("header__links--active");

					}
				self.$(".header_links--sub.header__links--active").removeClass("header__links--active");
				self.$(".primary .header__links--active").removeClass("header__links--active");
				self.$(this).addClass("header__links--active");
				if (curentElement) {
					curentElement.addClass("header__links--active");
					curentElement = null;
				}
			});
			var curentElement;
			this.$(".header_links").click(function () {

				self.$(".active").removeClass("active");
				if (!self.$(this).hasClass("header__links--active")) {
					$(".header__links--active").removeClass("header__links--active");
				}
				if (self.$(this).parent().parent().parent().parent().hasClass("header_links--sub")) {
					curentElement = self.$(this).parent().parent().parent().parent();
				}
				$(this).parent().addClass("active");
				$(this).addClass("header__links--active");
			});
			this.$(".primary").click(function () {
				self.$(".active").removeClass("active");
				self.$(this).addClass("active");
			});
			var r = this.get("router");

			sessionStorage.setItem("isInitial", true);
			this._setActualTransition(r.get("currentRouteName"));

			r.addObserver("currentRouteName", this, "currentRouteNameChanged");
		},
		isSubMenuShowing: false,
		isStatment: false,
		isServices: false,
		isAdminstration: false,
		routing: _ember['default'].inject.service('-routing'),
		actions: {
			statementsMenu: function statementsMenu() {
				this.set("isServices", false);
				this.set("isAdminstration", false);
				if (this.get("isStatment") === true) {
					this.set("isStatment", false);
					this.set("isSubMenuShowing", false);
				} else {
					this.set("isStatment", true);
					this.set("isSubMenuShowing", true);
				}
			},
			servicesMenu: function servicesMenu() {
				this.set("isStatment", false);
				this.set("isAdminstration", false);
				if (this.get("isServices") === true) {
					this.set("isServices", false);
					this.set("isSubMenuShowing", false);
				} else {
					this.set("isServices", true);
					this.set("isSubMenuShowing", true);
				}
			},
			adminstrationMenu: function adminstrationMenu() {
				this.set("isServices", false);
				this.set("isStatment", false);
				if (this.get("isAdminstration") === true) {
					this.set("isAdminstration", false);
					this.set("isSubMenuShowing", false);
				} else {
					this.set("isAdminstration", true);
					this.set("isSubMenuShowing", true);
				}
			},
			closeSideBarLeft: function closeSideBarLeft() {
				_ember['default'].$("#crossLeft").removeClass("cross--show");
				_ember['default'].$("#filter").removeClass("filter--show");
				_ember['default'].$("body").removeClass("isOpen--left");
				_ember['default'].$("#sidebarLeft").removeClass("sidebar__open--left");
				_ember['default'].$("#navbar").removeClass("header2--left");
			},
			closeSideBarRight: function closeSideBarRight() {
				_ember['default'].$("#crossRight").removeClass("cross--show");
				_ember['default'].$("#filter").removeClass("filter--show");
				_ember['default'].$("body").removeClass("isOpen--right");
				_ember['default'].$("#sidebarRight").removeClass("sidebar__open--right");
				_ember['default'].$("#navbar").removeClass("header2--right");
			},
			openSideBarLeft: function openSideBarLeft() {
				_ember['default'].$("#crossLeft").addClass("cross--show");
				_ember['default'].$("#filter").addClass("filter--show");
				_ember['default'].$("#sidebarLeft").addClass("sidebar__open--left");
				_ember['default'].$("body").addClass("isOpen--left");
				_ember['default'].$("#navbar").addClass("header2--left");
			},
			openSideBarRight: function openSideBarRight() {
				_ember['default'].$("#crossRight").addClass("cross--show");
				_ember['default'].$("#filter").addClass("filter--show");
				_ember['default'].$("#sidebarRight").addClass("sidebar__open--right");
				_ember['default'].$("body").addClass("isOpen--right");
				_ember['default'].$("#navbar").addClass("header2--right");
			},
			closeNav: function closeNav() {
				_ember['default'].$("#crossRight").removeClass("cross--show");
				_ember['default'].$("#crossLeft").removeClass("cross--show");
				_ember['default'].$("#filter").removeClass("filter--show");
				_ember['default'].$("body").removeClass("isOpen--left");
				_ember['default'].$("#sidebarLeft").removeClass("sidebar__open--left");
				_ember['default'].$("body").removeClass("isOpen--right");
				_ember['default'].$("#sidebarRight").removeClass("sidebar__open--right");
				_ember['default'].$("#navbar").removeClass("header2--right");
				_ember['default'].$("#navbar").removeClass("header2--left");
			},
			closeNavSend: function closeNavSend() {
				_ember['default'].$("#crossRight").removeClass("cross--show");
				_ember['default'].$("#crossLeft").removeClass("cross--show");
				_ember['default'].$("#filter").removeClass("filter--show");
				_ember['default'].$("body").removeClass("isOpen--left");
				_ember['default'].$("#sidebarLeft").removeClass("sidebar__open--left");
				_ember['default'].$("body").removeClass("isOpen--right");
				_ember['default'].$("#sidebarRight").removeClass("sidebar__open--right");
				_ember['default'].$("#navbar").removeClass("header2--right");
				_ember['default'].$("#navbar").removeClass("header2--left");
				//_kmq.push(['record', 'Send Now Clicked']);
			},
			trackClickSend: function trackClickSend() {
				//_kmq.push(['record', 'Send Now Clicked']);
			},

			logout: function logout() {

				localStorage.removeItem("key");
				localStorage.removeItem("accountID");
				localStorage.removeItem("signature");
				location.reload(true);
			}

		}

	});
});