define('vopay_app/helpers/is-uni', ['exports', 'ember'], function (exports, _ember) {
  exports.isUni = isUni;

  function isUni(params /*, hash*/) {
    if (params[0] >= 1681 && params[0] <= 1729) {
      return true;
    } else {
      return false;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(isUni);
});