define('vopay_app/components/cancel-button', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: "",
		api: _ember['default'].inject.service('api'),
		IsRefund: false,
		loader: _ember['default'].inject.service('loader'),
		AllowRefund: true,

		init: function init() {
			this._super.apply(this, arguments);
			this.set("Country", localStorage.getItem("Country"));
			//verify this hack
			if ((localStorage.getItem("EFTCollectEnabled") != "1" || localStorage.getItem("EFTSendEnabled") != "1") && localStorage.getItem("ACHFundEnabled") != "1" && localStorage.getItem("ACHWithdrawEnabled") != "1" && this.get("type") != "Credit Card") {
				this.set("AllowRefund", false);
			}
		},
		actions: {
			cancel: function cancel() {
				var _this = this;

				var options = {};
				options.TransactionID = this.get("transactionId");
				if ((this.get("type") == "Withdraw" || this.get("type") == "Fund" || this.get("type") == "EFT Withdrawal" || this.get("type") == "EFT Funding" || this.get("type") == "ACH Withdrawal" || this.get("type") == "ACH Funding") && this.get("IsRefund")) {
					this.get("loader").UpdateLoading(true);
					if (this.get("Country") == "US") {
						this.get("api").refundACHTransaction(options).then(function (data) {

							if (_this.get("type") == "Fund") {
								window.location.href = "/#/dashboard/transaction/Refund/" + data.TransactionID + "/" + _this.get("currency");
							} else {
								window.location.href = "/#/dashboard/transaction/Refund/" + data.TransactionID + "/" + _this.get("currency");
							}
							_this.set("isDeleteModal", false);
						}, function (err) {

							_this.set("ErrorMessage", err);
							_this.set("isError", true);
							_this.get("loader").UpdateLoading(false);
						});
					} else {
						this.get("api").refundTransaction(options).then(function (data) {

							if (_this.get("type") == "Fund") {
								window.location.href = "/#/dashboard/transaction/Refund/" + data.TransactionID + "/" + _this.get("currency");
							} else {
								window.location.href = "/#/dashboard/transaction/Refund/" + data.TransactionID + "/" + _this.get("currency");
							}
							_this.set("isDeleteModal", false);
						}, function (err) {

							_this.set("ErrorMessage", err);
							_this.set("isError", true);
							_this.get("loader").UpdateLoading(false);
						});
					}
				} else if (this.get("type") == "Credit Card" && this.get("IsRefund")) {
					this.get("loader").UpdateLoading(true);
					options.Amount = this.get("amount");
					this.get("api").refundCreditCardTransaction(options).then(function (data) {
						window.location.href = "/#/dashboard/transaction/Refund/" + data.TransactionID + "/" + _this.get("currency");
						_this.set("isDeleteModal", false);
					}, function (err) {
						_this.set("ErrorMessage", err);
						_this.set("isError", true);
						_this.get("loader").UpdateLoading(false);
					});
				} else if (this.get("type") == "Schedule") {

					var options2 = {
						ScheduledTransactionID: this.get("transactionId")
					};
					if (this.get("scheduleType") == "fund") {
						this.get("api").fundScheduleCancel(options2).then(function (data) {
							window.location.reload();
						}, function (err) {

							_this.set("ErrorMessage", err);
							_this.set("isError", true);
						});
					} else {
						this.get("api").withdrawScheduleCancel(options2).then(function (data) {
							window.location.reload();
						}, function (err) {

							_this.set("ErrorMessage", err);
							_this.set("isError", true);
						});
					}
				} else if (this.get("type") == "Beneficiary" || this.get("type") == "PayLink") {

					this.get("api").CancelPaylink(this.get("BeneficiaryData")).then(function (data) {

						window.location.reload();
					}, function (err) {

						_this.set("ErrorMessage", err);
						_this.set("isError", true);
					});
				} else if (this.get("isPost") == 1) {
					if (this.get("type") == "Interac Bulk Payout") {
						//post approval cancellation

						this.get("api").cancelBulkPayoutTransaction(options).then(function (data) {
							window.location.reload();
						}, function (err) {

							_this.set("ErrorMessage", err);
							_this.set("isError", true);
							//this.errorMessage(err);
						});
					}
				} else if (this.get("isPost") == 0) {
						if (this.get("Country") == "US") {
							this.get("api").voidACHTransaction(options).then(function (data) {
								window.location.reload();
							}, function (err) {
								_this.set("ErrorMessage", err);
								_this.set("isError", true);
								//this.errorMessage(err);
							});
						} else {
								this.get("api").cancelAccountTransactions(options).then(function (data) {
									window.location.reload();
								}, function (err) {
									_this.set("ErrorMessage", err);
									_this.set("isError", true);
									//this.errorMessage(err);
								});
							}
					}
			},

			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			showDeleteModal: function showDeleteModal() {
				this.set("isDeleteModal", true);
			},
			closeDeleteModal: function closeDeleteModal() {
				this.set("isDeleteModal", false);
			}

		}
	});
});