define('vopay_app/components/linked-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		firebase: _ember['default'].inject.service('firebase'),
		options: ["Update", "Pay", "Fund", "Unlink"],
		MoveToEdit: function MoveToEdit(key) {
			window.location.href = "/#/dashboard/link-accounts/update/" + key;
		},
		MoveToPay: function MoveToPay(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.add-account");
		},
		MoveToFund: function MoveToFund(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.fund");
		},
		MoveToCollect: function MoveToCollect(key) {
			sessionStorage.setItem("Benefiricary", key);
			this.get("routing").transitionTo("dashboard.collect");
		},
		RemoveAccount: function RemoveAccount(key) {
			var _this = this;

			this.get("firebase").deleteRecordUser("linkedAccount", key);
			this.get("firebase").getRecordUser('linkedAccount').then(function (data) {
				_this.set("linkedAccounts", data);
			});
		},
		actions: {
			MoveToFund: function MoveToFund(key) {
				sessionStorage.setItem("Benefiricary", key);
				this.get("routing").transitionTo("dashboard.fund");
			},
			handleSelection: function handleSelection(value, key) {

				if (value === "Fund") {
					this.MoveToFund(key);
				} else if (value === "Pay") {
					this.MoveToPay(key);
				} else if (value === "Collect") {
					this.MoveToCollect(key);
				} else if (value === "Update") {
					this.MoveToEdit(key);
				} else if (value === "Unlink") {
					this.RemoveAccount(key);
				}
			}
		}
	});
});