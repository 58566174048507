define('vopay_app/utils/entry-filter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({

    onlyNumbers: function onlyNumbers(value) {

      if (/[\D]+/g.test(value)) {
        return value.replace(/[\D]+/g, '');
      }
      return value;
    },
    noSpaces: function noSpaces(value) {
      if (/\s/g.test(value)) {
        return value.replace(/\s/g, '');
      }
      return value;
    },
    noSpecialCharacter: function noSpecialCharacter(value) {
      if (/[<>{}()%$@!*&?+=^_#]+/g.test(value)) {
        return value.replace(/[<>{}()%$@!*&?+=^_#]+/g, '');
      }
      return value;
    },
    onlyAlphaNumeric: function onlyAlphaNumeric(value) {

      if (/[^a-zA-Z0-9]+/g.test(value)) {
        return value.replace(/[^a-zA-Z0-9]+/g, '');
      }
      return value;
    },
    onlyNameCharacters: function onlyNameCharacters(value) {

      if (/[^a-zA-Z0-9=$\s.*-]+/g.test(value)) {
        return value.replace(/[^a-zA-Z0-9=$\s.*-]+/g, '');
      }
      return value;
    },
    onlyCharacters: function onlyCharacters(value) {

      if (/[^a-zA-Z ]+/g.test(value)) {
        return value.replace(/[^a-zA-Z ]+/g, '');
      }
      return value;
    },
    onlyCurrency: function onlyCurrency(value) {

      if (!value || value < 0) {
        return value;
      }

      if (/^0{2,}/g.test(value)) {
        return 0;
      }

      if (/[^\d.]+/g.test(value)) {
        return value.replace(/[^\d.]+/g, '');
      }

      var strValue = '' + value;
      var i = strValue.indexOf('.');

      if (i !== -1) {
        var v = strValue.substring(0, i + 3);
        value = v;
      }
      return value;
    },
    onlyCurrencySimple: function onlyCurrencySimple(value) {

      if (!value || value < 0) {
        return value;
      }

      if (/^0{2,}/g.test(value)) {
        return 0;
      }

      var strValue = '' + value;
      var i = strValue.indexOf('.');

      if (i !== -1) {
        var v = strValue.substring(0, i + 3);
        value = v;
      }
      return value;
    }
  });
});