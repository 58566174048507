define("vopay_app/components/message-box", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		name: null,
		isVisible: true,
		init: function init() {
			this._super.apply(this, arguments);

			if (sessionStorage.getItem(this.get("name"))) {
				_ember["default"].$(".messagebox").hide();
				this.set("isVisible", null);
			}
		},
		actions: {
			closeMessage: function closeMessage() {
				_ember["default"].$(".messagebox").hide();
				sessionStorage.setItem(this.get("name"), true);
			}
		}
	});
});