define("vopay_app/templates/dashboard/overview", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/dashboard/overview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard dashboard--grey");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "overviewBanner");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Sign Up Today And Get Your First Transfer Free");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        return morphs;
      },
      statements: [["inline", "overview-transaction", [], ["availablePaymentMethods", ["subexpr", "@mut", [["get", "model.paymentMethods", ["loc", [null, [6, 26], [6, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "rates", ["subexpr", "@mut", [["get", "model.rates", ["loc", [null, [7, 8], [7, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [8, 7], [8, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "countries", ["subexpr", "@mut", [["get", "model.countries", ["loc", [null, [9, 12], [9, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "userRecipients", ["subexpr", "@mut", [["get", "model.userRecipients", ["loc", [null, [10, 17], [10, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "userInfo", ["subexpr", "@mut", [["get", "model.userInfo", ["loc", [null, [11, 11], [11, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 1], [12, 3]]], 0, 0], ["inline", "overview-list", [], ["transactions", ["subexpr", "@mut", [["get", "model.transactions", ["loc", [null, [14, 15], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 1], [15, 3]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});