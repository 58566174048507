define('vopay_app/components/unauthorized-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        userPermissions: _ember['default'].inject.service('user-permissions'),
        routing: _ember['default'].inject.service('-routing'),
        showUnauthorizedLoader: _ember['default'].computed('userPermissions.showUnauthorizedMessage', function () {
            return this.get('userPermissions.showUnauthorizedMessage');
        }),
        actions: {
            goToHome: function goToHome() {
                this.get('userPermissions').UpdateMessage(false);
            }
        }
    });
});