define("vopay_app/templates/components/currency-chart", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "vopay_app/templates/components/currency-chart.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("			\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'value');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["get", "currency", ["loc", [null, [6, 20], [6, 28]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currency", ["loc", [null, [6, 32], [6, 44]]], 0, 0, 0, 0]],
        locals: ["key", "currency"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "vopay_app/templates/components/currency-chart.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("			\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'value');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["get", "currency", ["loc", [null, [12, 20], [12, 28]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currency", ["loc", [null, [12, 32], [12, 44]]], 0, 0, 0, 0]],
        locals: ["key", "currency"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/currency-chart.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("FX Rate*\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("select");
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("option");
        dom.setAttribute(el4, "class", "currencyInput__option");
        dom.setAttribute(el4, "value", "USD");
        dom.setAttribute(el4, "disabled", "");
        dom.setAttribute(el4, "hidden", "");
        dom.setAttribute(el4, "selected", "selected");
        var el5 = dom.createTextNode("USD");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("	");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("select");
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("option");
        dom.setAttribute(el4, "class", "currencyInput__option");
        dom.setAttribute(el4, "value", "CAD");
        dom.setAttribute(el4, "disabled", "");
        dom.setAttribute(el4, "hidden", "");
        dom.setAttribute(el4, "selected", "selected");
        var el5 = dom.createTextNode("CAD");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("	");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("select");
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("option");
        dom.setAttribute(el4, "class", "currencyInput__option");
        dom.setAttribute(el4, "value", "7");
        var el5 = dom.createTextNode("Week");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("option");
        dom.setAttribute(el4, "class", "currencyInput__option");
        dom.setAttribute(el4, "value", "31");
        var el5 = dom.createTextNode("Month");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("option");
        dom.setAttribute(el4, "class", "currencyInput__option");
        dom.setAttribute(el4, "value", "365");
        dom.setAttribute(el4, "selected", "selected");
        var el5 = dom.createTextNode("Year");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n	");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "currencychart");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "terms");
        var el3 = dom.createTextNode("* The rates displayed by our free currency converter are neither “buy” nor “sell” rates, but interbank rates, the wholesale exchange rates between banks for transaction amounts over $5 million USD equivalent. Interbank rates don’t include the spreads, handling fees, and other charges that may be assessed by foreign exchange providers. Please note that, as such, these rates will vary from the rates available to our private and small business customers, due to transaction sizes and processing costs. These rates are therefore provided for indicative purposes only.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element2, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element3, 'onchange');
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        morphs[2] = dom.createAttrMorph(element4, 'onchange');
        morphs[3] = dom.createMorphAt(element4, 3, 3);
        morphs[4] = dom.createAttrMorph(element5, 'onchange');
        return morphs;
      },
      statements: [["attribute", "onchange", ["subexpr", "action", ["currencyDestinationChanged"], ["value", "target.value"], ["loc", [null, [null, null], [3, 78]]], 0, 0], 0, 0, 0, 0], ["block", "each-in", [["get", "currencies", ["loc", [null, [5, 13], [5, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [7, 14]]]], ["attribute", "onchange", ["subexpr", "action", ["currencySourceChanged"], ["value", "target.value"], ["loc", [null, [null, null], [9, 73]]], 0, 0], 0, 0, 0, 0], ["block", "each-in", [["get", "currencies", ["loc", [null, [11, 13], [11, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 2], [13, 14]]]], ["attribute", "onchange", ["subexpr", "action", ["timeFrameChanged"], ["value", "target.value"], ["loc", [null, [null, null], [15, 68]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});