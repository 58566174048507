define('vopay_app/routes/transfer', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		removeBG: (function () {
			_ember['default'].$("html").removeClass("moneytransfer__background");
			_ember['default'].$("body").removeClass("moneytransfer__background");
		}).on('deactivate'),
		beforeModel: function beforeModel() {
			_ember['default'].$("html").addClass("moneytransfer__background");
			_ember['default'].$("body").addClass("moneytransfer__background");
		}

	});
});
// model() {

// 	let authed = this.get('session.isAuthenticated');

// 	//if authenticated, grab some info about the user
// 	if(authed) {

// 		return Ember.RSVP.hash({
// 			paymentMethods: this.get('api').getPaymentMethods(),
// 			countries: this.get('api').getCountries(),
// 			userRecipients: this.get('api').listRecipients(),
// 			userInfo: this.get('api').userInfo(),
// 			rates: this.get('api').getQuotes(defaultQuotes)
// 		});

// 	} else {
// 		this.transitionTo('login');
// 	}
//  }