define('vopay_app/components/new-payment-method', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter) {
	exports['default'] = _ember['default'].Component.extend({

		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		isDual: false,
		userName: null,
		// Avoiding wrong entry data

		watchfIsStore: _ember['default'].observer('isStore', function () {

			if (this.get("isStore") === false) {
				this.set("isDefault", false);
				_ember['default'].$("#isDefault").attr("disabled", true);
			} else {
				_ember['default'].$("#isDefault").removeAttr("disabled");
			}
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		// watchUserName: Ember.observer('userInfo', function(){
		// 	let name = this.get("userInfo.FirstName")+" "+this.get("userInfo.LastName");
		// 	this.set("userName", name);
		// }),
		init: function init() {
			this._super.apply(this, arguments);
			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});
			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});
		},

		actions: {

			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			}
		}
	});
});