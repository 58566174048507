define('vopay_app/controllers/error', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    memoryStorage: _ember['default'].inject.service('memory-storage'), //inject a storage service

    /**
     * These computed properties will help to handle a special case,
     * when there is no error status and message (usually when internet
     * connection is gone)
     */
    message: _ember['default'].computed('model', function () {
      if (this.model !== undefined && this.model.msg !== undefined) {

        return this.model.msg;
      }

      return undefined;
    }),
    request: _ember['default'].computed('model', function () {
      if (this.model !== undefined && this.model.request !== undefined) {

        return this.model.request;
      }

      return undefined;
    }),
    actions: {
      retry: function retry() {
        //get a failed transition from storage
        var transition = this.get('memoryStorage').getAndRemove('failedTransition');
        if (transition !== undefined) {
          transition.retry(); //retry transition
        }
      }
    }
  });
});