define("vopay_app/helpers/frequency-label", ["exports", "ember"], function (exports, _ember) {
  exports.frequencyLabel = frequencyLabel;

  function frequencyLabel(params /*, hash*/) {
    var value = params[0];
    if (value == "monthly") {
      return "Monthly";
    } else if (value == "weekly") {
      return "Weekly";
    } else if (value == "biweekly") {
      return "Bi-Weekly";
    } else if (value == "3 months") {
      return "Quarterly";
    } else if (value == "6 months") {
      return "Semi-Annually";
    } else if (value == "yearly") {
      return "Annually";
    } else if (value == "bimonthly") {
      return "Bi-Monthly";
    } else if (value == "daily") {
      return "Daily";
    } else {
      return "";
    }
  }

  exports["default"] = _ember["default"].Helper.helper(frequencyLabel);
});