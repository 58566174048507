define("vopay_app/templates/dashboard/info", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 141
          }
        },
        "moduleName": "vopay_app/templates/dashboard/info.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "account-overview", [], ["currency", ["subexpr", "@mut", [["get", "model.DefaultCurrency", ["loc", [null, [1, 28], [1, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "balance", ["subexpr", "@mut", [["get", "model.balance", ["loc", [null, [1, 60], [1, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "bankHoliday", ["subexpr", "@mut", [["get", "model.bankHoliday", ["loc", [null, [1, 88], [1, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "accountLimits", ["subexpr", "@mut", [["get", "model.accountLimits", ["loc", [null, [1, 120], [1, 139]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 141]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});