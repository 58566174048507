define("vopay_app/components/owner-form", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		customIdDOB: "",
		customIdDOI: "",
		init: function init() {
			this._super.apply(this, arguments);
			this.set("customIdDOB", "dob" + this.get("number"));
			this.set("customIdDOI", "doi" + this.get("number"));
		}
	});
});