define("vopay_app/components/general-dropdown", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    menuActive: false,
    dropDownClass: "form__dropdownToggle--grey",
    dropDownSelClass: "form__selDropdown--grey",
    dropDownStateClass: "",
    selDropDownStateClass: "",
    currentSelection: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentSelection', this.get('default'));
      if (this.get('isSelected')) {
        this.set("dropDownClass", "");
        this.set("dropDownSelClass", "");
      }
    },
    watchDefault: _ember["default"].observer('default', function () {

      this.set('currentSelection', this.get('default'));
    }),

    toggleMenu: function toggleMenu() {
      if (!this.get('menuActive')) {
        this.set('menuActive', true);
        this.set('dropDownStateClass', 'form__dropdownToggle--active');
        this.set('selDropDownStateClass', 'form__selDropdown--active');
      } else {
        this.set('menuActive', false);
        this.set('dropDownStateClass', '');
        this.set('selDropDownStateClass', '');
      }
    },
    actions: {
      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },
      /***
      * delevry dropdown
      * @params item (String)
      * Sets current selected item the dropdown
      ***/
      selectItem: function selectItem(item) {
        this.sendAction('value', item);
        this.set('currentSelection', item);
        this.set('dropDownClass', '');
        this.set('dropDownSelClass', '');
        this.toggleMenu();
      }
    }
  });
});
/***
* General Dropdown
* @params data (Array) data to display in list
* @params default (String) inital default string
* @params value (function) function that will be used to set value with selected value
* shows correct dropdown for a delevery method
***/