define('vopay_app/components/paylink-resend', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        api: _ember['default'].inject.service('api'),
        firebase: _ember['default'].inject.service('firebase'),
        routing: _ember['default'].inject.service('-routing'),
        actions: {
            CopyLink: function CopyLink() {
                /* Get the text field */
                var copyText = document.getElementById("link");

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/

                /* Copy the text inside the text field */
                document.execCommand("copy");
            },
            ResendPayLink: function ResendPayLink() {
                var _this = this;

                var options = {
                    RedirectURL: "https://request.vopay.com/",
                    AccountSelectionMethod: "any",
                    PaymentSelectionMethod: "bank",
                    RedirectMethod: "JavascriptMessage",
                    customerName: this.get("LongName")
                };
                if (this.get("type") == "Schedule") {
                    this.set("PayLinkDetails.ScheduledTransactionID", this.get("transactionID"));
                    this.set("PayLinkDetails.EmailAddress", this.get("PayLinkDetails.ReceiverEmailAddress"));
                    this.get('api').getApiURL(options).then(function (iframe) {
                        _this.set("PayLinkDetails.IframeKey", iframe.IframeKey);
                        _this.get("api").RequestAccountInfo(_this.get("PayLinkDetails")).then(function (data) {
                            _this.set("Sucess", true);
                        }, function (error) {
                            _this.set("ErrorMessage", error);
                            _this.set("isError", true);
                        });
                    }, function (error2) {
                        _this.set("ErrorMessage", error2);
                        _this.set("isError", true);
                    });
                } else if (this.get("type") == "Beneficiary") {
                    this.set("PayLinkDetails.EmailAddress", this.get("PayLinkDetails.ReceiverEmailAddress"));
                    this.set("PayLinkDetails.PaylinkRequestID", this.get("transactionID"));
                    this.set("PayLinkDetails.Address1", "n/a");
                    this.set("PayLinkDetails.City", "n/a");
                    this.set("PayLinkDetails.Province", "BC");
                    this.set("PayLinkDetails.Country", "CA");
                    this.set("PayLinkDetails.PostalCode", "A1B2C3");

                    this.get('api').getApiURL(options).then(function (iframe) {
                        _this.set("PayLinkDetails.IframeKey", iframe.IframeKey);
                        _this.get("api").RequestCreateBeneficiaries(_this.get("PayLinkDetails")).then(function (data) {
                            _this.set("Sucess", true);
                        }, function (error) {
                            _this.set("ErrorMessage", error);
                            _this.set("isError", true);
                        });
                    }, function (error2) {
                        _this.set("ErrorMessage", error2);
                        _this.set("isError", true);
                    });
                } else {
                    this.set("PayLinkDetails.TransactionID", this.get("transactionID"));

                    if (this.get("PayLinkDetails.ReceiverEmailAddress")) {
                        this.set("PayLinkDetails.EmailAddress", this.get("PayLinkDetails.ReceiverEmailAddress"));
                    }
                    this.get('api').getApiURL(options).then(function (iframe) {
                        _this.set("PayLinkDetails.IframeKey", iframe.IframeKey);
                        _this.get("api").RequestAccountInfo(_this.get("PayLinkDetails")).then(function (data) {
                            _this.set("Sucess", true);
                        }, function (error) {
                            _this.set("ErrorMessage", error);
                            _this.set("isError", true);
                        });
                    }, function (error2) {
                        _this.set("ErrorMessage", error2);
                        _this.set("isError", true);
                    });
                }
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            closeModalSuccess: function closeModalSuccess() {
                this.set("Sucess", false);
            }
        }
    });
});