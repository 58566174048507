define('vopay_app/components/business-signup', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		businessSections: ["Option 1", "Option 2", "Option2", "Option4", "Other"],
		businessTypes: ["Private Corporation", "Public Corporation", "Sole Proprietorship", "Not for Profit", "Government Entity", "Other"],
		countryList: ["Australia", "Bulgaria", "China", "Denmark", "France", "Germany", "Honge Kong", "Hungary", "India", "Italy", "Japan", "New Zealand", "Norway", "Poland", "United Kingdom", "Romania", "Russia", "Singapore", "Spain", "South Africa", "Sweden", "Switzerland", "Thailand", "United Arab Emirates", "United States of America", "Other"],
		currencyList: ["AUD (Australian Dollar)", "BGN (Bulgarian Lev)", "CHF (Swiss Franc)", "CNY (Chinese Yuan)", "DKK (Danish Krone) ", "EUR (EURO)", "GBP (Pound Sterling ) ", "HKD (Hong Kong Dollar) ", "HUF (Hungarian Forint) ", "INR (Indian Rupee) ", "JPY (Japanese Yen) ", "NOK (Norwegian Krone)", "NZD (New Zealand Dollar)", "PLN (Polish Zloty) ", "RON (Romanian New Leu)", "RUB (Russian Rouble) ", "SEK (Swedish Krona) ", "SGD (Singapore Dollar)", "THB (Thai Baht)", "USD (United States Dollar)", "ZAR (South African Rand)", "Other"],
		directorNumbers: ["1"],
		directorCurrNum: 1,
		ownerNumbers: ["1"],
		ownerCurrNum: 1,
		currencyNumbers: ["1"],
		currencyCurrNum: 1,
		countryNumbers: ["1"],
		countryCurrNum: 1,
		ownerTypeSelected: false,
		stageNumber: 1,
		stageName: "Business Detials",
		ownerTypes: ["Individual", "Corperate"],
		init: function init() {
			this._super.apply(this, arguments);
		},
		didInsertElement: function didInsertElement() {

			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});

			this.initialiseAutoAddress();

			_ember['default'].$("input").keyup(function () {
				_ember['default'].$(this).addClass("form__input--active");
			});
			_ember['default'].$("input").focusout(function () {
				_ember['default'].$(this).removeClass("form__input--active");
			});

			_ember['default'].$("#street-address").focusout(function () {
				setTimeout(function () {
					var city = _ember['default'].$("#city").val();
					var state = _ember['default'].$("#state").val();
					var postcode = _ember['default'].$("#postcode").val();
					if (city) {
						_ember['default'].$("#city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#state").parsley().reset();
					}
					if (postcode) {
						_ember['default'].$("#postcode").parsley().reset();
					}
				}, 500);
			});

			_ember['default'].$("#postcode").focusout(function () {
				setTimeout(function () {
					var city = _ember['default'].$("#city").val();
					var state = _ember['default'].$("#state").val();
					var streetAddress = _ember['default'].$("#street-address").val();
					if (city) {
						_ember['default'].$("#city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#state").parsley().reset();
					}
					if (streetAddress) {
						_ember['default'].$("#street-address").parsley().reset();
					}
				}, 500);
			});
		},
		initialiseAutoAddress: function initialiseAutoAddress() {
			/** 
      let fields = [{
        element: "street-address",
        field: "Line1"
      }, {
        element: "street-address2",
        field: "Line2",
        mode: window.pca.fieldMode.POPULATE
      }, {
        element: "city",
        field: "City",
        mode: window.pca.fieldMode.POPULATE
      }, {
        element: "state",
        field: "ProvinceName",
        mode: window.pca.fieldMode.POPULATE
      }, {
        element: "postcode",
        field: "PostalCode"
      }, {
        element: "country",
        field: "CountryName",
        mode: window.pca.fieldMode.COUNTRY
      }],
      options = {
        key: "pb71-zp25-pd69-ke92"
      },
     control = new window.pca.Address(fields, options); // jshint ignore:line
   **/
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		addFormData: function addFormData(formObj) {
			var arrLength = formObj.length;
			var data = {};

			for (var i = 0; i < arrLength; i++) {
				data[formObj[i].name] = formObj[i].value;
			}

			return data;
		},
		actions: {
			moveToPayment: function moveToPayment() {},
			businessTypeSelected: function businessTypeSelected(value) {
				if (value === "Other") {
					_ember['default'].$("#businessType").val("");
					_ember['default'].$("#businessType").attr('type', 'text');
					_ember['default'].$("#businessType").focus();
					_ember['default'].$("#businessTypeDd").hide();
				} else {
					_ember['default'].$("#businessType").val(value);
					_ember['default'].$("#businessType").parsley().validate();
				}
			},
			businessSectorSelected: function businessSectorSelected(value) {
				if (value === "Other") {
					_ember['default'].$("#businessSector").val("");
					_ember['default'].$("#businessSector").attr('type', 'text');
					_ember['default'].$("#businessSector").focus();
					_ember['default'].$("#businessSectorDd").hide();
				} else {
					_ember['default'].$("#businessSector").val(value);
					_ember['default'].$("#businessSector").parsley().validate();
				}
			},
			currencySelected: function currencySelected(value) {},
			countrySelected: function countrySelected(value) {},

			ownerTypeSelected: function ownerTypeSelected(value) {
				_ember['default'].$("#ownerType").val(value);
				_ember['default'].$("#ownerType").parsley().validate();

				this.set('ownerTypeSelected', true);
				if (value === "Individual") {
					this.set('isIndividual', true);
				} else {
					this.set('isIndividual', false);
				}
			},
			update: function update() {

				//let self = this;
				//serialize the form and get the values

				//validate here, if all good proceed
				if (this.validateForm("#signupForm")) {
					_ember['default'].$('#signupBtn').removeAttr('disabled');
					_ember['default'].$("#signupForm").hide();
					_ember['default'].$("#productServicesForm").show();
					this.set("stageNumber", 2);
					this.set("stageName", "Product Detials");
				}
			},
			updateProductServices: function updateProductServices() {
				//let self = this;
				//let options = {};

				if (this.validateForm("#productServicesForm")) {
					_ember['default'].$("#productServicesForm").hide();
					_ember['default'].$("#primaryContactForm").show();
					this.set("stageNumber", 3);
					this.set("stageName", "Primary Contact");
				}
			},
			updatePrimaryConact: function updatePrimaryConact() {
				//let self = this;
				//serialize the form and get the values

				//let options = {};

				//validate here, if all good proceed
				if (this.validateForm("#primaryContactForm")) {
					_ember['default'].$("#primaryContactForm").hide();
					_ember['default'].$("#signAuthorityForm").show();
					this.set("stageNumber", 4);
					this.set("stageName", "Signing Authorities");
				}
			},
			updateSigner: function updateSigner() {
				//let self = this;
				//serialize the form and get the values

				//validate here, if all good proceed
				// let form = Ember.$('#signAuthorityForm');

				if (this.validateForm("#signAuthorityForm")) {
					_ember['default'].$("#signAuthorityForm").hide();
					_ember['default'].$("#directorsForm").show();
					this.set("stageNumber", 5);
					this.set("stageName", "Directors");
				}
			},
			updateDirectors: function updateDirectors() {
				//let self = this;
				//serialize the form and get the values

				//validate here, if all good proceed
				//let form = Ember.$('#directorsForm');

				if (this.validateForm("#directorsForm")) {
					_ember['default'].$("#directorsForm").hide();
					_ember['default'].$("#fileUploadForm").show();
					this.set("stageNumber", 6);
					this.set("stageName", "File Uploads");
				}
			},
			backDirectors: function backDirectors() {
				_ember['default'].$("#directorsForm").show();

				_ember['default'].$("#signupForm").hide();
				_ember['default'].$("#productServicesForm").hide();
				_ember['default'].$("#primaryContactForm").hide();
				_ember['default'].$("#signAuthorityForm").hide();
				_ember['default'].$("#fileUploadForm").hide();

				this.set("stageNumber", 5);
				this.set("stageName", "Directors");
			},
			backSigner: function backSigner() {
				_ember['default'].$("#signAuthorityForm").show();

				_ember['default'].$("#signupForm").hide();
				_ember['default'].$("#productServicesForm").hide();
				_ember['default'].$("#primaryContactForm").hide();
				_ember['default'].$("#directorsForm").hide();
				_ember['default'].$("#fileUploadForm").hide();

				this.set("stageNumber", 4);
				this.set("stageName", "Signing Authorities");
			},
			backPrimaryConact: function backPrimaryConact() {
				_ember['default'].$("#primaryContactForm").show();

				_ember['default'].$("#signupForm").hide();
				_ember['default'].$("#productServicesForm").hide();
				_ember['default'].$("#signAuthorityForm").hide();
				_ember['default'].$("#directorsForm").hide();
				_ember['default'].$("#fileUploadForm").hide();

				this.set("stageNumber", 3);
				this.set("stageName", "Primary Contact");
			},
			backProductServices: function backProductServices() {
				_ember['default'].$("#productServicesForm").show();

				_ember['default'].$("#signupForm").hide();
				_ember['default'].$("#primaryContactForm").hide();
				_ember['default'].$("#signAuthorityForm").hide();
				_ember['default'].$("#directorsForm").hide();
				_ember['default'].$("#fileUploadForm").hide();

				this.set("stageNumber", 2);
				this.set("stageName", "Product Detials");
			},
			backBusiness: function backBusiness() {
				_ember['default'].$("#signupForm").show();

				_ember['default'].$("#productServicesForm").hide();
				_ember['default'].$("#primaryContactForm").hide();
				_ember['default'].$("#signAuthorityForm").hide();
				_ember['default'].$("#directorsForm").hide();
				_ember['default'].$("#fileUploadForm").hide();

				this.set("stageNumber", 1);
				this.set("stageName", "Business Detials");
			},
			AgreeToTerms: function AgreeToTerms() {
				_ember['default'].$("#termsConditions").hide();
				_ember['default'].$("#riskDisclosureSatment").show();
			},
			backTerms: function backTerms() {
				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#termsConditions").hide();
			},
			AgreeToRisk: function AgreeToRisk() {
				_ember['default'].$("#clientApplicationAuthorization").show();
				_ember['default'].$("#riskDisclosureSatment").hide();
			},
			backRisk: function backRisk() {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#riskDisclosureSatment").hide();
			},
			AgreeToAgreeToAuthoization: function AgreeToAgreeToAuthoization() {
				_ember['default'].$("#clientApplicationAuthorization").hide();
				_ember['default'].$("#fileUploadForm").hide();
				_ember['default'].$(".active2").hide();
				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#email").show();
			},
			backAuthorization: function backAuthorization() {
				_ember['default'].$("#clientApplicationAuthorization").hide();
				_ember['default'].$("#riskDisclosureSatment").show();
			},
			uploadFiles: function uploadFiles() {
				_ember['default'].$("#overlay").show();
				_ember['default'].$("#termsConditions").show();
				var applicationId = this.get("firebase").getRecordSignupCollection();
				var directorData = this.addFormData(_ember['default'].$('#directorsForm').serializeArray());
				var authorityData = this.addFormData(_ember['default'].$('#signAuthorityForm').serializeArray());

				var signupData = this.addFormData(_ember['default'].$('#signupForm').serializeArray());
				var primaryContactData = this.addFormData(_ember['default'].$('#primaryContactForm').serializeArray());
				this.get("firebase").addRecordSignup(applicationId, "BusinessProfile", signupData);
				this.get("firebase").addRecordSignup(applicationId, "PrimaryContact", primaryContactData);

				var ownerNumbers = this.get("ownerNumbers");
				var directorNumbers = this.get("directorNumbers");
				for (var i = 0; i < directorNumbers.length; i++) {
					var data = {};
					data["firstName"] = directorData["firstname" + directorNumbers[i]];
					data["lastName"] = directorData["firstname" + directorNumbers[i]];
					data["DOB"] = directorData["dob" + directorNumbers[i]];
					data["citizenship"] = directorData["citizenship" + directorNumbers[i]];
					data["streetaddress"] = directorData["streetaddress" + directorNumbers[i]];
					data["state"] = directorData["state" + directorNumbers[i]];
					data["postcode"] = directorData["postcode" + directorNumbers[i]];
					data["country"] = directorData["country" + directorNumbers[i]];
					this.get("firebase").addRecordSignupMulti(applicationId, "Directors", data);
				}
				var type = authorityData["ownerType"];
				this.get("firebase").addRecordSignup(applicationId, "Ownership/Type", type);
				this.get("firebase").addRecordSignup(applicationId, "Ownership/Owners", "");
				for (var j = 0; j < ownerNumbers.length; j++) {
					var data = {};

					data["firstName"] = authorityData["firstname" + ownerNumbers[j]];
					data["lastName"] = authorityData["firstname" + ownerNumbers[j]];

					data["streetaddress"] = authorityData["streetaddress" + ownerNumbers[j]];
					data["state"] = authorityData["state" + ownerNumbers[j]];
					data["postcode"] = authorityData["postcode" + ownerNumbers[j]];
					data["country"] = authorityData["country" + ownerNumbers[j]];
					data["ownership"] = authorityData["ownership" + ownerNumbers[j]];
					if (this.get("isIndividual")) {
						data["DOB"] = authorityData["dob" + ownerNumbers[j]];
						data["citizenship"] = authorityData["citizenship" + ownerNumbers[j]];
					} else {
						data["jurisdiction"] = authorityData["jurisdiction" + ownerNumbers[j]];
						data["DOI"] = authorityData["doi" + ownerNumbers[j]];
					}

					this.get("firebase").addRecordSignupMulti(applicationId, "Ownership/Owners", data);
				}

				var productServicesData = this.addFormData(_ember['default'].$('#productServicesForm').serializeArray());
				var productData = {};
				productData["FXNeeds"] = productServicesData["FXNeeds"];
				productData["GoodsAndServices"] = productServicesData["GoodsAndServices"];
				productData["Purpose"] = productServicesData["purpose"];

				this.get("firebase").addRecordSignup(applicationId, "ProductServices", productData);

				var countryNumbers = this.get("countryNumbers");
				var currencyNumber = this.get("currencyNumbers");
				for (var k = 0; k < countryNumbers.length; k++) {
					var data = {};
					data["Country"] = productServicesData["countrySel" + countryNumbers[k]];
					this.get("firebase").addRecordSignupMulti(applicationId, "ProductServices/Countries", data);
				}
				for (var l = 0; l < currencyNumber.length; l++) {
					var data = {};
					data["Currency"] = productServicesData["currency" + currencyNumber[l]];
					this.get("firebase").addRecordSignupMulti(applicationId, "ProductServices/Currencies", data);
				}
				if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {

					return;
				}

				var input = document.getElementById('uploadFile');
				for (var m = 0; m < input.files.length; m++) {
					var file = input.files[m];
					this.get("firebase").addFile(signupData.businessName, file);
				}
			},
			addDirector: function addDirector() {
				this.set("directorCurrNum", this.get("directorCurrNum") + 1);
				this.get('directorNumbers').pushObject(this.get("directorCurrNum"));
			},
			removeDirector: function removeDirector() {
				this.set('directorNumbers', this.get('directorNumbers').slice(0, -1));
				this.set("directorCurrNum", this.get("directorCurrNum") - 1);
			},
			addOwner: function addOwner() {
				this.set("ownerCurrNum", this.get("ownerCurrNum") + 1);
				this.get('ownerNumbers').pushObject(this.get("ownerCurrNum"));
			},
			removeOwner: function removeOwner() {
				this.set('ownerNumbers', this.get('ownerNumbers').slice(0, -1));
				this.set("ownerCurrNum", this.get("ownerCurrNum") - 1);
			},
			addCountry: function addCountry() {
				this.set("countryCurrNum", this.get("countryCurrNum") + 1);
				this.get('countryNumbers').pushObject(this.get("countryCurrNum"));
			},
			removeCountry: function removeCountry() {
				this.set('countryNumbers', this.get('countryNumbers').slice(0, -1));
				this.set("countryCurrNum", this.get("countryCurrNum") - 1);
			},
			addCurrency: function addCurrency() {
				this.set("currencyCurrNum", this.get("currencyCurrNum") + 1);
				this.get('currencyNumbers').pushObject(this.get("currencyCurrNum"));
			},
			removeCurrency: function removeCurrency() {
				this.set('currencyNumbers', this.get('currencyNumbers').slice(0, -1));
				this.set("currencyCurrNum", this.get("currencyCurrNum") - 1);
			}
		}
	});
});