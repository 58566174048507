define('vopay_app/components/account-balance', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        api: _ember['default'].inject.service('api'),
        currency: "CAD",

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.set("currency", this.get("DefaultCurrency"));
            this.get("api").getGCMCurriencies().then(function (data) {

                _this.set("currencies", data.CurrenciesList);
            });
        },

        actions: {
            currencyChanged: function currencyChanged(value) {
                var _this2 = this;

                var options = {};
                this.set("currency", value);
                options.Currency = value;
                this.get("api").getAccountBalance(options).then(function (data) {

                    _this2.set("balance", data);
                }, function (error) {
                    var data2 = {
                        AccountBalance: 0,
                        PendingFunds: 0,
                        SecurityDeposit: 0,
                        AvailableFunds: 0
                    };
                    _this2.set("balance", data2);
                });
            }
        }
    });
});