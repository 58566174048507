define('vopay_app/components/convert-pay-recent', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		options: ["Update", "Tracking"],
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,

		init: function init() {

			this._super.apply(this, arguments);
			var count = 0;
			var transactions = this.get("transactions");

			if (transactions) {
				var transactions2 = {};
				for (var k in transactions) {
					if (transactions.hasOwnProperty(k)) {
						++count;
					}
				}
				for (var j in transactions) {
					if (transactions.hasOwnProperty(j)) {
						var next = count - 1;
						transactions2[next] = transactions[j];
						--count;
					}
				}
				var transactions3 = {};
				var keys = Object.keys(transactions2);
				for (var i = 0; i < keys.length && i < this.get("limit"); i++) {
					var next = i + 1;
					transactions3[next] = transactions2[i];
				}
				this.set("transactions", transactions3);
			}
		},
		actions: {
			moveToTransfer: function moveToTransfer() {
				this.get("routing").transitionTo("transfer");
			},
			moveToNewRecipients: function moveToNewRecipients() {
				this.get("routing").transitionTo("dashboard.recipients.new");
			},
			moveToNewBankAccount: function moveToNewBankAccount() {
				this.get("routing").transitionTo("dashboard.account.payment.new");
			},
			searchTransactions: function searchTransactions() {
				var transactions = this.get("transactions");
				var searchTerms = _ember['default'].$("input[name='keyword']").val().toLowerCase();
				this.filterTransactions(searchTerms, transactions);
			},
			resetTransactions: function resetTransactions() {
				var transactions = this.get("transactions");
				this.filterTransactions('', transactions);
				_ember['default'].$("input[name='keyword']").val('');
				_ember['default'].$('#searchTransactions').addClass('searchHide');
			},
			updateRemmittance: function updateRemmittance(id) {
				window.location = "/#/dashboard/transactions/" + id + "/recipient";
			},
			updatePayment: function updatePayment(id) {
				window.location = "/#/dashboard/transactions/" + id + "/payment";
			},
			selectionPayment: function selectionPayment(selection, id) {
				if (selection === "Update") {
					window.location = "/#/dashboard/transactions/" + id + "/payment";
				} else {
					window.location = "/#/dashboard/transactions/" + id + "/";
				}
			},
			selectionRemittance: function selectionRemittance(selection, id) {
				if (selection === "Update") {
					window.location = "/#/dashboard/transactions/" + id + "/recipient";
				} else {
					window.location = "/#/dashboard/transactions/" + id + "/";
				}
			}
		}

	});
});