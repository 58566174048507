define("vopay_app/helpers/short-last-name", ["exports", "ember"], function (exports, _ember) {
	exports.shortLastName = shortLastName;

	function shortLastName(params /*, hash*/) {
		if (params[0]) {
			var value = params[0].toUpperCase();
			var first = value.charAt(0);
			var shortName = first + ".";
			return shortName;
		} else {
			return params[0];
		}
	}

	exports["default"] = _ember["default"].Helper.helper(shortLastName);
});