define('vopay_app/components/phone-input', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
	exports['default'] = _ember['default'].Component.extend({
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			_ember['default'].$(".phoneInput__input").focusin(function () {
				_ember['default'].$("#phone").addClass("phoneInput--active");
				_ember['default'].$("#errorbox-phone").addClass("phoneInput__description--active");
			});
			_ember['default'].$(".phoneInput__input").focusout(function () {
				_ember['default'].$("#phone").removeClass("phoneInput--active");
				_ember['default'].$("#errorbox-phone").removeClass("phoneInput__description--active");
			});
			_ember['default'].$("#phoneInput").parsley().on("field:error", function () {
				_ember['default'].$("#phone").addClass("phoneInput--error");
			});
			_ember['default'].$("#phoneInput").parsley().on("field:success", function () {
				_ember['default'].$("#phone").removeClass("phoneInput--error");
			});
		},
		watchPhoneNumber: _ember['default'].observer('phoneNumber', function () {
			this.set('phoneNumber', this.get('entryUtil').onlyNumbers(this.get('phoneNumber')));
		})
	});
});