define('vopay_app/components/beneficiary-upload', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros) {
	exports['default'] = _ember['default'].Component.extend({
		ServiceOptions: ["EFT", "e-Transfer"],
		DocumentOptions: ["Debit (Collect Funds)", "Credit (Send Funds)"],
		Service: "EFT",
		Document: "Debit",
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		progress: [],
		firebase: _ember['default'].inject.service('firebase'),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var self = this;
			this.$("input[type=file]#uploadFile").change(function () {
				self.set("isInvalid", false);
				self.set("isEmpty", false);
				self.set("isInvalidFile", false);
			});
			$('#uploadFile').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		CanadianProvinces: {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon"

		},
		currencies: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		currencyName: "Canadian Dollar",
		currency: "CAD",
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('api').getCountries().then(function (data) {
				_this.set('countries', data);
			});
		},
		parseCSV: function parseCSV(lines) {
			var bankingObjects = [];
			for (var index = 0; index < lines.length; index++) {
				var values = lines[index].split(';');

				if (this.get("Service") === "EFT") {
					if (values[0].trim() != "Currency" && values.length > 1) {

						var BankInfo = {};
						BankInfo.CompanyName = this.parseName((values[3] + "").trim());
						BankInfo.FirstName = this.parseName((values[1] + "").trim());
						BankInfo.LastName = this.parseName((values[2] + "").trim());
						BankInfo.AccountHolderName = BankInfo.CompanyName ? BankInfo.CompanyName : BankInfo.FirstName + ' ' + BankInfo.LastName;
						BankInfo.Currency = (values[0] + "").trim();
						BankInfo.Address1 = (values[4] + "").trim();
						BankInfo.City = (values[5] + "").trim();
						BankInfo.Province = this.parseProvince((values[6] + "").trim());
						BankInfo.Country = this.parseCountry((values[7] + "").trim());
						BankInfo.PostalCode = (values[8] + "").trim();
						BankInfo.ClientReferenceNumber = (values[9] + "").trim();
						BankInfo.FinancialInstitutionNumber = this.parseNumber((0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", (values[10] + "").trim(), 3]));
						BankInfo.BranchTransitNumber = this.parseNumber(values[11]);
						BankInfo.AccountNumber = this.parseNumber(values[12]);
						BankInfo.Type = "Bank Account";
						this.set('financialInstitutionNumber');

						var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
							return parseInt(b.code) === parseInt(values[10]);
						});
						if (bank[0]) {

							BankInfo.InstitutionName = bank[0].name;
						} else {
							BankInfo.InstitutionName = "";
						}

						bankingObjects.push(BankInfo);
					}
				} else if (this.get("Service") === "e-Transfer") {
					if (values[0].trim() != "Currency" && values.length > 1) {
						var BankInfo = {};
						BankInfo.RecipientName = (values[1] + "").trim();
						BankInfo.Email = (values[2] + "").trim();
						BankInfo.SecurityQuestion = (values[3] + "").trim();
						BankInfo.SecurityResponse = (values[4] + "").trim();
						BankInfo.ClientReferenceNumber = (values[6] + "").trim();
						BankInfo.Currency = (values[0] + "").trim();
						BankInfo.Memo = (values[5] + "").trim();
						BankInfo.Type = "Interac E-Transfer";
						BankInfo.Country = "CA";
						bankingObjects.push(BankInfo);
					}
				}
			}
			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {

				this.set("isProgress", true);
				this.payment(bankingObjects, 0, bankingObjects.length, "beneficiary", this.get("Service"), errors);
			} else {
				this.set("isEmpty", true);
			}
		},

		finish: function finish(errors) {
			var errors2 = errors.slice(0);

			this.set("progress", errors2);
			this.set("isFinished", true);
			this.set("isProgress", false);
			_ember['default'].$("#FileSummary").show();
			_ember['default'].$("#FileInput").hide();
		},
		validateEmail: function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		validateInstitution: function validateInstitution(institution) {
			var re = /^\d{1,3}$/;
			return re.test(String(institution).toLowerCase());
		},
		validateTransit: function validateTransit(transit) {
			var re = /^\d{1,5}$/;
			return re.test(String(transit).toLowerCase());
		},
		validateAccount: function validateAccount(account) {
			var re = /^\d{6,12}$/;
			return re.test(String(account).toLowerCase());
		},
		validateIsOnlyNumbers: function validateIsOnlyNumbers(text) {
			var re = /^\d+$/;
			return re.test(String(text).toLowerCase());
		},
		validatePhoneNumber: function validatePhoneNumber(text) {
			var re = /^[a-zA-Z0-9()-+.\s]*$/;
			return re.test(String(text).toLowerCase());
		},
		eft: function eft(beneficiary, errors2, PaymentType, index) {

			var self = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				self.get("api").saveBankAccount(beneficiary);
				beneficiary.Id = index + 1;
				beneficiary.Message = "Successfully submitted";
				resolve(beneficiary);
			});
		},
		emt: function emt(beneficiary, errors2, PaymentType, index) {
			var self = this;
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				self.get("firebase").createRecordUser(PaymentType, beneficiary);
				beneficiary.Id = index + 1;
				beneficiary.Message = "Successfully submitted";
				resolve(beneficiary);
			});
		},
		payment: function payment(listing, i, length, PaymentType, Service, errors) {
			var self = this;
			var errors2 = errors.slice(0);
			for (var index = 0; index < length; index++) {
				var beneficiary = listing[index];
				if (Service == "EFT" || Service == "Electronic Fund Transfer") {
					if (beneficiary.Address1 == "undifined" || beneficiary.Address1 == "undefined undefined" || beneficiary.City == "undifined" || beneficiary.Province == "undifined" || beneficiary.PostalCode == "undifined" || beneficiary.Country == "undifined") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "	Invalid Parameter(s): Address";
						errors2.push(beneficiary);
					} else if ((beneficiary.CompanyName == null || beneficiary.CompanyName == "") && (beneficiary.FirstName == null || beneficiary.FirstName == "") && (beneficiary.LastName == null || beneficiary.LastName == "")) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Company Name";
						errors2.push(beneficiary);
					} else if ((beneficiary.FirstName == null || beneficiary.FirstName == "") && (beneficiary.CompanyName == null || beneficiary.CompanyName == "")) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): First Name";
						errors2.push(beneficiary);
					} else if ((beneficiary.LastName == null || beneficiary.LastName == "") && (beneficiary.CompanyName == null || beneficiary.CompanyName == "")) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Last Name";
						errors2.push(beneficiary);
					} else if (!self.validateEmail(beneficiary.Email) && beneficiary.Email != null && beneficiary.Email != "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Email";
						errors2.push(beneficiary);
					} else if (!self.validatePhoneNumber(beneficiary.PhoneNumber) && beneficiary.PhoneNumber != null && beneficiary.PhoneNumber != "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Phone Number";
						errors2.push(beneficiary);
					} else if (!self.validateAccount(beneficiary.AccountNumber) || beneficiary.AccountNumber == null || beneficiary.AccountNumber == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Account Number";
						beneficiary.AccountNumber = null;
						errors2.push(beneficiary);
					} else if (beneficiary.PostalCode == null || beneficiary.PostalCode == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Postal Code";
						beneficiary.PostalCode = null;
						errors2.push(beneficiary);
					} else if (beneficiary.Country == null || beneficiary.Country == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Country";
						beneficiary.Country = null;
						errors2.push(beneficiary);
					} else if (beneficiary.Province == null || beneficiary.Province == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Province";
						beneficiary.Province = null;
						errors2.push(beneficiary);
					} else if (beneficiary.City == null || beneficiary.City == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): City";
						beneficiary.City = null;
						errors2.push(beneficiary);
					} else if (beneficiary.Address1 == null || beneficiary.Address1 == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Address";
						beneficiary.Address1 = null;
						errors2.push(beneficiary);
					} else if (!self.validateTransit(beneficiary.BranchTransitNumber) || beneficiary.BranchTransitNumber == null || beneficiary.BranchTransitNumber == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Branch Transit Number";
						beneficiary.BranchTransitNumber = null;
						errors2.push(beneficiary);
					} else if (!self.validateInstitution(beneficiary.FinancialInstitutionNumber) || beneficiary.FinancialInstitutionNumber == null || beneficiary.FinancialInstitutionNumber == "" || beneficiary.InstitutionName == null || beneficiary.InstitutionName == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Financial Institution Number";
						beneficiary.FinancialInstitutionNumber = null;
						errors2.push(beneficiary);
					} else if (beneficiary.Currency == null || beneficiary.Currency == "" || beneficiary.Currency != "CAD" && beneficiary.Currency != "USD") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Currency";
						beneficiary.FinancialInstitutionNumber = null;
						errors2.push(beneficiary);
					} else {
						this.eft(beneficiary, errors2, PaymentType, index).then(function (ben) {
							errors2.push(ben);
						});
					}
				} else {
					if (self.validateIsOnlyNumbers(beneficiary.RecipientName) || beneficiary.RecipientName == null || beneficiary.RecipientName == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): RecipientName";
						errors2.push(beneficiary);
					} else if (beneficiary.Currency == null || beneficiary.Currency == "" || beneficiary.Currency != "CAD") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Currency";
						beneficiary.FinancialInstitutionNumber = null;
						errors2.push(beneficiary);
					} else if (!self.validateEmail(beneficiary.Email) || beneficiary.RecipientName == null || beneficiary.RecipientName == "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Email";
						errors2.push(beneficiary);
					} else if (!self.validatePhoneNumber(beneficiary.PhoneNumber) && beneficiary.PhoneNumber != null && beneficiary.PhoneNumber != "") {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Invalid Parameter(s): Phone Number";
						errors2.push(beneficiary);
					}
					/**
     else if(beneficiary.SecurityQuestion == null || beneficiary.SecurityQuestion == ""){
     	beneficiary.Id = index+1;
     	beneficiary.Message = "Invalid Parameter(s): Security Question";
     	errors2.push(beneficiary);
     	
     }
     else if(beneficiary.SecurityResponse == null || beneficiary.SecurityResponse == ""){
     	beneficiary.Id = index+1;
     	beneficiary.Message = "Invalid Parameter(s): Security Response";
     	errors2.push(beneficiary);
     	
     }
     **/
					else {
							this.emt(beneficiary, errors2, PaymentType, index).then(function (ben) {
								errors2.push(ben);
							});
						}
				}
			}
			var interval = setInterval(function () {

				if (errors2.length >= length) {
					errors2.sort(function (a, b) {

						// Compare the 2 dates
						if (a.Id < b.Id) return -1;

						if (a.Id > b.Id) return 1;
						return 0;
					});
					self.finish(errors2);

					clearInterval(interval);
				}
			}, 100);
		},
		parsePostalCode: function parsePostalCode(value) {
			if (value != null) {
				return value.replace(/[^a-zA-Z0-9]+/g, '');
			} else {
				return value;
			}
		},
		parseName: function parseName(value) {
			if (value) {
				return value.replace(/[^a-zA-Z0-9=$\s.*-]+/g, '');
			} else {
				return value;
			}
		},
		parseCountry: function parseCountry(value) {
			if (value) {
				if (value.length > 2) {
					if (value == "Canada") {
						return "CA";
					} else {
						return this.getIsoCountry(value);
					}
				} else {
					return value;
				}
			} else {
				return value;
			}
		},
		parseNumber: function parseNumber(value) {
			if (value) {
				return value.replace(/[\D]+/g, '');
			} else {
				return value;
			}
		},
		parseDollar: function parseDollar(value) {
			if (value) {
				return value.replace(/[^\d.]+/g, '');
			} else {
				return value;
			}
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getKeyByValue: function getKeyByValue(object, value) {
			return Object.keys(object).find(function (key) {
				return object[key].toLowerCase() === value.toLowerCase();
			});
		},
		parseProvince: function parseProvince(value) {
			if (value) {
				if (value.length > 2) {

					return this.getKeyByValue(this.get("CanadianProvinces"), value);
				} else if (value.toUpperCase() == "PQ") {
					return "QC";
				} else if (value.toUpperCase() == "QB") {
					return "QC";
				} else if (value.toUpperCase() == "NF") {
					return 'NL';
				} else {
					return value;
				}
			} else {
				return value;
			}
		},
		actions: {
			closeModalProgress: function closeModalProgress() {
				this.set("isProgress", false);
			},
			handleService: function handleService(value) {
				this.set("Service", value);
				this.set("isInvalidFile", false);
			},
			handleDocument: function handleDocument(value) {
				if (value == "Credit (Send Funds)") {
					this.set("Document", "Credit");
				} else {
					this.set("Document", "Debit");
				}
			},
			moveToUploadFile: function moveToUploadFile() {
				$("#FileSummary").hide();
				$("#FileInput").show();
				$("#uploadFile").val(null);
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				this.set("currency", currency);
				this.set("currencyName", this.getCurrencyName(currency));
			},
			upload: function upload() {
				var _this2 = this;

				if ($('#uploadFile').parsley().validate()) {
					var file = $('#uploadFile')[0].files[0];
					var name = file.name;
					var ext = name.split('.').pop().toLowerCase();
					if (ext === "csv") {
						var reader2;

						(function () {
							reader2 = new FileReader();

							var self = _this2;
							reader2.onload = function (event) {
								// Then you can do something with the result
								var data = event.target.result;

								var wb = XLSX.read(data, { type: 'binary' });
								var lines = [];
								wb.SheetNames.forEach(function (sheetName) {
									var csv = XLSX.utils.sheet_to_csv(wb.Sheets[sheetName], { FS: ";", blankrows: false });
									if (csv.length) {
										var csvArr = csv.split("\n");
										lines.push(csvArr);
									}
								});

								var results = lines[0];
								var values = results[0].split(';');
								if (values.length >= 7 && values.length <= 8 && self.get("Service") === "EFT") {
									self.set("isInvalidFile", true);
								} else if (values.length >= 13 && values.length <= 14 && self.get("Service") === "e-Transfer") {
									self.set("isInvalidFile", true);
								} else {
									self.parseCSV(results);
								}
							};

							reader2.readAsBinaryString(file);
						})();
					} else {
						this.set("isInvalid", true);
					}
				}
			},
			pdf: function pdf() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
				}, 200);
				return false;
			},
			csv: function csv() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xls: function xls() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			doc: function doc() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			text: function text() {

				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			json: function json() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xml: function xml() {
				setTimeout(function () {
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			printPage: function printPage() {
				setTimeout(function () {
					window.print();
				}, 200);
			}
		}
	});
});