define('vopay_app/helpers/complete-with-zeros', ['exports', 'ember'], function (exports, _ember) {
  exports.completeWithZeros = completeWithZeros;

  function completeWithZeros(params /*, hash*/) {

    if (!params[0]) {
      // throw new Error("Direction has to be informed");
      console.warn("Direction has to be informed");
      return '';
    }

    if (!params[1]) {
      // throw new Error("Value has to be informed");
      console.warn("Value has to be informed");
      return '';
    }

    if (!params[2]) {
      // throw new Error("Max has to be informed");
      console.warn("Max has to be informed");
      return '';
    }

    var z = '';
    var qty = params[2] - params[1].length;
    for (var i = 0; i < qty; i++) {
      z += '0';
    }
    return params[0] === 'l' ? z + params[1] : params[1] + z;
  }

  exports['default'] = _ember['default'].Helper.helper(completeWithZeros);
});