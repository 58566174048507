define("vopay_app/helpers/mask-account", ["exports", "ember"], function (exports, _ember) {
  exports.maskAccount = maskAccount;

  function maskAccount(params /*, hash*/) {
    var number = params[0];
    var last4 = number.slice(-4);
    return "***" + last4;
  }

  exports["default"] = _ember["default"].Helper.helper(maskAccount);
});