define('vopay_app/services/firebase', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		createRecordUser: function createRecordUser(Key, Value) {
			var newKey = window.firebase.database().ref('users/' + localStorage.getItem("user") + '/').child(Key).push().key;
			Value.timestamp = window.firebase.database.ServerValue.TIMESTAMP;
			window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Key + '/' + newKey).set(Value);
			return newKey;
		},
		updateRecordUser: function updateRecordUser(Collection, Key, Value) {
			window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Collection + '/' + Key).set(Value);
		},
		updateRecordSingle: function updateRecordSingle(Key, Value) {
			window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Key).set(Value);
		},
		getRecordUser: function getRecordUser(Key) {
			return new _ember['default'].RSVP.Promise(function (resolve) {
				window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Key + '/').once('value').then(function (snapshot) {
					resolve(snapshot.val());
				});
			});
		},
		getSingleRecordUser: function getSingleRecordUser(Collection, Key) {
			return new _ember['default'].RSVP.Promise(function (resolve) {
				window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Collection + '/' + Key).once('value').then(function (snapshot) {
					resolve(snapshot.val());
				});
			});
		},
		deleteRecordUser: function deleteRecordUser(Collection, Key) {
			window.firebase.database().ref('users/' + localStorage.getItem("user") + '/' + Collection + '/' + Key).remove();
		},
		addFileUser: function addFileUser(Collection, Key, Blob) {
			var storageRef = window.firebase.storage().ref();
			storageRef.child('users/' + localStorage.getItem("user") + '/' + Collection + '/' + Key + '/' + Blob.name).put(Blob).then(function () {});
		},
		deleteFileUser: function deleteFileUser(Collection, Key) {
			var storageRef = window.firebase.storage().ref();
			storageRef.child('users/' + localStorage.getItem("user") + '/' + Collection + '/' + Key + '/')['delete']().then(function () {
				// File deleted successfully

			});
		},
		getRecordSignupCollection: function getRecordSignupCollection() {
			var newKey = window.firebase.database().ref('signup').push().key;
			return newKey;
		},
		addRecordSignup: function addRecordSignup(Collection, Key, Value) {
			window.firebase.database().ref('signup/' + Collection + '/' + Key).set(Value);
		},
		addRecordSignupMulti: function addRecordSignupMulti(Collection, Key, Value) {
			var newKey = window.firebase.database().ref('signup/' + Collection + '/').child(Key).push().key;
			window.firebase.database().ref('signup/' + Collection + '/' + Key + '/' + newKey).set(Value);
		},
		addFile: function addFile(Collection, Blob) {
			var storageRef = window.firebase.storage().ref();
			storageRef.child('signup/' + Collection + '/' + Blob.name).put(Blob).then(function () {});
		}
	});
});