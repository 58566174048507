define('vopay_app/components/reconciliation-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        Description: "",
        Id: "",
        Type: "",
        Service: "",
        IsBatchFile: false,
        HyperLinkText: "",
        init: function init() {
            this._super.apply(this, arguments);
            this.set("Description", this.get("transaction.Description"));
            var DescriptionParts = this.get("Description").trim().split(' ');

            if (DescriptionParts.includes("DEFT") && DescriptionParts.includes("SETTLEMENT")) {
                this.set("Id", DescriptionParts[DescriptionParts.length - 1]);
                this.set("Service", "eft");
                if (this.get("transaction.Credit")) {
                    this.set("Type", "credit");
                    this.set("Amount", this.get("transaction.Credit"));
                } else {
                    this.set("Type", "debit");
                    this.set("Amount", this.get("transaction.Debit"));
                }
                this.set("HyperLinkText", DescriptionParts[DescriptionParts.length - 1]);
                this.set("IsBatchFile", true);
                this.set("Description", DescriptionParts.slice(0, -1).join(' '));
            } else if (DescriptionParts.includes("INTERAC") && DescriptionParts.includes("E-TRANSFER") && DescriptionParts.includes("BULK") && DescriptionParts.includes("SEND")) {
                this.set("Service", "interac");
                this.set("Type", "debit");
                this.set("Amount", this.get("transaction.Debit"));
                var Id = DescriptionParts[DescriptionParts.length - 1].substring(12);
                this.set("Id", Id);
                this.set("HyperLinkText", DescriptionParts[DescriptionParts.length - 1]);
                this.set("IsBatchFile", true);
                this.set("Description", DescriptionParts.slice(0, -1).join(' '));
            } else {}
        },
        didReceiveAttrs: function didReceiveAttrs(options) {
            this._super.apply(this, arguments);
            if (options.newAttrs.transaction.value.Description) {
                this.set("IsBatchFile", false);
                this.set("Description", options.newAttrs.transaction.value.Description);
                var DescriptionParts = this.get("Description").trim().split(' ');
                if (DescriptionParts.includes("DEFT") && DescriptionParts.includes("SETTLEMENT")) {
                    this.set("Id", DescriptionParts[DescriptionParts.length - 1]);
                    this.set("HyperLinkText", DescriptionParts[DescriptionParts.length - 1]);

                    this.set("Description", DescriptionParts.slice(0, -1).join(' '));
                    this.set("Service", "eft");
                    if (this.get("transaction.Credit")) {
                        this.set("Type", "credit");
                        this.set("Amount", this.get("transaction.Credit"));
                    } else {
                        this.set("Type", "debit");
                        this.set("Amount", this.get("transaction.Debit"));
                    }

                    this.set("IsBatchFile", true);
                } else if (DescriptionParts.includes("INTERAC") && DescriptionParts.includes("E-TRANSFER") && DescriptionParts.includes("BULK") && DescriptionParts.includes("SEND")) {
                    var Id = DescriptionParts[DescriptionParts.length - 1].substring(12);
                    this.set("HyperLinkText", DescriptionParts[DescriptionParts.length - 1]);
                    this.set("Description", DescriptionParts.slice(0, -1).join(' '));

                    this.set("Service", "interac");
                    this.set("Type", "debit");
                    this.set("Id", Id);
                    this.set("Amount", this.get("transaction.Debit"));

                    this.set("IsBatchFile", true);
                } else {}
            }
        }
    });
});