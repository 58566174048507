define('vopay_app/routes/dashboard/sub-accounts/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    api: _ember['default'].inject.service('api'),
    userPermissions: _ember['default'].inject.service('user-permissions'),
    model: function model() {
      this.get("userPermissions").HasPermissionRoute("ViewSubaccounts");
      return _ember['default'].RSVP.hash({
        data: this.get('api').getSubAccounts(),
        currencies: this.get('api').getGCMCurriencies()
      });
    }

  });
});