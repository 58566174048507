define('vopay_app/routes/dashboard/recipients/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model() {
      var authed = this.get('session.isAuthenticated');

      if (authed) {

        return _ember['default'].RSVP.hash({
          recipients: this._retrieveRecipients(),
          transactions: this.get('api').getTransactions()
        });
      } else // userInfo: this.get('api').userInfo()
        {
          this.transitionTo('login');
        }
    },
    _parsePaymentData: function _parsePaymentData(payment) {

      var paymentObject = {};
      var parsedPayment = {};

      paymentObject = JSON.parse(payment);

      for (var key in paymentObject) {
        var iKey = key.replace(/_+/g, "");
        parsedPayment[iKey] = paymentObject[key];
      }

      return parsedPayment;
    },
    _retrieveRecipients: function _retrieveRecipients() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.get('api').listRecipients().then(function (data) {

          for (var i = 0; i < data.length; i++) {
            data[i].BankAccount = _this._parsePaymentData(data[i].BankAccount);
            if (data[i].DeliveryMethod === "cash") {
              data[i].DeliveryMethodClass = 'cashPickUp';
              data[i].DeliveryMethodLabel = 'Cash Pick-Up';
            } else {
              data[i].DeliveryMethodClass = 'bankDeposit';
              data[i].DeliveryMethodLabel = 'Bank Deposit';
            }
          }
          resolve(data);
        });
      });
    }
  });
});