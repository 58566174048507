define('vopay_app/components/currency-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		SendingCurrency: "CAD",
		RecipientCurrency: "USD",
		startdays: 364,
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get("api").getGCMCurriencies().then(function (data) {

				_this.set("currencies", data.CurrenciesList);
			});
		},
		convertDate: function convertDate(days) {
			var today = new Date();
			today.setDate(today.getDate() - days);
			var DD = this.pad(today.getDate(), 2);
			var MM = this.pad(today.getMonth() + 1, 2);
			var YYYY = today.getFullYear();
			var date = YYYY + "-" + MM + "-" + DD;
			return date;
		},
		pad: function pad(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		},
		didInsertElement: function didInsertElement() {
			var _this2 = this;

			var options = {};
			options.SendingCurrency = "CAD";
			options.RecipientCurrency = "USD";
			options.StartDate = this.convertDate(this.get("startdays"));
			options.EndDate = this.convertDate(0);
			// var chartData = [];
			// this.generateList(6, "CAD", "USD", chartData).then(
			// 	data =>{
			// 		let keys = Object.keys(data);
			//          	var min =  data[0]["value"];
			//          	var max =  data[0]["value"];
			//          	for(var i = 0; i < data.length; i++){
			//          		if(min > data[i]["value"]){
			//          			min =  data[i]["value"];
			//          		}
			//          		if(max <  data[i]["value"]){
			//          			max =  data[i]["value"];
			//          		}

			//          	}
			//          	max = Math.ceil((max+0.01)*100)/100;
			//          	min = Math.floor((min-0.01)*100)/100;

			//          	window.currencyChart = new Morris.Line({
			// 		  // ID of the element in which to draw the chart.
			// 		  element: 'currencychart',
			// 		  // Chart data records -- each entry in this array corresponds to a point on
			// 		  // the chart.
			// 		  data: data,
			// 		  // The name of the data record attribute that contains x-values.
			// 		  xkey: 'date',
			// 		  ymin: min,
			// 		  ymax: max,
			// 		  // A list of names of data record attributes that contain y-values.
			// 		  ykeys: ['value'],
			// 		  // Labels for the ykeys -- will be displayed when you hover over the
			// 		  // chart.
			// 		  resize: true,
			// 		  xLabels: "day",
			// 		  labels: ['Value'],
			// 		  lineColors: ['#007bff']

			// 		});
			// 	});
			if (!localStorage.getItem("data")) {
				this.get("api").getHistoricRate(options).then(function (data) {
					var chartData = [];
					var keys = Object.keys(data);
					var min = data[keys[0]][options.RecipientCurrency];
					var max = data[keys[0]][options.RecipientCurrency];
					for (var i = 0; i < keys.length; i++) {
						if (min > data[keys[i]][options.RecipientCurrency]) {
							min = data[keys[i]][options.RecipientCurrency];
						}
						if (max < data[keys[i]][options.RecipientCurrency]) {
							max = data[keys[i]][options.RecipientCurrency];
						}
						var dataPoint = {
							date: keys[i],
							value: data[keys[i]][options.RecipientCurrency]
						};
						chartData.push(dataPoint);
					}
					max = Math.ceil((max + 0.01) * 100) / 100;
					min = Math.floor((min - 0.01) * 100) / 100;
					localStorage.setItem("data", JSON.stringify(chartData));
					localStorage.setItem("dataMin", min);
					localStorage.setItem("dataMax", max);
					window.currencyChart = new window.Morris.Line({
						// ID of the element in which to draw the chart.
						element: 'currencychart',
						// Chart data records -- each entry in this array corresponds to a point on
						// the chart.
						data: chartData,
						// The name of the data record attribute that contains x-values.
						xkey: 'date',
						ymin: min,
						ymax: max,
						// A list of names of data record attributes that contain y-values.
						ykeys: ['value'],
						// Labels for the ykeys -- will be displayed when you hover over the
						// chart.
						resize: true,
						xLabels: "day",
						labels: ['Value'],
						lineColors: ['#007bff'],
						pointSize: 0,
						yLabelFormat: function yLabelFormat(y) {
							return y.toFixed(3);
						}

					});
				});
			} else {
				(function () {
					var chartData2 = JSON.parse(localStorage.getItem("data"));
					var min2 = parseFloat(localStorage.getItem("dataMin"));
					var max2 = parseFloat(localStorage.getItem("dataMax"));
					window.currencyChart = new window.Morris.Line({
						// ID of the element in which to draw the chart.
						element: 'currencychart',
						// Chart data records -- each entry in this array corresponds to a point on
						// the chart.
						data: chartData2,
						// The name of the data record attribute that contains x-values.
						xkey: 'date',
						ymin: min2,
						ymax: max2,
						// A list of names of data record attributes that contain y-values.
						ykeys: ['value'],
						// Labels for the ykeys -- will be displayed when you hover over the
						// chart.
						resize: true,
						xLabels: "day",
						labels: ['Value'],
						lineColors: ['#007bff'],
						pointSize: 0,
						yLabelFormat: function yLabelFormat(y) {
							return y.toFixed(3);
						}

					});
					var options = {};
					options.SendingCurrency = _this2.get("SendingCurrency");
					options.RecipientCurrency = _this2.get("RecipientCurrency");
					options.StartDate = _this2.convertDate(_this2.get("startdays"));
					options.EndDate = _this2.convertDate(0);

					_this2.get("api").getHistoricRate(options).then(function (data) {
						var chartData = [];
						var keys = Object.keys(data);
						var min = data[keys[0]][options.RecipientCurrency];
						var max = data[keys[0]][options.RecipientCurrency];
						for (var i = 0; i < keys.length; i++) {
							if (min > data[keys[i]][options.RecipientCurrency]) {
								min = data[keys[i]][options.RecipientCurrency];
							}
							if (max < data[keys[i]][options.RecipientCurrency]) {
								max = data[keys[i]][options.RecipientCurrency];
							}
							var dataPoint = {
								date: keys[i],
								value: data[keys[i]][options.RecipientCurrency]
							};
							chartData.push(dataPoint);
						}
						max = Math.ceil((max + 0.01) * 100) / 100;
						min = Math.floor((min - 0.01) * 100) / 100;

						localStorage.setItem("data", JSON.stringify(chartData));
						localStorage.setItem("dataMin", min);
						localStorage.setItem("dataMax", max);
						window.currencyChart.options["ymin"] = min;
						window.currencyChart.options["ymax"] = max;
						window.currencyChart.setData(chartData);
					});
				})();
			}
		},
		generateList: function generateList(number, sending, recipient, chartData) {
			var _this3 = this;

			return new _ember['default'].RSVP.Promise(function (resolve) {
				var options = {};
				options.SendingCurrency = sending;
				options.RecipientCurrency = recipient;
				options.StartDate = _this3.convertDate(number);
				_this3.get("api").getDatedRate(options).then(function (data) {
					var dataPoint = {
						date: options.StartDate,
						value: data
					};
					_this3.get("chartData");
					chartData.push(dataPoint);
					if (number === 0) {
						resolve(chartData);
					} else {
						resolve(_this3.generateList(number - 1, sending, recipient, chartData));
					}
				});
			});
		},
		actions: {

			currencySourceChanged: function currencySourceChanged(value) {
				this.set("SendingCurrency", value);
				// var chartData = [];
				// this.generateList(6, this.get("SendingCurrency"), this.get("RecipientCurrency"), chartData).then(
				// data =>{
				// 	let keys = Object.keys(data);
				//         	var min =  data[0]["value"];
				//         	var max =  data[0]["value"];
				//         	for(var i = 0; i < data.length; i++){
				//         		if(min > data[i]["value"]){
				//         			min =  data[i]["value"];
				//         		}
				//         		if(max <  data[i]["value"]){
				//         			max =  data[i]["value"];
				//         		}
				//         	}
				//         	max = Math.ceil((max+0.01)*100)/100;
				//         	min = Math.floor((min-0.01)*100)/100;
				//         	window.currencyChart.options["ymin"] = min;
				//         	window.currencyChart.options["ymax"] = max;
				//         	window.currencyChart.setData(chartData);
				//         });
				// this.set("SendingCurrency", value);
				var options = {};
				options.SendingCurrency = this.get("SendingCurrency");
				options.RecipientCurrency = this.get("RecipientCurrency");
				options.StartDate = this.convertDate(this.get("startdays"));
				options.EndDate = this.convertDate(0);

				this.get("api").getHistoricRate(options).then(function (data) {
					var chartData = [];
					var keys = Object.keys(data);
					var min = data[keys[0]][options.RecipientCurrency];
					var max = data[keys[0]][options.RecipientCurrency];
					for (var i = 0; i < keys.length; i++) {
						if (min > data[keys[i]][options.RecipientCurrency]) {
							min = data[keys[i]][options.RecipientCurrency];
						}
						if (max < data[keys[i]][options.RecipientCurrency]) {
							max = data[keys[i]][options.RecipientCurrency];
						}
						var dataPoint = {
							date: keys[i],
							value: data[keys[i]][options.RecipientCurrency]
						};
						chartData.push(dataPoint);
					}
					max = Math.ceil((max + 0.01) * 100) / 100;
					min = Math.floor((min - 0.01) * 100) / 100;

					window.currencyChart.options["ymin"] = min;
					window.currencyChart.options["ymax"] = max;
					window.currencyChart.setData(chartData);
				});
			},
			currencyDestinationChanged: function currencyDestinationChanged(value) {
				this.set("RecipientCurrency", value);
				// this.generateList(6, this.get("SendingCurrency"), this.get("RecipientCurrency"), chartData).then(
				// data =>{
				// 	let keys = Object.keys(data);
				//         	var min =  data[0]["value"];
				//         	var max =  data[0]["value"];
				//         	for(var i = 0; i < data.length; i++){
				//         		if(min > data[i]["value"]){
				//         			min =  data[i]["value"];
				//         		}
				//         		if(max <  data[i]["value"]){
				//         			max =  data[i]["value"];
				//         		}
				//         	}
				//         	max = Math.ceil((max+0.01)*100)/100;
				//         	min = Math.floor((min-0.01)*100)/100;
				//         	window.currencyChart.options["ymin"] = min;
				//         	window.currencyChart.options["ymax"] = max;
				//         	window.currencyChart.setData(chartData);
				//         });
				var options = {};
				options.SendingCurrency = this.get("SendingCurrency");
				options.RecipientCurrency = this.get("RecipientCurrency");
				options.StartDate = this.convertDate(this.get("startdays"));
				options.EndDate = this.convertDate(0);

				this.get("api").getHistoricRate(options).then(function (data) {
					var chartData = [];
					var keys = Object.keys(data);
					var min = data[keys[0]][options.RecipientCurrency];
					var max = data[keys[0]][options.RecipientCurrency];
					for (var i = 0; i < keys.length; i++) {
						if (min > data[keys[i]][options.RecipientCurrency]) {
							min = data[keys[i]][options.RecipientCurrency];
						}
						if (max < data[keys[i]][options.RecipientCurrency]) {
							max = data[keys[i]][options.RecipientCurrency];
						}
						var dataPoint = {
							date: keys[i],
							value: data[keys[i]][options.RecipientCurrency]
						};
						chartData.push(dataPoint);
					}
					max = Math.ceil((max + 0.01) * 100) / 100;
					min = Math.floor((min - 0.01) * 100) / 100;

					window.currencyChart.options["ymin"] = min;
					window.currencyChart.options["ymax"] = max;
					window.currencyChart.setData(chartData);
				});
			},
			timeFrameChanged: function timeFrameChanged(range) {
				var value = range - 1;
				if (range > 60) {
					window.currencyChart.options["pointSize"] = 0;
				} else {
					window.currencyChart.options["pointSize"] = 4;
				}

				this.set("startdays", value);
				var options = {};
				options.SendingCurrency = this.get("SendingCurrency");
				options.RecipientCurrency = this.get("RecipientCurrency");
				options.StartDate = this.convertDate(this.get("startdays"));
				options.EndDate = this.convertDate(0);

				this.get("api").getHistoricRate(options).then(function (data) {
					var chartData = [];
					var keys = Object.keys(data);
					var min = data[keys[0]][options.RecipientCurrency];
					var max = data[keys[0]][options.RecipientCurrency];
					for (var i = 0; i < keys.length; i++) {
						if (min > data[keys[i]][options.RecipientCurrency]) {
							min = data[keys[i]][options.RecipientCurrency];
						}
						if (max < data[keys[i]][options.RecipientCurrency]) {
							max = data[keys[i]][options.RecipientCurrency];
						}
						var dataPoint = {
							date: keys[i],
							value: data[keys[i]][options.RecipientCurrency]
						};
						chartData.push(dataPoint);
					}
					max = Math.ceil((max + 0.01) * 100) / 100;
					min = Math.floor((min - 0.01) * 100) / 100;

					window.currencyChart.options["ymin"] = min;
					window.currencyChart.options["ymax"] = max;
					window.currencyChart.setData(chartData);
				});
			}
		}

	});
});