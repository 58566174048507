define("vopay_app/templates/components/account-signature", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 6
          }
        },
        "moduleName": "vopay_app/templates/components/account-signature.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "moneyTransfer__heading--sub moneyTransfer__heading--subActive moneyTransfer__heading--subMinor");
        var el2 = dom.createTextNode("Signature #");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form__element form__element--25");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "data-parsley-trigger", "focusout");
        dom.setAttribute(el2, "data-parsley-required", "true");
        dom.setAttribute(el2, "class", "form__input");
        dom.setAttribute(el2, "required", "true");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form__label");
        var el3 = dom.createTextNode("First Name");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form__element form__element--25");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "data-parsley-trigger", "focusout");
        dom.setAttribute(el2, "data-parsley-required", "true");
        dom.setAttribute(el2, "class", "form__input");
        dom.setAttribute(el2, "required", "true");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form__label");
        var el3 = dom.createTextNode("Last Name");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form__element form__element--50 form__element--block");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "class", "form__input");
        dom.setAttribute(el2, "placeholder", "someone@example.com");
        dom.setAttribute(el2, "required", "true");
        dom.setAttribute(el2, "data-parsley-trigger", "focusout");
        dom.setAttribute(el2, "data-parsley-required", "true");
        dom.setAttribute(el2, "data-parsley-type", "email");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form__label");
        var el3 = dom.createTextNode("Work Email");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form__element form__element--25");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "class", "form__input");
        dom.setAttribute(el2, "placeholder", "123456789");
        dom.setAttribute(el2, "required", "true");
        dom.setAttribute(el2, "data-parsley-trigger", "focusout");
        dom.setAttribute(el2, "data-parsley-required", "true");
        dom.setAttribute(el2, "data-parsley-type", "number");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form__label");
        var el3 = dom.createTextNode("Phone Number");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form__element form__element--25");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "class", "form__input");
        dom.setAttribute(el2, "placeholder", "eg CEO");
        dom.setAttribute(el2, "required", "true");
        dom.setAttribute(el2, "data-parsley-trigger", "focusout");
        dom.setAttribute(el2, "data-parsley-required", "true");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form__label");
        var el3 = dom.createTextNode("Job Title");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var element1 = dom.childAt(fragment, [4, 1]);
        var element2 = dom.childAt(fragment, [6, 1]);
        var element3 = dom.childAt(fragment, [8, 1]);
        var element4 = dom.childAt(fragment, [10, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element0, 'name');
        morphs[2] = dom.createAttrMorph(element1, 'name');
        morphs[3] = dom.createAttrMorph(element2, 'name');
        morphs[4] = dom.createAttrMorph(element3, 'name');
        morphs[5] = dom.createAttrMorph(element4, 'name');
        return morphs;
      },
      statements: [["content", "number", ["loc", [null, [1, 117], [1, 127]]], 0, 0, 0, 0], ["attribute", "name", ["concat", ["FirstName", ["get", "number", ["loc", [null, [3, 106], [3, 112]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", ["LastName", ["get", "number", ["loc", [null, [7, 85], [7, 91]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", ["WorkEmail", ["get", "number", ["loc", [null, [12, 45], [12, 51]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", ["PhoneNumber", ["get", "number", ["loc", [null, [16, 47], [16, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", ["JobTitle", ["get", "number", ["loc", [null, [20, 44], [20, 50]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});