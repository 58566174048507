define('vopay_app/components/authenticate-user', ['exports', 'ember', 'vopay_app/utils/cookies-manager'], function (exports, _ember, _vopay_appUtilsCookiesManager) {
    exports['default'] = _ember['default'].Component.extend({
        cookiesmanager: _vopay_appUtilsCookiesManager['default'].create(),
        session: _ember['default'].inject.service('session'),
        loader: _ember['default'].inject.service('loader'),
        init: function init() {
            this._super.apply(this, arguments);
            var token = this.get("cookiesmanager").getCookie("dashboardToken");
            var Username = this.get("cookiesmanager").getCookie("dashboardUsername");
            localStorage.setItem("Username", decodeURIComponent(Username));
            this.get("loader").UpdateLoading(true);
            var options = {
                JWToken: token
            };
            this.get("cookiesmanager").removeCookie("dashboardToken");
            this.get("cookiesmanager").removeCookie("dashboardUsername");
            this.get('session').authenticate('authenticator:custom', options).then(function (authData) {
                location.replace("/#/dashboard");
                location.reload();
            });
        }
    });
});