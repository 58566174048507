define("vopay_app/templates/components/account-standing", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "vopay_app/templates/components/account-standing.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("					");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("			\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'value');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["get", "currency", ["loc", [null, [9, 22], [9, 30]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currency", ["loc", [null, [9, 34], [9, 46]]], 0, 0, 0, 0]],
        locals: ["key", "currency"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "vopay_app/templates/components/account-standing.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "accountStanding__section");
        var el2 = dom.createTextNode("\n\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "accountStanding__element accountStanding__element--last");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "accountStanding__header");
        var el4 = dom.createTextNode("Account Summary\n\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("select");
        var el5 = dom.createTextNode("\n				");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("option");
        dom.setAttribute(el5, "class", "currencyInput__option");
        dom.setAttribute(el5, "value", "CAD");
        dom.setAttribute(el5, "disabled", "");
        dom.setAttribute(el5, "hidden", "");
        dom.setAttribute(el5, "selected", "selected");
        var el6 = dom.createTextNode("CAD");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("			");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "accountStanding__item");
        var el4 = dom.createTextNode("Balance ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "accountStanding__item--featured");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "accountStanding__item");
        var el4 = dom.createTextNode("Authorized Pending Funds ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "accountStanding__item--featured");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "accountStanding__item");
        var el4 = dom.createTextNode("Security Deposit / Holdback ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "accountStanding__item--featured");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "accountStanding__item accountStanding__item--highlight");
        var el4 = dom.createTextNode("Available Balance ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "accountStanding__item--featured accountStanding__item--highlight");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1]);
        var element2 = dom.childAt(element1, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element2, 'onchange');
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [7, 1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element1, [9, 1]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "onchange", ["subexpr", "action", ["currencyChanged"], ["value", "target.value"], ["loc", [null, [null, null], [6, 69]]], 0, 0], 0, 0, 0, 0], ["block", "each-in", [["get", "currencies", ["loc", [null, [8, 15], [8, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 4], [10, 16]]]], ["inline", "currency-seperator", [["get", "accountBalance.AccountBalance", ["loc", [null, [13, 110], [13, 139]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 89], [13, 141]]], 0, 0], ["inline", "currency-seperator", [["get", "accountBalance.PendingFunds", ["loc", [null, [14, 127], [14, 154]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 106], [14, 156]]], 0, 0], ["inline", "currency-seperator", [["get", "accountBalance.SecurityDeposit", ["loc", [null, [15, 130], [15, 160]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 109], [15, 162]]], 0, 0], ["inline", "currency-seperator", [["get", "accountBalance.AvailableFunds", ["loc", [null, [16, 186], [16, 215]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 165], [16, 217]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});