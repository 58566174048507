define('vopay_app/components/add-payment-faild-trans', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		paymentMethod: null,
		addPaymentMethodFormVisible: false,
		update: {},
		init: function init() {
			this._super.apply(this, arguments);
			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);
			this.set("update.TransactionID", this.get("transaction.RemittanceTransactionID"));
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			if (payment.IsDefault === "on") {
				payment.IsDefault = true;
			} else {
				payment.IsDefault = false;
			}

			if (this.get('paymentMethod') === 'CreditCard') {
				payment.type = 'creditcard';
			} else if (this.get('paymentMethod') === 'Bank') {
				payment.type = 'bankaccount';
			}

			return payment;
		},
		actions: {
			paymentChanged: function paymentChanged(paymentId, paymentType) {
				this.set('paymentMethod', paymentType);
				this.set('addPaymentMethodFormVisible', !!!paymentId);
				if (paymentType === "CreditCard") {
					this.set('update.CreditCardID', paymentId);
				}

				if (paymentType === "Bank") {
					this.set('update.BankAccountID', paymentId);
				}
			},
			update: function update() {
				var _this = this;

				if (this.validateForm('#paymentForm')) {
					(function () {
						var self = _this;
						_ember['default'].$('#stepThreeBtn').attr('disabled', 'disabled');
						var paymentFormArr = _ember['default'].$('#addPayment').serializeArray();
						_this.set("update.PaymentMethod", _this.get("paymentMethod"));
						if (_this.get('addPaymentMethodFormVisible')) {
							// Add payment

							if (_this.get('paymentMethod') === 'CreditCard' || _this.get('paymentMethod') === 'Bank') {

								_this.set('paymentToAdd', _this.addPaymentMethod(paymentFormArr));
							}
						}
						if (_this.get('paymentToAdd')) {
							_this.get('api').addPaymentMethod(_this.get('paymentToAdd')).then(function (bank) {
								// Resolve payment method
								if (bank.hasOwnProperty('ID')) {
									_this.set('update.BankAccountID', bank['ID']);
								}
								_this.get('api').updatePaymentMethodTrans(_this.get("update")).then(function () {
									if (_this.get("paymentToAdd.IsDefault")) {
										_this.get('api').setDefault(self.get('update.BankAccountID')).then(function () {}, function (err) {
											_this.handleErrors(paymentFormArr, err);
										});
									}
									if (_this.get("paymentToAdd.Store")) {
										_this.get('api').retryTransaction(_this.get("transaction.RemittanceTransactionID")).then(function () {
											_ember['default'].$('html,body').scrollTop(0);
											_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
											_this.get('routing').transitionTo('dashboard.confirmation', [_this.get("transaction.RemittanceTransactionID")]);
										}, function () {
											_this.get('routing').transitionTo('error');
											_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
										});
									} else {
										_this.get('api').deleteBankAccount(self.get('update.BankAccountID')).then(function () {
											_this.get('api').retryTransaction(_this.get("transaction.RemittanceTransactionID")).then(function () {
												_ember['default'].$('html,body').scrollTop(0);
												_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
												_this.get('routing').transitionTo('dashboard.confirmation', [_this.get("transaction.RemittanceTransactionID")]);
											}, function () {
												_this.get('routing').transitionTo('error');
												_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
											});
										}, function (err) {
											_this.handleErrors(paymentFormArr, err);
										});
									}
								}, function (err) {});
							}, function (err) {
								_this.handleErrors(paymentFormArr, err);
							});
						} else {
							_this.get('api').updatePaymentMethodTrans(_this.get("update")).then(function () {
								_this.get('api').retryTransaction(_this.get("transaction.RemittanceTransactionID")).then(function () {
									_ember['default'].$('html,body').scrollTop(0);
									_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
									_this.get('routing').transitionTo('dashboard.confirmation', [_this.get("transaction.RemittanceTransactionID")]);
								}, function () {
									_this.get('routing').transitionTo('error');
									_ember['default'].$('#stepThreeBtn').removeAttr('disabled');
								});
							}, function (err) {});
						}
					})();
				}
			}
		}
	});
});