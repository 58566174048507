define('vopay_app/components/subaccount-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		transactionType: ["All", "Active", "Not Active"],
		keyword: "",
		type: "",
		currency: "",
		init: function init() {
			this._super.apply(this, arguments);
			var data = this.get("data");
			this.set("SubAccounts", data.Subaccounts);
			this.set("SubAccountsOriginal", data.Subaccounts);
			var currencies = this.get("currencies.CurrenciesList");
			var all = ["All"];
			currencies = all.concat(currencies);

			this.set("currencies2", currencies);
		},
		filterTransactions: function filterTransactions(searchTerms, type, currency) {
			var OrginalSubaccounts = this.get("SubAccountsOriginal");
			var filteredList = {};

			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.replace(/\$/g, '');
			searchTerms = searchTerms.replace(/\,/g, '');
			searchTerms = searchTerms.replace(/\s|_/g, "", '');
			if (OrginalSubaccounts) {
				var keys = Object.keys(OrginalSubaccounts);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {

					if (typeof OrginalSubaccounts[i] !== 'undefined') {

						if (type === null || type === "" || type === "All" || type === OrginalSubaccounts[i].IsActive) {

							if (OrginalSubaccounts[i].LegalBusinessName.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
								next = next + 1;
								filteredList[next] = OrginalSubaccounts[i];
							}
						}
					}
				}
				this.set("SubAccounts", filteredList);
			}
		},
		hasBalance: function hasBalance(accounts, currency) {},
		actions: {
			transfer: function transfer(accountId, currency) {

				sessionStorage.setItem("transferAccount", accountId);
				sessionStorage.setItem("transferCurrency", currency);
				this.get("routing").transitionTo("dashboard.transfer");
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("currency"));
			},
			filterType: function filterType(type) {
				if (type == "All") {
					type == "";
				} else if (type == "Active") {
					type = true;
				} else if (type == "Not Active") {
					type = false;
				}

				this.set("type", type);
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("currency"));
			},
			filterCurrency: function filterCurrency(currency) {
				if (currency == "All") {
					currency = "";
				}
				this.set("currency", currency);
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("currency"));
			}
		}
	});
});