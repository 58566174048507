define('vopay_app/helpers/upper-case', ['exports', 'ember'], function (exports, _ember) {
  exports.upperCase = upperCase;

  function upperCase(params /*, hash*/) {
    if (!params[0]) {
      return params[0];
    }
    return params[0].toUpperCase();
  }

  exports['default'] = _ember['default'].Helper.helper(upperCase);
});