define('vopay_app/components/claim-form', ['exports', 'ember', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/constants', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appHelpersCompleteWithZeros, _vopay_appConstants, _vopay_appUtilsEntryFilter) {
	exports['default'] = _ember['default'].Component.extend({
		paymentTypeOptions: ["Bank Account", "Credit Card", "Interac E-Transfer"],
		isBank: true,
		defaultPaymentType: "Bank Account",
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			this.$(".newPaymentMethod__institution").hide();
			this.$('.financialInstitutionNumber').focusout(function () {
				this.$(".newPaymentMethod__institution").slideDown();
			});
		},
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyNameCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyNameCharacters(this.get('lastname')));
		}),
		watchCompanyName: _ember['default'].observer('companyName', function () {
			this.set('companyName', this.get('entryUtil').onlyNameCharacters(this.get('companyName')));
		}),

		watchPhoneNumber: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		actions: {
			handlePaymentType: function handlePaymentType(value) {
				if (value === "Interac E-Transfer") {
					this.set("isInteracEtransfer", true);
				} else {
					this.set("isInteracEtransfer", false);
				}
				if (value === "Credit Card") {

					this.set("isCredit", true);
				} else {
					this.set("isCredit", false);
				}
				if (value === "Bank Account") {
					this.set("isBank", true);
				} else {
					this.set("isBank", false);
				}
			},
			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = this.$("#form" + this.get("number") + " .form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					this.$("#form" + this.get("number") + " .form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					this.$("#form" + this.get("number") + " .form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);

					this.$("#form" + this.get("number") + " .InstitutionName").parsley().reset();
					this.$("#form" + this.get("number") + " .financialInstitutionNumber").parsley().reset();
					this.$("#form" + this.get("number") + " .newPaymentMethod__institution").slideDown();
				} else {

					this.$("#form" + this.get("number") + " .form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					this.$("#form" + this.get("number") + " .financialInstitutionNumber").val('');
					this.$("#form" + this.get("number") + " .financialInstitutionNumber").parsley().validate();
					// Ember.$("#financialInstitutionNumber").focus();
				}
			}

		}

	});
});