define('vopay_app/helpers/local-date-no-time', ['exports', 'ember'], function (exports, _ember) {
  exports.localDateNoTime = localDateNoTime;

  function localDateNoTime(params /*, hash*/) {
    var a = window.moment.utc(params[0]);
    var newDate = a.utc().format('YYYY-MM-DD');
    return newDate;
  }

  exports['default'] = _ember['default'].Helper.helper(localDateNoTime);
});