define('vopay_app/helpers/date-string', ['exports', 'ember'], function (exports, _ember) {
  exports.dateString = dateString;

  function dateString(params /*, hash*/) {
    var a = window.moment.utc(params[0]);
    var newDate = a.utc().format('MMM D, yyyy');
    return newDate;
  }

  exports['default'] = _ember['default'].Helper.helper(dateString);
});