define('vopay_app/components/business-detials-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyCharacters(this.get('lastname')));
		}),
		businessSections: ["Option 1", "Option 2", "Option2", "Option4", "Other"],
		businessTypes: ["Private Corporation", "Public Corporation", "Sole Proprietorship", "Not for Profit", "Government Entity", "Other"],
		watchCity: _ember['default'].observer('city', function () {

			this.set('city', this.get('entryUtil').onlyCharacters(this.get('city')));
		}),

		watchProvince: _ember['default'].observer('province', function () {

			this.set('province', this.get('entryUtil').onlyCharacters(this.get('province')));
		}),
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updatePrimaryContact: function updatePrimaryContact() {
				this.get("routing").transitionTo("dashboard.account.business-profile");
			},
			businessTypeSelected: function businessTypeSelected(value) {
				if (value === "Other") {
					_ember['default'].$("#businessType").val("");
					_ember['default'].$("#businessType").attr('type', 'text');
					_ember['default'].$("#businessType").focus();
					_ember['default'].$("#businessTypeDd").hide();
				} else {
					_ember['default'].$("#businessType").val(value);
					_ember['default'].$("#businessType").parsley().validate();
				}
			},
			businessSectorSelected: function businessSectorSelected(value) {
				if (value === "Other") {
					_ember['default'].$("#businessSector").val("");
					_ember['default'].$("#businessSector").attr('type', 'text');
					_ember['default'].$("#businessSector").focus();
					_ember['default'].$("#businessSectorDd").hide();
				} else {
					_ember['default'].$("#businessSector").val(value);
					_ember['default'].$("#businessSector").parsley().validate();
				}
			}
		}
	});
});