define('vopay_app/components/request-info-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		options: ["Cancel"],
		api: _ember['default'].inject.service('api'),
		types: ["All"],
		statuses: ["All", "Pending", "Declined", "Cancelled", "Completed"],
		transactionType: ["All", "Transaction", "Scheduled", "Beneficiary"],
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,
		keyword: "",
		currency: "CAD",
		type: null,
		date: null,
		status: "all",
		startDate: "",
		endDate: "",
		isShowingModal: false,
		watchdate: _ember['default'].observer('date', function () {
			var start = localStorage.getItem("startDate");
			var end = localStorage.getItem("endDate");
			if (start && end && start != "" && end != "") {
				this.set("startDate", start);
				this.set("endDate", end);

				localStorage.setItem("startDate", "");
				localStorage.setItem("endDate", "");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
			}
		}),

		init: function init() {

			this._super.apply(this, arguments);
			var currencies = this.get("currencies.CurrenciesList");
			this.set("currencies2", currencies);
			var count = 0;
			var transactions = this.get("transactions");
			var transaction2 = {};

			for (var k = 0; k < Object.keys(transactions).length; k++) {

				transactions[k]["key"] = k;
				transaction2[Object.keys(transactions).length - 1 - k] = transactions[k];
			}
			this.set("transactions", transaction2);
		},
		didInsertElement: function didInsertElement() {
			this.set("startDate", window.moment().utc().subtract(6, 'days').format('YYYY-MM-DD'));
			this.set("endDate", window.moment().utc().format('YYYY-MM-DD'));
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				startDate: window.moment().subtract(6, 'days'),
				endDate: window.moment(),
				ranges: {
					'Today': [window.moment().utc(), window.moment().utc()],
					'Yesterday': [window.moment().utc().subtract(1, 'days'), window.moment().utc().subtract(1, 'days')],
					'Last 7 Days': [window.moment().utc().subtract(6, 'days'), window.moment().utc()],
					'Last 30 Days': [window.moment().utc().subtract(29, 'days'), window.moment().utc()],
					'This Month': [window.moment().utc().startOf('month'), window.moment().utc().endOf('month')],
					'Last Month': [window.moment().utc().subtract(1, 'month').startOf('month'), window.moment().utc().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});

			localStorage.setItem("startDate", "");
			localStorage.setItem("endDate", "");
			_ember['default'].$('input[name="daterange"]').val(window.moment().utc().subtract(6, 'days').format('MM/DD/YYYY') + " - " + window.moment().utc().format('MM/DD/YYYY'));
			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
				localStorage.setItem("startDate", picker.startDate.format('YYYY-MM-DD'));
				localStorage.setItem("endDate", picker.endDate.format('YYYY-MM-DD'));
				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY'));
				_ember['default'].$('input[name="daterange"]').trigger("change");
			});
			this.getTransactions(1, this.get("transactions"));

			this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
		},
		pad: function pad(n, width, z) {
			z = z || '0';

			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		},
		refreshTransactions: function refreshTransactions(startDate, endDate, currency) {
			var _this = this;

			var options = {};
			var StartDateParts = startDate.split("-");
			var date3 = new Date(StartDateParts[0], StartDateParts[1] - 1, StartDateParts[2]);
			date3.setHours(0);
			date3.setMinutes(0);
			date3.setSeconds(0);

			var year3 = date3.getUTCFullYear();
			var month3 = date3.getUTCMonth() + 1;
			var day3 = date3.getUTCDate();
			var hour3 = date3.getUTCHours();
			var minutes3 = date3.getUTCMinutes();;
			var seconds3 = date3.getUTCSeconds();

			startDate = year3 + "-" + this.pad(month3, 2, "0") + "-" + this.pad(day3, 2, "0") + " " + this.pad(hour3, 2, "0") + ":" + this.pad(minutes3, 2, "0") + ":" + this.pad(seconds3, 2, "0");
			options.StartDateTime = startDate;

			var currentDate = new Date();
			var EndDateParts = endDate.split("-");
			var date = new Date(EndDateParts[0], EndDateParts[1] - 1, EndDateParts[2]);
			date.setHours(23);
			date.setMinutes(59);
			date.setSeconds(59);

			var year = date.getUTCFullYear();
			var month = date.getUTCMonth() + 1;
			var day = date.getUTCDate();
			var hour = date.getUTCHours();
			var minutes = date.getUTCMinutes();
			var seconds = date.getUTCSeconds();

			endDate = year + "-" + this.pad(month, 2, "0") + "-" + this.pad(day, 2, "0") + " " + this.pad(hour, 2, "0") + ":" + this.pad(minutes, 2, "0") + ":" + this.pad(seconds, 2, "0");
			options.EndDateTime = endDate;
			options.Currency = currency;
			this.get("api").getAccountTransactions(options).then(function (data) {
				var count = 0;
				var transactions = data.Transactions;

				if (transactions) {
					//let transactions2 = {};
					for (var transaction in transactions) {
						if (_ember['default'].$.inArray(transactions[transaction].TransactionType, _this.get("types")) === -1) {
							_this.get("types").push(transactions[transaction].TransactionType);
						}
					}
					for (var k in transactions) {
						if (transactions.hasOwnProperty(k)) {
							++count;
						}
					}
				}

				_this.set("transactions", transactions);
				_this.filterTransactions(_this.get("keyword"), _this.get("type"), _this.get("status"), _this.get("transactions"), 1);
			}, function (errors) {
				_this.set("transactions", {});
			});
			var options2 = {};
			options2.StartDateTime = startDate;
			var date2 = new Date(EndDateParts[0], EndDateParts[1] - 1, EndDateParts[2]);
			date2.setDate(date2.getDate() + 7);
			date2.setHours(23, 59, 59);
			var year2 = date2.getUTCFullYear();
			var month2 = date2.getUTCMonth() + 1;
			var day2 = date2.getUTCDate();
			var hour2 = date.getUTCHours();
			var minutes2 = date.getUTCMinutes();
			var seconds2 = date.getUTCSeconds();
			var endDate2 = year2 + "-" + this.pad(month2, 2, "0") + "-" + this.pad(day2, 2, "0") + " " + this.pad(hour2, 2, "0") + ":" + this.pad(minutes2, 2, "0") + ":" + this.pad(seconds2, 2, "0");

			options2.EndDateTime = endDate2;
			options2.Currency = currency;
			this.get("api").getFailedTransactions(options2).then(function (data) {
				var transactions = data.FailedTransactions;
				_this.set("errors", transactions);
			});
		},
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
					var next = i + 1;
					filteredList[next] = transactions[i];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(searchTerms, type, status, transactions, page, startDate, endDate, currency) {
			var filteredList = {};
			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.replace(/\$/g, '');
			searchTerms = searchTerms.replace(/\,/g, '');

			var startDateUtc = new Date(window.moment.utc(startDate).toDate());
			var endDateUtc = new Date(window.moment.utc(endDate).toDate());

			endDateUtc.setDate(endDateUtc.getDate() + 1);
			if (transactions) {
				var keys = Object.keys(transactions);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[i] !== 'undefined') {

						var transactiontime = new Date(window.moment.utc(transactions[i].RequestDate).toDate());
						if (transactiontime <= endDateUtc && transactiontime >= startDateUtc || transactions[i].RequestDate == "") {

							if (currency == transactions[i].Currency || currency === "CAD" && transactions[i].Currency === "") {

								if ((type === null || type === "" || type === "All" || type === transactions[i].TransactionType || type.toLowerCase() === transactions[i].PaylinkRequestType.toLowerCase()) && transactions[i].Status != null && transactions[i].Status != "" && (status === null || status === "" || status === "all" || status === transactions[i].Status)) {

									if (transactions[i].TransactionID != null && transactions[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms) || transactions[i].ScheduledPaymentsID != null && transactions[i].ScheduledPaymentsID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
										next = next + 1;
										filteredList[next] = transactions[i];
									} else if ((transactions[i].FirstName + " " + transactions[i].LastName).toLowerCase().includes(searchTerms)) {
										next = next + 1;
										filteredList[next] = transactions[i];
									} else if (transactions[i].Amount !== null && transactions[i].Amount.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
										next = next + 1;
										filteredList[next] = transactions[i];
									} else if (searchTerms === null || searchTerms === "") {
										next = next + 1;
										filteredList[next] = transactions[i];
									}
								}
							}
						}
					}
				}

				_ember['default'].$('#searchTransactions').addClass('searchHide');
				this.set("filteredList", filteredList);
				this.getTransactions(page, this.get("filteredList"));
			}
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		cancelTransaction: function cancelTransaction(id) {
			var _this2 = this;

			var options = {};
			options.TransactionID = id;
			this.get("api").cancelAccountTransactions(options).then(function (data) {
				var start = _this2.get("startDate");
				var end = _this2.get("endDate");
				_this2.refreshTransactions(start, end, _this2.get("currency"));
			}, function (err) {
				_this2.errorMessage(err);
			});
		},
		actions: {
			MoveToTransactionDetails: function MoveToTransactionDetails(id, type) {
				window.location = "/#/dashboard/transaction/" + type + "/" + id + "/";
			},
			handleSelection: function handleSelection(name, id) {
				if (name === "Cancel") {
					this.cancelTransaction(id);
				}
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			getPage: function getPage(pageNumber) {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), pageNumber, this.get("startDate"), this.get("endDate"), this.get("currency"));
			},
			moveToTransfer: function moveToTransfer() {
				this.get("routing").transitionTo("transfer");
			},
			moveToNewRecipients: function moveToNewRecipients() {
				this.get("routing").transitionTo("dashboard.recipients.new");
			},
			moveToNewBankAccount: function moveToNewBankAccount() {
				this.get("routing").transitionTo("dashboard.account.payment.new");
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
			},
			filterType: function filterType(type) {
				this.set("type", type);
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
			},
			filterStatus: function filterStatus(status) {
				this.set("status", status.toLowerCase());
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
			},
			filterCurrency: function filterCurrency(currency) {
				this.set("currency", currency);
				var start = this.get("startDate");
				var end = this.get("endDate");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
			},
			pdf: function pdf() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			csv: function csv() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			xls: function xls() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'xlsx', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			doc: function doc() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			text: function text() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			json: function json() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			xml: function xml() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
				return false;
			},
			printPage: function printPage() {

				var pageNumberCurrent = this.get("pageNumber");
				this.set("pageSize", this.get("recordCount"));
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), 1, this.get("startDate"), this.get("endDate"), this.get("currency"));
				var self = this;

				setTimeout(function () {
					window.print();
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), pageNumberCurrent, self.get("startDate"), self.get("endDate"), self.get("currency"));
				}, 200);
			}

		}

	});
});