define("vopay_app/templates/components/currency-input", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 5
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "currencyInput_currency currencyInput_currency--active");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "currency", ["loc", [null, [26, 74], [26, 88]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 4
                },
                "end": {
                  "line": 29,
                  "column": 4
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "currencyInput_currency currencyInput_currency--active");
              var el2 = dom.createTextNode("CAD");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 8
                },
                "end": {
                  "line": 33,
                  "column": 8
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "class", "currencyInput__option");
              dom.setAttribute(el1, "value", "canada");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createTextNode("Canada (CAD)");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 10
                  },
                  "end": {
                    "line": 38,
                    "column": 10
                  }
                },
                "moduleName": "vopay_app/templates/components/currency-input.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "class", "currencyInput__option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" (");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(")");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element11, 'value');
                morphs[1] = dom.createMorphAt(element11, 0, 0);
                morphs[2] = dom.createMorphAt(element11, 2, 2);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "currency.iso3", ["loc", [null, [35, 63], [35, 76]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "capitalize-first-letter", [["get", "currency.name", ["loc", [null, [37, 24], [37, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 81], [37, 39]]], 0, 0], ["content", "currency.iso3", ["loc", [null, [37, 41], [37, 58]]], 0, 0, 0, 0]],
              locals: ["key", "currency"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 8
                },
                "end": {
                  "line": 39,
                  "column": 8
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each-in", [["get", "currencies", ["loc", [null, [34, 21], [34, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 10], [38, 22]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 4
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "currencyInput_select currencyInput_select--white");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("select");
            dom.setAttribute(el2, "class", "currencyInput_content currencyInput_content--white currencyInput__selectArrow");
            dom.setAttribute(el2, "type", "button");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element12, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createMorphAt(element12, 3, 3);
            morphs[2] = dom.createAttrMorph(element14, 'onchange');
            morphs[3] = dom.createMorphAt(element14, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["currencyInput__selectFlag ", ["subexpr", "lower-case-no-space", [["get", "currency", ["loc", [null, [24, 66], [24, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 43], [24, 77]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "currency", ["loc", [null, [25, 11], [25, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [25, 5], [29, 11]]]], ["attribute", "onchange", ["subexpr", "action", ["selectChanged"], ["value", "target.value"], ["loc", [null, [null, null], [30, 70]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "lock", ["loc", [null, [31, 14], [31, 18]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [31, 8], [39, 15]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 7
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "currencyInput_currency");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "currency", ["loc", [null, [46, 45], [46, 59]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 6
                },
                "end": {
                  "line": 49,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "currencyInput_currency");
              var el2 = dom.createTextNode("CAD");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 10
                },
                "end": {
                  "line": 53,
                  "column": 12
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("option");
              dom.setAttribute(el1, "class", "currencyInput__option");
              dom.setAttribute(el1, "value", "canada");
              dom.setAttribute(el1, "selected", "selected");
              var el2 = dom.createTextNode("Canada (CAD)");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 14
                  },
                  "end": {
                    "line": 58,
                    "column": 14
                  }
                },
                "moduleName": "vopay_app/templates/components/currency-input.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                dom.setAttribute(el1, "class", "currencyInput__option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" (");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(")");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element7, 'value');
                morphs[1] = dom.createMorphAt(element7, 0, 0);
                morphs[2] = dom.createMorphAt(element7, 2, 2);
                return morphs;
              },
              statements: [["attribute", "value", ["concat", [["get", "currency.iso3", ["loc", [null, [55, 67], [55, 80]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "capitalize-first-letter", [["get", "currency.name", ["loc", [null, [57, 24], [57, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 85], [57, 39]]], 0, 0], ["content", "currency.iso3", ["loc", [null, [57, 41], [57, 58]]], 0, 0, 0, 0]],
              locals: ["key", "currency"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 12
                },
                "end": {
                  "line": 59,
                  "column": 12
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each-in", [["get", "currencies", ["loc", [null, [54, 25], [54, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 14], [58, 26]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 4
              },
              "end": {
                "line": 62,
                "column": 4
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "currencyInput_select");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("         ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("select");
            dom.setAttribute(el2, "disabled", "true");
            dom.setAttribute(el2, "class", "currencyInput_content");
            dom.setAttribute(el2, "type", "button");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            morphs[1] = dom.createMorphAt(element8, 3, 3);
            morphs[2] = dom.createAttrMorph(element10, 'onchange');
            morphs[3] = dom.createMorphAt(element10, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["currencyInput__selectFlag ", ["subexpr", "lower-case-no-space", [["get", "currency", ["loc", [null, [44, 68], [44, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 45], [44, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "currency", ["loc", [null, [45, 13], [45, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [45, 7], [49, 13]]]], ["attribute", "onchange", ["subexpr", "action", ["selectChanged"], ["value", "target.value"], ["loc", [null, [null, null], [50, 89]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "lock", ["loc", [null, [51, 16], [51, 20]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [51, 10], [59, 19]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 6
                },
                "end": {
                  "line": 68,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "currency", ["loc", [null, [67, 8], [67, 22]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 6
                },
                "end": {
                  "line": 70,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        CAD\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 3
              },
              "end": {
                "line": 73,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "dropdown-toggle currencyInput__picker currencyInput__picker--white");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-toggle", "dropdown");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "form__dropdownArrow icon-chevron-down");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var element6 = dom.childAt(element5, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createAttrMorph(element6, 'class');
            morphs[2] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["activateMenu"], [], ["loc", [null, [64, 101], [64, 126]]], 0, 0], ["attribute", "class", ["concat", ["currencyInput__pickerFlag ", ["subexpr", "lower-case-no-space", [["get", "currency", ["loc", [null, [65, 68], [65, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 45], [65, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "currency", ["loc", [null, [66, 12], [66, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [66, 6], [70, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 76,
                  "column": 6
                },
                "end": {
                  "line": 78,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "currency", ["loc", [null, [77, 8], [77, 22]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 6
                },
                "end": {
                  "line": 80,
                  "column": 6
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        CAD\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 3
              },
              "end": {
                "line": 83,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "dropdown-toggle currencyInput__picker");
            dom.setAttribute(el1, "disabled", "true");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-toggle", "dropdown");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createAttrMorph(element4, 'class');
            morphs[2] = dom.createMorphAt(element3, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["activateMenu"], [], ["loc", [null, [74, 88], [74, 113]]], 0, 0], ["attribute", "class", ["concat", ["currencyInput__pickerFlag ", ["subexpr", "lower-case-no-space", [["get", "currency", ["loc", [null, [75, 68], [75, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 45], [75, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "currency", ["loc", [null, [76, 12], [76, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [76, 6], [80, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 6
              },
              "end": {
                "line": 87,
                "column": 6
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "currencyInput__optionFlag cad");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Canada ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "currencyInput__optionCurrency");
            var el4 = dom.createTextNode("CAD");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["element", "action", ["currencyChanged", "canada"], [], ["loc", [null, [86, 24], [86, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 8
                },
                "end": {
                  "line": 95,
                  "column": 8
                }
              },
              "moduleName": "vopay_app/templates/components/currency-input.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "currencyInput__optionCurrency");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var element1 = dom.childAt(element0, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createMorphAt(element0, 2, 2);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [4]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["currencyChanged", ["get", "currency.iso3", ["loc", [null, [90, 52], [90, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [90, 24], [90, 68]]], 0, 0], ["attribute", "class", ["concat", ["currencyInput__optionFlag ", ["subexpr", "lower-case-no-space", [["get", "currency.iso3", ["loc", [null, [91, 75], [91, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [91, 53], [91, 90]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currency.name", ["loc", [null, [91, 100], [91, 117]]], 0, 0, 0, 0], ["content", "currency.iso3", ["loc", [null, [92, 58], [92, 75]]], 0, 0, 0, 0]],
            locals: ["key", "currency"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 6
              },
              "end": {
                "line": 96,
                "column": 6
              }
            },
            "moduleName": "vopay_app/templates/components/currency-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each-in", [["get", "currencies", ["loc", [null, [88, 19], [88, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [88, 8], [95, 20]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 2
            },
            "end": {
              "line": 98,
              "column": 2
            }
          },
          "moduleName": "vopay_app/templates/components/currency-input.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "dropdown-menu currencyInput__dropdown");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("   ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "not", [["get", "lock", ["loc", [null, [22, 15], [22, 19]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 10], [22, 20]]], 0, 0]], [], 0, 1, ["loc", [null, [22, 4], [62, 11]]]], ["block", "if", [["subexpr", "not", [["get", "lock", ["loc", [null, [63, 14], [63, 18]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 9], [63, 19]]], 0, 0]], [], 2, 3, ["loc", [null, [63, 3], [83, 10]]]], ["block", "if", [["get", "lock", ["loc", [null, [85, 12], [85, 16]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [85, 6], [96, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 100,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/currency-input.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "currencyInput");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element15, 1, 1);
        morphs[1] = dom.createMorphAt(element15, 3, 3);
        return morphs;
      },
      statements: [["inline", "input", [], ["className", "currencyInputField", "key-up", "senderAmountChanged", "class", "currencyInputField", "value", ["subexpr", "@mut", [["get", "amount", ["loc", [null, [7, 10], [7, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "disabled", ["subexpr", "@mut", [["get", "disable", ["loc", [null, [9, 13], [9, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "data-parsley-type-message", " ", "data-parsley-required-message", "Please enter an amount here", "data-parsley-max-message", ["subexpr", "@mut", [["get", "maxMessage", ["loc", [null, [12, 29], [12, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "data-parsley-trigger", "input focusout keyup", "data-parsley-trigger-after-failure", "input", "data-parsley-validation-threshold", "0", "data-parsley-errors-container", "#errorbox-converter", "required", "required", "autocomplete", "off"], ["loc", [null, [3, 2], [19, 4]]], 0, 0], ["block", "if", [["subexpr", "not", [["get", "justInput", ["loc", [null, [21, 13], [21, 22]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 8], [21, 23]]], 0, 0]], [], 0, null, ["loc", [null, [21, 2], [98, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});