define('vopay_app/components/recipient-list', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		options: ['Send Now'],
		init: function init() {
			this._super.apply(this, arguments);
			var count = 0;
			var recipients = this.get("recipients");
			var transactions = this.get("transactions");

			if (recipients) {
				var recipients2 = {};
				for (var k in recipients) {
					if (recipients.hasOwnProperty(k)) {
						++count;
					}
				}
				for (var j in recipients) {

					if (recipients.hasOwnProperty(j)) {
						var next = count - 1;
						recipients2[next] = recipients[j];
						--count;
					}
					for (var trans in transactions) {
						if (transactions[trans].RemittanceRecipientID === recipients[j].RemittanceRecipientID) {
							if (transactions[trans].PaymentStatus === "successful" && transactions[trans].RemittanceStatus === "successful") {

								recipients2[count].isInprogress = false;
							} else {
								recipients2[count].isInprogress = true;
							}
						}
					}
				}
				this.set("recipients", recipients2);
			}
		},
		deleteRecipient: function deleteRecipient(id) {
			var recipients2 = {};
			var recipients = this.get("recipients");
			var count = 0;
			for (var j in recipients) {
				if (recipients[j].RemittanceRecipientID === id) {} else {
					recipients2[count] = recipients[j];
					count++;
				}
			}
			this.set("recipients", recipients2);
			this.get('api').deleteRecipient(id).then(function () {});
		},
		sendMoney: function sendMoney(id) {
			var recipients = this.get("recipients");
			var recipient = {};

			for (var j in recipients) {
				if (recipients[j].RemittanceRecipientID === id) {

					recipient = recipients[j];
					break;
				}
			}
			if (recipient.Country === "Republic of Ireland") {
				_ember['default'].set(recipient, "Country", "Ireland");
			}
			localStorage.setItem("recipientCountry", recipient.Country);
			localStorage.setItem("recipientCurrency", recipient.Currency);
			localStorage.setItem("recipientID", recipient.RemittanceRecipientID);
			if (recipient.Nickname) {
				localStorage.setItem("recipientName", recipient.Nickname);
			} else {
				if (recipient.LastName) {
					localStorage.setItem("recipientName", recipient.FirstName + " " + recipient.LastName);
				} else {
					localStorage.setItem("recipientName", recipient.FirstName);
				}
			}
			localStorage.setItem("recipientDeliveryMethod", recipient.DeliveryMethod);
			this.get("routing").transitionTo("transfer");
		},
		actions: {
			sendNow: function sendNow(id) {
				this.sendMoney(id);
			},
			moveToNewRecipients: function moveToNewRecipients() {
				this.get("routing").transitionTo("dashboard.recipients.new");
			},
			handleSelection: function handleSelection(selection, id) {
				if (selection === "Send Now") {
					this.sendMoney(id);
				}
				if (selection === "Delete Recipient") {
					this.deleteRecipient(id);
				}
			}
		}
	});
});