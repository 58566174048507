define("vopay_app/helpers/currency-date", ["exports", "ember"], function (exports, _ember) {
  exports.currencyDate = currencyDate;

  function currencyDate(params /*, hash*/) {
    if (params[0]) {
      var weekday = new Array(7);

      weekday[0] = "Sun";
      weekday[1] = "Mon";
      weekday[2] = "Tue";
      weekday[3] = "Wed";
      weekday[4] = "Thu";
      weekday[5] = "Fri";
      weekday[6] = "Sat";
      var month = new Array(12);
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sep";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";

      var arr = params[0].split(/[- :,]/);
      var date = new Date(arr[0], arr[1] - 1, arr[2], arr[4], arr[5], 0);

      var year = date.getFullYear();
      var month2 = date.getMonth();
      var day = date.getDate();

      var newDate = weekday[date.getDay()] + ", " + month[month2] + " " + day + " " + year;

      return newDate;
    } else {

      return params[0];
    }
  }

  exports["default"] = _ember["default"].Helper.helper(currencyDate);
});