define('vopay_app/components/deposit-widget', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		sender: {
			amount: "11,000.00",
			currency: "CAD",
			country: "Canada"
		},
		sameamount: true,
		lastAmount: 100,
		maxAmout: 9999999999,
		minAmout: 1,
		isDeposit: true,
		init: function init() {
			this._super.apply(this, arguments);
			// get Countries from the API
		},
		message: "You don't have permission to access this service",
		isShowingModal: false,
		actions: {
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			openModal: function openModal() {
				this.set("isShowingModal", true);
				return false;
			},
			selectPaymentChanged: function selectPaymentChanged(payment, type, accountName) {

				if (payment === "new") {
					this.get("routing").transitionTo("dashboard.add-account");
					if (this.get("isDeposit") === true) {
						sessionStorage.setItem("isDeposit", this.get("isDeposit"));
					}
				}
				this.set("accountName", "X-" + accountName);
			},
			moveToAddBankAccount: function moveToAddBankAccount() {
				sessionStorage.setItem("isDeposit", this.get("isDeposit"));
				sessionStorage.setItem("Amount", this.get("sender.amount"));
				this.get("routing").transitionTo("dashboard.add-account");
			},
			completeTransaction: function completeTransaction() {

				_ember['default'].$("#overviewTransactionForm").hide();
				_ember['default'].$("#transactionConfirmation").show();
			},
			moveToWidget: function moveToWidget() {
				_ember['default'].$("#transactionConfirmation").hide();
				_ember['default'].$("#overviewTransactionForm").show();
			},
			moveToTransactionResponse: function moveToTransactionResponse() {
				_ember['default'].$("#transactionConfirmation").hide();
				_ember['default'].$("#transactionResponse").show();
			},
			redisplayWidget: function redisplayWidget() {
				_ember['default'].$("#transactionResponse").hide();
				_ember['default'].$("#overviewTransactionForm").show();
			},
			changeWidthrawl: function changeWidthrawl() {
				this.set("isDeposit", false);
				return false;
			},
			changeDeposit: function changeDeposit() {
				this.set("isDeposit", true);
				return false;
			},
			senderAmountChanged: function senderAmountChanged() {}
		}

	});
});