define('vopay_app/routes/dashboard/beneficiaries/details', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			this.get("userPermissions").HasPermissionRoute("ViewBeneficiaries");
			if (id["token"]) {
				var options = {
					Token: id["token"]
				};
				if (id["type"] == 'CreditCard') {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getCreditCards(false, id["token"]),
						key: id["user"],
						Type: id["type"]
					});
				} else {
					return _ember['default'].RSVP.hash({
						currencies: this.get('api').getGCMCurriencies(),
						beneficiary: this.get("api").getBankAccounts(false, id["token"]),
						bankTransactions: this.get("api").getIQ11Transactions({ Token: id["token"] }),
						bankBalance: this.get("api").getIQ11Balance({ Token: id["token"] }),
						key: id["user"],
						Type: id["type"]
					});
				}
			} else {
				return _ember['default'].RSVP.hash({
					currencies: this.get('api').getGCMCurriencies(),
					beneficiary: this.get("firebase").getSingleRecordUser("beneficiary", id["user"]),
					key: id["user"],
					Type: "etransfer"
				});
			}
		}
	});
});