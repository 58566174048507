define('vopay_app/helpers/failure-exists', ['exports', 'ember'], function (exports, _ember) {
	exports.failureExists = failureExists;

	function failureExists(params /*, hash*/) {
		var errors = params[0];
		var TransactionID = params[1];
		var ParentTransactionID = params[2];
		for (var key in errors) {
			if (errors.hasOwnProperty(key)) {
				if (errors[key].TransactionID === TransactionID || errors[key].TransactionID === ParentTransactionID) {
					return true;
				}
			}
		}
		return false;
	}

	exports['default'] = _ember['default'].Helper.helper(failureExists);
});