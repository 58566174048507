define("vopay_app/routes/password-reset", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Route.extend({

        model: function model(RouteParamters) {
            return _ember["default"].RSVP.hash({
                PasswordToken: RouteParamters["PasswordToken"]

            });
        }
    });
});