define('vopay_app/components/accounting-statements-list-item', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
    exports['default'] = _ember['default'].Component.extend({
        isActive: false,
        tagName: "",
        entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
        watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

            this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
        }),
        watchAccountNumber: _ember['default'].observer('accountNumber', function () {

            this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
        }),
        watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

            this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
        }),
        init: function init() {
            this._super.apply(this, arguments);
            var statement = this.get("statement");
            if (statement.Customer != null && statement.Customer.BankAccount != null) {
                this.set("financialInstitutionNumber", statement.Customer.BankAccount.FinancialInstitutionNumber);
                this.set("branchTransitNumber", statement.Customer.BankAccount.BranchTransitNumber);
                this.set("accountNumber", statement.Customer.BankAccount.AccountNumber);
            }
        },
        actions: {
            ActivateStatment: function ActivateStatment() {
                this.set("isActive", !this.get("isActive"));
            }
        }
    });
});