define('vopay_app/components/mailing-address-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),

		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updateMailingAddress: function updateMailingAddress() {
				this.get("routing").transitionTo("dashboard.account.business-profile");
			}
		}
	});
});