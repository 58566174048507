define('vopay_app/helpers/first-letter', ['exports', 'ember'], function (exports, _ember) {
  exports.firstLetter = firstLetter;

  function firstLetter(params /*, hash*/) {
    var value = params[0].toUpperCase();
    var first = value.charAt(0);
    return first;
  }

  exports['default'] = _ember['default'].Helper.helper(firstLetter);
});