define('vopay_app/app', ['exports', 'ember', 'vopay_app/resolver', 'ember-load-initializers', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appResolver, _emberLoadInitializers, _vopay_appConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  _ember['default'].Logger.warn = function () {};
  _ember['default'].Logger.deprecate = function () {};

  App = _ember['default'].Application.extend({
    modulePrefix: _vopay_appConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _vopay_appConfigEnvironment['default'].podModulePrefix,
    Resolver: _vopay_appResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _vopay_appConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});