define('vopay_app/components/login-form', ['exports', 'ember', 'vopay_app/mixins/manage-cookies', 'vopay_app/config/environment', 'vopay_app/utils/cookies-manager', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appMixinsManageCookies, _vopay_appConfigEnvironment, _vopay_appUtilsCookiesManager, _vopay_appUtilsEntryFilter) {
	var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;
	var isProduction = _vopay_appConfigEnvironment['default'].isProduction;
	//const apiBase = config.apiBase;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsManageCookies['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		image: _vopay_appConfigEnvironment['default'].imageLarge,
		cookiesmanager: _vopay_appUtilsCookiesManager['default'].create(),
		userAccounts: _ember['default'].inject.service('user-accounts'),
		datadog: _ember['default'].inject.service('datadog'),
		session: _ember['default'].inject.service('session'),
		routing: _ember['default'].inject.service('-routing'),
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		loader: _ember['default'].inject.service('loader'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		email: null,
		errorMsg: null,
		fbURL: null,
		googleURL: null,
		RememberDevice: false,
		Username: "",
		validPassword: false,

		init: function init() {

			this._super.apply(this, arguments);

			// Remember me
			if (localStorage.getItem("logout") === true || localStorage.getItem("logout") === "true") {
				this.set("logoutMessage", true);
				localStorage.removeItem("logout");
			}
			if (localStorage.getItem("reset") === true || localStorage.getItem("reset") === "true") {
				this.set("resetMessage", true);
				localStorage.removeItem("reset");
			}
			if (this.getCookie('VOP-USR-EMAIL')) {

				this.set('email', this.getCookie('VOP-USR-EMAIL'));
			}
		},
		didInsertElement: function didInsertElement() {
			var _this = this;

			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$("input").keyup(function () {
				_ember['default'].$(this).addClass("form__input--active");
			});
			_ember['default'].$("input").focusout(function () {
				_ember['default'].$(this).removeClass("form__input--active");
			});
			window.Parsley.addValidator('captchaValidation', {

				validateString: function validateString(value) {
					if (value.length > 0) {
						return true;
					} else {
						return false;
					}
				},
				messages: { en: 'Please confirm you are not a robot.' }
			});
			try {
				(function () {
					grecaptcha.render("g-recaptcha");
					var self = _this;
					window.recaptchaCallback = function recaptchaCallback(token) {
						self.addCaptchaValidation();
					};
				})();
			} catch (e) {}
		},
		cleanErrors: function cleanErrors() {
			if ($('#g-recaptcha-response').parsley()) {
				$('#g-recaptcha-response').parsley().reset();
			}
		},
		addCaptchaValidation: function addCaptchaValidation() {
			this.cleanErrors();
			$('#g-recaptcha-response').attr('required', true);
			// We are going to create a new validator on Parsley
			$('#g-recaptcha-response').attr('data-parsley-captcha-validation', true);
			$('#g-recaptcha-response').attr('data-parsley-error-message', "Please confirm you are not a robot.");
			$('#g-recaptcha-response').attr('data-parsley-errors-container', "#captchaerrors");
			$('#ResetPasswordEmail').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},

		watchCode: _ember['default'].observer('Code', function () {

			this.set('Code', this.get('entryUtil').onlyNumbers(this.get('Code')));
		}),
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {

					filteredList[keys[i]] = transactions[keys[i]];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(transactions) {
			var filteredList = {};

			if (transactions) {
				var keys = Object.keys(transactions);

				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[keys[i]] !== 'undefined') {

						if (transactions[keys[i]].SubType != null && transactions[keys[i]].SubType == "PayLink") {

							filteredList[keys[i]] = transactions[keys[i]];
						}
					}
				}

				return filteredList;
			}
		},
		hasPaylink: function hasPaylink(transactions, Id) {

			if (transactions) {
				var keys = Object.keys(transactions);

				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[keys[i]] !== 'undefined') {

						if (transactions[keys[i]].PaylinkRequestID == Id) {

							return true;
						}
					}
				}
			}
			return false;
		},
		checkResetPassword: function checkResetPassword() {
			if (this.get("PasswordResetRequired")) {
				$("#TwoFADataForm").hide();
				$("#loginForm").hide();
				$("#ChangePasswordLogin").show();
			} else {
				this.setAuthentication();
			}
		},
		setAuthentication: function setAuthentication() {
			var _this2 = this;

			localStorage.setItem("key", this.get("key"));
			localStorage.setItem("accountID", this.get("accountID"));
			localStorage.setItem("AccountName", this.get("AccountName"));
			localStorage.setItem("JWT", this.get("JWT"));
			this.get("datadog").setUser(localStorage.getItem("Username"), this.get("key"), localStorage.getItem("signature"), this.get("accountID"), this.get("AccountName"), this.get("JWT"));
			this.get("api").getAccountPermissions().then(function (AccountPermission) {
				_this2.get("userPermissions").SetPermissions(AccountPermission.Permissions);
				var browserInfo = bowser.getParser(window.navigator.userAgent).parsedResult;
				var RememberMeData = {
					BrowserName: browserInfo.browser.name,
					BrowserVersion: browserInfo.browser.version,
					BrowserPlatform: browserInfo.platform.type,
					Username: _this2.get("Username")
				};
				if (_this2.get('session.isAuthenticated')) {
					(function () {
						var RememberDevice = _this2.get("RememberDevice");
						if (RememberDevice) {
							_this2.get("firebase").createRecordUser("RememberedDevices", RememberMeData);
						}
						var userAgent = navigator.userAgent;

						_this2.get("api").fetchGeolocation().then(function (ipLocationData) {
							var loginInfo = _extends({}, browserInfo, ipLocationData, {
								Username: _this2.get("Username"),
								RefferingUrl: document.referrer,
								RawUserAgent: userAgent

							});
							_this2.get("firebase").createRecordUser("LoginHistory", loginInfo);
							var accounts = _this2.get("userAccounts").GetAccounts();
							_this2.get("cookiesmanager").setCookie("dashboardToken", accounts[_this2.get("AccountName")]);
							_this2.get("cookiesmanager").setCookie("dashboardUsername", _this2.get("Username"));
							localStorage.removeItem("JWT");
							localStorage.removeItem("key");
							localStorage.removeItem("accountID");
							localStorage.removeItem("signature");
							window.location.href = _vopay_appConfigEnvironment['default'].switchPortalURL;
							//this.get("routing").transitionTo("dashboard.info");
						}, function (error) {
							var loginInfo = _extends({}, browserInfo, {
								Username: _this2.get("Username"),
								RefferingUrl: document.referrer,
								RawUserAgent: userAgent
							});
							_this2.get("firebase").createRecordUser("LoginHistory", loginInfo);
							var accounts = _this2.get("userAccounts").GetAccounts();
							_this2.get("cookiesmanager").setCookie("dashboardToken", accounts[_this2.get("AccountName")]);
							_this2.get("cookiesmanager").setCookie("dashboardUsername", _this2.get("Username"));
							localStorage.removeItem("JWT");
							localStorage.removeItem("key");
							localStorage.removeItem("accountID");
							localStorage.removeItem("signature");
							window.location.href = _vopay_appConfigEnvironment['default'].switchPortalURL;
							//this.get("routing").transitionTo("dashboard.info");
						});
					})();
				} else {}
			}, function (error) {});
		},
		actions: {
			/***
     * login
     *
     * Authenticates the user
     */
			closeModalLogout: function closeModalLogout() {
				this.set("logoutMessage", false);
			},
			closeModaReset: function closeModaReset() {
				this.set("resetMessage", false);
			},
			login: function login() {
				var _this3 = this;

				var self = this;
				//serialize the form and get the values
				var loginData = _ember['default'].$('#loginForm').serializeArray();
				var arrLength = loginData.length;
				var options = {};

				//validate here, if all good proceed
				var form = _ember['default'].$('#loginForm');
				form.parsley().validate();

				//check if its valid
				if (form.parsley().isValid()) {
					//set all the form data
					for (var i = 0; i < arrLength; i++) {
						options[loginData[i].name] = loginData[i].value;
					}

					// Remember me
					if (options['StoreLogin']) {

						var expDate = new Date(new Date().setFullYear(9999)).toUTCString();
						this.setCookie('VOP-USR-EMAIL', options['Username'] + '; expires=' + expDate);
					} else {

						this.removeCookie('VOP-USR-EMAIL');
					}
					localStorage.setItem("Username", options['Username']);

					// sign up the user
					this.get('session').authenticate('authenticator:custom', options).then(function (authData) {
						//if we're logged in, send the user to the transfer

						if (self.get('session.isAuthenticated')) {
							var d = new Date();
							sessionStorage.setItem("SessionTime", d.getTime());
							clearInterval(window.sessionTimeout);
							window.sessionTimeout = setInterval(function () {
								var d2 = new Date();
								if (3600000 < d2.getTime() - sessionStorage.getItem("SessionTime")) {
									clearInterval(window.sessionTimeout);
									localStorage.removeItem("JWT");
									localStorage.removeItem("key");
									localStorage.removeItem("accountID");
									localStorage.removeItem("signature");
									localStorage.removeItem("subAccount");
									localStorage.removeItem("currentUrlSubAccount");
									this.get("datadog").clearUser();
									self.get("routing").transitionTo("login");
								}
							}, 1000);
							authData = self.get('session.data.authenticated');
							self.set("JWT", authData.JWT);
							self.set("key", authData.Key);
							self.set("accountID", authData.AccountID);
							self.set("AccountName", authData.AccountName);
							self.set("PasswordResetRequired", authData.PasswordResetRequired);
							self.set("Username", options.Username);
							if (isProduction && !localStorage.getItem("remember")) {
								if (authData.MFAEnabled) {
									$("#loginForm").hide();
									$("#TwoFADataForm").show();
								} else {
									$("#loginForm").hide();
									$("#MFAInstallation").show();
								}
							} else {
								_this3.checkResetPassword();
							}
						}
					})['catch'](function (result) {
						self.set('errorMsg', result);
					});
				}

				return false;
			},
			EnterPinCode: function EnterPinCode() {
				var _this4 = this;

				var TwoFAData = _ember['default'].$('#TwoFADataForm').serializeArray();
				var arrLength = TwoFAData.length;
				var options = {};

				var form = _ember['default'].$('#TwoFADataForm');
				form.parsley().validate();

				if (form.parsley().isValid()) {
					//set all the form data
					for (var i = 0; i < arrLength; i++) {
						options[TwoFAData[i].name] = TwoFAData[i].value;
					}
					options.Username = this.get("Username");
					this.get("api").portalAuthenticate(options).then(function (data) {
						if (data.Authorized == true) {
							var RememberDevice = _this4.get("RememberDevice");
							if (RememberDevice) {
								localStorage.setItem("remember", true);
							}
							_this4.checkResetPassword();
						} else {
							_this4.set('errorMsgPin', "Incorrect Code");
						}
					}, function (error) {
						_this4.set('errorMsgPin', error);
					});
				}
			},
			CancelMFA: function CancelMFA() {
				$("#loginForm").show();
				$("#TwoFADataForm").hide();
				this.set("Code", "");
				this.set("password", "");
			},
			EnableMFA: function EnableMFA() {
				var _this5 = this;

				this.get("api").portalGenerateQRCode({ Username: this.get("Username") }).then(function (QRCode) {
					_this5.set("QRCode", QRCode.QRCode);
					$("#MFAInstallation").hide();
					$("#QRCode").show();
				}, function (error) {});
			},
			EnterCodePage: function EnterCodePage() {
				$("#QRCode").hide();
				$("#TwoFADataForm").show();
			},
			skip: function skip() {
				this.checkResetPassword();
			},
			isValidHandler: function isValidHandler(isValid) {
				this.set("validPassword", isValid);
			},
			ShowResetPassword: function ShowResetPassword() {
				$("#loginForm").hide();
				$("#ResetPasswordEmail").show();
			},
			CancelResetPassword: function CancelResetPassword() {
				$("#loginForm").show();
				$("#ResetPasswordEmail").hide();
				$("#PasswordResetEmailSent").hide();
				this.set("errorMsgResetPassword", null);
				this.set("ResetUsername", "");
				grecaptcha.reset();
			},
			ResetPassword: function ResetPassword() {
				var _this6 = this;

				var ResetPassword = _ember['default'].$('#ResetPasswordEmail').serializeArray();
				var arrLength = ResetPassword.length;
				var options = {};
				this.addCaptchaValidation();
				var form = _ember['default'].$('#ResetPasswordEmail');
				form.parsley().validate();
				if (form.parsley().isValid()) {
					for (var i = 0; i < arrLength; i++) {
						options[ResetPassword[i].name] = ResetPassword[i].value;
					}
					this.get("loader").UpdateLoading(true);
					this.get("api").verifyRecaptcha(options).then(function (data) {
						_this6.get("api").resetPasswordEmail(options).then(function (data2) {
							$("#PasswordResetEmailSent").show();
							$("#ResetPasswordEmail").hide();
							_this6.set("errorMsgResetPassword", null);
							_this6.set("ResetUsername", "");
							grecaptcha.reset();
							_this6.get("loader").UpdateLoading(false);
						}, function (error2) {
							_this6.set('errorMsgResetPassword', 'Sorry we were unable to reset your password please contact <a href="mailto:helpdesk@vopay.com">helpdesk@vopay.com</a>');
							_this6.get("loader").UpdateLoading(false);
						});
					}, function (error) {
						_this6.get("loader").UpdateLoading(false);
					});
				}
			},
			ChangePassword: function ChangePassword() {
				var _this7 = this;

				var ChangePassword = _ember['default'].$('#ChangePasswordLogin').serializeArray();
				var arrLength = ChangePassword.length;
				var options = {};
				var form = _ember['default'].$('#ChangePasswordLogin');
				form.parsley().validate();
				if (form.parsley().isValid() && this.get("validPassword")) {
					var i;

					(function () {
						for (i = 0; i < arrLength; i++) {
							options[ChangePassword[i].name] = ChangePassword[i].value;
						}
						options.Username = _this7.get("Username");
						options.OldPassword = _this7.get("password");
						var self = _this7;
						_this7.get('api').portalChangePassword(options).then(function (data) {
							var options2 = {};
							options2.Username = localStorage.getItem("Username");
							options2.Password = options.NewPassword;
							_this7.get('session').authenticate('authenticator:custom', options2).then(function () {
								//if we're logged in, send the user to the transfer
								var authData = self.get('session.data.authenticated');
								self.set("key", authData.Key);
								self.set("accountID", authData.AccountID);
								self.set("AccountName", authData.AccountName);
								self.set("JWT", authData.JWT);
								self.set("PasswordResetRequired", authData.PasswordResetRequired);
								self.set("Username", options.Username);
								self.setAuthentication();
							});
						}, function (error) {
							_this7.set('errorMsgChangePassword', error);
						});
					})();
				}
			}

		}
	});
});