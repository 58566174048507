define("vopay_app/components/create-account", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		sectors: ["Agriculture and agri-food research and statistics", "Construction Culture and Leisure", "Energy", "Information and Communications Technology", "Manufacturing Natural Resources", "Retail and Wholesale", "Science and Technology", "Transportaion", "Travel and Tourism"],
		type: null,
		init: function init() {
			this._super.apply(this, arguments);
		},
		/*Validate form returning true | false (valid or not)*/
		validateForm: function validateForm(formId) {

			var form = _ember["default"].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		actions: {
			selectUpload: function selectUpload() {
				_ember["default"].$('#step3 .selected').removeClass('selected');
				_ember["default"].$(event.target).addClass('selected');
			},
			nextStep: function nextStep() {
				var id = _ember["default"].$(event.target).closest("section").prop("id");
				_ember["default"].$("#" + id).addClass('complete');
				_ember["default"].$('.open').removeClass('open');
				_ember["default"].$("#" + id).next("section").addClass('open');
			},
			editStep: function editStep() {
				var id = _ember["default"].$(event.target).closest("section").prop("id");
				_ember["default"].$('.open').removeClass('open');
				_ember["default"].$("#" + id).removeClass('complete');
				_ember["default"].$("#" + id).addClass('open');
			}
		}
	});
});