define("vopay_app/helpers/error-message-fix", ["exports", "ember"], function (exports, _ember) {
  exports.errorMessageFix = errorMessageFix;

  function errorMessageFix(params /*, hash*/) {
    if (params[0].substr(0, 9) == "Flinks - ") {
      return "Online Banking - " + params[0].slice(9);
    } else {
      return params[0];
    }
  }

  exports["default"] = _ember["default"].Helper.helper(errorMessageFix);
});