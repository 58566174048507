define('vopay_app/components/currency-country-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selectedCurrency: null,
    menuActive: false,
    tagName: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(".currencyInput_content option[value=\"" + this.get("country") + "\"]").prop('selected', true);
    },
    toggleMenu: function toggleMenu() {

      if (!this.get('menuActive')) {
        this.set('menuActive', true);
      } else {
        this.set('menuActive', false);
      }
    },

    actions: {
      /***
      * currencyChanged
      * @param country (String) - Country to set
      * @param currency (String) - Currency to set
      *
      * Sets the country and currency, sends to top level component
      */
      currencyChanged: function currencyChanged(country, currency) {
        this.sendAction('currencyChanged', country, currency);
        _ember['default'].$(".currencyInput_content option[value=\"" + country + "\"]").prop('selected', true);
        this.toggleMenu();
      },

      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },

      /***
      * showPopup
      *
      * Triggers event to trigger popup
      */
      showPopup: function showPopup() {
        _ember['default'].$.event.trigger({
          type: "triggerPopup",
          popup: "suggestion"
        });

        this.toggleMenu();
      },

      /***
        Activate proper action on element selection
      */
      selectChanged: function selectChanged(country, currency) {
        if (country === "other") {
          this.send('showPopup');
        } else {
          this.send('currencyChanged', country, currency);
        }
      },

      senderAmountChanged: function senderAmountChanged(amount) {
        this.sendAction('senderAmountChanged', amount);
      }

    }
  });
});