define("vopay_app/components/promotion-bar", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		name: null,
		isVisible: true,
		promoEndDate: "",
		init: function init() {
			this._super.apply(this, arguments);

			if (sessionStorage.getItem(this.get("name"))) {
				_ember["default"].$("#overviewBanner").hide();
				this.set("isVisible", null);
			}
			this.endDate();
		},
		endDate: function endDate() {
			var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var finish = "";
			var today = new Date();
			var endOfMonth = new Date(today.getUTCFullYear(), today.getUTCMonth() + 1, 0);
			var day = endOfMonth.getDate();
			var lastDigit = day.toString().split('').pop();
			if (lastDigit === "1") {
				finish = "st";
			} else {
				finish = "th";
			}
			var month = endOfMonth.getMonth();
			var promoEndDate = monthNames[month] + " " + day + finish;
			this.set("promoEndDate", promoEndDate);
		},
		actions: {
			closeMessage: function closeMessage() {
				_ember["default"].$("#overviewBanner").hide();
				sessionStorage.setItem(this.get("name"), true);
			}
		}
	});
});