define('vopay_app/routes/dashboard/request-accounts/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    userPermissions: _ember['default'].inject.service('user-permissions'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    loader: _ember['default'].inject.service('loader'),
    beforeModel: function beforeModel() {
      this.get("loader").UpdateLoading(true);
    },
    afterModel: function afterModel() {
      this.get("loader").UpdateLoading(false);
    },
    model: function model() {
      this.get("userPermissions").HasPermissionRoute("ViewTransactionHistory");
      var authed = true;
      if (authed) {

        return _ember['default'].RSVP.hash({
          payLinkHistory: this.get('api').getPaylinkHistory(),
          currencies: this.get('api').getGCMCurriencies()
        });
      } else {
        this.transitionTo('login');
      }
    }

  });
});