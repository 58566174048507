define("vopay_app/routes/dashboard/support/ticket-categories", ["exports"], function (exports) {});
// import Ember from 'ember';

// export default Ember.Route.extend({
//    api: Ember.inject.service('api'),
//    userPermissions: Ember.inject.service('user-permissions'),
//    model() {
//       this.get("userPermissions").HasPermissionRoute("ViewSupportTickets");
//       return Ember.RSVP.hash({
//          getTicketCategories: this.get('api').getTicketCategories()
//      });
//    }
// });