define('vopay_app/helpers/local-class', ['exports', 'ember-css-modules/helpers/local-class'], function (exports, _emberCssModulesHelpersLocalClass) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCssModulesHelpersLocalClass['default'];
    }
  });
  Object.defineProperty(exports, 'localClass', {
    enumerable: true,
    get: function get() {
      return _emberCssModulesHelpersLocalClass.localClass;
    }
  });
});