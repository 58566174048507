define("vopay_app/templates/components/transfer-summary", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 1
            },
            "end": {
              "line": 5,
              "column": 1
            }
          },
          "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "transferSummary__header transferSummary__header--inverse");
          var el2 = dom.createTextNode("Total: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "transferSummany__money--header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" CAD");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "transferSummary__dropdownArrow transferSummary__dropupArrow");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "transferSummary__headerText");
          var el3 = dom.createTextNode("Check Summary");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element13);
          morphs[1] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["activateMenu"], [], ["loc", [null, [4, 74], [4, 99]]], 0, 0], ["inline", "decimal-points", [["get", "totalToPay", ["loc", [null, [4, 171], [4, 181]]], 0, 0, 0, 0], 2], [], ["loc", [null, [4, 152], [4, 186]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 1
              },
              "end": {
                "line": 12,
                "column": 1
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("	");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "transferSummary__header");
            dom.setAttribute(el1, "disabled", "");
            var el2 = dom.createTextNode("Transfer Summary");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "transferSummary__dropdownArrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["element", "action", ["activateMenu"], [], ["loc", [null, [11, 41], [11, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 1
              },
              "end": {
                "line": 14,
                "column": 1
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("	");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "id", "transferSummaryHeader");
            dom.setAttribute(el1, "class", "transferSummary__header");
            var el2 = dom.createTextNode("Transfer Summary");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "transferSummary__dropdownArrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["element", "action", ["activateMenu"], [], ["loc", [null, [13, 68], [13, 93]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 2
              },
              "end": {
                "line": 28,
                "column": 2
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "transferSummary__item");
            var el2 = dom.createTextNode(" Discount ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "transferSummary__money");
            var el3 = dom.createTextNode("-");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["content", "discount", ["loc", [null, [27, 84], [27, 96]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 4
              },
              "end": {
                "line": 40,
                "column": 4
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					Bank Deposit\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 5
                },
                "end": {
                  "line": 43,
                  "column": 5
                }
              },
              "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						Cash Pick-Up\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 5
                },
                "end": {
                  "line": 45,
                  "column": 5
                }
              },
              "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						Choose Method\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 4
              },
              "end": {
                "line": 46,
                "column": 4
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "deliveryMethod", ["loc", [null, [41, 15], [41, 29]]], 0, 0, 0, 0], "cash"], [], ["loc", [null, [41, 11], [41, 37]]], 0, 0]], [], 0, 1, ["loc", [null, [41, 5], [45, 12]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 3
              },
              "end": {
                "line": 69,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "transferSummary__item transferSummary__item--btn");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "transferSummary__element");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "transferSummary__button ");
            var el4 = dom.createTextNode("Apply");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 0]);
            var element3 = dom.childAt(element2, [2]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createAttrMorph(element3, 'onclick');
            return morphs;
          },
          statements: [["inline", "input", [], ["required", "true", "data-parsley-required-message", "The above field is required. Please enter a valid promo cod.", "id", "promoCode", "type", "text", "class", "transferSummary__input ", "name", "promocode", "data-parsley-required-message", "That promo code is not valid.", "value", ["subexpr", "@mut", [["get", "promocode", ["loc", [null, [68, 367], [68, 376]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [68, 106], [68, 378]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["activatePromo"], [], ["loc", [null, [null, null], [68, 420]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 3
              },
              "end": {
                "line": 72,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "transferSummary__item transferSummary__item--promo");
            var el2 = dom.createTextNode("Promo Code Applied: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "transferSummary__closeIcon");
            var el3 = dom.createTextNode("    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createAttrMorph(element1, 'onclick');
            return morphs;
          },
          statements: [["content", "promocode", ["loc", [null, [71, 86], [71, 99]]], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["removePromo"], [], ["loc", [null, [null, null], [71, 173]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 1
            },
            "end": {
              "line": 77,
              "column": 1
            }
          },
          "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "transferSummary__overlay");
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "transferSummary__section transferSummary__section--grey ");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "transferSummary__item");
          var el3 = dom.createTextNode("Sending Amount");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "transferSummary__money");
          var el4 = dom.createTextNode("\n\n			");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n			CAD");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "transferSummary__item");
          var el3 = dom.createTextNode("Fee ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "transferSummary__money");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" CAD");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "transferSummary__item transferSummary__item--highlight transferSummary__item--highlight--blue transferSummary__item--title");
          var el3 = dom.createElement("strong");
          var el4 = dom.createTextNode("Total to Pay ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" CAD");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "transferSummary__item transferSummary__item--highlight");
          var el3 = dom.createElement("strong");
          var el4 = dom.createTextNode("Recipient Gets ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "transferSummary__content");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "transferSummary__section transferSummary__section--animate");
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "transferSummary__item");
          var el4 = dom.createTextNode("Sending Method ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createTextNode("\n\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n			");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "transferSummary__item");
          var el4 = dom.createTextNode("Payment Method ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createTextNode("Bank Account");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "transferSummary__item");
          var el4 = dom.createTextNode("Exchange Rate  ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createTextNode(" 1 CAD = ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "transferSummary__item");
          var el4 = dom.createTextNode("ETA ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "transferSummary__money");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("+");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("More Details");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "transferSummary__content transferSummary__promo");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "transferSummary__section");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [5]);
          var element7 = dom.childAt(element6, [9, 0, 1]);
          var element8 = dom.childAt(fragment, [7]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element9, [5, 1]);
          var element11 = dom.childAt(element8, [3]);
          var element12 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [1, 1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [3, 1]), 0, 0);
          morphs[3] = dom.createMorphAt(element6, 5, 5);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [7, 0, 1]), 1, 1);
          morphs[5] = dom.createMorphAt(element7, 0, 0);
          morphs[6] = dom.createMorphAt(element7, 2, 2);
          morphs[7] = dom.createMorphAt(dom.childAt(element9, [1, 1]), 1, 1);
          morphs[8] = dom.createMorphAt(element10, 1, 1);
          morphs[9] = dom.createMorphAt(element10, 3, 3);
          morphs[10] = dom.createMorphAt(dom.childAt(element9, [7, 1]), 0, 0);
          morphs[11] = dom.createAttrMorph(element11, 'onclick');
          morphs[12] = dom.createMorphAt(element12, 1, 1);
          morphs[13] = dom.createMorphAt(element12, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "isDisabled", ["loc", [null, [10, 7], [10, 17]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 1], [14, 8]]]], ["inline", "decimal-points", [["get", "amount", ["loc", [null, [20, 21], [20, 27]]], 0, 0, 0, 0], 2], [], ["loc", [null, [20, 3], [20, 32]]], 0, 0], ["content", "fee", ["loc", [null, [25, 77], [25, 86]]], 0, 0, 0, 0], ["block", "if", [["get", "hasPromo", ["loc", [null, [26, 8], [26, 16]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [26, 2], [28, 9]]]], ["inline", "decimal-points", [["get", "totalToPay", ["loc", [null, [29, 214], [29, 224]]], 0, 0, 0, 0], 2], [], ["loc", [null, [29, 196], [29, 229]]], 0, 0], ["content", "recipientAmount", ["loc", [null, [30, 129], [30, 148]]], 0, 0, 0, 0], ["content", "currency", ["loc", [null, [30, 149], [30, 163]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "deliveryMethod", ["loc", [null, [38, 14], [38, 28]]], 0, 0, 0, 0], "bank"], [], ["loc", [null, [38, 10], [38, 36]]], 0, 0]], [], 3, 4, ["loc", [null, [38, 4], [46, 11]]]], ["inline", "decimal-points", [["get", "exchangeRate", ["loc", [null, [50, 115], [50, 127]]], 0, 0, 0, 0], 4], [], ["loc", [null, [50, 98], [50, 131]]], 0, 0], ["content", "currency", ["loc", [null, [50, 132], [50, 144]]], 0, 0, 0, 0], ["content", "arrivalDate", ["loc", [null, [51, 78], [51, 93]]], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["changeDetails"], [], ["loc", [null, [null, null], [54, 39]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "newPromo", ["loc", [null, [67, 9], [67, 17]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [67, 3], [69, 10]]]], ["block", "if", [["get", "hasPromo", ["loc", [null, [70, 9], [70, 17]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [70, 3], [72, 10]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/transfer-summary.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "transferSummary");
        dom.setAttribute(el1, "class", "transferSummary transferSummary--noScroll");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "notMenuActive", ["loc", [null, [3, 7], [3, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 1], [77, 8]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});