define("vopay_app/helpers/is-transaction-label", ["exports", "ember"], function (exports, _ember) {
  exports.isTransactionLabel = isTransactionLabel;

  function isTransactionLabel(params /*, hash*/) {

    if (localStorage.getItem("TransactionLabelOverrideEnabled") === "1") {
      return true;
    } else {
      return false;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(isTransactionLabel);
});