define("vopay_app/templates/components/signing-authority-edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 3
              },
              "end": {
                "line": 15,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/signing-authority-edit.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "owner-form", [], ["number", ["subexpr", "@mut", [["get", "numberOwner", ["loc", [null, [13, 11], [13, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "isIndividual", ["subexpr", "@mut", [["get", "isIndividual", ["loc", [null, [14, 17], [14, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 4], [14, 31]]], 0, 0]],
          locals: ["numberOwner"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 3
              },
              "end": {
                "line": 19,
                "column": 3
              }
            },
            "moduleName": "vopay_app/templates/components/signing-authority-edit.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "#");
            dom.setAttribute(el1, "class", "form__link--remove");
            var el2 = dom.createTextNode("Remove Owner");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["removeOwner"], [], ["loc", [null, [18, 16], [18, 40]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 21,
              "column": 2
            }
          },
          "moduleName": "vopay_app/templates/components/signing-authority-edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "#");
          var el2 = dom.createTextNode("Add Owner");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("			\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "each", [["get", "ownerNumbers", ["loc", [null, [11, 11], [11, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 3], [15, 12]]]], ["element", "action", ["addOwner"], [], ["loc", [null, [16, 15], [16, 36]]], 0, 0], ["block", "if", [["subexpr", "gt", [["get", "ownerCurrNum", ["loc", [null, [17, 13], [17, 25]]], 0, 0, 0, 0], "1"], [], ["loc", [null, [17, 9], [17, 30]]], 0, 0]], [], 1, null, ["loc", [null, [17, 3], [19, 10]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 3
            },
            "end": {
              "line": 24,
              "column": 85
            }
          },
          "moduleName": "vopay_app/templates/components/signing-authority-edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Cancel");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/signing-authority-edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form__section form__section--noPadding dashboard__section--form");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "form__header dashboard__header--sub");
        var el4 = dom.createTextNode("Primary Contact");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--100");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "style", "display:none;");
        dom.setAttribute(el4, "class", "form__input");
        dom.setAttribute(el4, "id", "ownerType");
        dom.setAttribute(el4, "name", "ownerType");
        dom.setAttribute(el4, "placeholder", "Pharmaceuticals");
        dom.setAttribute(el4, "data-parsley-trigger", "focusout");
        dom.setAttribute(el4, "data-parsley-required", "true");
        dom.setAttribute(el4, "data-parsley-required-message", "The above field is required. Please enter a valid Owner Type.");
        dom.setAttribute(el4, "data-parsley-errors-container", "#errorOwnerType");
        dom.setAttribute(el4, "required", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("Ownership Type");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "form__description form__description--error form__message--errorInline");
        dom.setAttribute(el4, "id", "errorOwnerType");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--block50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "form__btn form__btn--inline");
        var el5 = dom.createTextNode("Save");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n\n		\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [7]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [3]), 3, 3);
        morphs[1] = dom.createMorphAt(element2, 5, 5);
        morphs[2] = dom.createElementMorph(element4);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["inline", "general-dropdown", [], ["default", "Choose Ownership Type", "data", ["subexpr", "@mut", [["get", "ownerTypes", ["loc", [null, [6, 60], [6, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "ownerTypeSelected"], ["loc", [null, [6, 3], [6, 98]]], 0, 0], ["block", "if", [["get", "ownerTypeSelected", ["loc", [null, [10, 8], [10, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 2], [21, 9]]]], ["element", "action", ["updateSigningAuthority"], [], ["loc", [null, [23, 11], [23, 46]]], 0, 0], ["block", "link-to", ["dashboard.account.business-profile"], ["class", "dashboard__cancel"], 1, null, ["loc", [null, [24, 3], [24, 97]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});