define("vopay_app/templates/components/mailing-address-edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 3
            },
            "end": {
              "line": 30,
              "column": 85
            }
          },
          "moduleName": "vopay_app/templates/components/mailing-address-edit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Cancel");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/mailing-address-edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form__section form__section--noPadding dashboard__section--form");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "form__header dashboard__header--sub");
        var el4 = dom.createTextNode("Mailing Address");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--100");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "class", "form__input");
        dom.setAttribute(el4, "id", "street-address");
        dom.setAttribute(el4, "name", "streetaddress");
        dom.setAttribute(el4, "placeholder", "First Line of Your Address");
        dom.setAttribute(el4, "data-parsley-trigger", "focusout");
        dom.setAttribute(el4, "data-parsley-required", "true");
        dom.setAttribute(el4, "data-parsley-required-message", "The above field is required. Please enter a valid address.");
        dom.setAttribute(el4, "required", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("Mailing Address");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("City");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("Province");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "class", "form__input");
        dom.setAttribute(el4, "id", "postcode");
        dom.setAttribute(el4, "name", "postcode");
        dom.setAttribute(el4, "placeholder", "A1A 1A1");
        dom.setAttribute(el4, "data-parsley-trigger", "focusout");
        dom.setAttribute(el4, "data-parsley-required", "true");
        dom.setAttribute(el4, "maxlength", "7");
        dom.setAttribute(el4, "data-parsley-required-message", "The above field is required. Please enter a valid postal code.");
        dom.setAttribute(el4, "required", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("Postal Code");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "hidden");
        dom.setAttribute(el4, "class", "form__input");
        dom.setAttribute(el4, "name", "country");
        dom.setAttribute(el4, "placeholder", "Country");
        dom.setAttribute(el4, "value", "Canada");
        dom.setAttribute(el4, "data-parsley-trigger", "focusout");
        dom.setAttribute(el4, "data-parsley-required", "true");
        dom.setAttribute(el4, "required", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "class", "form__input");
        dom.setAttribute(el4, "id", "country");
        dom.setAttribute(el4, "name", "country2");
        dom.setAttribute(el4, "placeholder", "Country");
        dom.setAttribute(el4, "value", "Canada");
        dom.setAttribute(el4, "disabled", "true");
        dom.setAttribute(el4, "data-parsley-trigger", "focusout");
        dom.setAttribute(el4, "data-parsley-required", "true");
        dom.setAttribute(el4, "required", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "form__label");
        var el5 = dom.createTextNode("Country");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form__element form__element--block50");
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "form__btn form__btn--inline");
        var el5 = dom.createTextNode("Save");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n			");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [13]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "text", "class", "form__input", "id", "city", "name", "city", "placeholder", "Enter City Name", "data-parsley-trigger", "focusout", "data-parsley-required", "true", "maxlength", "30", "required", "true", "data-parsley-required-message", "The above field is required. Please enter a valid city.", "value", ["subexpr", "@mut", [["get", "cityReg", ["loc", [null, [10, 282], [10, 289]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 3], [10, 291]]], 0, 0], ["inline", "input", [], ["type", "text", "class", "form__input", "id", "state", "name", "state", "placeholder", "Province/State", "data-parsley-trigger", "focusout", "data-parsley-required", "true", "maxlength", "25", "required", "true", "data-parsley-required-message", "The above field is required. Please enter a valid province.", "value", ["subexpr", "@mut", [["get", "provinceReg", ["loc", [null, [14, 287], [14, 298]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 3], [14, 300]]], 0, 0], ["element", "action", ["updateMailingAddress"], [], ["loc", [null, [29, 11], [29, 44]]], 0, 0], ["block", "link-to", ["dashboard.account.business-profile"], ["class", "dashboard__cancel"], 0, null, ["loc", [null, [30, 3], [30, 97]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});