define('vopay_app/components/director-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		directorNumbers: ["1"],
		directorCurrNum: 1,
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updateDirectors: function updateDirectors() {
				this.get("routing").transitionTo("dashboard.account.directors");
			},
			addDirector: function addDirector() {
				this.set("directorCurrNum", this.get("directorCurrNum") + 1);
				this.get('directorNumbers').pushObject(this.get("directorCurrNum"));
			},
			removeDirector: function removeDirector() {
				this.set('directorNumbers', this.get('directorNumbers').slice(0, -1));
				this.set("directorCurrNum", this.get("directorCurrNum") - 1);
			}
		}
	});
});