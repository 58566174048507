define('vopay_app/helpers/is-cassie', ['exports', 'ember'], function (exports, _ember) {
  exports.isCassie = isCassie;

  function isCassie(params /*, hash*/) {
    if (params[0] >= 1823 && params[0] <= 1835) {
      return true;
    } else {
      return false;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(isCassie);
});