define('vopay_app/components/subaccount-permissions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        subAccountData: null,
        accountID: "",
        api: _ember['default'].inject.service('api'),
        routing: _ember['default'].inject.service('-routing'),
        isEftCollect: false,
        isEftSend: false,
        isInteracMoneyRequest: false,
        isInteracBulkPayout: false,
        isSaved: false,
        init: function init() {
            this._super.apply(this, arguments);
            var self = this;
            var data = this.get("data");

            var subaaccount = self.findSubAccount(data.Subaccounts, self.get("accountID"));
            self.set("EFTCollectEnabled", subaaccount.EFTCollectEnabled);
            self.set("EFTSendEnabled", subaaccount.EFTSendEnabled);
            self.set("InteracMoneyRequestEnabled", subaaccount.InteracMoneyRequestEnabled);
            self.set("InteracBulkPayoutEnabled", subaaccount.InteracBulkPayoutEnabled);
            self.set("EFTCollectEnabledPrev", subaaccount.EFTCollectEnabled);
            self.set("EFTSendEnabledPrev", subaaccount.EFTSendEnabled);
            self.set("InteracMoneyRequestEnabledPrev", subaaccount.InteracMoneyRequestEnabled);
            self.set("InteracBulkPayoutEnabledPrev", subaaccount.InteracBulkPayoutEnabled);

            if (localStorage.getItem("EFTCollectEnabled") === "1") {
                this.set("isEftCollect", true);
            }
            if (localStorage.getItem("EFTSendEnabled") === "1") {
                this.set("isEftSend", true);
            }
            if (localStorage.getItem("InteracMoneyRequestEnabled") === "1") {
                this.set("isInteracMoneyRequest", true);
            }
            if (localStorage.getItem("InteracBulkPayoutEnabled ") === "1") {
                this.set("isInteracBulkPayout", true);
            }

            if (!subaaccount.EFTCollectEnabled && !subaaccount.EFTSendEnabled && !subaaccount.InteracMoneyRequestEnabled && !subaaccount.InteracBulkPayoutEnabled) {
                self.set("PortalOnly", true);
                self.set("PortalOnlyPrev", true);
            } else {
                self.set("PortalOnly", false);
                self.set("PortalOnlyPrev", false);
            }
        },
        findSubAccount: function findSubAccount(subAccountList, name) {

            for (var key in subAccountList) {
                if (subAccountList[key].AccountID == name) {
                    return subAccountList[key];
                }
            }
        },
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        actions: {
            moveToOverview: function moveToOverview() {
                $("#Overview").show();
                $("#paymentInfo").hide();
                var paymentToAdd = {
                    EFTCollectEnabled: this.get("EFTCollectEnabled"),
                    EFTSendEnabled: this.get("EFTSendEnabled"),
                    InteracMoneyRequestEnabled: this.get("InteracMoneyRequestEnabled"),
                    InteracBulkPayoutEnabled: this.get("InteracBulkPayoutEnabled"),
                    SubaccountID: this.get("accountID")
                };
                this.set("isSaved", false);
                this.set("paymentToAdd", paymentToAdd);
            },
            moveToPayment: function moveToPayment() {
                $("#Overview").hide();
                $("#paymentInfo").show();
            },
            TurnOffPortalOnlyC: function TurnOffPortalOnlyC() {
                this.set("PortalOnly", false);

                this.set("EFTCollectEnabled", !this.get("EFTCollectEnabledPrev"));
                this.set("EFTCollectEnabledPrev", this.get("EFTCollectEnabled"));
                if (!this.get("EFTCollectEnabled") && !this.get("EFTSendEnabled") && !this.get("InteracMoneyRequestEnabled") && !this.get("InteracBulkPayoutEnabled")) {
                    this.set("PortalOnly", true);
                    this.set("PortalOnlyPrev", true);
                }
            },
            TurnOffPortalOnlyS: function TurnOffPortalOnlyS() {
                this.set("PortalOnly", false);
                this.set("EFTSendEnabled", !this.get("EFTSendEnabledPrev"));
                this.set("EFTSendEnabledPrev", this.get("EFTSendEnabled"));
                if (!this.get("EFTCollectEnabled") && !this.get("EFTSendEnabled") && !this.get("InteracMoneyRequestEnabled") && !this.get("InteracBulkPayoutEnabled")) {
                    this.set("PortalOnly", true);
                    this.set("PortalOnlyPrev", true);
                }
            },
            TurnOffPortalOnlyM: function TurnOffPortalOnlyM() {
                this.set("PortalOnly", false);
                this.set("InteracMoneyRequestEnabled", !this.get("InteracMoneyRequestEnabledPrev"));
                this.set("InteracMoneyRequestEnabledPrev", this.get("InteracMoneyRequestEnabled"));
                if (!this.get("EFTCollectEnabled") && !this.get("EFTSendEnabled") && !this.get("InteracMoneyRequestEnabled") && !this.get("InteracBulkPayoutEnabled")) {
                    this.set("PortalOnly", true);
                    this.set("PortalOnlyPrev", true);
                }
            },
            TurnOffPortalOnlyB: function TurnOffPortalOnlyB() {
                this.set("PortalOnly", false);
                this.set("InteracBulkPayoutEnabled", !this.get("InteracBulkPayoutEnabledPrev"));
                this.set("InteracBulkPayoutEnabledPrev", this.get("InteracBulkPayoutEnabled"));
                if (!this.get("EFTCollectEnabled") && !this.get("EFTSendEnabled") && !this.get("InteracMoneyRequestEnabled") && !this.get("InteracBulkPayoutEnabled")) {
                    this.set("PortalOnly", true);
                    this.set("PortalOnlyPrev", true);
                }
            },

            TurnOffEverythingElse: function TurnOffEverythingElse() {
                if (this.get("PortalOnlyPrev") == false) {
                    this.set("EFTCollectEnabled", false);
                    this.set("EFTSendEnabled", false);
                    this.set("InteracMoneyRequestEnabled", false);
                    this.set("InteracBulkPayoutEnabled", false);
                    this.set("EFTCollectEnabledPrev", false);
                    this.set("EFTSendEnabledPrev", false);
                    this.set("InteracMoneyRequestEnabledPrev", false);
                    this.set("InteracBulkPayoutEnabledPrev", false);
                    this.set("PortalOnly", !this.get("PortalOnlyPrev"));
                }
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            save: function save() {
                var _this = this;

                var options = this.get("paymentToAdd");
                var self = this;
                this.get("api").SetSubAccountPermissions(options).then(function (data2) {
                    self.get('api').getSubAccounts().then(function (data) {

                        var subaaccount = self.findSubAccount(data.Subaccounts, self.get("accountID"));
                        self.set("EFTCollectEnabled", subaaccount.EFTCollectEnabled);
                        self.set("EFTSendEnabled", subaaccount.EFTSendEnabled);
                        self.set("InteracMoneyRequestEnabled", subaaccount.InteracMoneyRequestEnabled);
                        self.set("InteracBulkPayoutEnabled", subaaccount.InteracBulkPayoutEnabled);

                        if (localStorage.getItem("EFTCollectEnabled") === "1") {
                            _this.set("isEftCollect", true);
                        }
                        if (localStorage.getItem("EFTSendEnabled") === "1") {
                            _this.set("isEftSend", true);
                        }
                        if (localStorage.getItem("InteracMoneyRequestEnabled") === "1") {
                            _this.set("isInteracMoneyRequest", true);
                        }
                        if (localStorage.getItem("InteracBulkPayoutEnabled ") === "1") {
                            _this.set("isInteracBulkPayout", true);
                        }

                        if (!subaaccount.EFTCollectEnabled && !subaaccount.EFTSendEnabled && !subaaccount.InteracMoneyRequestEnabled && !subaaccount.InteracBulkPayoutEnabled) {
                            self.set("PortalOnly", true);
                        } else {
                            self.set("PortalOnly", false);
                        }
                        $("#Overview").hide();
                        $("#paymentInfo").show();
                        _this.set("isSaved", true);
                    }, function (error) {});
                }, function (error) {
                    _this.errorMessage(error);
                });
            }
        }

    });
});