define('vopay_app/components/primary-contact-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyCharacters(this.get('lastname')));
		}),
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updatePrimaryContact: function updatePrimaryContact() {
				this.get("routing").transitionTo("dashboard.account.primary-contact");
			}
		}
	});
});