define('vopay_app/components/password-widget', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		password: null,
		hasCharcters: false,
		hasNumber: false,
		watchPassword: _ember['default'].observer('password', function () {
			var password = this.get("password");
			if (password.length >= 8) {
				this.set("hasCharcters", true);
			} else {
				this.set("hasCharcters", false);
			}
			if (/\d/g.test(password)) {
				this.set("hasNumber", true);
			} else {
				this.set("hasNumber", false);
			}

			if (/[a-z]/g.test(password)) {
				this.set("hasLowerCase", true);
			} else {
				this.set("hasLowerCase", false);
			}
			if (/[A-Z]/g.test(password)) {
				this.set("hasUpperCase", true);
			} else {
				this.set("hasUpperCase", false);
			}

			if (/[^\w]/g.test(password)) {
				this.set("hasSpecialCharacter", true);
			} else {
				this.set("hasSpecialCharacter", false);
			}

			if (this.get("hasSpecialCharacter") && this.get("hasUpperCase") && this.get("hasLowerCase") && this.get("hasNumber") && this.get("hasCharcters")) {
				this.sendAction("isValidHandler", true);
			} else {
				this.sendAction("isValidHandler", false);
			}
		})
	});
});