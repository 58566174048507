define("vopay_app/helpers/currency-seperator", ["exports", "ember"], function (exports, _ember) {
	exports.currencySeperator = currencySeperator;

	function currencySeperator(params /*, hash*/) {
		if (!params[1] || params[1] === "CAD" || params[1] === "AUD" || params[1] === "SGD" || params[1] === "HKD" || params[1] === "NZD") {
			params[1] = "USD";
		}

		if (!!(typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function')) {
			try {
				var formatter = new Intl.NumberFormat('en-US', {
					style: "currency",
					currency: params[1],
					currencyDisplay: "narrowSymbol",
					minimumFractionDigits: 2
				});
				return formatter.format(params[0]);
			} catch (error) {
				var formatter = new Intl.NumberFormat('en-US', {
					style: "currency",
					currency: params[1],
					minimumFractionDigits: 2
				});
				return formatter.format(params[0]);
			}
		} else {
			return params[0];
		}
	}

	exports["default"] = _ember["default"].Helper.helper(currencySeperator);
});