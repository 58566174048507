define('vopay_app/routes/dashboard/accounts', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		model: function model() {
			var authed = true;
			if (authed) {

				return _ember['default'].RSVP.hash({
					currencies: this.get('api').getGCMCurriencies(),
					beneficiaries: this.get("firebase").getRecordUser("accounts")
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});