define("vopay_app/components/railz-frame", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    didInsertElement: function didInsertElement() {
      var RedirectURL = "";
      if (location.hostname == "localhost") {

        RedirectURL = "https://" + location.hostname + ":4200/";
      } else if (location.hostname == "bs-local.com") {

        RedirectURL = "https://" + location.hostname + ":4200/";
      } else {

        RedirectURL = "https://" + location.hostname + "/";
      }
      var widget = new RailzConnect();
      widget.mount({
        parentElement: this.element,
        widgetId: 'wid_prod_7ccf12d4-3cb1-45df-9cba-c35dd2a6429e',
        businessName: localStorage.getItem("accountID"),
        brandColor: "000000",
        accentColor: "FFFFFF",
        language: 'en',
        redirectUrl: RedirectURL + "#/dashboard/integrations/accounting-software/",
        closeRedirectUrl: RedirectURL + "#/dashboard/integrations/accounting-software/",
        removeRailzWatermark: true,

        closeEnabled: true
      });
    }

  });
});