define('vopay_app/routes/home', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		beforeModel: function beforeModel(params) {},
		model: function model(params) {
			var authed = this.get('session.isAuthenticated');
			var urlParams = new URLSearchParams(location.search);
			if (urlParams.has('Token') == true) {

				localStorage.setItem("FlinksToken", urlParams.get('Token'));
				localStorage.setItem("MaskedAccount", urlParams.get('MaskedAccount'));
				localStorage.setItem("FullName", urlParams.get('FullName'));
			} else {
				if (authed) {
					this.transitionTo('dashboard.info');
				} else {
					this.transitionTo('login');
				}
			}

			//if authenticated, grab some info about the user
		}
	});
});