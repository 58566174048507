define("vopay_app/helpers/min-date", ["exports", "ember"], function (exports, _ember) {
  exports.minDate = minDate;

  function minDate(params /*, hash*/) {

    var value = params[0];
    var test = params[1];

    var amount = moment(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    var min = moment(test).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (amount.isAfter(min) || amount.isSame(min) || value == "") {
      return false;
    } else {
      return true;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(minDate);
});