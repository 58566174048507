define('vopay_app/adapters/transaction', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Adapter.extend({
    host: 'http://dev1.vopay.com/',
    namespace: 'api/remit/transactions/',
    headers: _ember['default'].computed(function () {
      return {
        'X-CSRF-TOKEN': _ember['default'].get(document.cookie.match(/CSRF-TOKEN\=([^;]*)/), "1"),
        'X-Requested-With': 'XMLHttpRequest'
      };
    }).volatile()
  });
});