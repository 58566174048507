define("vopay_app/components/client-transfer-funds", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		Currency: "CAD",
		currency2: "CAD",
		CurrencyName: "Canadian Dollar",
		api: _ember["default"].inject.service('api'),
		max: 0,
		amount: "",
		currencies3: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		currencies4: {
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		options: ["Transfer", "Fund and Transfer", "Transfer and Withdraw", "Fund, Transfer and Withdraw"],
		country: "Canada",
		isoCountry: "CA",
		wachValue: _ember["default"].observer('amount', function () {

			var isZeroAppend = false;
			var amount = this.get('amount');
			if (amount) {
				var myElement = this.$("#amount")[0];
				if (myElement) {
					var start = myElement.selectionStart;
					var end = myElement.selectionEnd;

					var amount2 = amount;
					if (amount.replace()) {
						amount2 = amount.replace(/[^\d.]+/g, '');
						amount2 = Math.floor(parseFloat(amount2));
					}
					var split = amount.split(".");
					var ending = split[1];

					var amount3 = undefined;

					var ending2 = null;
					if (ending && ending.length && ending.length > 2) {

						ending2 = ending.substring(0, 2);
					} else {
						ending2 = ending;
					}

					if (ending2 || ending2 === 0) {
						amount3 = amount2.toLocaleString("en-CA") + "." + ending2;
					} else if (split[0] == "") {

						if (ending2 || ending2 === 0) {
							amount3 = "0." + ending2;
							isZeroAppend = true;
						} else {
							amount3 = "0.";
							isZeroAppend = true;
						}
					} else if (!ending && split.length == 2) {
						amount3 = amount2.toLocaleString("en-CA") + ".";
					} else {
						if (amount2) {
							amount3 = amount2.toLocaleString("en-CA");
						} else {
							amount3 = "";
						}
					}

					this.$(myElement).val(amount3);

					if (myElement.setSelectionRange) {
						if (isZeroAppend) {
							myElement.setSelectionRange(start + 2, end + 2);
						} else if (amount3.length > amount.length && amount3.length > 4) {
							myElement.setSelectionRange(start + 1, end + 1);
						} else if (amount3.length < amount.length) {
							myElement.setSelectionRange(start - 1, end - 1);
						} else {
							myElement.setSelectionRange(start, end);
						}
					} else if (myElement.createTextRange) {
						var range = myElement.createTextRange();
						range.collapse(true);
						range.moveEnd('character', start);
						range.moveStart('character', end);
						range.select();
					}
				}
			}
		}),
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember["default"].$("#paymentForm").parsley().destroy();
			_ember["default"].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		getFormObject: function getFormObject(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},

		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		init: function init() {
			this._super.apply(this, arguments);
			if (localStorage.getItem("Country") == "US") {
				this.set("currency2", "USD");
				this.set("Currency", "USD");
				this.set("country", "United States");
				this.set("CurrencyName", "United States Dollar");
				this.set("isoCountry", "US");
			}
		},
		validateForm: function validateForm(formId) {

			var form = _ember["default"].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		isYourOwnClientAccount: function isYourOwnClientAccount(ClientAccountId) {
			var ClientAccounts = this.get("clientAccounts.ClientAccounts");
			var selectedAccount = Object.values(ClientAccounts).find(function (account) {
				return account.ClientAccountID === ClientAccountId;
			});
			return selectedAccount.ParentAccountID === localStorage.getItem("accountID");
		},
		isBalanceCheck: function isBalanceCheck(value) {
			if (this.get("transferType") === "Transfer" || this.get("transferType") === "Transfer and Withdraw") {
				return Number(this.get("max")) >= Number(value);
			} else {
				return true;
			}
		},
		actions: {
			moveToPayment: function moveToPayment() {
				$("#Overview").hide();
				$("#paymentInfo").show();
			},

			overview: function overview() {
				var formData = this.getFormObject(_ember["default"].$('#paymentForm').serializeArray());
				formData.Amount = _ember["default"].$("#amount").val().replace(/\,/g, "");

				if (this.validateForm("#paymentForm") && this.isBalanceCheck(formData.Amount)) {

					this.set("paymentToAdd", formData);
					this.set("paymentToAdd.TransferType", this.get("transferType"));
					$("#Overview").show();
					$("#paymentInfo").hide();
				}
			},
			handleType: function handleType(type) {
				this.set("transferType", type);
			},
			DebitorChange: function DebitorChange(value) {
				var _this = this;

				if (value != "") {
					this.get("api").getClientAccountBalance({ ClientAccountID: value }).then(function (data) {
						_this.set("max", data.AvailableFunds.replace(/,/g, ''));
					});
				} else {
					this.set("max", "0");
				}
			},
			CreditorChange: function CreditorChange() {},
			CurrencyChanged: function CurrencyChanged() {},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			transfer: function transfer() {
				var _this2 = this;

				var paymentForm = this.get("paymentToAdd");
				var DebitorIsOwn = this.isYourOwnClientAccount(paymentForm.DebitorClientAccountID);
				var RecipientIsOwn = this.isYourOwnClientAccount(paymentForm.RecipientClientAccountID);
				paymentForm.DebitorIsOwn = DebitorIsOwn;
				paymentForm.RecipientIsOwn = RecipientIsOwn;
				var TransactionOverview = {
					Fund: {
						Currency: this.get("Currency"),
						TransactionID: 0,
						Amount: paymentForm.Amount,
						TransactionDate: moment.utc().format("YYYY-MM-DD"),
						Status: "Pending",
						DebitorClientAccountID: paymentForm.DebitorClientAccountID,
						DebitorClientAccountName: paymentForm.DebitorClientAccountID,
						DebitorIsOwn: paymentForm.DebitorIsOwn,
						DebitorBankAccount: null,
						isActive: false
					},
					Transfer: {
						Currency: this.get("Currency"),
						TransactionID: 0,
						TransactionDate: moment.utc().format("YYYY-MM-DD"),
						Amount: paymentForm.Amount,
						isActive: true,
						DebitorClientAccountID: paymentForm.DebitorClientAccountID,
						DebitorClientAccountName: paymentForm.DebitorClientAccountName,
						DebitorIsOwn: paymentForm.DebitorIsOwn,
						RecipientClientAccountID: paymentForm.RecipientClientAccountID,
						RecipientClientAccountName: paymentForm.RecipientClientAccountName,
						RecipientIsOwn: paymentForm.RecipientIsOwn
					},
					Withdraw: {
						Currency: this.get("Currency"),
						TransactionID: 0,
						TransactionDate: moment.utc().format("YYYY-MM-DD"),
						Amount: paymentForm.Amount,
						RecipientClientAccountID: paymentForm.RecipientClientAccountID,
						RecipientClientAccountName: paymentForm.RecipientClientAccountName,
						RecipientIsOwn: paymentForm.RecipientIsOwn,
						Status: "Pending",
						RecipientBankAccount: null,
						isActive: false
					}
				};
				if (paymentForm.TransferType === "Fund and Transfer") {

					this.get("api").fundTransfer(paymentForm).then(function (data) {
						_this2.get("api").getDefaultBankAccount({ ClientAccountID: paymentForm.DebitorClientAccountID }).then(function (bankDebitor) {
							TransactionOverview.Fund.DebitorBankAccount = bankDebitor.DefaultBankAccount;
							TransactionOverview.Fund.isActive = true;
							TransactionOverview.Fund.TransactionID = data.FundingTransactionID;
							TransactionOverview.Transfer.TransactionID = data.AccountTransferID;
							$("#Overview").hide();
							_this2.set("TransactionOverview", TransactionOverview);
						}, function (error) {
							_this2.errorMessage(error);
						});
						//window.location.href = "#/dashboard/transaction/Fund/"+data.FundingTransactionID+"/"+this.get("Currency");
					}, function (error) {

						_this2.errorMessage(error);
					});
				} else if (paymentForm.TransferType === "Transfer and Withdraw") {

					this.get("api").transferWithdraw(paymentForm).then(function (data) {
						_this2.get("api").getDefaultBankAccount({ ClientAccountID: paymentForm.RecipientClientAccountID }).then(function (bankRecipient) {
							TransactionOverview.Withdraw.RecipientBankAccount = bankRecipient.DefaultBankAccount;
							TransactionOverview.Withdraw.isActive = true;
							TransactionOverview.Withdraw.TransactionID = data.WithdrawTransactionID;
							TransactionOverview.Transfer.TransactionID = data.AccountTransferID;
							$("#Overview").hide();
							_this2.set("TransactionOverview", TransactionOverview);
						}, function (error) {
							_this2.errorMessage(error);
						});
						//window.location.href = "#/dashboard/transaction/Withdraw/"+data.WithdrawTransactionID+"/"+this.get("Currency");
					}, function (error) {
						_this2.errorMessage(error);
					});
				} else if (paymentForm.TransferType === "Transfer") {
					this.get("api").transferTo(paymentForm).then(function (data) {
						TransactionOverview.Transfer.TransactionID = data.TransactionID;
						$("#Overview").hide();
						_this2.set("TransactionOverview", TransactionOverview);
						//window.location.href = "#/dashboard/transaction/Withdraw/"+data.WithdrawTransactionID+"/"+this.get("Currency");
					}, function (error) {
						_this2.errorMessage(error);
					});
				} else {
					this.get("api").fundTransferWithdraw(paymentForm).then(function (data) {
						_this2.get("api").getDefaultBankAccount({ ClientAccountID: paymentForm.DebitorClientAccountID }).then(function (bankDebitor) {
							_this2.get("api").getDefaultBankAccount({ ClientAccountID: paymentForm.RecipientClientAccountID }).then(function (bankRecipient) {
								TransactionOverview.Fund.DebitorBankAccount = bankDebitor.DefaultBankAccount;
								TransactionOverview.Withdraw.RecipientBankAccount = bankRecipient.DefaultBankAccount;
								TransactionOverview.Fund.isActive = true;
								TransactionOverview.Withdraw.isActive = true;
								TransactionOverview.Fund.TransactionID = data.FundingTransactionID;
								TransactionOverview.Withdraw.TransactionID = data.WithdrawTransactionID;
								TransactionOverview.Transfer.TransactionID = data.AccountTransferID;
								TransactionOverview.Withdraw.Status = "Waiting";
								$("#Overview").hide();
								_this2.set("TransactionOverview", TransactionOverview);
							}, function (error) {
								_this2.errorMessage(error);
							});
						}, function (error) {
							_this2.errorMessage(error);
						});
						// window.location.href = "#/dashboard/transaction/Withdraw/"+data.WithdrawTransactionID+"/"+this.get("Currency");
					}, function (error) {
						_this2.errorMessage(error);
					});
				}
			}
		}

	});
});