define('vopay_app/routes/dashboard/recipient-update-info', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    moveToTop: (function () {
      window.scrollTo(0, 0);
    }).on('activate'),
    model: function model(id) {

      var authed = this.get('session.isAuthenticated');
      //if authenticated, grab some info about the user
      if (authed) {

        return _ember['default'].RSVP.hash({
          model: this._info(id['transaction']),
          test: "hello"
        });
      } else {
        this.transitionTo('login');
      }
    },
    getCountry: function getCountry(countryAbbv) {
      var arrCurrency = this.get('countries').filter(function (f) {
        return f.Abbreviation.toUpperCase() === countryAbbv.toUpperCase();
      }).map(function (c) {
        return c.Country;
      });
      return arrCurrency[0];
    },
    getCountryID: function getCountryID(country) {
      var arrCurrency = this.get('countries').filter(function (f) {
        return f.Country.toUpperCase() === country.toUpperCase();
      }).map(function (c) {
        return c.CountryID;
      });
      return arrCurrency[0];
    },
    getCountryAbbv: function getCountryAbbv(country) {
      var arrCurrency = this.get('countries').filter(function (f) {
        return f.Country.toUpperCase() === country.toUpperCase();
      }).map(function (c) {
        return c.Abbreviation;
      });
      return arrCurrency[0];
    },

    _info: function _info(id) {
      var _this = this;

      return this.get('api').getTransactions(id).then(function (trans) {
        return _this._retrieveRecipient(trans);
      });
    },
    _retrieveRecipient: function _retrieveRecipient(trans) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this2.get('api').listRecipients().then(function (data) {

          var recipient = {};

          for (var key in data) {
            if (trans.RemittanceRecipientID === data[key].RemittanceRecipientID) {

              data[key].BankAccount = _this2._parsePaymentData(data[key].BankAccount);
              recipient = data[key];
              break;
            }
          }
          if (recipient.Country === "Republic of Ireland") {
            recipient.Country = "Ireland";
          }
          trans.recipient = recipient;

          _this2.get('api').getCountries().then(function (data3) {

            _this2.set('countries', data3);

            var country = _this2.getCountry(trans.ReceiveCountry);

            trans.countryCode = _this2.getCountryID(country);
            trans.countryAbbv = _this2.getCountryAbbv(country);
            recipient.country = country;

            trans.currency = trans.ReceiveCurrency;
            trans.countryName = recipient.Country;

            var options = {};
            options.currency = trans.currency;
            options.bankCountry = trans.countryAbbv;
            options.benefCountry = trans.countryAbbv;
            var type = "bank";
            if (recipient.Country === "Philippines") {
              if (recipient.PhoneNumberCountry) {
                type = "cash";
              }
            }

            _this2.get('api').availableRecipients(options, type).then(function (data2) {
              trans.recipientAvailable = data2.List[0];
              resolve(trans);
            });
          });
        });
      });
    },
    _parsePaymentData: function _parsePaymentData(payment) {

      var paymentObject = {};
      var parsedPayment = {};

      paymentObject = JSON.parse(payment);

      for (var key in paymentObject) {
        var iKey = key.replace(/_+/g, "");
        parsedPayment[iKey] = paymentObject[key];
      }

      return parsedPayment;
    }
  });
});