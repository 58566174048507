define("vopay_app/templates/error", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 6
          }
        },
        "moduleName": "vopay_app/templates/error.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("-<div class=\"errorBox\">\n\n  <h2>\n{{#if message}}\n        {{message}}\n    {{else}}\n        Unexpected Error\n    {{/if}}  </h2>\n\n  <p>\n{{#if request}}\n        {{request}}\n    {{else}}\n        Trying to access wrong resource\n    {{/if}}  </p>\n\n  <div class=\"form\">\n    <button class=\"form__btn form__btn--60\" {{action 'retry'}}>Try again</button> or\n    <a class=\"form__anchor--blue\" href=\"http://vopay.com\" alt=\"vopay.com\">Go to the main page</a>\n  </div>\n\n</div>\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "errorContainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "errorContent");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "errorHeader");
        var el4 = dom.createTextNode("That wasn’t supposed to happen...");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "errorDescription");
        var el4 = dom.createTextNode("We can't seem to find the page you're looking for. Not sure what you’re looking for?\n    Check out our ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://vopay.com/support/");
        var el5 = dom.createTextNode("FAQ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" for help, or head ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://vopay.com/");
        var el5 = dom.createTextNode("back to home.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});