define("vopay_app/helpers/capitalize-first-letter", ["exports", "ember"], function (exports, _ember) {
  exports.capitalizeFirstLetter = capitalizeFirstLetter;

  function capitalizeFirstLetter(params /*, hash*/) {
    var value = params[0];
    if (value) {
      var values = value.split(" ");
      for (var i = 0; i < values.length; i++) {
        values[i] = values[i].charAt(0).toUpperCase() + values[i].slice(1);
      }
      return values.join(" ");
    } else {
      return value;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(capitalizeFirstLetter);
});