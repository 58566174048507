define('vopay_app/components/global-pay-page', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend({
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);
			this.set("WebHookURL", this.get("webhook.WebHookURL"));
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		actions: {
			update: function update() {
				var _this = this;

				var options = {};
				options.GlobalPayAppID = this.get("GlobalPayAppID");
				options.GlobalPaySharedSecret = this.get("GlobalPaySharedSecret");
				var self = this;
				this.get("api").updateGlobalPay(options).then(function (data) {
					//re get webhook url
					self.set("isSaved", true);
					$("#Overview").hide();
					$("#paymentInfo").show();
				}, function (error) {
					self.set("isSaved", false);
					_this.errorMessage(error);
				});
			},
			moveToOverview: function moveToOverview() {
				if (this.validateForm('#paymentForm')) {
					$("#Overview").show();
					$("#paymentInfo").hide();
					this.set("isSaved", false);
				}
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			showForm: function showForm() {
				this.set("isForm", true);
			},
			closeForm: function closeForm() {
				this.set("isForm", false);
			}
		}
	});
});