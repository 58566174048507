define('vopay_app/components/user-permissions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        api: _ember['default'].inject.service('api'),
        firebase: _ember['default'].inject.service('firebase'),
        userPermissions: {
            ViewSubaccounts: false,
            AddSubaccounts: false,
            CreatePayments: false,
            CreateCollections: false,
            ViewBeneficiaries: false,
            AddBeneficiaries: false,
            ViewBulkFileUpload: false,
            CreateBulkFileUpload: false,
            ViewScheduledTransactions: false,
            ViewTransactionHistory: false,
            ViewSettings: false,
            AddSettings: false,
            ViewUsers: false,
            AddUsers: false,
            ApproveTransactions: false,
            ManageUserPermissions: false,
            CreateAccountTransfer: false,
            ViewClientAccounts: false,
            AddClientAccounts: false,
            CreateClientAccountTransfers: false,
            CreateConversions: false,
            ManageDefaultPayments: false,
            CreateSupportTickets: false,
            ViewSupportTickets: false,
            ViewReconciliation: false,
            ViewAccountingBillsAndInvoices: false,
            ProcessAccountingBillsAndInvoices: false,
            ManageTransactionGroups: false,
            TransactionCancellation: false
        },
        init: function init() {
            this._super.apply(this, arguments);
            var userPermissionsObject = {
                ViewSubaccounts: false,
                AddSubaccounts: false,
                CreatePayments: false,
                CreateCollections: false,
                ViewBeneficiaries: false,
                AddBeneficiaries: false,
                ViewBulkFileUpload: false,
                CreateBulkFileUpload: false,
                ViewScheduledTransactions: false,
                ViewTransactionHistory: false,
                ViewSettings: false,
                AddSettings: false,
                ViewUsers: false,
                AddUsers: false,
                ApproveTransactions: false,
                ManageUserPermissions: false,
                CreateAccountTransfer: false,
                ViewClientAccounts: false,
                AddClientAccounts: false,
                CreateClientAccountTransfers: false,
                CreateConversions: false,
                ManageDefaultPayments: false,
                CreateSupportTickets: false,
                ViewSupportTickets: false,
                ViewReconciliation: false,
                ManageTransactionGroups: false,
                TransactionCancellation: false
            };
            var UserPermissionsList = this.get("Permissions");

            this.set("isPaaS", localStorage.getItem("AccountType") === "PaaS");
            for (var property in UserPermissionsList) {
                userPermissionsObject[UserPermissionsList[property]] = true;
            }
            this.set("userPermissions", userPermissionsObject);
            this.set('isSubAccount', localStorage.getItem("SubAccountPermissions") === '1');
            this.set('isClientAccount', localStorage.getItem("ClientAccountsEnabled") === '1');
            this.set('AccountingSoftwareIntegrationEnabled', localStorage.getItem("AccountingSoftwareIntegrationEnabled") === '1');
            this.set('AccountingSystemIntegrationEnabled', localStorage.getItem("AccountingSystemIntegrationEnabled") === '1');
        },
        didInsertElement: function didInsertElement() {
            _ember['default'].$('form').parsley({
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
        },
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        validateForm: function validateForm(formId) {

            var form = _ember['default'].$(formId);

            form.parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset]',
                inputs: 'input, textarea, select, input[type=hidden], :hidden',
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            }).validate();

            return form.parsley().isValid();
        },
        actions: {
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            moveToOverview: function moveToOverview() {
                _ember['default'].$("#permissionsInfo").hide();
                _ember['default'].$("#Overview").show();
            },
            moveToPermissions: function moveToPermissions() {
                _ember['default'].$("#permissionsInfo").show();
                _ember['default'].$("#Overview").hide();
            },
            save: function save() {
                var _this = this;

                var options = this.get("userPermissions");
                options.EmailAddress = this.get("Username");

                this.get("api").changeUserPermissions(options).then(function (data) {
                    $("#Overview").hide();
                    $("#permissionsInfo").show();
                    _this.set("isSaved", true);
                }, function (error) {
                    _this.errorMessage(error);
                });
            }
        }
    });
});