define('vopay_app/components/bulk-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		Payments: ["1"],
		PaymentsCurrNum: 1,
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		addSerialize: function addSerialize(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},
		init: function init() {
			this._super.apply(this, arguments);
			var data = this.get("beneficiaries");
			var data2 = this.get("linkedAccount");
			data = $.extend(true, {}, data, data2);
			this.set("beneficiaries", data);
		},
		actions: {
			addAccount: function addAccount() {
				this.set("PaymentsCurrNum", this.get("PaymentsCurrNum") + 1);
				this.get('Payments').pushObject(this.get("PaymentsCurrNum"));
			},
			review: function review() {
				var formArray = $("#bulk").serializeArray();
				var listing = [];
				var beneficiaries = this.get("beneficiaries");
				var collectAmount = 0;
				var payAmount = 0;
				var collectNumber = 0;
				var payNumber = 0;
				var payCurrencyAmount = [];
				var collectAmount = [];

				for (var i = 0; i < formArray.length; i++) {
					if (formArray[i]['name'] == "Key") {
						var key = formArray[i]['value'];

						if (beneficiaries[key]) {
							var listingObject = Object.assign({}, beneficiaries[key]);
							for (var j = 0; j < formArray.length; j++) {
								if (formArray[j]['name'] == "Service-" + key) {
									listingObject.Service = formArray[j]['value'];
								}
								if (formArray[j]['name'] == "Amount-" + key) {
									listingObject.DollarAmount = formArray[j]['value'];
								}
							}
							if (listingObject.DollarAmount == "" || listingObject.DollarAmount == null) {
								listingObject.DollarAmount = 0;
							}

							if (listingObject.Service == "Pay") {
								if (!payCurrencyAmount[listingObject.Currency]) {
									payCurrencyAmount[listingObject.Currency] = 0;
								}
								payNumber = payNumber + 1;
								payCurrencyAmount[listingObject.Currency] = parseFloat(payCurrencyAmount[listingObject.Currency]) + parseFloat(listingObject.DollarAmount);
							} else {
								if (!collectAmount[listingObject.Currency]) {
									collectAmount[listingObject.Currency] = 0;
								}
								collectNumber = collectNumber + 1;
								collectAmount[listingObject.Currency] = parseFloat(collectAmount[listingObject.Currency]) + parseFloat(listingObject.DollarAmount);
							}
							listing.push(listingObject);
						}
					}
				}

				var listing2 = Object.assign({}, listing);
				var collectAmount2 = Object.assign({}, collectAmount);
				var payAmount2 = Object.assign({}, payCurrencyAmount);
				var total = Object.assign({}, payAmount2);

				for (var key in collectAmount2) {
					if (total.hasOwnProperty(key)) {
						total[key] = total[key] + collectAmount2[key];
					} else {
						total[key] = collectAmount2[key];
					}
				}

				this.set("collectNumber", collectNumber);
				this.set("payNumber", payNumber);
				this.set("payAmount", payAmount2);
				this.set("collectAmount", collectAmount2);
				var totalNumber = collectNumber + payNumber;
				this.set("total", total);
				this.set("totalNumber", totalNumber);
				this.set("listing", "");
				this.set("listing", listing2);
				_ember['default'].$("#Overview").show();
				_ember['default'].$("#bulk").hide();
			},
			moveToBulk: function moveToBulk() {
				_ember['default'].$("#Overview").hide();
				_ember['default'].$("#bulk").show();
			},
			pay: function pay() {
				var listing = this.get("listing");

				for (var key in listing) {
					var beneficiary = listing[key];
					if (beneficiary.DollarAmount != "") {
						if (this.get("service") == "EMT") {
							if (beneficiary.Type == "Pay") {
								this.get("api").ClaimMoneyRequest(beneficiary).then(function (transaction) {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								}, function () {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								});
							} else {
								this.get("api").BulkPayout(beneficiary).then(function (transaction) {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								}, function () {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								});
							}
						} else {
							if (beneficiary.Type == "Pay") {
								this.get("api").withdrawTransaction(beneficiary).then(function (transaction) {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								}, function () {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								});
							} else {
								this.get("api").fundTransaction(beneficiary).then(function (transaction) {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								}, function () {
									_ember['default'].$('#bulk button').removeAttr('disabled');
								});
							}
						}
					}
				}
				var self = this;
				setTimeout(function () {
					self.get("routing").transitionTo("dashboard.transactions");
				}, 3000);
			}
		}
	});
});