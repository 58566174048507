define('vopay_app/routes/dashboard/add-beneficiary', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		model: function model() {

			var authed = this.get('session.isAuthenticated');

			if (authed) {

				return _ember['default'].RSVP.hash({
					userInfo: this.get('api').userInfo(),
					beneficiaries: this.get("firebase").getRecordUser("beneficiary")
				});
			} else {

				this.transitionTo('login');
			}
		}
	});
});