define('vopay_app/components/template-form', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports['default'] = _ember['default'].Component.extend({
		api: _ember['default'].inject.service('api'),
		PaymentTypeOptions: ["Collect", "Payment"],
		ServiceOptions: ["EFT"],
		PaymentType: "Collect",
		ServiceType: "EFT",
		routing: _ember['default'].inject.service('-routing'),
		country: "Canada",
		currency: "CAD",
		currency2: "CAD",
		currencyName: "Canadian Dollar",
		currencies2: _vopay_appConstants['default'].currencies,
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		currencies5: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		isError: false,
		init: function init() {
			this._super.apply(this, arguments);
			var templates = this.get("templates");
			var findTemplateId = this.get("key");
			if (templates) {
				var template = Object.values(templates).find(function (templateObj) {
					return templateObj.ID == findTemplateId;
				});
				this.set("template", template);
			}
			if (this.get("isEdit") === "true") {
				this.set("PaymentType", this.get("template.PaymentType"));
				this.set("ServiceType", this.get("template.Service"));
			} else if (this.get("isPayment")) {
				this.set("PaymentType", "Payment");
			} else {
				this.set("PaymentType", "Collect");
			}
			if (this.get("template.ACTCurrencies_ID")) {
				var Currency = "CAD";
				if (this.get("template.ACTCurrencies_ID") === "1") {
					Currency = "USD";
				}
				this.set("template.Currency", Currency);
				this.set("currency2", Currency);
				this.set("currencyName", this.getCurrencyName(Currency));
			}
			if (this.get("PaymentType") == "Payment") {
				if (localStorage.getItem("GCMEnabled") === "1") {} else if (localStorage.getItem("USDEFTSendEnabled") === "0") {
					this.set("currencies2", this.get("currencies5"));
				} else {
					this.set("currencies2", this.get("currencies4"));
				}
			} else {
				if (localStorage.getItem("USDEFTCollectEnabled") === "0") {
					this.set("currencies2", this.get("currencies5"));
				} else {
					this.set("currencies2", this.get("currencies4"));
				}
			}
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			_ember['default'].$("#paymentForm").parsley();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		serialize: function serialize(formObj) {
			var arrLength = formObj.length;
			var arr = {};

			for (var i = 0; i < arrLength; i++) {
				arr[formObj[i].name] = formObj[i].value;
			}
			return arr;
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			handlePaymentType: function handlePaymentType(value) {
				this.set("PaymentType", value);
			},
			handleServiceType: function handleServiceType(value) {
				this.set("ServiceType", value);
				this.set("currency2", "CAD");
				this.set("currencyName", "Canadian Dollar");
			},
			EditTemplate: function EditTemplate() {
				var _this = this;

				if (this.validateForm('#templateForm')) {
					var form = this.serialize(_ember['default'].$('#templateForm').serializeArray());
					form.PaymentType = this.get("PaymentType");
					form.GroupID = this.get("key");
					this.get("api").editGroup(form).then(function (data) {
						if (_this.get("isPayment") === "true") {
							location.href = "/#/dashboard/bulk-payment/";
						} else {
							location.href = "/#/dashboard/bulk-collect/";
						}
					}, function (error) {
						_this.set("ErrorMessage", error);
						_this.set("isError", true);
					});
				}
			},
			CreateTemplate: function CreateTemplate() {
				var _this2 = this;

				if (this.validateForm('#templateForm')) {
					var form = this.serialize(_ember['default'].$('#templateForm').serializeArray());
					form.PaymentType = this.get("PaymentType");
					form.Service = this.get("ServiceType").toLowerCase();
					form.Currency = this.get("currency2");
					this.get("api").createGroup(form).then(function (data) {
						if (_this2.get("isPayment") === "true") {

							location.href = "/#/dashboard/bulk-payment/group/" + data.GroupID;
						} else {

							location.href = "/#/dashboard/bulk-collect/group/" + data.GroupID;
						}
					}, function (error) {
						_this2.set("ErrorMessage", error);
						_this2.set("isError", true);
					});
				}
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				this.set("currency2", currency);
				this.set("currencyName", this.getCurrencyName(currency));
			}

		}
	});
});