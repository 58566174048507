define('vopay_app/helpers/padding-zero', ['exports', 'ember'], function (exports, _ember) {
  exports.paddingZero = paddingZero;

  function paddingZero(params /*, hash*/) {
    var number = params[0];
    var length = params[1];
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }

    return str;
  }

  exports['default'] = _ember['default'].Helper.helper(paddingZero);
});