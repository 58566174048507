define('vopay_app/components/account-menu', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		actions: {
			pageChange: function pageChange(value) {
				if (value === "businessProfile") {
					this.get("routing").transitionTo('dashboard.business-profile');
				} else if (value === "primaryContact") {
					this.get("routing").transitionTo('dashboard.account.primary-contact');
				} else if (value === "directors") {
					this.get("routing").transitionTo('dashboard.account.directors');
				} else if (value === "documents") {
					this.get("routing").transitionTo('dashboard.account.documents');
				} else {}
			}
		}
	});
});