define('vopay_app/components/sign-up', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {

	var apiBase = _vopay_appConfigEnvironment['default'].apiBase;

	var isProduction = _vopay_appConfigEnvironment['default'].isProduction;
	// import Constants from '../constants';

	exports['default'] = _ember['default'].Component.extend({
		//change data
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		passwordType: "password",
		passwordState: "Show",
		countries: [],
		validPassword: false,
		togglePassword: function togglePassword() {
			if (this.get("passwordState") === "Show") {
				this.set("passwordState", "Hide");
				this.set("passwordType", "text");
			} else {
				this.set("passwordState", "Show");
				this.set("passwordType", "password");
			}
		},
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			var self = this;
			//retrieve countries
			this.get('api').getCountries().then(function (countries) {
				self.set('countries', countries);
			});
			this.get("api").getSocial().then(function (data) {
				_this.set("fbURL", data.facebook);
				_this.set("googleURL", apiBase + "" + data.google);
			}, function (error) {});
		},
		/***
  * getCurrency
  *
  * Return currency by country
  */
		getCurrency: function getCurrency(country) {
			var arrCurrency = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === country.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return arrCurrency[0];
		},
		errorMsg: null,
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$("input").keyup(function () {
				_ember['default'].$(this).addClass("form__input--active");
			});
			_ember['default'].$("input").focusout(function () {
				_ember['default'].$(this).removeClass("form__input--active");
			});
			//check if local storage is available
			if (typeof Storage !== "undefined") {
				//get the URL parameters and store in local storage
				if (this.getUrlParameter('country')) {
					localStorage.setItem('recipientCountry', this.getUrlParameter('country'));
					var currency = this.getCurrency(this.getUrlParameter('country'));
					localStorage.setItem('currency', currency);
				}

				if (this.getUrlParameter('amount')) {
					localStorage.setItem('senderAmount', this.getUrlParameter('amount'));
				}
			}

			_ember['default'].$('input[name=password').keyup(function () {
				_ember['default'].$('.password__requirments').slideDown();
			});
		},

		/***
   * getUrlParameter
   * @param sParam (String) - The parameter to retreive
   *
   * Returns a URL parameter value
   */
		getUrlParameter: function getUrlParameter(sParam) {

			var sPageURL = decodeURIComponent(window.location.hash.substring(9)),
			    sURLVariables = sPageURL.split('&'),
			    sParameterName,
			    i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : sParameterName[1];
				}
			}
		},

		actions: {
			/***
    * signup
    *
    * Signs up a new user
    */
			signup: function signup() {
				var self = this;
				//serialize the form and get the values
				var signupData = _ember['default'].$('#signup').serializeArray();
				var arrLength = signupData.length;
				var options = {};
				//validate here, if all good proceed

				var form = _ember['default'].$('#signup');
				form.parsley().validate();

				//check if form is valid
				if (form.parsley().isValid()) {
					//set all the form data
					if (this.get("validPassword")) {
						for (var i = 0; i < arrLength; i++) {
							options[signupData[i].name] = signupData[i].value;
						}

						//sign up the user
						this.get('api').signup(options).then(function () {
							if (isProduction) {
								window.fbq('track', 'SignUp');
							}
							options.username = options.email;

							window.facebookConversion = true;
							window.ga('send', 'pageview', '/signup-success');
							self.get('session').authenticate('authenticator:custom', options).then(function () {
								self.get("routing").transitionTo("dashboard.info");
							});
						})['catch'](function (result) {
							self.set('errorMsg', result.error);
						});
					}
				}

				return false;
			},
			showPassword: function showPassword() {
				this.togglePassword();
			},
			setGoogle: function setGoogle() {
				localStorage.setItem("googleLogin", true);
				return true;
			},
			setFB: function setFB() {
				localStorage.setItem("facebookLogin", true);
				return true;
			},
			isValidHandler: function isValidHandler(isValid) {
				this.set("validPassword", isValid);
			}
		}
	});
});