define("vopay_app/helpers/date-format", ["exports", "ember"], function (exports, _ember) {
  exports.dateFormat = dateFormat;

  function dateFormat(params /*, hash*/) {
    var value = params[0];

    var amount = moment(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return amount.format("YYYY-MM-DD");
  }

  exports["default"] = _ember["default"].Helper.helper(dateFormat);
});