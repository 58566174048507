define('vopay_app/components/users-list', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
    exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
        api: _ember['default'].inject.service('api'),
        firebase: _ember['default'].inject.service('firebase'),
        keyword: "",
        validPassword: false,
        init: function init() {
            this._super.apply(this, arguments);
            this.filterTransactions();
        },
        didInsertElement: function didInsertElement() {
            _ember['default'].$('form').parsley({
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
        },
        refreshUserList: function refreshUserList() {
            var _this = this;

            this.get('api').getUserAccounts().then(function (data) {
                _this.set("users", data.Users);
                _this.set("keyword", "");
                _this.filterTransactions();
            }, function (error) {
                _this.errorMessage(error);
            });
        },
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        filterTransactions: function filterTransactions() {
            var keyword = this.get("keyword");
            var users = this.get("users");
            var filteredList = {};
            keyword = keyword.toLowerCase();
            keyword = keyword.replace(/\$/g, '');
            keyword = keyword.replace(/\,/g, '');
            if (users) {
                var keys = Object.keys(users);
                var next = -1;
                for (var i = 0; i < keys.length; i++) {
                    if (keyword === "" || users[i].Username.toLowerCase().replace(/\s|_/g, "").includes(keyword)) {
                        next = next + 1;
                        filteredList[next] = users[i];
                    }
                }
            }
            this.set("filteredList", filteredList);
        },
        validateForm: function validateForm(formId) {

            var form = _ember['default'].$(formId);

            form.parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset]',
                inputs: 'input, textarea, select, input[type=hidden], :hidden',
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            }).validate();

            return form.parsley().isValid();
        },

        actions: {
            RemoveUser: function RemoveUser() {
                var _this2 = this;

                var options = {
                    EmailAddress: this.get("username")
                };

                this.get("api").deleteUserAccount(options).then(function (data) {
                    _this2.refreshUserList();
                    _this2.set("isRemove", false);
                }, function (error) {
                    _this2.errorMessage(error);
                });
            },
            ShowRemoveUser: function ShowRemoveUser(username) {
                this.set("username", username);
                this.set("isRemove", true);
            },
            closeRemoveModal: function closeRemoveModal() {
                this.set("isRemove", false);
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            filterKeyword: function filterKeyword(keyword) {
                this.set("keyword", keyword);
                this.filterTransactions();
            },
            ShowAddUser: function ShowAddUser() {
                this.set("EmailAddUser", "");

                this.set("AddUser", true);
            },
            HideAddUser: function HideAddUser() {
                this.set("AddUser", false);
            },
            HideChangePassword: function HideChangePassword() {
                this.set("isChangePassword", false);
            },
            ShowChangePassword: function ShowChangePassword(username) {
                this.set("username", username);
                this.set("NewPassword", "");
                this.set("validPassword", false);
                this.set("isChangePassword", true);
            },
            isValidHandler: function isValidHandler(isValid) {
                this.set("validPassword", isValid);
            },
            ChangePassword: function ChangePassword() {
                var _this3 = this;

                if (this.validateForm("#ChangePassword") && this.get("validPassword")) {
                    var options = {
                        EmailAddress: this.get("username"),
                        NewPassword: this.get("NewPassword")
                    };
                    this.get("api").changeUserPassword(options).then(function (data) {
                        _this3.set("isChangePassword", false);
                        _this3.set("isChangePasswordSucess", true);
                    }, function (error) {
                        _this3.errorMessage(error);
                    });
                }
            },
            AddUser: function AddUser() {
                var _this4 = this;

                if (this.validateForm("#AddUser")) {
                    var options = {
                        EmailAddress: this.get("EmailAddUser")
                    };
                    this.get("api").createUserAccount(options).then(function (data) {
                        _this4.refreshUserList();

                        _this4.set("AddUser", false);
                    }, function (error) {
                        _this4.errorMessage(error);
                    });
                }
            },
            closeChangePasswordSucess: function closeChangePasswordSucess() {
                this.set("isChangePasswordSucess", false);
            }
        }
    });
});