define('vopay_app/components/products-services-edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		countryList: ["Australia", "Bulgaria", "China", "Denmark", "France", "Germany", "Honge Kong", "Hungary", "India", "Italy", "Japan", "New Zealand", "Norway", "Poland", "United Kingdom", "Romania", "Russia", "Singapore", "Spain", "South Africa", "Sweden", "Switzerland", "Thailand", "United Arab Emirates", "United States of America", "Other"],
		currencyList: ["AUD (Australian Dollar)", "BGN (Bulgarian Lev)", "CHF (Swiss Franc)", "CNY (Chinese Yuan)", "DKK (Danish Krone) ", "EUR (EURO)", "GBP (Pound Sterling ) ", "HKD (Hong Kong Dollar) ", "HUF (Hungarian Forint) ", "INR (Indian Rupee) ", "JPY (Japanese Yen) ", "NOK (Norwegian Krone)", "NZD (New Zealand Dollar)", "PLN (Polish Zloty) ", "RON (Romanian New Leu)", "RUB (Russian Rouble) ", "SEK (Swedish Krona) ", "SGD (Singapore Dollar)", "THB (Thai Baht)", "USD (United States Dollar)", "ZAR (South African Rand)", "Other"],
		currencyNumbers: ["1"],
		currencyCurrNum: 1,
		countryNumbers: ["1"],
		countryCurrNum: 1,
		didInsertElement: function didInsertElement() {
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		actions: {
			updateProduct: function updateProduct() {
				this.get("routing").transitionTo("dashboard.account.business-profile");
			},
			addCountry: function addCountry() {
				this.set("countryCurrNum", this.get("countryCurrNum") + 1);
				this.get('countryNumbers').pushObject(this.get("countryCurrNum"));
			},
			removeCountry: function removeCountry() {
				this.set('countryNumbers', this.get('countryNumbers').slice(0, -1));
				this.set("countryCurrNum", this.get("countryCurrNum") - 1);
			},
			addCurrency: function addCurrency() {
				this.set("currencyCurrNum", this.get("currencyCurrNum") + 1);
				this.get('currencyNumbers').pushObject(this.get("currencyCurrNum"));
			},
			removeCurrency: function removeCurrency() {
				this.set('currencyNumbers', this.get('currencyNumbers').slice(0, -1));
				this.set("currencyCurrNum", this.get("currencyCurrNum") - 1);
			},
			currencySelected: function currencySelected(value) {},
			countrySelected: function countrySelected(value) {}
		}
	});
});