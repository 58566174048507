define('vopay_app/helpers/institution-bank', ['exports', 'ember', 'vopay_app/constants'], function (exports, _ember, _vopay_appConstants) {
	exports.institutionBank = institutionBank;
	var address_components = {};

	function institutionBank(params /*, hash*/) {
		function pad(number, length) {

			var str = '' + number;
			while (str.length < length) {
				str = '0' + str;
			}

			return str;
		}
		var Banks = _vopay_appConstants['default'].canadaBanks.filter(function (f) {
			return pad(f.code, 3) === params[0];
		}).map(function (c) {
			return c.name;
		});
		if (Banks[0]) {
			return Banks[0];
		} else {
			return false;
		}
	}

	exports['default'] = _ember['default'].Helper.helper(institutionBank);
});