define('vopay_app/components/flinks-page', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend({
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		init: function init() {
			this._super.apply(this, arguments);
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		actions: {
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			deactivate: function deactivate() {
				var _this = this;

				var options = {};
				options.WebHookUrl = "";
				options.Disabled = true;
				var self = this;
				this.get("api").updateWebhook(options).then(function (data) {
					//re get webhook url

					_this.get("api").getWebhook().then(function (data2) {
						self.set("webhook", data2);
						self.set("WebHookURL", _this.get("webhook.WebHookURL"));
						self.set("isForm", false);
					}, function () {});
				}, function (error) {
					_this.errorMessage(error);
				});
			},
			update: function update() {
				var _this2 = this;

				var options = {};
				options.FlinksUrl = this.get("WebHookURL");
				var self = this;
				this.get("api").updateFlinks(options).then(function (data) {
					//re get webhook url
					self.set("isForm", false);
				}, function (error) {
					_this2.errorMessage(error);
				});
			},
			showForm: function showForm() {
				this.set("isForm", true);
			},
			closeForm: function closeForm() {
				this.set("isForm", false);
			}
		}
	});
});