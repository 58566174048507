define('vopay_app/components/accounting-panel', ['exports', 'ember', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConfigEnvironment) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

    exports['default'] = _ember['default'].Component.extend({
        isOpenStatements: true,
        selectedStatments: {},
        isoCountry: "CA",
        api: _ember['default'].inject.service('api'),
        SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
        didInsertElement: function didInsertElement() {
            _ember['default'].$('form').parsley({
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
        },
        validateForm: function validateForm(formId) {

            var form = _ember['default'].$(formId);

            form.parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset]',
                inputs: 'input, textarea, select, input[type=hidden], :hidden'
            }).validate();

            return form.parsley().isValid();
        },
        formObject: function formObject(formdata) {

            var arrLength = formdata.length;
            var formObj = {};

            for (var i = 0; i < arrLength; i++) {
                formObj[formdata[i].name] = formdata[i].value;
            }
            return formObj;
        },
        pad: function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        },
        showErrorMessage: function showErrorMessage(message) {
            this.set("isError", true);
            this.set("ErrorMessage", message);
        },
        checkIsAllowed: function checkIsAllowed(currency) {
            if (localStorage.getItem("PortalViewOnly") === "1") {
                this.set("isShowingModal", true);
                return false;
            } else if (this.get("isoCountry") == "CA" && localStorage.getItem("EFTCollectEnabled") === "0" && this.get("StatmentType") == "Invoices") {
                this.set("isShowingModal", true);
                return false;
            } else if (this.get("isoCountry") == "CA" && localStorage.getItem("EFTSendEnabled") === "0" && this.get("StatmentType") == "Bills") {
                this.set("isShowingModal", true);
                return false;
            } else if (this.get("isoCountry") === "CA" && currency == "USD" && this.get("StatmentType") == "Invoices" && localStorage.getItem("USDEFTCollectEnabled") === "0") {
                this.set("isShowingModal", true);
                return false;
            } else if (this.get("isoCountry") === "CA" && currency == "USD" && this.get("StatmentType") == "Bills" && localStorage.getItem("USDEFTSendEnabled") === "0") {
                this.set("isShowingModal", true);
                return false;
            } else {
                return true;
            }
        },
        checkBalance: function checkBalance(currency, balance) {
            var _this = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var options = {};
                options.Currency = currency;

                _this.get("api").getAccountBalance(options).then(function (data) {

                    if (data.AvailableFunds >= balance) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, function (error) {
                    reject(error);
                });
            });
        },
        hasFunds: function hasFunds(FundsObject) {
            var _this2 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var promises = [];
                var self = _this2;
                Object.entries(FundsObject).forEach(function (_ref) {
                    var _ref2 = _slicedToArray(_ref, 2);

                    var key = _ref2[0];
                    var value = _ref2[1];

                    promises.push(self.checkBalance(key, value.amount));
                });
                _ember['default'].RSVP.allSettled(promises).then(function (array) {
                    // array == [
                    //   { state: 'fulfilled', value: 1 },
                    //   { state: 'rejected', reason: Error },
                    //   { state: 'rejected', reason: Error }
                    // ]
                    // Note that for the second item, reason.message will be '2', and for the
                    // third item, reason.message will be '3'.

                    var hasFunds = true;
                    array.forEach(function (element) {

                        if (element.value == false) {
                            hasFunds = false;
                        }
                    });
                    resolve(hasFunds);
                }, function (error) {
                    reject(error);
                });
            });
        },
        actions: {
            closeModal: function closeModal() {
                this.set("isShowingModal", false);
                return false;
            },
            toggleStatementsTab: function toggleStatementsTab() {
                this.set("isOpenStatements", !this.get("isOpenStatements"));
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            },
            ReviewTransfers: function ReviewTransfers() {
                var _this3 = this;

                if (this.validateForm('#StatmentItems')) {
                    var statements;
                    var totalAmount;
                    var CurrencyAmounts;
                    var statementsObjectArray;
                    var isAllowed;
                    var self;

                    (function () {
                        var self = _this3;
                        statements = [];
                        totalAmount = 0;
                        CurrencyAmounts = {};

                        $(".activatedData").each(function () {
                            var item = self.formObject($(this).find(":input").serializeArray());
                            var statement = Object.assign({}, Object.values(self.get("OpenStatements")).find(function (element) {
                                return element.id === item.id;
                            }));
                            if (!statement.Customer) {
                                statement.Customer = {};
                            } else {
                                delete statement.Customer;
                                statement.Customer = {};
                            }
                            if (!statement.Customer.BankAccount) {
                                statement.Customer.BankAccount = {};
                            }
                            statement.Customer.BankAccount.AccountNumber = item.AccountNumber;
                            statement.Customer.BankAccount.BranchTransitNumber = self.pad(item.BranchTransitNumber, 5, 0);
                            statement.Customer.BankAccount.FinancialInstitutionNumber = self.pad(item.FinancialInstitutionNumber, 3, 0);
                            if (!CurrencyAmounts[statement.currencyRef.id]) {
                                CurrencyAmounts[statement.currencyRef.id] = {
                                    amount: 0
                                };
                            }
                            CurrencyAmounts[statement.currencyRef.id]["amount"] = CurrencyAmounts[statement.currencyRef.id]["amount"] + parseFloat(statement.amountDue);
                            totalAmount = totalAmount + statement.amountDue;
                            statements.push(statement);
                        });

                        _this3.set("totalAmount", totalAmount);
                        _this3.set("numberOfTransactions", statements.length);
                        statementsObjectArray = _extends({}, statements);

                        _this3.set("selectedStatments", statementsObjectArray);
                        isAllowed = true;
                        self = _this3;

                        Object.keys(CurrencyAmounts).forEach(function (key) {
                            if (isAllowed) {
                                isAllowed = self.checkIsAllowed(key);
                            }
                        });

                        if (isAllowed) {
                            if (_this3.get("StatmentType") === "Bills") {
                                _this3.hasFunds(CurrencyAmounts).then(function (data) {
                                    if (data) {
                                        $("#ReviewStatements").show();
                                        $("#Statments").hide();
                                    } else {
                                        _this3.showErrorMessage("Insufficient Funds");
                                    }
                                }, function (error) {
                                    _this3.showErrorMessage(error);
                                });
                            } else {
                                $("#ReviewStatements").show();
                                $("#Statments").hide();
                            }
                        }
                    })();
                }
            },
            backToStatements: function backToStatements() {
                $("#ReviewStatements").hide();
                $("#Statments").show();
            },
            SendStatements: function SendStatements() {
                var _this4 = this;

                var Statments = this.get("selectedStatments");
                var Accounts = this.get("Accounts.Accounts");
                if (this.get("StatmentType") == "Bills") {
                    var options = {
                        Bills: JSON.stringify(Statments),
                        ServiceName: Accounts[0].ServiceName,
                        BusinessName: Accounts[0].BusinessName
                    };
                    this.get("api").SubmitRailzBills(options).then(function (data) {

                        _this4.set("isOpenStatements", false);
                        $("#ReviewStatements").hide();
                        $("#Statments").show();
                    }, function (error) {
                        _this4.showErrorMessage(error);
                    });
                } else {
                    var options = {
                        Invoices: JSON.stringify(Statments),
                        ServiceName: Accounts[0].ServiceName,
                        BusinessName: Accounts[0].BusinessName
                    };
                    this.get("api").SubmitRailzInvoices(options).then(function (data) {

                        _this4.set("isOpenStatements", false);
                        $("#ReviewStatements").hide();
                        $("#Statments").show();
                    }, function (error) {
                        _this4.showErrorMessage(error);
                    });
                }
            }
        }
    });
});