define('vopay_app/components/edit-beneficiary', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment) {
	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		isEdit: false,
		userName: null,
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		accountOptions: ["Deposit", "Withdraw"],
		accountTypeOptions: ["Individual", "Business"],
		defaultAccountType: "Individual",
		isBusiness: false,
		isDeposit: false,
		country: "Canada",
		currency: "CAD",
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		countryID: 2,
		stageNumber: 1,
		stageName: "Account Holder Details",
		defaultType: "Deposit",
		amount: "10,000.00",
		max: "0",
		isNew: false,
		beneficiaries: null,
		isCredit: false,
		currencies2: {

			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},

			EUR: {
				name: 'Euro',
				iso3: 'EUR'
			},

			GBP: {
				name: 'Pound Sterling',
				iso3: 'GBP'
			},

			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		watchPhoneNumber: _ember['default'].observer('data.PhoneNumber', function () {

			this.set('data.PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('data.PhoneNumber')));
		}),
		watchDualPhoneNumber: _ember['default'].observer('data.DualPhoneNumber', function () {

			this.set('data.DualPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('data.DualPhoneNumber')));
		}),
		watchSignatoryPhoneNumber: _ember['default'].observer('data.SignatoryPhoneNumber', function () {

			this.set('data.SignatoryPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('data.SignatoryPhoneNumber')));
		}),
		watchPhoneNumberRequest: _ember['default'].observer('data.PhoneNumberRequest', function () {

			this.set('data.PhoneNumberRequest', this.get('entryUtil').onlyNumbers(this.get('data.PhoneNumberRequest')));
		}),
		watchFirstName: _ember['default'].observer('data.FirstName', function () {
			this.set('data.FirstName', this.get('entryUtil').onlyNameCharacters(this.get('data.FirstName')));
		}),

		watchLastName: _ember['default'].observer('data.LastName', function () {
			this.set('data.LastName', this.get('entryUtil').onlyNameCharacters(this.get('data.LastName')));
		}),
		watchCompanyName: _ember['default'].observer('data.CompanyName', function () {
			this.set('data.CompanyName', this.get('entryUtil').onlyNameCharacters(this.get('data.CompanyName')));
		}),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			this.set("DualPhoneNumber", this.get("data.DualPhoneNumber"));
			if (this.get("data.CompanyName")) {
				this.set("isBusiness", true);
			} else {
				this.set("isBusiness", false);
			}
			if (this.get("data.Type") === "Credit Card") {
				this.set("isCredit", true);
			} else {
				this.set("isCredit", false);
			}
			if (this.get("data.Type") === "Interac E-Transfer") {
				this.set("isInteracEtransfer", true);
			} else {
				this.set("isInteracEtransfer", false);
			}
			if (this.get("data.Type") === "Money Request") {
				this.set("isRequest", true);
			} else {
				this.set("isRequest", false);
			}
			if (this.get("data.OuthType") != null || this.get("data.Token") != null) {
				this.set("isFlinksFlow", true);
			} else {
				this.set("isFlinksFlow", false);
			}
			this.set("currency2", this.get("data.Currency"));
			this.set("isoCountry", this.get("data.Country"));

			this.get('api').getCountries().then(function (data) {
				_this.set('countries', data);
			});

			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);

			// this.set("isDeposit",sessionStorage.getItem("isDeposit"));
			// if(this.get("isDeposit") === "true"){
			// 	this.set("defaultType", "Deposit");
			// }
			// else{
			// 	this.set("defaultType", "Withdraw");
			// }
		},
		SetIq11URL: function SetIq11URL() {
			var _this2 = this;

			var options = null;
			var AccountSelectionMethod = "any";
			if (this.get("isDeposit") !== "true") {
				AccountSelectionMethod = "manual";
			}

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),

					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),

					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),

					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this2.set("FlinksURL", data.EmbedURL);
			});
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			if (this.get("data.Type") === "Interac E-Transfer") {

				_ember['default'].$("#etransfer").show();
			} else if (this.get("data.Type") === "Money Request") {
				_ember['default'].$("#request").show();
			} else {

				_ember['default'].$("#benifeciaryInfo").show();
			}
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$(".newPaymentMethod__institution").slideDown();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});

			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});

			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});
			if (this.get("data.Type") === "Bank Account") {
				this.initialiseAutoAddress();
			}

			_ember['default'].$("#beneficiary-address").focusout(function () {
				setTimeout(function () {
					var address = _ember['default'].$("#beneficiary-address").val();
					var city = _ember['default'].$("#beneficiary-city").val();
					var state = _ember['default'].$("#beneficiary-state").val();
					var postcode = _ember['default'].$("#beneficiary-postcode").val();
					if (address) {
						_ember['default'].$("#beneficiary-address").parsley().reset();
					}
					if (city) {
						_ember['default'].$("#beneficiary-city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#beneficiary-state").parsley().reset();
					}
					if (postcode) {
						_ember['default'].$("#beneficiary-postcode").parsley().reset();
					}
				}, 500);
			});
			_ember['default'].$("#beneficiary-address").keyup(function () {
				if (!_ember['default'].$("#beneficiary-address").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-city").keyup(function () {
				if (!_ember['default'].$("#beneficiary-city").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-postcode").keyup(function () {
				if (!_ember['default'].$("#beneficiary-postcode").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});

			var self = this;
			_ember['default'].$("#beneficiary-address").on('input', function () {

				_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
			});
			_ember['default'].$("#beneficiary-city").on('input', function () {

				_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
			});
			_ember['default'].$("#beneficiary-postcode").on('input', function () {

				_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
			});
			window.addEventListener('message', function (e) {
				if (e.data.step == "ACCOUNT_SELECTED") {
					localStorage.setItem("accountId", e.data.accountId);
				}
				if (e.data.step == "REDIRECT") {
					localStorage.setItem("loginId", e.data.loginId);
					localStorage.setItem("institution", e.data.institution);
					//location.href = e.data.url;
				}
				if (e.data.Step == "LINK") {
					localStorage.setItem("institution", e.data.Institution);
					localStorage.setItem("AccountNumber", e.data.AccountNumber);
					localStorage.setItem("BankId", e.data.BankId);
					localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);
					localStorage.setItem("FlinksToken", e.data.Token);
				}
			});
		},
		MoveToOverview: function MoveToOverview(accountId, institution, loginId) {
			var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
			this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
			this.set('paymentToAdd.InstitutionName', institution);
			if (this.get("country") === "Canada") {
				this.set("paymentToAdd.Country", "CA");
			} else {
				this.set("paymentToAdd.Country", this.get("isoCountry"));
			}
			if (this.get("paymentToAdd.Iban")) {
				this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
			}

			this.set("paymentToAdd.Currency", this.get("currency2"));
			this.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
			var flinksdata = JSON.stringify(this.get("paymentToAdd"));
			localStorage.setItem("FlinksData", flinksdata);
			var self = this;
			var flinksInterval = setInterval(function () {

				if (localStorage.getItem("FlinksToken") != null) {
					self.set("paymentToAdd", JSON.parse(localStorage.getItem("FlinksData")));
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").hide();
					self.set("isOverview", true);
					_ember['default'].$("#Overview").show();
					_ember['default'].$("#etransfer").hide();
					self.set("isFlinks2", true);
					self.set("isFlinks", false);
					self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
					if (localStorage.getItem("AccountNumber")) {
						self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
					} else {
						self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
					}
					if (localStorage.getItem("BankId")) {
						self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
					}
					localStorage.removeItem("BankId");
					localStorage.removeItem("InstitutionNumber");
					self.set("paymentToAdd.BranchTransitNumber", "");
					self.set("paymentToAdd.AccountNumber", "");
					localStorage.removeItem("AccountNumber");
					self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
					localStorage.removeItem("FlinksData");
					localStorage.removeItem("TokenLocation");
					localStorage.removeItem("FlinksToken");
					localStorage.removeItem("MaskedAccount");
					localStorage.removeItem("FullName");
					clearInterval(flinksInterval);
				}
			}, 100);
		},
		initialiseAutoAddress: function initialiseAutoAddress() {
			//let countries = Constants.countries;
			/** 
   let fields = [{
   	element: "beneficiary-address",
   	field: "Line1"
   }, {
   	element: "beneficiary-state",
   	field: "ProvinceCode",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-city",
   	field: "City",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-postcode",
   	field: "PostalCode",
   	mode: window.pca.fieldMode.POPULATE
   },
   { element: "Country", field: "CountryName", mode: window.pca.fieldMode.COUNTRY }],
   options = {
   	key: "fp61-gw79-xc88-xy19"
   },
     control = new window.pca.Address(fields, options); // jshint ignore:line
   **/
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		filterList: function filterList(currency, country) {
			var filteredBeneficiaries = {};
			var data = this.get("beneficiaries");
			for (var key in data) {
				if (data[key]["Currency"] === currency && data[key]["Country"] === country) {
					filteredBeneficiaries[key] = data[key];
				}
			}
			this.set("beneficiaries2", filteredBeneficiaries);
		},
		addPaymentMethod: function addPaymentMethod(formObj) {
			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		updateInstitutionName: function updateInstitutionName() {

			var finNumber = this.get('data.FinancialInstitutionNumber');
			this.set('data.FinancialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

			var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
				return parseInt(b.code) === parseInt(finNumber);
			});

			//let eClass = Ember.$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

			// if(eClass) {
			// 	Ember.$(".form__bankFlagIcon").removeClass(`${eClass[0]}`);
			// }

			if (bank[0]) {

				_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
				this.set('institutionName', bank[0].name);
				//Ember.$("#institutionName").parsley().reset();
				//Ember.$("#financialInstitutionNumber").parsley().reset();
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			} else {
				_ember['default'].$("#paymentForm").parsley().destroy();
				_ember['default'].$('form').parsley({
					successClass: " ",
					errorClass: "form__input--error",
					errorsWrapper: "<p></p>",
					errorTemplate: "<span class='form__message'></span>"
				});
				_ember['default'].$(".newPaymentMethod__institution").slideUp();
				_ember['default'].$(".form__bankFlagIcon").addClass('b0');
				this.set('financialInstitutionNumber', '');
				this.set('institutionName', '');
				_ember['default'].$("#financialInstitutionNumber").val('');
				if (!finNumber) {
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
				} else {
					_ember['default'].$("#financialInstitutionNumber").parsley().validate();
				}
				_ember['default'].$("#financialInstitutionNumber").focus();
			}
		},
		actions: {
			moveToBank: function moveToBank() {
				if (this.validateForm('#paymentForm')) {
					this.set("isBank", true);
					this.set("isOverview", false);
					_ember['default'].$("#Overview").hide();
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").show();
					this.set("stageNumber", 2);
					this.set("stageName", "Bank Details");
					if (this.get("data.FinancialInstitutionNumber")) {
						this.updateInstitutionName();
					}
				}
				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");
			},
			moveToPayment: function moveToPayment() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#paymentInfo").show();
				_ember['default'].$("#bankInfo").hide();
				this.set("isBank", false);
				this.set("stageNumber", 1);
				this.set("stageName", "Payment Details");
				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");
			},
			moveToBenifeciary: function moveToBenifeciary() {
				_ember['default'].$("#beneficiary-address").focusout(function () {

					setTimeout(function () {
						var address = _ember['default'].$("#beneficiary-address").val();
						var city = _ember['default'].$("#beneficiary-city").val();
						var state = _ember['default'].$("#beneficiary-state").val();
						var postcode = _ember['default'].$("#beneficiary-postcode").val();

						if (address) {
							_ember['default'].$("#beneficiary-address").parsley().reset();
						}
						if (city) {
							_ember['default'].$("#beneficiary-city").parsley().reset();
						}
						if (state) {
							_ember['default'].$("#beneficiary-state").parsley().reset();
						}
						if (postcode) {
							_ember['default'].$("#beneficiary-postcode").parsley().reset();
						}
					}, 500);
				});
				_ember['default'].$("#beneficiary-address").keyup(function () {
					if (!_ember['default'].$("#beneficiary-address").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-city").keyup(function () {
					if (!_ember['default'].$("#beneficiary-city").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				_ember['default'].$("#beneficiary-postcode").keyup(function () {
					if (!_ember['default'].$("#beneficiary-postcode").val()) {
						_ember['default'].$("#beneficiary-city").val("");
						_ember['default'].$("#beneficiary-postcode").val("");
						_ember['default'].$("#beneficiary-address").val("");
						_ember['default'].$("#beneficiary-state").val("");
					}
				});
				var self = this;
				_ember['default'].$("#beneficiary-address").on('input', function () {

					_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
				});
				_ember['default'].$("#beneficiary-city").on('input', function () {

					_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
				});
				_ember['default'].$("#beneficiary-postcode").on('input', function () {

					_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
				});
				this.set("isOverview", false);
				_ember['default'].$("#Overview").hide();
				_ember['default'].$("#paymentInfo").hide();
				_ember['default'].$("#benifeciaryInfo").show();
				_ember['default'].$("#bankInfo").hide();
				this.set("isFlinks2", false);
				this.set("isBank", false);
				this.set("stageNumber", 1);
				this.set("isBackBeneficiary", false);
				this.set("stageName", "Account Holder Details");
				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");
			},
			handleAccountOptions: function handleAccountOptions(value) {

				if (value === "Withdraw") {
					this.set("isDeposit", "false");
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
					this.set('signatureNumDel', 1);
				} else if (value === "Both") {
					this.set("isDeposit", true);
					this.set('signatureNumDel', 0);
					while (this.get("signatureCurrNum") > 0) {
						this.set('signatureNumbers', this.get('signatureNumbers').slice(0, -1));
						this.set("signatureCurrNum", this.get("signatureCurrNum") - 1);
					}
					this.set('signatureNumDel', 1);
					this.set("signatureCurrNum", this.get("signatureCurrNum") + 1);
					this.get('signatureNumbers').pushObject(this.get("signatureCurrNum"));
				} else {
					this.set("isDeposit", "true");
				}
			},
			showFlinks: function showFlinks() {
				this.SetIq11URL();
				this.set("isFlinks", true);
				var self = this;
				window.flinksInterval = setInterval(function () {
					if (localStorage.getItem("FlinksToken") != null) {
						var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
						self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
						if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
							self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
							self.set("paymentToAdd.FinancialInstitutionNumber", "");
						} else {
							var finNumber = localStorage.getItem("InstitutionNumber");
							var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
								return parseInt(b.code) === parseInt(finNumber);
							});

							if (bank[0]) {
								self.set('paymentToAdd.InstitutionName', bank[0].name);
							} else {
								self.set('paymentToAdd.InstitutionName', "");
							}
							self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
						}
						if (self.get("country") === "Canada") {
							self.set("paymentToAdd.Country", "CA");
						} else {
							self.set("paymentToAdd.Country", self.get("isoCountry"));
						}
						if (self.get("paymentToAdd.Iban")) {
							self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
						}

						self.set("paymentToAdd.Currency", self.get("currency2"));
						self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#benifeciaryInfo").hide();
						_ember['default'].$("#bankInfo").hide();
						self.set("isOverview", true);
						_ember['default'].$("#Overview").show();
						_ember['default'].$("#etransfer").hide();
						self.set("isFlinks2", true);
						self.set("isFlinks", false);

						self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
						if (localStorage.getItem("AccountNumber")) {
							self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
						} else {
							self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
						}
						if (localStorage.getItem("BankId")) {
							self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
						}
						localStorage.removeItem("BankId");
						localStorage.removeItem("InstitutionNumber");
						self.set("paymentToAdd.BranchTransitNumber", "");
						self.set("paymentToAdd.AccountNumber", "");
						localStorage.removeItem("AccountNumber");
						self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
						localStorage.removeItem("TokenLocation");
						localStorage.removeItem("FlinksToken");
						localStorage.removeItem("MaskedAccount");
						localStorage.removeItem("FullName");
						localStorage.removeItem("accountId");
						localStorage.removeItem("loginId");
						localStorage.removeItem("institution");
						clearInterval(window.flinksInterval);
					}
				}, 100);
			},
			closeModalFlinks: function closeModalFlinks() {
				this.set("isFlinks", false);
				clearInterval(window.flinksInterval);
			},
			handleAccountType: function handleAccountType(value) {
				if (value === "Business") {
					this.set("isBusiness", true);
				} else {
					this.set("isBusiness", false);
				}
			},
			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('data.FinancialInstitutionNumber');
				this.set('data.FinancialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					_ember['default'].$(".newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			moveToEtransfer: function moveToEtransfer() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#paymentInfo").hide();
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#etransfer").show();
				this.set("stageNumber", 1);
				this.set("stageName", "Account Holder Details");
			},
			moveToRequest: function moveToRequest() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#paymentInfo").hide();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#etransfer").hide();
				_ember['default'].$("#request").show();
				this.set("stageNumber", 1);
				this.set("stageName", "Account Holder Details");
			},
			moveToOverview: function moveToOverview() {

				if (this.validateForm('#paymentForm')) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));

					if (this.get("paymentToAdd.Iban")) {
						this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
					}
					this.set("paymentToAdd.Country", this.get("isoCountry"));
					this.set("paymentToAdd.Currency", this.get("currency2"));
					//var newBeneficiary= this.get('store').createRecord('beneficiary', this.get("paymentToAdd"));
					//newBeneficiary.save();

					_ember['default'].$("#etransfer").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").hide();
					_ember['default'].$("#request").hide();
					_ember['default'].$("#Overview").show();
					this.set("isOverview", true);
				}
			},
			moveToOverview2: function moveToOverview2() {

				if (this.validateForm('#paymentForm')) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));

					if (this.get("data.AccountNumber")) {

						this.set("paymentToAdd.AccountNumber", this.get("data.AccountNumber"));
						this.set("paymentToAdd.BranchTransitNumber", this.get("data.BranchTransitNumber"));
						this.set("paymentToAdd.FinancialInstitutionNumber", this.get("data.FinancialInstitutionNumber"));
					}

					if (this.get("data.FlinksAccountID")) {

						this.set("paymentToAdd.FlinksAccountID", this.get("data.FlinksAccountID"));
						this.set("paymentToAdd.FlinksLoginID", this.get("data.FlinksLoginID"));
						this.set("paymentToAdd.InstitutionName", this.get("data.InstitutionName"));
						this.set("paymentToAdd.OuthType", this.get("data.OuthType"));
						this.set("isFlinks2", true);
					}
					if (this.get("data.Token")) {

						this.set("paymentToAdd.InstitutionName", this.get("data.InstitutionName"));
						this.set("paymentToAdd.MaskedAccount", this.get("data.MaskedAccount"));
						this.set("paymentToAdd.Token", this.get("data.Token"));
						this.set("paymentToAdd.OuthType", this.get("data.OuthType"));
						this.set("isFlinks2", true);
					}
					if (this.get("data.BankId")) {
						this.set("paymentToAdd.BankId", this.get("data.BankId"));
					}

					this.set("paymentToAdd.Country", this.get("isoCountry"));
					this.set("paymentToAdd.Currency", this.get("currency2"));
					//var newBeneficiary= this.get('store').createRecord('beneficiary', this.get("paymentToAdd"));
					//newBeneficiary.save();

					this.set("isBackBeneficiary", true);

					_ember['default'].$("#etransfer").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").hide();
					_ember['default'].$("#request").hide();
					_ember['default'].$("#Overview").show();
					this.set("isOverview", true);
				}
			},
			create: function create() {
				// Add payment
				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");
				if (this.get("data.timestamp")) {
					this.set("paymentToAdd.timestamp", this.get("data.timestamp"));
				}
				if (this.get("isDeposit") === "true") {
					_ember['default'].$('#paymentForm button').removeAttr('disabled');
					var data = this.get("paymentToAdd");
					if (this.get("isCredit")) {
						data.Type = "Credit Card";
					} else if (this.get("isInteracEtransfer")) {
						data.Type = "Interac E-Transfer";
					} else {
						data.Type = "Bank Account";
					}
					this.get("firebase").updateRecordUser("accounts", this.get("key"), data);
					this.get("routing").transitionTo("dashboard.accounts");
				} else if (this.get("isFlinks2")) {
					var data = this.get("paymentToAdd");
					if (this.get("isCredit")) {
						data.Type = "Credit Card";
					} else if (this.get("isInteracEtransfer")) {
						data.Type = "Interac E-Transfer";
					} else if (this.get("isRequest")) {
						data.Type = "Money Request";
					} else {
						data.Type = "Bank Account";
					}
					data.OuthType = "iQ11";

					if (this.get("isDeposit") === "true") {
						this.get("firebase").updateRecordUser("linkedAccount", this.get("key"), data);
						this.get("routing").transitionTo("dashboard.accounts");
					} else {
						this.get("firebase").updateRecordUser("beneficiary", this.get("key"), data);
						this.get("routing").transitionTo("dashboard.beneficiaries");
					}
				} else {
					_ember['default'].$('#paymentForm button').removeAttr('disabled');
					var data = this.get("paymentToAdd");
					if (this.get("isCredit")) {
						data.Type = "Credit Card";
					} else if (this.get("isInteracEtransfer")) {
						data.Type = "Interac E-Transfer";
					} else if (this.get("isRequest")) {
						data.Type = "Money Request";
					} else {
						data.Type = "Bank Account";
					}

					this.get("firebase").updateRecordUser("beneficiary", this.get("key"), data);
					this.get("routing").transitionTo("dashboard.beneficiaries");
				}
			}

		}
	});
});