define('vopay_app/components/currency-converter', ['exports', 'ember', 'vopay_app/utils/pooler'], function (exports, _ember, _vopay_appUtilsPooler) {
  exports['default'] = _ember['default'].Component.extend({
    defaultAmount: 300,
    defaultSenderCountry: 'canada',
    defaultRecipientCountry: 'germany',
    senderCurrency: null,
    recipientCurrency: null,
    lastCurrency: null,
    rates: null,
    errot: null,
    //api: Ember.inject.service('api'),
    poolerUtil: _vopay_appUtilsPooler['default'].create(),
    watchExchangeRate: _ember['default'].observer('exchangeRate', function () {}),
    watchSenderAmount: _ember['default'].observer('senderAmount', function () {

      if (this.get('senderAmount') > this.get("limit")) {
        this.set('senderAmount', this.get("limit"));
      }
      if (!this.get('senderAmount')) {
        this.set('totalToPay', "N/A");
        this.set('recipientAmount', "N/A");
        this.set('fee', "N/A");
      } else {}
    }),
    watchSameAmount: _ember['default'].observer('sameamount', function () {
      var amount = this.get("senderAmount");
      this.sendAction('senderAmountChanged', amount);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$("#sendingInput .currencyInput input").focus();
    },

    init: function init() {
      this._super.apply(this, arguments);
      //if no amount is provided, set the default one
      if (!this.get('senderAmount')) {
        this.set('senderAmount', this.get('defaultAmount'));
      } else {
        this.set('defaultAmount', this.get('senderAmount'));
      }

      //if no sender country is provided, set the default one
      if (!this.get('senderCountry')) {
        this.set('senderCountry', this.get('defaultSenderCountry'));
      }

      //if no recipient country is provided, set the default one
      if (!this.get('recipient')) {
        this.set('recipient', this.get('defaultRecipientCountry'));
      }

      //set currencies based on country
      var senderCurrency = this.getCurrency(this.get('defaultSenderCountry'));
      var recipientCurrency = this.getCurrency(this.get('recipient'));

      this.set('senderCurrency', senderCurrency);
      this.set('recipientCurrency', recipientCurrency);
      this.sendAction('senderAmountChanged', this.get("defaultAmount"));
    },

    /***
     * getCurrency
     *
     * Return currency by country
     */
    getCurrency: function getCurrency(country) {
      var arrCurrency = this.get('countries').filter(function (f) {
        return f.Country.toUpperCase() === country.toUpperCase();
      }).map(function (c) {
        return c.DefaultCurrency;
      });
      return arrCurrency[0];
    },
    getCountryID: function getCountryID(country) {
      var arrCurrency = this.get('countries').filter(function (f) {
        return f.Country.toUpperCase() === country.toUpperCase();
      }).map(function (c) {
        return c.CountryID;
      });
      return arrCurrency[0];
    },

    /***
     * updateRecipientAmount
     *
     * Update the recipient amount based on the sender amount
     */
    updateRecipientAmount: function updateRecipientAmount() {},

    actions: {
      /***
       * senderAmountChanged
       *
       * Sender amount has been updated by the user
       */
      senderAmountChanged: function senderAmountChanged(amount) {

        this.sendAction('senderAmountChanged', amount);
      },

      /***
       * recipientCurrencyChanged
       * @param country (String) - The country selected
       *
       * Country updated
       */
      recipientCurrencyChanged: function recipientCurrencyChanged(country) {
        var currency = this.getCurrency(country);
        var countryID = this.getCountryID(country);
        var amount = this.get('senderAmount');

        this.set('recipientCurrency', currency);
        this.sendAction('recipientCurrencyChanged', country, currency, amount, countryID);
      },
      recipientAmountChanged: function recipientAmountChanged(amount) {
        this.sendAction('recipientAmountChanged', amount);
      }

    }

  });
});
/**
 * Initalising properties:
 *
 * amount: starting amount (optional)
 * recipient: recipient country (optional)
 * recipientCurrencyChanged (callback): fired when the recipient country is changed
 *
 */