define('vopay_app/controllers/reset', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),
		api: _ember['default'].inject.service('api'),
		routing: _ember['default'].inject.service('-routing'),
		queryParams: ['PasswordResetToken'],
		PasswordResetToken: null,
		passwordType: "password",
		passwordState: "Show",
		reset: false,
		errorMsg: null,
		validPassword: false,
		togglePassword: function togglePassword() {
			if (this.get("passwordState") === "Show") {
				this.set("passwordState", "Hide");
				this.set("passwordType", "text");
			} else {
				this.set("passwordState", "Show");
				this.set("passwordType", "password");
			}
		},
		actions: {
			showPassword: function showPassword() {
				this.togglePassword();
			},
			resetPassword: function resetPassword() {
				_ember['default'].$('form').parsley({
					successClass: " ",
					errorClass: "form__input--error",
					errorsWrapper: "<p></p>",
					errorTemplate: "<span class='form__message'></span>"
				});
				var form = _ember['default'].$('#resetForm');
				form.parsley().validate();

				var self = this;
				var options = {};
				if (form.parsley().isValid()) {
					if (this.get("validPassword")) {
						options.newPassword = _ember['default'].$("input[name=password]").val();
						options.passwordResetToken = this.get("PasswordResetToken");
						this.get('api').resetPassword(options).then(function () {

							self.set("reset", true);
						})['catch'](function (data) {

							self.set('errorMsg', data);
						});
					}
				}
			},
			isValidHandler: function isValidHandler(isValid) {
				this.set("validPassword", isValid);
			}
		}
	});
});