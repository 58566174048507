define("vopay_app/components/delivery-input", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    deliveryMethod: "Bank Deposit",
    deliverySelectClass: "bankDeposit",
    tagName: '',
    country: null,
    watchCountry: _ember["default"].observer('country', function () {
      this.set("deliveryMethod", null);
      this.set("deliverySelectClass", "");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var item = this.get("deliveryMethod");
      var styleClass = item;
      // styleClass = styleClass.replace(/\s/g, '').replace(/-/g, '');
      // styleClass = styleClass.toLowerCase();
      this.set("deliverySelectClass", styleClass);
      if (this.get("noAutoSelect")) {
        this.set("deliveryMethod", null);
        this.set("deliverySelectClass", "");
      }
    },

    actions: {
      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      deliveryChanged: function deliveryChanged(item) {
        _ember["default"].$("#delivery").parsley().reset();
        this.set('deliveryMethod', item);
        var styleClass = item;
        // styleClass = styleClass.replace(/\s/g, '').replace(/-/g, '');
        // styleClass = styleClass.toLowerCase();
        this.set('deliverySelectClass', styleClass);
        this.sendAction('deliveryChanged', item);
      },
      deliverySelChanged: function deliverySelChanged(item) {
        _ember["default"].$("#delivery").parsley().reset();
        this.set('deliveryMethod', item);
        var styleClass = item;
        // styleClass = styleClass.replace(/\s/g, '').replace(/-/g, '');
        // styleClass = styleClass.toLowerCase();
        this.set('deliverySelectClass', styleClass);
        this.sendAction('deliveryChanged', item);
      }
    }
  });
});
/***
* delevry dropdown
* @params availableDeliveryMethods (Object)
* shows correct dropdown for a delevery method
***/