define('vopay_app/components/submit-ticket-page', ['exports', 'ember', 'vopay_app/mixins/error-handler', 'vopay_app/utils/base64encoder'], function (exports, _ember, _vopay_appMixinsErrorHandler, _vopay_appUtilsBase64encoder) {
    exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
        api: _ember['default'].inject.service('api'),
        ticketDefaultCategory: 'General Inquiry',
        ticketDefaultSubCategory: 'General Inquiry',

        addTicketMethod: function addTicketMethod(formObj) {
            var arrLength = formObj.length;
            var ticket = {};

            for (var i = 0; i < arrLength; i++) {
                ticket[formObj[i].name] = formObj[i].value;
            }

            return ticket;
        },

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            _ember['default'].$("#ticketForm").parsley().destroy();
            _ember['default'].$('form').parsley({
                successClass: " ",
                errorClass: "form__input--error",
                errorsWrapper: "<p></p>",
                errorTemplate: "<span class='form__message'></span>"
            });
        },

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('api').getTicketCategories().then(function (data) {
                _this.set('TicketCategories', data.SupportTicketCategory);
                _this.set('TicketSubCategories', data.SupportTicketSubCategory);
                _this.set('TicketCategoryLabels', Object.values(data.SupportTicketCategory).map(function (obj) {
                    return obj.Category;
                }));
            });
        },

        validateForm: function validateForm(formId) {

            var form = _ember['default'].$(formId);

            form.parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset]',
                inputs: 'input, textarea, select, input[type=hidden], :hidden'
            }).validate();

            return form.parsley().isValid();
        },
        submit: function submit(ticketToSend) {
            var _this2 = this;

            this.get("api").submitTicket(ticketToSend).then(function () {
                $("#email").show();
                $("#ticketForm").hide();
            }, function (err) {
                _this2.errorMessage(err);
            });
        },
        errorMessage: function errorMessage(Message) {
            this.set("ErrorMessage", Message);
            this.set("isError", true);
        },
        actions: {
            SubmitTicketForm: function SubmitTicketForm() {
                var _this3 = this;

                if (this.validateForm("#ticketForm")) {
                    (function () {
                        var ticketFormArr = _ember['default'].$('#ticketForm').serializeArray();
                        var hasAttachment = $("#UploadAttachment")[0].files[0] != undefined;
                        var ticketSubject = ticketFormArr.find(function (ticketObj) {
                            return ticketObj.name === 'TicketSubject';
                        });
                        var formObject = _this3.addTicketMethod(ticketFormArr);

                        if (ticketSubject && formObject.Category) {
                            ticketSubject.value = formObject.Category + ' - ' + ticketSubject.value;
                        }

                        if (hasAttachment) {
                            (function () {
                                var self = _this3;
                                (0, _vopay_appUtilsBase64encoder['default'])($("#UploadAttachment")[0]).then(function (dataFile) {
                                    ticketFormArr.push({ name: 'TicketAttachment', value: dataFile });
                                    self.set('ticketToSend', self.addTicketMethod(ticketFormArr));
                                    self.submit(self.get("ticketToSend"));
                                });
                            })();
                        } else {
                            _this3.set('ticketToSend', _this3.addTicketMethod(ticketFormArr));
                            _this3.submit(_this3.get("ticketToSend"));
                        }
                    })();
                }
            },
            categorySelected: function categorySelected(value) {
                var _this4 = this;

                var ticketCategory = Object.keys(this.get('TicketCategories')).map(function (k) {
                    return _this4.get('TicketCategories')[k];
                }).find(function (item) {
                    return item.Category === value;
                });
                var ticketSubCategories = Object.keys(this.get('TicketSubCategories')).map(function (k) {
                    return _this4.get('TicketSubCategories')[k];
                }).filter(function (el) {
                    return el.SupportTicketCatagories_ID === ticketCategory.ID;
                });
                var ticketSubCategoriesLabel = Object.values(ticketSubCategories).map(function (obj) {
                    return obj.SubCategory;
                });

                this.set('ticketSubCategoriesLabel', ticketSubCategoriesLabel);
                this.set('ticketDefaultSubCategory', ticketSubCategoriesLabel[0]);

                if (value == "Transaction issue") {
                    this.set("isTransactionInquiry", true);
                } else {
                    this.set("isTransactionInquiry", false);
                }
                _ember['default'].$("#TicketCategoryLabels").val(value);
                _ember['default'].$("#TicketCategoryLabels").parsley().validate();
            },
            subCategorySelected: function subCategorySelected(value) {
                _ember['default'].$("#TicketSubCategoriesLabel").val(value);
                _ember['default'].$("#TicketSubCategoriesLabel").parsley().validate();
            },
            closeModalError: function closeModalError() {
                this.set("isError", false);
            }
        }
    });
});