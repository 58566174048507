define("vopay_app/templates/components/template-info", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 3
            },
            "end": {
              "line": 8,
              "column": 3
            }
          },
          "moduleName": "vopay_app/templates/components/template-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "dashboard__btn confirmation__btn");
          var el2 = dom.createTextNode("\n					Start New Bulk Transaction\n				");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["moveToBulk"], [], ["loc", [null, [5, 49], [5, 72]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "vopay_app/templates/components/template-info.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "dashboard__section dashboard__section--grey dashboard__section--first dashboard__section--heading");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "dashboard__header");
        var el4 = dom.createTextNode("Group\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form dashboard__section  dashboard__section--body");
        var el3 = dom.createTextNode("\n		\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "IsFinished", ["loc", [null, [4, 9], [4, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 3], [8, 10]]]], ["inline", "template-accounts", [], ["isPayment", ["subexpr", "@mut", [["get", "isPayment", ["loc", [null, [14, 32], [14, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "currencyName", ["subexpr", "@mut", [["get", "currencyName", ["loc", [null, [14, 55], [14, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "template", ["subexpr", "@mut", [["get", "template", ["loc", [null, [14, 77], [14, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "template.Service", ["loc", [null, [14, 94], [14, 110]]], 0, 0, 0, 0]], [], [], 0, 0], "beneficiaries", ["subexpr", "@mut", [["get", "beneficiaries", ["loc", [null, [14, 125], [14, 138]]], 0, 0, 0, 0]], [], [], 0, 0], "contacts", ["subexpr", "@mut", [["get", "contacts", ["loc", [null, [14, 148], [14, 156]]], 0, 0, 0, 0]], [], [], 0, 0], "PaymentType", ["subexpr", "@mut", [["get", "PaymentType", ["loc", [null, [14, 169], [14, 180]]], 0, 0, 0, 0]], [], [], 0, 0], "key", ["subexpr", "@mut", [["get", "key", ["loc", [null, [14, 185], [14, 188]]], 0, 0, 0, 0]], [], [], 0, 0], "FinishHandler", "FinishHandler"], ["loc", [null, [14, 2], [14, 221]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});