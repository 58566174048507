define('vopay_app/components/change-address', ['exports', 'ember', 'vopay_app/mixins/error-handler'], function (exports, _ember, _vopay_appMixinsErrorHandler) {
  exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
    session: _ember['default'].inject.service('session'),
    api: _ember['default'].inject.service('api'),
    routing: _ember['default'].inject.service('-routing'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('form').parsley({
        successClass: " ",
        errorClass: "form__input--error",
        errorsWrapper: "<p></p>",
        errorTemplate: "<span class='form__message'></span>"
      });
      _ember['default'].$("input").keyup(function () {
        _ember['default'].$(this).addClass("form__input--blueActive");
      });
      _ember['default'].$("input").focusout(function () {
        _ember['default'].$(this).removeClass("form__input--blueActive");
      });
      this.initialiseRecipientAddress();
      _ember['default'].$("#street-address").focusout(function () {
        setTimeout(function () {
          var city = _ember['default'].$("#city").val();
          var state = _ember['default'].$("#state").val();
          var postcode = _ember['default'].$("#postcode").val();
          if (city) {
            _ember['default'].$("#city").parsley().reset();
          }
          if (state) {
            _ember['default'].$("#state").parsley().reset();
          }
          if (postcode) {
            _ember['default'].$("#postcode").parsley().reset();
          }
        }, 500);
      });

      _ember['default'].$("#postcode").focusout(function () {
        setTimeout(function () {
          var city = _ember['default'].$("#city").val();
          var state = _ember['default'].$("#state").val();
          var streetAddress = _ember['default'].$("#street-address").val();
          if (city) {
            _ember['default'].$("#city").parsley().reset();
          }
          if (state) {
            _ember['default'].$("#state").parsley().reset();
          }
          if (streetAddress) {
            _ember['default'].$("#street-address").parsley().reset();
          }
        }, 500);
      });
    },

    /***
     * initialiseRecipientAddress
     *
     * Initialises the Canada Post address library
     */
    initialiseRecipientAddress: function initialiseRecipientAddress() {

      var fields = [{
        element: "streetaddress",
        field: "Line1"
      }, {
        element: "state",
        field: "ProvinceName",
        mode: pca.fieldMode.POPULATE
      }, {
        element: "city",
        field: "City",
        mode: pca.fieldMode.POPULATE
      }, {
        element: "postcode",
        field: "PostalCode",
        mode: pca.fieldMode.POPULATE
      }, {
        element: "country2",
        field: "CountryName",
        mode: pca.fieldMode.COUNTRY
      }],
          options = {
        key: "UN11-KD36-HG19-PD82"
      },
          control = new pca.Address(fields, options); // jshint ignore:line
    },

    actions: {

      changeAddress: function changeAddress() {
        var _this = this;

        var self = this,
            form = _ember['default'].$('#addressForm'),
            formArr = form.serializeArray(),
            options = [];

        for (var i = 0; i < formArr.length; i++) {
          options[formArr[i].name] = formArr[i].value;
        }

        form.parsley().validate();

        if (form.parsley().isValid()) {
          this.get('api').updateUser(options).then(function () {

            self.get('routing').transitionTo('dashboard.account.contact');
          }, function (err) {

            _this.handleErrors(form, err);
          });
        }

        return false;
      }
    }
  });
});
/* global pca */