define("vopay_app/components/bulk-file", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		ServiceOptions: ["EFT", "VoPayInstant", "e-Transfer", "Pay Link"],
		DocumentOptions: ["Debit (Collect Funds)", "Credit (Send Funds)"],
		DocumentOptionsSingle: ["Credit (Send Funds)"],
		Service: "EFT",
		Document: "Debit",
		api: _ember["default"].inject.service('api'),
		routing: _ember["default"].inject.service('-routing'),
		progress: [],
		isCSV: false,
		isEFT: false,
		isBulkPayout: false,
		isMoneyRequest: false,
		isPaylink: false,
		isRTR: false,
		isPaaS: false,
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var self = this;
			this.$("input[type=file]#uploadFile").change(function () {
				self.set("isInvalid", false);
				self.set("isEmpty", false);
			});
			$('#uploadFile').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
		},
		CanadianProvinces: {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon"

		},
		USProvinces: {
			AL: "Alabama",
			AK: "Alaska",
			AS: "American Samoa",
			AZ: "Arizona",
			AR: "Arkansas",
			AA: "Armed Forces America",
			AE: "Armed Forces Other Areas",
			AP: "Armed Forces Pacific",
			CA: "California",
			CO: "Colorado",
			CT: "Connecticut",
			DE: "Delaware",
			DC: "District of Columbia",
			FL: "Florida",
			GA: "Georgia",
			GU: "Guam",
			HI: "Hawaii",
			ID: "Idaho",
			IL: "Illinois",
			IN: "Indiana",
			IA: "Iowa",
			KS: "Kansas",
			KY: "Kentucky",
			LA: "Louisiana",
			ME: "Maine",
			MH: "Marshall Islands",
			MD: "Maryland",
			MA: "Massachusetts",
			MI: "Michigan",
			FM: "Micronesia",
			MN: "Minnesota",
			MS: "Mississippi",
			MO: "Missouri",
			MT: "Montana",
			NE: "Nebraska",
			NV: "Nevada",
			NH: "New Hampshire",
			NJ: "New Jersey",
			NM: "New Mexico",
			NY: "New York",
			NC: "North Carolina",
			ND: "North Dakota",
			MP: "Northern Mariana Islands",
			OH: "Ohio",
			OK: "Oklahoma",
			OR: "Oregon",
			PW: "Palau",
			PA: "Pennsylvania",
			PR: "Puerto Rico",
			RI: "Rhode Island",
			SC: "South Carolina",
			SD: "South Dakota",
			TN: "Tennessee",
			TX: "Texas",
			UT: "Utah",
			VT: "Vermont",
			VI: "Virgin Islands",
			VA: "Virginia",
			WA: "Washington",
			WV: "West Virginia",
			WI: "Wisconsin",
			WY: "Wyomin"
		},
		currencies: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}

		},
		currenciesEFT: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}

		},
		currencyName: "Canadian Dollar",
		currency: "CAD",
		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('api').getCountries().then(function (data) {
				_this.set('countries', data);
			});
			this.set("isPaaS", localStorage.getItem("AccountType") === "PaaS");
		},
		processFile: function processFile(workbook) {
			var _this2 = this;

			var result = [];
			workbook.SheetNames.forEach(function (sheetName) {
				var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], { FS: ";", blankrows: false });
				if (csv.length) {
					var csvArr = csv.split("\n");
					result.push(csvArr);
				}
			});
			var results = result[0];
			if (results) {
				var header = results[0];
				results.shift();
				if (header.split(";")[0] == "Loan #") {

					results = this.cleanArray(results);
					if (results && results.length > 0) {
						var errors = [];
						var arrayObjects = this.processDataPresto(results);

						// this.set("progress", errors);
						// this.set("isFinished", false);
						// errors.push("Processing data");
						// this.set("progress", errors);

						if (this.get("Document").toLowerCase() == "credit") {
							this.checkBalance(arrayObjects).then(function (data) {
								var errors2 = errors.slice(0);
								if (data === true) {

									// errors2.push("Submitting "+arrayObjects.length+" transactions");

									_this2.set("progress", errors2);
									if (arrayObjects.length > 0) {
										_this2.payment(arrayObjects, 0, arrayObjects.length, _this2.get("Document"), _this2.get("Service"), errors2);
									} else {
										_this2.finish(errors);
									}
								} else {

									// errors2.push("Insufficient available funds on account");

									_this2.set("ErrorMessage", "Insufficient Funds");
									_this2.set("isError", true);
								}
							}, function (error) {
								// var errors2 = errors.slice(0)
								// errors.push("Unsupported currency");

								_this2.set("ErrorMessage", "Unsupported Currency");
								_this2.set("isError", true);
							});
						} else {
							// errors.push("Submitting "+arrayObjects.length+" transactions");
							// this.set("progress", errors);	this.set("isProgress", true);
							if (arrayObjects.length > 0) {
								this.payment(arrayObjects, 0, arrayObjects.length, this.get("Document"), this.get("Service"), errors);
							} else {
								this.finish(errors);
							}
						}
					} else {
						this.set("isEmpty", true);
					}
				} else {

					var temp = header.replace(/\s|_/g, "").replace(/,|_/g, "");
					var headerPos = this.processHeader(header);

					results = this.cleanArray(results);
					if (results && results.length > 0) {
						var errors = [];
						// this.set("progress", errors);
						// this.set("isFinished", false);
						// errors.push("Processing data");
						// this.set("progress", errors);
						var arrayObjects = this.processData(headerPos, results);
						if (this.get("Service") != "EFT" && this.get("Service") != "Electronic Fund Transfer" && headerPos.Email == null) {
							this.set("isInvalid", true);
						} else {
							if (this.get("Document").toLowerCase() == "credit") {
								this.checkBalance(arrayObjects).then(function (data) {
									var errors2 = errors.slice(0);
									if (data === true) {
										// errors2.push("Submitting "+arrayObjects.length+" transactions");

										_this2.set("progress", errors2);
										if (arrayObjects.length > 0) {
											_this2.payment(arrayObjects, 0, arrayObjects.length, _this2.get("Document"), _this2.get("Service"), errors2);
										} else {
											_this2.finish(errors);
										}
									} else {

										// errors2.push("Insufficient available funds on account");

										_this2.set("ErrorMessage", "Insufficient Funds");
										_this2.set("isError", true);
									}
								}, function (error) {
									// var errors2 = errors.slice(0)
									// errors.push("Unsupported currency");

									_this2.set("ErrorMessage", "Unsupported Currency");
									_this2.set("isError", true);
								});
							} else {
								// errors.push("Submitting "+arrayObjects.length+" transactions");
								// this.set("progress", errors);

								if (arrayObjects.length > 0) {
									this.payment(arrayObjects, 0, arrayObjects.length, this.get("Document"), this.get("Service"), errors);
								} else {
									this.finish(errors);
								}
							}
						}
					} else {
						this.set("isEmpty", true);
					}
				}
			} else {

				this.set("isInvalid", true);
			}
		},
		checkBalance: function checkBalance(arrayObjects) {
			var _this3 = this;

			return new _ember["default"].RSVP.Promise(function (resolve, reject) {
				var currency = null;
				if (arrayObjects[0].Currency) {
					currency = arrayObjects[0].Currency;
				} else {
					currency = "CAD";
				}
				var balance = 0;
				for (var index = 0; index < arrayObjects.length; index++) {
					var item = arrayObjects[index];
					if (item.Amount) {
						var amount = parseFloat(item.Amount);
						if (!isNaN(amount)) {
							balance = balance + amount;
						}
					}
				}
				var options = {};
				options.Currency = currency;
				if (_this3.get("isPaaS")) {
					resolve(true);
				} else {
					_this3.get("api").getAccountBalance(options).then(function (data) {
						if (data.AvailableFunds >= balance) {
							resolve(true);
						} else {
							resolve(false);
						}
					}, function (error) {
						reject(error);
					});
				}
			});
		},
		processDataPresto: function processDataPresto(data) {
			var arrayObjects = [];

			for (var index = 0; index < data.length; index++) {
				var objeclinets = data[index].split(";");
				var object = {};
				if (objeclinets[0] != "") {
					object.ClientReferenceNumber = objeclinets[0];
					object.FirstName = this.parseName(objeclinets[3]);
					object.LastName = this.parseName(objeclinets[4]);
					object.Address1 = objeclinets[5];
					object.City = objeclinets[6];
					object.Province = this.parseProvince(objeclinets[7]);

					if (objeclinets[8] == "") {
						object.Country = "CA";
					} else {
						object.Country = this.parseCountry(objeclinets[8]);
					}

					object.PostalCode = this.parsePostalCode(objeclinets[9]);
					object.AccountNumber = this.parseNumber(objeclinets[10]);
					object.FinancialInstitutionNumber = this.parseNumber(objeclinets[11]);
					object.BranchTransitNumber = this.parseNumber(objeclinets[12]);
					object.Amount = this.parseDollar(objeclinets[13]);
					arrayObjects.push(object);
				}
			}
			return arrayObjects;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getKeyByValue: function getKeyByValue(object, value) {
			return Object.keys(object).find(function (key) {
				return object[key].toLowerCase() === value.toLowerCase();
			});
		},
		parsePostalCode: function parsePostalCode(value) {
			if (value != null) {
				return value.replace(/[^a-zA-Z0-9]+/g, '');
			} else {
				return value;
			}
		},
		parseName: function parseName(value) {
			if (value) {
				return value.replace(/[^a-zA-Z0-9=$\s.*-]+/g, '');
			} else {
				return value;
			}
		},
		parseCountry: function parseCountry(value) {
			if (value) {
				if (value.length > 2) {
					if (value.toLowerCase() == "canada") {
						return "CA";
					} else {
						if (this.getIsoCountry(value)) {
							return this.getIsoCountry(value);
						} else {
							return value.toUpperCase();
						}
					}
				} else {
					return value.toUpperCase();
				}
			} else {
				return value.toUpperCase();
			}
		},
		parseNumber: function parseNumber(value) {
			if (value) {
				return value.replace(/[\D]+/g, '');
			} else {
				return value;
			}
		},
		parseDollar: function parseDollar(value) {
			if (value) {
				return value.replace(/[^\d.]+/g, '');
			} else {
				return value;
			}
		},
		parseProvince: function parseProvince(value, country) {
			var isDefined = false;

			if (country == null) {
				country = "CA";
			}
			if (value) {
				if (value.length > 2) {
					if (country == "CA") {
						return this.getKeyByValue(this.get("CanadianProvinces"), value);
					} else if (country == "US") {
						return this.getKeyByValue(this.get("USProvinces"), value);
					} else {
						return undefined;
					}
				} else if (value.toUpperCase() == "PQ") {
					return "QC";
				} else if (value.toUpperCase() == "QB") {
					return "QC";
				} else if (value.toUpperCase() == "NF") {
					return 'NL';
				} else {
					if (country == "CA") {
						isDefined = this.get("CanadianProvinces").hasOwnProperty(value.toUpperCase());
					}
					if (country == "US") {
						isDefined = this.get("USProvinces").hasOwnProperty(value.toUpperCase());
					}

					if (isDefined) {
						return value.toUpperCase();
					} else {
						return undefined;
					}
				}
			} else {
				return value.toUpperCase();
			}
		},

		processData: function processData(headerPos, data) {
			var arrayObjects = [];
			for (var index = 0; index < data.length; index++) {
				if (data[index].split(";")[1] != "Total") {
					arrayObjects.push(this.getObject(headerPos, data[index].split(";")));
				}
			}

			return arrayObjects;
		},
		getObject: function getObject(headerPos, data) {
			var object = {};
			if (headerPos.FirstName != null && headerPos.LastName != null && headerPos.FirstName != '' && headerPos.LastName != '') {
				object.FirstName = this.parseName(data[headerPos.FirstName]);
				object.LastName = this.parseName(data[headerPos.LastName]);
				object.RecipientName = this.parseName(data[headerPos.FirstName]) + " " + this.parseName(data[headerPos.LastName]);
			}
			if (headerPos.FullName != null && headerPos.FullName != '') {
				var Name = this.parseName(data[headerPos.FullName]);
				if (Name.split(' ').slice(0, -1).join(' ') != "" && Name.split(' ').slice(-1).join(' ') != "" && Name.split(' ').slice(0, -1).join(' ') != null && Name.split(' ').slice(-1).join(' ') != null) {
					object.FirstName = Name.split(' ').slice(0, -1).join(' ');
					object.LastName = Name.split(' ').slice(-1).join(' ');
				} else {
					object.CompanyName = Name;
				}
			}
			if (headerPos.BusinessName != null && headerPos.BusinessName != '') {
				object.CompanyName = this.parseName(data[headerPos.CompanyName]);
				object.RecipientName = this.parseName(data[headerPos.CompanyName]);
			}
			if (headerPos.SenderName != null && headerPos.SenderName != '') {
				object.SenderName = this.parseNamedat([headerPos.SenderName]);
			}
			if (headerPos.PendingDue != null && headerPos.PendingDue != '') {
				object.Amount = this.tofloat(data[headerPos.PendingDue]);
			}
			if (headerPos.Amount != null && headerPos.Amount != '') {
				object.Amount = this.tofloat(data[headerPos.Amount]);
			}
			if (headerPos.Notes != null && headerPos.Notes != '') {
				object.Notes = data[headerPos.Notes];
			}
			if (headerPos.Transit != null && headerPos.Transit != '') {
				object.BranchTransitNumber = this.parseNumber(data[headerPos.Transit]);
			}
			if (headerPos.InstitutionNumber != null && headerPos.InstitutionNumber != '') {
				object.FinancialInstitutionNumber = this.parseNumber(data[headerPos.InstitutionNumber]);
			}
			if (headerPos.AccountNumber != null && headerPos.AccountNumber != '') {
				object.AccountNumber = this.parseNumber(data[headerPos.AccountNumber]);
			}
			if (headerPos.IBAN != null && headerPos.IBAN != '') {
				object.Iban = data[headerPos.IBAN];
			}
			if (headerPos.ABA != null && headerPos.ABA != '') {
				object.Aba = data[headerPos.ABA];
			}
			if (headerPos.SortCode != null && headerPos.SortCode != '') {
				object.SortCode = data[headerPos.SortCode];
			}
			if (headerPos.Address != null && headerPos.Address != '') {
				object.Address1 = data[headerPos.Address].replace(/^-+/, '');
			}
			if (headerPos.Address1 != null && headerPos.Address1 != '') {
				object.Address1 = data[headerPos.Address1].replace(/^-+/, '');
			}
			if (headerPos.Address2 != null && headerPos.Address2 != '') {
				object.Address2 = data[headerPos.Address2].replace(/^-+/, '');
			}
			if (headerPos.Country != null && headerPos.Country != '') {
				object.Country = data[headerPos.Country];
			}
			if (headerPos.City != null && headerPos.City != '') {
				object.City = data[headerPos.City];
			}
			if (headerPos.Province != null && headerPos.Province != '') {
				object.Province = data[headerPos.Province];
			}
			if (headerPos.Currency != null && headerPos.Currency != '') {
				object.Currency = data[headerPos.Currency];
			}
			if (headerPos.Email != null && headerPos.Email != '') {
				object.Email = data[headerPos.Email].replace(/^-+/, '').replace(/^ +/, '');
				object.EmailAddress = data[headerPos.Email].replace(/^-+/, '').replace(/^ +/, '');
			}
			if (headerPos.Message != null && headerPos.Message != '') {
				object.MessageForRecipient = data[headerPos.Message];
			}
			if (headerPos.PostalCode != null && headerPos.PostalCode != '') {
				object.PostalCode = data[headerPos.PostalCode];
			}
			if (headerPos.Question != null && headerPos.Question != '') {
				object.Question = data[headerPos.Question];
			}
			if (headerPos.Answer != null && headerPos.Answer != '') {
				object.Answer = data[headerPos.Answer];
			}
			if (headerPos.DOB != null && headerPos.DOB != '') {
				object.DOB = data[headerPos.DOB];
			}
			if (headerPos.PhoneNumber != null && headerPos.PhoneNumber != '') {
				object.PhoneNumber = data[headerPos.PhoneNumber].replace(/\s+$/g, '');
			}
			if (headerPos.ClientReferenceNumber != null && headerPos.ClientReferenceNumber != '') {
				object.ClientReferenceNumber = data[headerPos.ClientReferenceNumber];
			}
			if (data.Currency == null) {
				object.Currency = "CAD";
			}

			return object;
		},
		cleanArray: function cleanArray(results) {
			var results2 = [];
			for (var index = 0; index < results.length; index++) {
				var temp = results[index].replace(/\s|_/g, "").replace(/,|_/g, "");
				if (temp !== "" && temp !== null) {
					results2.push(results[index]);
				}
			}
			return results2;
		},
		/**
  	returns associative object with positions on cell where needed information.
  	return {
  		FirstName: index,
  		LastName: index,
  		Name: index,
  		Amount: index,
  		Id: index,
  		Penalty: index,
  		Amortization Due: index,
  		Pending Due: index,
  		Address: index,
  		Country: index,
  		City: indx,
  		Province: index,
  		Address1: index,
  		Address2: index,
  		ClientReferenceNumber: index,
  		Currency: index,
  		Email: index
  		Postal Code: index,
  		Transit: indes,
  		IBAN: index,
  		ABA: index,
  		Sort Code: index,
  		Message: index,
  		Notes: index
  	}
  **/
		processHeader: function processHeader(header) {
			var headerItems = header.split(';');
			var object = {
				"FirstName": null,
				"LastName": null,
				"BusinessName": null,
				"SenderName": null,
				"FullName": null,
				"Amount": null,
				"Penalty": null,
				"AmortizationDue": null,
				"PendingDue": null,
				"LoanNumber": null,
				"Address": null,
				"Country": null,
				"City": null,
				"Province": null,
				"Address1": null,
				"Address2": null,
				"PhoneNumber": null,
				"DOB": null,
				"ClientReferenceNumber": null,
				"Currency": null,
				"Email": null,
				"PostalCode": null,
				"Transit": null,
				"IBAN": null,
				"ABA": null,
				"SortCode": null,
				"InstitutionNumber": null,
				"AccountNumber": null,
				"Question": null,
				"Answer": null,
				"Message": null,
				"Notes": null
			};
			headerItems.forEach(function (item, index, arr) {
				var item2 = String(item.toLowerCase().replace(/\s|_/g, ""));
				if (item2.includes("firstname") || item2.includes("first_name")) {
					object.FirstName = index;
				} else if (item2.includes("lastname") || item2.includes("last_name")) {
					object.LastName = index;
				} else if (item2.includes("fullname")) {
					object.FullName = index;
				} else if (item2.includes("sendname") || item2.includes("sendername")) {
					object.SenderName = index;
				} else if (item2.includes("name")) {
					object.BusinessName = index;
				} else if (item2.includes("amortization")) {
					object.AmortizationDue = index;
				} else if (item2.includes("pending")) {
					object.PendingDue = index;
				} else if (item2.includes("penalty")) {
					object.Penalty = index;
				} else if (item2.includes("duedate")) {
					object.DueDate = index;
				} else if (item2.includes("pastdue")) {
					object.PastDue = index;
				} else if (item2.includes("amount") || item2.includes("due") || item2.includes("collect") || item2.includes("payment") || item2.includes("amount_$")) {
					object.Amount = index;
				} else if (item2.includes("address") && item2.includes("1")) {
					object.Address1 = index;
				} else if (item2.includes("address") && item2.includes("2")) {
					object.Address2 = index;
				} else if (item2.includes("address")) {
					object.Address = index;
				} else if (item2.includes("country")) {
					object.Country = index;
				} else if (item2.includes("city")) {
					object.City = index;
				} else if (item2.includes("postalcode")) {
					object.PostalCode = index;
				} else if (item2.includes("postal_code")) {
					object.PostalCode = index;
				} else if (item2.includes("province") || item2.includes("state")) {
					object.Province = index;
				} else if (item2.includes("phone") || item2.includes("tel") || item2.includes("mobile") || item2.includes("cell")) {
					object.PhoneNumber = index;
				} else if (item2.includes("date") || item2.includes("birth") || item2.includes("dob")) {
					object.DOB = index;
				} else if (item2.includes("currency") || item2.includes("iso") || item2.includes("currencies")) {
					object.Currency = index;
				} else if (item2.includes("email")) {
					object.Email = index;
				} else if (item2.includes("transit") || item2.includes("branch") || item2.includes("transit#")) {
					object.Transit = index;
				} else if (item2.includes("iban")) {
					object.IBAN = index;
				} else if (item2.includes("aba")) {
					object.ABA = index;
				} else if (item2.includes("sort")) {
					object.SortCode = index;
				} else if (item2.includes("institution") || item2.includes("bank#")) {
					object.InstitutionNumber = index;
				} else if (item2.includes("account") || item2.includes("account#")) {
					object.AccountNumber = index;
				} else if (item2.includes("message")) {
					object.Message = index;
				} else if (item2.includes("question")) {
					object.Question = index;
				} else if (item2.includes("answer")) {
					object.Answer = index;
				} else if (item2.includes("note") || item2.includes("description")) {
					object.Notes = index;
				} else if ((item2.includes("loan") || item2.includes("clientnumber")) && !item2.includes("loan(a,b,c)")) {
					object.LoanNumber = index;
					object.ClientReferenceNumber = index;
				}
			});
			return object;
		},
		tofloat: function tofloat(numberString) {
			return parseFloat(numberString.replace(/[^0-9\.-]+/g, ""));
		},
		parseGoogleResponse: function parseGoogleResponse(components) {

			var address_components = {};
			components.address_components.forEach(function (c) {

				if (c.types[0] === 'route') {
					address_components.Street = c.long_name;
				} else if (c.types[0] === 'street_number') {
					address_components.StreetNumber = c.long_name;
				} else if (c.types[0] === 'administrative_area_level_1') {
					address_components.Province = c.short_name;
				} else if (c.types[0] === 'locality') {
					address_components.City = c.long_name;
				} else if (c.types[0] === 'country') {
					address_components.Country = c.short_name;
				} else if (c.types[0] === 'postal_code') {
					address_components.PostalCode = c.short_name;
				}
			});
			return address_components;
		},
		parseAddress: function parseAddress(object) {
			var _this4 = this;

			return new _ember["default"].RSVP.Promise(function (resolve, reject) {

				var currentObject = object;
				var queryTerm = currentObject.Address1;
				if (currentObject.Address2) {
					queryTerm = queryTerm + " " + currentObject.Address2;
				}
				if (currentObject.Province) {
					queryTerm = queryTerm + " " + currentObject.Province;
				}
				if (currentObject.PostalCode) {
					queryTerm = queryTerm + " " + currentObject.PostalCode;
				}
				if (currentObject.City) {
					queryTerm = queryTerm + " " + currentObject.City;
				}
				if (currentObject.Country) {
					queryTerm = queryTerm + " " + currentObject.Country;
				}

				var request = {
					query: queryTerm,
					fields: ['place_id']
				};

				var service = new google.maps.places.PlacesService(document.createElement('div'));
				var self = _this4;
				service.findPlaceFromQuery(request, function (results, status) {
					if (status === google.maps.places.PlacesServiceStatus.OK) {
						var request2 = {
							placeId: results[0].place_id,
							fields: ['address_component']
						};

						var service2 = new google.maps.places.PlacesService(document.createElement('div'));
						service2.getDetails(request2, function (results2, status2) {
							if (status2 === google.maps.places.PlacesServiceStatus.OK) {
								var processedObject = self.parseGoogleResponse(results2);

								if (processedObject.StreetNumber && processedObject.Street) {
									currentObject.Address1 = processedObject.StreetNumber + " " + processedObject.Street;
								} else {
									currentObject.Address1 = "n/a";
								}
								if (processedObject.Country) {
									currentObject.City = processedObject.City;
								} else {
									currentObject.PostalCode = "n/a";
								}
								if (processedObject.Country) {
									currentObject.Country = processedObject.Country;
								} else {
									currentObject.PostalCode = "CA";
								}
								if (processedObject.PostalCode) {
									currentObject.PostalCode = processedObject.PostalCode;
								} else {
									currentObject.PostalCode = "n/a";
								}
								if (processedObject.Province) {
									currentObject.Province = processedObject.Province;
								} else {
									currentObject.PostalCode = "AB";
								}

								resolve(currentObject);
							} else {

								reject();
							}
						});
					} else {
						reject();
					}
				});
			});
		},
		finish: function finish(errors) {

			var errors2 = errors.slice(0);
			this.set("progress", errors2);
			this.set("isFinished", true);
			this.set("isProgress", false);
			_ember["default"].$("#FileSummary").show();
			_ember["default"].$("#FileInput").hide();

			var d = new Date();
			sessionStorage.setItem("SessionTime", d.getTime());
			window.sessionTimeout = setInterval(function () {
				var d2 = new Date();

				if (600000 < d2.getTime() - sessionStorage.getItem("SessionTime")) {
					clearInterval(window.sessionTimeout);
					localStorage.removeItem("JWT");
					localStorage.removeItem("key");
					localStorage.removeItem("accountID");
					localStorage.removeItem("signature");
					localStorage.setItem("logout", true);
					location.reload(true);
				}
			}, 1000);
		},
		validateEmail: function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		validateInstitution: function validateInstitution(institution) {
			var re = /^\d{1,3}$/;
			return re.test(String(institution).toLowerCase());
		},
		validateTransit: function validateTransit(transit) {
			var re = /^\d{1,5}$/;
			return re.test(String(transit).toLowerCase());
		},
		validateAccount: function validateAccount(account) {
			var re = /^\d{6,12}$/;
			return re.test(String(account).toLowerCase());
		},
		validateIsOnlyNumbers: function validateIsOnlyNumbers(text) {
			var re = /^\d+$/;
			return false;
		},
		validateAmount: function validateAmount(text) {
			var re = /^\d+(\.\d{1,2})?$/;
			return re.test(String(text).toLowerCase());
		},
		validatePhoneNumber: function validatePhoneNumber(text) {
			var re = /^[a-zA-Z0-9()-+.\s]*$/;
			return re.test(String(text).toLowerCase());
		},
		validateName: function validateName(text) {
			var re = /^(?!.*([,&]|\s{2}|.{100})).*$/;
			return re.test(String(text).toLowerCase());
		},
		validateRecipientName: function validateRecipientName(text) {
			var re = /^(?!.*([,&]|\s{2}|.{100})).*$/;
			return re.test(String(text).toLowerCase());
		},
		validateQuestion: function validateQuestion(text) {
			var re = /^(?!.*([,&]|\s{2}|.{100})).*$/;
			return re.test(String(text).toLowerCase());
		},
		validateAnswer: function validateAnswer(text) {
			var re = /^(?!.*([,&]|\s{2}|.{30})).*$/;
			return re.test(String(text).toLowerCase());
		},
		validateMemo: function validateMemo(text) {
			var re = /^(?!.*([,&]|\s{5}|.{500})).*$/;
			return re.test(String(text).toLowerCase());
		},
		validateDate: function validateDate(text) {
			var re = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
			return re.test(String(text).toLowerCase());
		},
		Format005Date: function Format005Date(value) {
			var str = value.substring(1);
			var date = window.moment(str, 'YYDDD').format("YYYY-MM-DD");
			return date;
		},
		payload: function payload(batch) {
			var self = this;
			return new _ember["default"].RSVP.Promise(function (resolve, reject) {
				self.get("api").acceptPayloadSendServer(batch).then(function (data) {
					batch.Message = "Successfully submitted the file to the server!";
					resolve(batch);
				}, function (error) {
					reject(error);
				});
			});
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		eft: function eft(beneficiary, errors2, PaymentType, index) {

			var self = this;
			return new _ember["default"].RSVP.Promise(function (resolve, reject) {
				if (PaymentType.toLowerCase() == "debit") {
					self.get("api").fundTransaction(beneficiary).then(function (transaction) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Successfully submitted";
						resolve(beneficiary);
					}, function (error) {
						beneficiary.Id = index + 1;

						if (error == "Invalid Parameter(s): Country (Does not exists in our records)") {
							error = "Invalid Parameter(s): Country";
						}
						beneficiary.Message = error;
						resolve(beneficiary);
					});
				}
				if (PaymentType.toLowerCase() == "credit") {
					self.get("api").withdrawTransaction(beneficiary).then(function (transaction) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Successfully submitted";

						resolve(beneficiary);
					}, function (error) {
						beneficiary.Id = index + 1;

						if (error == "Invalid Parameter(s): Country (Does not exists in our records)") {
							error = "Invalid Parameter(s): Country";
						}
						beneficiary.Message = error;
						resolve(beneficiary);
					});
				}
			});
		},
		emt: function emt(beneficiary, errors2, PaymentType, index) {
			var self = this;
			return new _ember["default"].RSVP.Promise(function (resolve, reject) {

				if (PaymentType.toLowerCase() == "debit") {
					self.get("api").ClaimMoneyRequest(beneficiary).then(function (transaction) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Successfully submitted";
						resolve(beneficiary);
					}, function (error) {
						beneficiary.Id = index + 1;
						beneficiary.Message = error;

						resolve(beneficiary);
					});
				}
				if (PaymentType.toLowerCase() == "credit") {
					self.get("api").BulkPayout(beneficiary).then(function (transaction) {
						beneficiary.Id = index + 1;
						beneficiary.Message = "Successfully submitted";
						resolve(beneficiary);
					}, function (error) {
						beneficiary.Id = index + 1;
						if (error == "Required parameter missing: Either Email Address or PhoneNumber musht be set.") {
							error = "Invalid Parameter(s): Email";
						}
						if (error == "Required parameter missing: Either EmailAddress or PhoneNumber must be set.") {
							error = "Required parameter missing: Email";
						}
						beneficiary.Message = error;
						resolve(beneficiary);
					});
				}
			});
		},
		payment: function payment(listing, i, length, PaymentType, Service, errors) {
			var self = this;
			var errors2 = errors.slice(0);

			var totalAmount = 0;

			for (var index = 0; index < length; index++) {
				var beneficiary = listing[index];

				if (!self.get("isCSV")) {
					if (!beneficiary.PostalCode || beneficiary.PostalCode == "") {
						beneficiary.PostalCode = "A1A1A1";
					}
					if (!beneficiary.City || beneficiary.City == "") {
						beneficiary.City = "n/a";
					}
					if (!beneficiary.Province || beneficiary.Province == "") {
						beneficiary.Province = "AB";
					}
					if (!beneficiary.Country || beneficiary.Country == "") {
						beneficiary.Country = "CA";
					}
					if (!beneficiary.Address1 || beneficiary.Address1 == "") {
						beneficiary.Address1 = "n/a";
					}
				}
				var AggregatorCredentialsProvided = false;
				if (!self.get("isBulkPayout") && !self.get("isMoneyRequest") && !self.get("isPaylink")) {
					if (beneficiary.ScheduleStartDate == null || beneficiary.ScheduleStartDate == "") {
						beneficiary.ScheduleStartDate = window.moment(new Date()).format("YYYY-MM-DD");
					} else {
						var date = window.moment(beneficiary.ScheduleStartDate, "YYYY-MM-DD");
						if (date.isBefore(new Date(), 'day')) {
							beneficiary.ScheduleStartDate = window.moment(new Date()).format("YYYY-MM-DD");
						}
					}
				}
				if (beneficiary.FlinksAccountID && beneficiary.FlinksAccountID != "" && beneficiary.FlinksLoginID && beneficiary.FlinksLoginID != "" || beneficiary.Token && beneficiary.Token != "" || beneficiary.InveriteRequestGUID && beneficiary.InveriteRequestGUID != "" || beneficiary.PlaidProcessorToken && beneficiary.PlaidProcessorToken != "" || beneficiary.PlaidAccountID && beneficiary.PlaidAccountID != "" && beneficiary.PlaidPublicToken && beneficiary.PlaidPublicToken != "" || beneficiary.PlaidAccountID && beneficiary.PlaidAccountID != "" && beneficiary.PlaidAccessToken && beneficiary.PlaidAccessToken != "") {
					AggregatorCredentialsProvided = true;
				}
				if (!self.get("isBulkPayout") && !self.get("isMoneyRequest") && !self.get("isPaylink") && !self.get("isRTR")) {
					beneficiary.Country = self.parseCountry(beneficiary.Country);
					beneficiary.Province = self.parseProvince(beneficiary.Province, beneficiary.Country);
				}
				if (beneficiary.Document != null && beneficiary.Document.toLowerCase() != PaymentType.toLowerCase()) {
					beneficiary.Id = index + 1;
					beneficiary.Message = "Incorrect Service";
					errors2.push(beneficiary);
					listing[index] = null;
				} else {
					beneficiary.Id = index + 1;
					beneficiary.Message = "Successfully submitted";
					errors2.push(beneficiary);

					if (!self.get("isPaylink")) {
						totalAmount += parseFloat(beneficiary.Amount);
					}
				}
			}
			var listing2 = listing.filter(function (item) {
				return item !== null;
			});
			if (listing.length != listing2.length) {
				this.set("ErrorMessage", "Incorrect service, selected service and service in file do not match");
				this.set("isError", true);
			} else {
				this.set("totalAmount", totalAmount);
				this.set("length", errors2.length);
				this.set("listing", listing2);
				this.set("progress", errors2);

				_ember["default"].$("#FileInput").hide();
				_ember["default"].$("#FileOverview").show();
			}
		},
		processBankingFileBMO: function processBankingFileBMO(lines) {
			var _this5 = this;

			var Currency = "CAD";
			var bankingObjects = [];
			for (var index = 0; index < lines.length; index++) {
				if (lines[index].charAt(0) === "A") {
					Currency = lines[index].substring(55, 58);
				} else if (lines[index].charAt(0) === "D" || lines[index].charAt(0) === "C") {

					var BankInfo = {};
					BankInfo.InvalidSize = lines[index].length == 1464 ? false : true;
					BankInfo.FinancialInstitutionNumber = lines[index].substring(44, 47);
					BankInfo.BranchTransitNumber = lines[index].substring(47, 52);
					BankInfo.AccountNumber = lines[index].substring(52, 64).replace(/\s+$/g, '');
					var Name = lines[index].substring(104, 134).replace(/\s+$/g, '');
					if (Name.split(' ').slice(0, -1).join(' ') != "" && Name.split(' ').slice(-1).join(' ') != "" && Name.split(' ').slice(0, -1).join(' ') != null && Name.split(' ').slice(-1).join(' ') != null) {
						BankInfo.FirstName = Name.split(' ').slice(0, -1).join(' ');
						BankInfo.LastName = Name.split(' ').slice(-1).join(' ');
					} else {
						BankInfo.CompanyName = Name;
					}
					var amount = lines[index].substring(27, 37);
					amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
					BankInfo.Amount = parseFloat(amount);
					BankInfo.Currency = Currency;
					BankInfo.Country = "CA";
					BankInfo.Address1 = "n/a";
					BankInfo.City = "n/a";
					BankInfo.PostalCode = "n/a";
					BankInfo.Province = "AB";

					BankInfo.ClientReferenceNumber = lines[index].substring(174, 193).replace(/\s+$/g, '');
					bankingObjects.push(BankInfo);
				}
			}
			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document").toLowerCase() == "credit") {
					this.checkBalance(bankingObjects).then(function (data) {
						if (data === true) {
							// errors2.push("Submitting "+arrayObjects.length+" transactions");

							_this5.payment(bankingObjects, 0, bankingObjects.length, _this5.get("Document"), _this5.get("Service"), errors);
						} else {

							// errors2.push("Insufficient available funds on account");

							_this5.set("ErrorMessage", "Insufficient Funds");
							_this5.set("isError", true);
						}
					}, function (error) {

						_this5.set("ErrorMessage", "Unsupported Currency");
						_this5.set("isError", true);
					});
				} else {

					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		processBankingFileStandard005: function processBankingFileStandard005(lines) {
			var _this6 = this;

			var Currency = "CAD";
			var bankingObjects = [];

			for (var index = 0; index < lines.length; index++) {
				if (lines[index].charAt(0) === "D" || lines[index].charAt(0) === "C") {

					var BankInfo = {};
					BankInfo.InvalidSize = lines[index].length == 1464 ? false : true;
					BankInfo.FinancialInstitutionNumber = lines[index].substring(44, 47);

					BankInfo.BranchTransitNumber = lines[index].substring(47, 52);

					BankInfo.AccountNumber = lines[index].substring(52, 64).replace(/\D/g, '');

					BankInfo.ClientReferenceNumber = lines[index].substring(174, 193);

					var Name = lines[index].substring(104, 134).replace(/[^\w\s]/gi, '');
					if (Name.split(' ').slice(0, -1).join(' ') != "" && Name.split(' ').slice(-1).join(' ') != "" && Name.split(' ').slice(0, -1).join(' ') != null && Name.split(' ').slice(-1).join(' ') != null) {
						BankInfo.FirstName = Name.split(' ').slice(0, -1).join(' ');
						BankInfo.LastName = Name.split(' ').slice(-1).join(' ');
					} else {
						BankInfo.CompanyName = Name;
					}
					var amount = lines[index].substring(27, 37);

					BankInfo.ScheduleStartDate = this.Format005Date(lines[index].substring(37, 43));
					amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
					BankInfo.Amount = parseFloat(amount);
					BankInfo.Currency = Currency;
					BankInfo.Country = "CA";
					BankInfo.Address1 = "n/a";
					BankInfo.City = "n/a";
					BankInfo.PostalCode = "A1A1A1";
					BankInfo.Province = "AB";

					BankInfo.Notes = lines[index].substring(229, 251);

					bankingObjects.push(BankInfo);
					lines[index] = lines[index].substr(264);
					while (lines[index].length >= 100) {
						var BankInfo2 = {};

						BankInfo2.FinancialInstitutionNumber = lines[index].substring(20, 23);
						BankInfo2.BranchTransitNumber = lines[index].substring(23, 28);
						BankInfo2.AccountNumber = lines[index].substring(28, 40).replace(/\D/g, '');
						var _Name = lines[index].substring(80, 110).replace(/\s+$/g, '');
						if (_Name.split(' ').slice(0, -1).join(' ') != "" && _Name.split(' ').slice(-1).join(' ') != "" && _Name.split(' ').slice(0, -1).join(' ') != null && _Name.split(' ').slice(-1).join(' ') != null) {
							BankInfo2.FirstName = _Name.split(' ').slice(0, -1).join(' ');
							BankInfo2.LastName = _Name.split(' ').slice(-1).join(' ');
						} else {
							BankInfo2.CompanyName = _Name;
						}
						var _amount = lines[index].substring(3, 13);
						_amount = _amount.substring(0, _amount.length - 2) + "." + _amount.substring(_amount.length - 2);

						BankInfo2.ScheduleStartDate = this.Format005Date(lines[index].substring(13, 19));
						BankInfo2.Amount = parseFloat(_amount);
						BankInfo2.Currency = Currency;
						BankInfo2.Country = "CA";
						BankInfo2.Address1 = "n/a";
						BankInfo2.City = "n/a";
						BankInfo2.PostalCode = "A1A1A1";
						BankInfo2.Province = "AB";

						BankInfo2.Notes = lines[index].substring(205, 227);

						if (!isNaN(BankInfo2.Amount)) {
							bankingObjects.push(BankInfo2);
						}

						lines[index] = lines[index].substr(240);
					}
				}
			}
			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document") == "Credit") {
					this.checkBalance(bankingObjects).then(function (data) {
						if (data === true) {

							_this6.payment(bankingObjects, 0, bankingObjects.length, _this6.get("Document"), _this6.get("Service"), errors);
						} else {

							_this6.set("ErrorMessage", "Insufficient Funds");
							_this6.set("isError", true);
						}
					}, function (error) {

						_this6.set("ErrorMessage", "Unsupported Currency");
						_this6.set("isError", true);
					});
				} else {

					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		processBankingFileBMO2: function processBankingFileBMO2(lines) {
			var _this7 = this;

			var Currency = "CAD";
			var bankingObjects = [];

			for (var index = 0; index < lines.length; index++) {
				if (lines[index].charAt(0) === "A") {
					Currency = lines[index].substring(55, 58);
				} else if (lines[index].charAt(0) === "D" || lines[index].charAt(0) === "C") {

					var BankInfo = {};
					BankInfo.InvalidSize = lines[index].length == 1464 ? false : true;
					BankInfo.FinancialInstitutionNumber = lines[index].substring(44, 47);
					BankInfo.BranchTransitNumber = lines[index].substring(47, 52);
					BankInfo.AccountNumber = lines[index].substring(52, 64).replace(/\s+$/g, '');
					var Name = lines[index].substring(104, 134).replace(/\s+$/g, '');
					if (Name.split(' ').slice(0, -1).join(' ') != "" && Name.split(' ').slice(-1).join(' ') != "" && Name.split(' ').slice(0, -1).join(' ') != null && Name.split(' ').slice(-1).join(' ') != null) {
						BankInfo.FirstName = Name.split(' ').slice(0, -1).join(' ');
						BankInfo.LastName = Name.split(' ').slice(-1).join(' ');
					} else {
						BankInfo.CompanyName = Name;
					}
					var amount = lines[index].substring(27, 37);
					amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
					BankInfo.Amount = parseFloat(amount);
					BankInfo.Currency = Currency;
					BankInfo.Country = "CA";
					BankInfo.Address1 = "n/a";
					BankInfo.City = "n/a";
					BankInfo.PostalCode = "n/a";
					BankInfo.Province = "AB";

					BankInfo.ClientReferenceNumber = lines[index].substring(174, 192).replace(/\s+$/g, '');
					bankingObjects.push(BankInfo);
					var lines2 = lines[index].split('000000000000000                        ');
					for (var index2 = 1; index2 < lines2.length; index2++) {
						var BankInfo2 = {};

						if (lines2[index2] !== "00000000000" && lines2[index2] && lines2[index2] !== "") {
							BankInfo2.FinancialInstitutionNumber = lines2[index2].substring(31, 34);
							BankInfo2.BranchTransitNumber = lines2[index2].substring(34, 39);
							BankInfo2.AccountNumber = lines2[index2].substring(39, 51).replace(/\s+$/g, '');
							var _Name2 = lines2[index2].substring(91, 121).replace(/\s+$/g, '');
							if (_Name2.split(' ').slice(0, -1).join(' ') != "" && _Name2.split(' ').slice(-1).join(' ') != "" && _Name2.split(' ').slice(0, -1).join(' ') != null && _Name2.split(' ').slice(-1).join(' ') != null) {
								BankInfo2.FirstName = _Name2.split(' ').slice(0, -1).join(' ');
								BankInfo2.LastName = _Name2.split(' ').slice(-1).join(' ');
							} else {
								BankInfo2.CompanyName = _Name2;
							}
							var _amount2 = lines2[index2].substring(14, 24);
							_amount2 = _amount2.substring(0, _amount2.length - 2) + "." + _amount2.substring(_amount2.length - 2);
							BankInfo2.Amount = parseFloat(_amount2);
							BankInfo2.Currency = Currency;
							BankInfo2.Country = "CA";
							BankInfo2.Address1 = "n/a";
							BankInfo2.City = "n/a";
							BankInfo2.PostalCode = "n/a";
							BankInfo2.Province = "AB";

							BankInfo2.ClientReferenceNumber = lines2[index2].substring(161, 179).replace(/\s+$/g, '');

							if (!isNaN(BankInfo2.Amount)) {
								bankingObjects.push(BankInfo2);
							}
						}
					}
				}
			}
			var errors = [];

			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document").toLowerCase() == "credit") {
					this.checkBalance(bankingObjects).then(function (data) {
						if (data === true) {
							// errors2.push("Submitting "+arrayObjects.length+" transactions");

							_this7.payment(bankingObjects, 0, bankingObjects.length, _this7.get("Document"), _this7.get("Service"), errors);
						} else {

							// errors2.push("Insufficient available funds on account");

							_this7.set("ErrorMessage", "Insufficient Funds");
							_this7.set("isError", true);
						}
					}, function (error) {

						_this7.set("ErrorMessage", "Unsupported Currency");
						_this7.set("isError", true);
					});
				} else {

					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		processBankingFileBMO80: function processBankingFileBMO80(lines) {
			var _this8 = this;

			var Currency = "CAD";
			var bankingObjects = [];
			for (var index = 0; index < lines.length; index++) {

				if (lines[index].charAt(0) === "D" || lines[index].charAt(0) === "C") {
					var BankInfo = {};
					BankInfo.InvalidSize = lines[index].length < 61 || lines[index].length > 80 ? true : false;
					BankInfo.FinancialInstitutionNumber = lines[index].substring(12, 15);
					BankInfo.BranchTransitNumber = lines[index].substring(15, 20);
					BankInfo.AccountNumber = lines[index].substring(20, 32).replace(/\s+$/g, '');
					var Name = lines[index].substring(32, 60).replace(/\s+$/g, '');
					if (Name.split(' ').slice(0, -1).join(' ') != "" && Name.split(' ').slice(-1).join(' ') != "" && Name.split(' ').slice(0, -1).join(' ') != null && Name.split(' ').slice(-1).join(' ') != null) {
						BankInfo.FirstName = Name.split(' ').slice(0, -1).join(' ');
						BankInfo.LastName = Name.split(' ').slice(-1).join(' ');
					} else {
						BankInfo.CompanyName = Name;
					}
					var amount = lines[index].substring(1, 11);
					amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2);
					BankInfo.Amount = parseFloat(amount);
					BankInfo.Currency = Currency;
					BankInfo.Country = "CA";
					BankInfo.Address1 = "n/a";
					BankInfo.City = "n/a";
					BankInfo.PostalCode = "n/a";
					BankInfo.Province = "AB";
					BankInfo.ClientReferenceNumber = lines[index].substring(61, 80).replace(/\s+$/g, '');
					bankingObjects.push(BankInfo);
				}
			}

			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document").toLowerCase() == "credit") {
					this.checkBalance(bankingObjects).then(function (data) {
						if (data === true) {
							// errors2.push("Submitting "+arrayObjects.length+" transactions");

							_this8.payment(bankingObjects, 0, bankingObjects.length, _this8.get("Document"), _this8.get("Service"), errors);
						} else {

							// errors2.push("Insufficient available funds on account");

							_this8.set("ErrorMessage", "Insufficient Funds");
							_this8.set("isError", true);
						}
					}, function (error) {

						_this8.set("ErrorMessage", "Unsupported Currency");
						_this8.set("isError", true);
					});
				} else {
					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		processMoneyWay: function processMoneyWay(lines) {
			var _this9 = this;

			var Currency = "CAD";
			var bankingObjects = [];

			for (var index = 0; index < lines.length; index++) {
				var values = lines[index].split('\t');

				if (values[0] != "Currency" && values[0] != "") {
					var BankInfo = {};
					var name = this.parseName(values[2]);
					var nameParts = name.split(" ");
					if (nameParts.length > 2 || nameParts.length < 2) {
						BankInfo.CompanyName = name;
					} else {
						BankInfo.FirstName = nameParts[0];
						BankInfo.LastName = nameParts[1];
					}

					BankInfo.Currency = values[0];
					BankInfo.Address1 = values[3];
					BankInfo.City = values[4];
					BankInfo.Country = this.parseCountry(values[6]);
					BankInfo.Province = this.parseProvince(values[5], BankInfo.Country);

					BankInfo.PostalCode = values[7];
					BankInfo.ClientReferenceNumber = values[8];
					BankInfo.FinancialInstitutionNumber = this.parseNumber(values[9]);
					BankInfo.BranchTransitNumber = this.parseNumber(values[10]);
					BankInfo.AccountNumber = this.parseNumber(values[11]);
					BankInfo.Amount = this.parseDollar(values[1]);
					BankInfo.Currency = values[0];
					bankingObjects.push(BankInfo);
				}
			}

			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document").toLowerCase() == "credit") {
					this.checkBalance(bankingObjects).then(function (data) {
						if (data === true) {
							// errors2.push("Submitting "+arrayObjects.length+" transactions");
							_this9.payment(bankingObjects, 0, bankingObjects.length, _this9.get("Document"), _this9.get("Service"), errors);
						} else {

							// errors2.push("Insufficient available funds on account");

							_this9.set("ErrorMessage", "Insufficient Funds");
							_this9.set("isError", true);
						}
					}, function (error) {
						_this9.set("ErrorMessage", "Unsupported Currency");
						_this9.set("isError", true);
					});
				} else {
					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		parseCSV: function parseCSV(lines) {
			var _this10 = this;

			var Currency = "CAD";
			var bankingObjects = [];
			for (var index = 0; index < lines.length; index++) {
				var values = lines[index].split(';');
				var offset = 0;

				if (values[0].toLowerCase() == "debit" || values[0].toLowerCase() == "credit" || values[0].toLowerCase() == "service" || values.length == 9 || values.length == 13 || values.length == 7) {
					offset = 1;
				}
				if (values[0] === "Transfer") {
					var BankInfo = {};
					BankInfo.RecipientName = values[2];
					BankInfo.Email = values[3];
					BankInfo.Question = values[6];
					BankInfo.Answer = values[7];
					BankInfo.ClientReferenceNumber = values[14];
					BankInfo.Currency = values[9];
					BankInfo.Amount = values[10];
					BankInfo.Language = values[5];
					bankingObjects.push(BankInfo);
				} else if (this.get("Service") === "e-Transfer") {
					this.set("isCSV", true);
					if ((values[0 + offset] + "").trim() != "Currency" && values.length > 3) {

						if (this.get("Document").toLowerCase() == "credit") {
							this.set("isBulkPayout", true);
							var BankInfo = {};
							BankInfo.RecipientName = (values[2 + offset] + "").trim();
							BankInfo.Email = (values[3 + offset] + "").trim();
							BankInfo.Question = (values[4 + offset] + "").trim();
							BankInfo.Answer = (values[5 + offset] + "").trim();
							BankInfo.ClientReferenceNumber = (values[7 + offset] + "").trim();
							BankInfo.Currency = (values[0 + offset] + "").trim();;
							BankInfo.Amount = this.parseDollar((values[1 + offset] + "").trim());
							BankInfo.Memo = (values[6 + offset] + "").trim();
							BankInfo.IdempotencyKey = (values[8 + offset] + "").trim();

							if (values[9 + offset]) {
								BankInfo.ClientAccountID = (values[9 + offset] + "").trim();
							}

							if (values[10 + offset]) {
								BankInfo.PhoneNumber = (values[10 + offset] + "").trim();
							}

							if (values[11 + offset]) {
								BankInfo.Notes = (values[11 + offset] + "").trim();
							}

							bankingObjects.push(BankInfo);
							if (offset == 1) {
								BankInfo.Document = (values[0] + "").trim();;
							}
						} else {
							this.set("isMoneyRequest", true);
							var BankInfo = {};
							BankInfo.RecipientName = (values[2 + offset] + "").trim();
							BankInfo.Email = (values[3 + offset] + "").trim();
							BankInfo.ClientReferenceNumber = (values[5 + offset] + "").trim();
							BankInfo.Currency = (values[0 + offset] + "").trim();
							BankInfo.Amount = this.parseDollar((values[1 + offset] + "").trim());
							BankInfo.MessageForRecipient = (values[4 + offset] + "").trim();
							BankInfo.IdempotencyKey = (values[6 + offset] + "").trim();

							if (values[7 + offset]) {
								BankInfo.ClientAccountID = (values[7 + offset] + "").trim();
							}

							if (values[8 + offset]) {
								BankInfo.PhoneNumber = (values[8 + offset] + "").trim();
							}

							if (values[9 + offset]) {
								BankInfo.Notes = (values[9 + offset] + "").trim();
							}

							bankingObjects.push(BankInfo);
							if (offset == 1) {
								BankInfo.Document = (values[0] + "").trim();;
							}
						}
					}
				} else if (this.get("Service") === "EFT") {
					this.set("isCSV", true);
					this.set("isEFT", true);
					if ((values[0 + offset] + "").trim() != "Currency" && values.length > 3) {
						var BankInfo = {};
						var name = (values[2 + offset] + "").trim();
						var nameParts = name.split(" ");
						if (nameParts.length > 2 || nameParts.length < 2) {
							BankInfo.CompanyName = name;
						} else {
							BankInfo.FirstName = nameParts[0];
							BankInfo.LastName = nameParts[1];
						}
						BankInfo.Address1 = (values[3 + offset] + "").trim();
						BankInfo.City = (values[4 + offset] + "").trim();
						BankInfo.Country = this.parseCountry((values[6 + offset] + "").trim());
						BankInfo.Province = this.parseProvince((values[5 + offset] + "").trim(), BankInfo.Country);
						BankInfo.PostalCode = (values[7 + offset] + "").trim();
						BankInfo.ClientReferenceNumber = (values[8 + offset] + "").trim();
						BankInfo.FinancialInstitutionNumber = this.parseNumber((values[9 + offset] + "").trim());
						BankInfo.BranchTransitNumber = this.parseNumber((values[10 + offset] + "").trim());
						BankInfo.AccountNumber = this.parseNumber((values[11 + offset] + "").trim());
						BankInfo.Amount = this.parseDollar((values[1 + offset] + "").trim());
						BankInfo.Currency = (values[0 + offset] + "").trim();
						if (values[21 + offset]) {
							BankInfo.ScheduleStartDate = (values[21 + offset] + "").trim();
						}
						if (values[20 + offset]) {
							BankInfo.IdempotencyKey = (values[20 + offset] + "").trim();
						}
						if (values[12 + offset]) {
							BankInfo.Token = (values[12 + offset] + "").trim();
						}
						if (values[13 + offset]) {
							BankInfo.FlinksAccountID = (values[13 + offset] + "").trim();
						}
						if (values[14 + offset]) {
							BankInfo.FlinksLoginID = (values[14 + offset] + "").trim();
						}
						if (values[15 + offset]) {
							BankInfo.PlaidPublicToken = (values[15 + offset] + "").trim();
						}
						if (values[16 + offset]) {
							BankInfo.PlaidAccessToken = (values[16 + offset] + "").trim();
						}
						if (values[17 + offset]) {
							BankInfo.PlaidAccountID = (values[17 + offset] + "").trim();
						}
						if (values[18 + offset]) {
							BankInfo.PlaidProcessorToken = (values[18 + offset] + "").trim();
						}
						if (values[19 + offset]) {
							BankInfo.InveriteRequestGUID = (values[19 + offset] + "").trim();
						}
						if (values[22 + offset]) {
							BankInfo.ClientAccountID = (values[22 + offset] + "").trim();
						}
						if (values[23 + offset]) {
							BankInfo.TransactionLabel = (values[23 + offset] + "").trim();
						}
						if (values[24 + offset]) {
							BankInfo.Notes = (values[24 + offset] + "").trim();
						}

						if (offset == 1) {
							BankInfo.Document = (values[0] + "").trim();
						}
						bankingObjects.push(BankInfo);
					}
				} else if (this.get("Service") === "Pay Link") {
					this.set("isCSV", true);
					this.set("isPaylink", true);
					if (("" + values[0]).trim() !== 'Client AccountID' && values.length > 3) {
						var transaction = {};
						transaction.ClientAccountID = ("" + values[0]).trim();
						transaction.ReceiverName = ("" + values[1]).trim();
						transaction.ReceiverEmailAddress = ("" + values[2]).trim();
						if (values[3]) {
							transaction.SenderName = ("" + values[3]).trim();
						}
						if (values[4]) {
							transaction.Note = ("" + values[4]).trim();
						}
						if (values[5]) {
							transaction.ClientReferenceNumber = ("" + values[5]).trim();
						}
						if (values[6]) {
							transaction.PaymentType = ("" + values[6]).trim();
						}
						if (values[7]) {
							transaction.Amount = this.parseDollar(("" + values[7]).trim()) || '';
						}
						if (values[8]) {
							transaction.Currency = ("" + values[8]).trim();
						}
						if (values[9]) {
							transaction.Frequency = ("" + values[9]).trim();
						}
						if (values[10]) {
							transaction.ScheduleStartDate = ("" + values[10]).trim();
						}
						if (values[11]) {
							transaction.ScheduleEndDate = ("" + values[11]).trim();
						}
						if (values[12]) {
							transaction.NameOfFrequency = ("" + values[12]).trim();
						}
						if (values[13]) {
							transaction.SemiMonthlyFrequencyType = ("" + values[13]).trim();
						}
						if (values[14]) {
							transaction.EndingAfterPayments = ("" + values[14]).trim();
						}
						if (values[15]) {
							transaction.RecipientMessage = ("" + values[15]).trim();
						}
						if (values[16]) {
							transaction.SendEmail = ("" + values[16]).trim();
						}
						if (values[17]) {
							transaction.InvoiceNumber = ("" + values[17]).trim();
						}
						if (values[18]) {
							transaction.DocumentID = ("" + values[18]).trim();
						}
						if (values[19]) {
							transaction.TransactionLabel = ("" + values[19]).trim();
						}
						if (values[20]) {
							transaction.IframeKey = ("" + values[20]).trim();
						}
						bankingObjects.push(transaction);
					}
				} else if (this.get("Service") === "VoPayInstant") {
					this.set("isCSV", true);
					this.set("isRTR", true);
					if ((values[0] + "").trim() != "Service" && values.length > 3 && values.length < 13) {
						var BankInfo = {};
						var name = (values[3] + "").trim();
						var nameParts = name.split(" ");
						if (nameParts.length > 2 || nameParts.length < 2) {
							BankInfo.FirstName = name;
							BankInfo.LastName = " ";
						} else {
							BankInfo.FirstName = nameParts[0];
							BankInfo.LastName = nameParts[1];
						}
						BankInfo.Document = (values[0] + "").trim();
						BankInfo.Currency = (values[1] + "").trim();
						BankInfo.Amount = this.parseDollar((values[2] + "").trim());

						BankInfo.ClientReferenceNumber = (values[4] + "").trim();
						BankInfo.FinancialInstitutionNumber = this.parseNumber((values[5] + "").trim());
						BankInfo.BranchTransitNumber = this.parseNumber((values[6] + "").trim());
						BankInfo.AccountNumber = this.parseNumber((values[7] + "").trim());
						if (values[8]) {
							BankInfo.Token = (values[8] + "").trim();
						}
						if (values[9]) {
							BankInfo.IdempotencyKey = (values[9] + "").trim();
						}
						if (values[10]) {
							BankInfo.ClientAccountID = (values[10] + "").trim();
						}
						if (values[11]) {
							BankInfo.Notes = (values[11] + "").trim();
						}
						bankingObjects.push(BankInfo);
					} else if ((values[0 + offset] + "").trim() != "Currency" && values.length > 3) {
						var BankInfo = {};
						var name = (values[2 + offset] + "").trim();
						var nameParts = name.split(" ");
						if (nameParts.length > 2 || nameParts.length < 2) {
							BankInfo.FirstName = name;
							BankInfo.LastName = " ";
						} else {
							BankInfo.FirstName = nameParts[0];
							BankInfo.LastName = nameParts[1];
						}
						BankInfo.ClientReferenceNumber = (values[8 + offset] + "").trim();
						BankInfo.FinancialInstitutionNumber = this.parseNumber((values[9 + offset] + "").trim());
						BankInfo.BranchTransitNumber = this.parseNumber((values[10 + offset] + "").trim());
						BankInfo.AccountNumber = this.parseNumber((values[11 + offset] + "").trim());
						BankInfo.Amount = this.parseDollar((values[1 + offset] + "").trim());
						BankInfo.Currency = (values[0 + offset] + "").trim();

						if (values[20 + offset]) {
							BankInfo.IdempotencyKey = (values[20 + offset] + "").trim();
						}
						if (values[12 + offset]) {
							BankInfo.Token = (values[12 + offset] + "").trim();
						}
						if (offset == 1) {
							BankInfo.Document = (values[0] + "").trim();
						}
						bankingObjects.push(BankInfo);
					}
				}
			}

			var errors = [];
			if (bankingObjects && bankingObjects.length > 0) {
				if (this.get("Document").toLowerCase() == "credit") {

					this.checkBalance(bankingObjects).then(function (data) {

						if (data === true) {
							// errors2.push("Submitting "+arrayObjects.length+" transactions");

							_this10.payment(bankingObjects, 0, bankingObjects.length, _this10.get("Document"), _this10.get("Service"), errors);
						} else {

							// errors2.push("Insufficient available funds on account");

							_this10.set("ErrorMessage", "Insufficient Funds");
							_this10.set("isError", true);
						}
					}, function (error) {
						_this10.set("ErrorMessage", "Unsupported Currency");
						_this10.set("isError", true);
					});
				} else {
					this.payment(bankingObjects, 0, bankingObjects.length, this.get("Document"), this.get("Service"), errors);
				}
			} else {
				this.set("isEmpty", true);
			}
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currenciesEFT');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},
		actions: {
			closeModalProgress: function closeModalProgress() {
				this.set("isProgress", false);
			},
			handleService: function handleService(value) {
				if (this.get("Service") == "VoPayInstant" && this.get("Service") != value) {
					this.set("Document", "Debit");
				}
				this.set("Service", value);
				if (value != "EFT") {
					this.set("currency", "CAD");
					this.set("currencyName", this.getCurrencyName("CAD"));
				}
				if (value == "VoPayInstant") {
					this.set("Document", "Credit");
				}
			},
			handleDocument: function handleDocument(value) {
				if (value == "Credit (Send Funds)") {
					this.set("Document", "Credit");
				} else {
					this.set("Document", "Debit");
				}
			},
			SendBulkFile: function SendBulkFile() {
				var _this11 = this;

				this.set("isProgress", true);
				this.set("isFinished", false);
				var listing2 = this.get("listing");
				var errors2 = this.get("progress");
				var totalAmount = this.get("totalAmount");
				var self = this;
				var batch = {};
				var Service = this.get("Service");
				var PaymentType = this.get("Document");
				if (listing2 != null) {
					batch.BatchData = listing2;
					batch.TransactionType = Service;
					batch.PaymentType = PaymentType;
					batch.Length = listing2.length;
					batch.TotalAmount = totalAmount;
					batch.BatchName = this.get("filename");
					if (batch.TransactionType == "e-Transfer") {
						batch.TransactionType = "Interac";
					} else if (batch.TransactionType == "Pay Link") {
						batch.TransactionType = "Paylink";
					}
					this.payload(batch).then(function (data) {
						errors2.sort(function (a, b) {

							// Compare the 2 dates
							if (a.Id < b.Id) return -1;

							if (a.Id > b.Id) return 1;
							return 0;
						});
						_this11.finish(errors2);
					}, function (error) {
						_this11.set("ErrorMessage", error);
						_this11.set("isError", true);
						_this11.set("isProgress", false);
					});
				}

				this.set('isEFT', false);
				this.set('isBulkPayout', false);
				this.set('isMoneyRequest', false);
				this.set('isPaylink', false);
				this.set('isRTR', false);

				_ember["default"].$("#FileOverview").hide();
				_ember["default"].$("#FileSummary").show();
			},
			moveToUploadFile: function moveToUploadFile() {
				_ember["default"].$("#FileSummary").hide();
				_ember["default"].$("#FileInput").show();
				_ember["default"].$("#uploadFile").val(null);
			},
			backToFileInput: function backToFileInput() {
				_ember["default"].$("#FileInput").show();
				_ember["default"].$("#FileOverview").hide();
				this.set("listing", null);
				this.set("progress", null);
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				this.set("currency", currency);
				this.set("currencyName", this.getCurrencyName(currency));
			},
			ProcessBulkFile: function ProcessBulkFile() {
				var _this12 = this;

				clearInterval(window.sessionTimeout);
				if ($('#uploadFile').parsley().validate()) {
					var file;
					var name;
					var ext;
					var reader2;
					var f;
					var reader;
					var reader2;
					var reader2;

					(function () {
						file = $('#uploadFile')[0].files[0];
						name = file.name;
						ext = name.split('.').pop().toLowerCase();

						var self = _this12;
						_this12.set("filename", name);
						_this12.set("isCSV", false);
						if (ext === "csv") {
							(function () {
								reader2 = new FileReader();

								var self = _this12;
								reader2.onload = function (event) {
									// Then you can do something with the result
									var data = event.target.result;

									var wb = XLSX.read(data, { type: 'binary', raw: true });
									var lines = [];
									wb.SheetNames.forEach(function (sheetName) {
										var csv = XLSX.utils.sheet_to_csv(wb.Sheets[sheetName], { FS: ";", blankrows: false });
										if (csv.length) {
											var csvArr = csv.split("\n");
											lines.push(csvArr);
										}
									});
									var results = lines[0];
									var values = results[0].split(';');

									if (self.get("Service") === "Pay Link" && (values[0] === 'Receiver Name' || (("" + values[5]).trim().toLocaleLowerCase() === 'fund' || ("" + values[5]).trim().toLocaleLowerCase() === 'withdraw' || ("" + values[5]).trim().toLocaleLowerCase() === '') && ("" + values[6]).trim().toLocaleLowerCase() !== 'fund' && ("" + values[6]).trim().toLocaleLowerCase() !== 'withdraw')) {
										self.set("ErrorMessage", 'Please note that the selected file is invalid. We recommend downloading the template and attempting the process again.');
										self.set("isError", true);
									} else {
										self.parseCSV(results);
									}
								};

								reader2.readAsBinaryString(file);
							})();
						} else if (ext === "xlsx" || ext === "xls" || ext === "odf" || ext === "ods" || ext === "csv" || ext === "tsv") {
							f = file;
							reader = new FileReader();

							reader.onload = function (e) {
								var data = e.target.result;
								var wb = XLSX.read(data, { type: 'binary' });
								self.processFile(wb);
							};

							reader.readAsBinaryString(f);
						} else if (ext === "txt") {
							(function () {
								reader2 = new FileReader();

								var self = _this12;
								reader2.onload = function (event) {
									// Then you can do something with the result
									var data = event.target.result;
									var lines = data.split(/\r?\n/);
									var values = lines[0].split('\t');
									if (values[0] == "Currency" || values[0] == "CAD") {

										//data = data.replace( /(\n|\r)\t/, '\t');
										//var lines = data.split(/\r?\n/);
										self.processMoneyWay(lines);
									} else {
										if (lines[0].length == 1464) {
											self.processBankingFileStandard005(lines);
										} else if (lines[0].length == 80 || lines[0].length == 27 || lines[0].length == 26 || lines[0].length == 28) {
											self.processBankingFileBMO80(lines);
										} else {
											self.set("isInvalid", true);
										}
									}
								};

								reader2.readAsText(file, "ASCII");
							})();
						} else if (ext === "deposits_out") {
							(function () {
								reader2 = new FileReader();

								var self = _this12;
								reader2.onload = function (event) {
									// Then you can do something with the result
									var data = event.target.result;
									var lines = data.split(/\r?\n/);
									if (lines[0].length == 1464) {
										self.processBankingFileBMO2(lines);
									} else {
										self.set("isInvalid", true);
									}
								};

								reader2.readAsText(file);
							})();
						} else {
							_this12.set("isInvalid", true);
						}
					})();
				}
			},
			pdf: function pdf() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'pdf', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			csv: function csv() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xls: function xls() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'csv', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			doc: function doc() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			text: function text() {

				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			json: function json() {
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			xml: function xml() {
				setTimeout(function () {
					_ember["default"].$('#tableID').addClass("importantShow");
					_ember["default"].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember["default"].$('#tableID').removeClass("importantShow");
				}, 200);
				return false;
			},
			printPage: function printPage() {
				setTimeout(function () {
					window.print();
				}, 200);
			}

		}
	});
});