define("vopay_app/helpers/past-today", ["exports", "ember"], function (exports, _ember) {
  exports.pastToday = pastToday;

  function pastToday(params /*, hash*/) {
    if (params[0]) {

      if (moment().utc().format("YYYY-MM-DD") >= params[0]) {
        // Date equals today's date
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(pastToday);
});