define('vopay_app/components/create-beneficiary', ['exports', 'ember', 'vopay_app/constants', 'vopay_app/helpers/complete-with-zeros', 'vopay_app/helpers/mask-account', 'vopay_app/utils/entry-filter', 'vopay_app/mixins/error-handler', 'vopay_app/config/environment'], function (exports, _ember, _vopay_appConstants, _vopay_appHelpersCompleteWithZeros, _vopay_appHelpersMaskAccount, _vopay_appUtilsEntryFilter, _vopay_appMixinsErrorHandler, _vopay_appConfigEnvironment) {
	var businessShortName = _vopay_appConfigEnvironment['default'].businessShortName;
	var businessLongName = _vopay_appConfigEnvironment['default'].businessLongName;

	exports['default'] = _ember['default'].Component.extend(_vopay_appMixinsErrorHandler['default'], {
		SupportEmail: _vopay_appConfigEnvironment['default'].businessEmail,
		LongName: businessLongName,
		ShortName: businessShortName,
		api: _ember['default'].inject.service('api'),
		firebase: _ember['default'].inject.service('firebase'),
		routing: _ember['default'].inject.service('-routing'),
		institutionName: '',
		financialInstitutionNumber: null,
		accountNumber: null,
		branchTransitNumber: null,
		entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
		isStore: true,
		isDefault: true,
		isEdit: false,
		userName: null,
		signatureNumbers: [],
		signatureCurrNum: 0,
		signatureNumDel: 0,
		accountOptions: ["Deposit", "Withdraw"],
		accountTypeOptions: ["Business", "Individual"],
		//Add Credit Card to list for saving credit cards
		paymentTypeOptions: ["Bank Account", "Interac E-Transfer"],
		paymentTypeOptionsCollect: ["Bank Account", "Interac E-Transfer"],
		paymentTypeOptionsSingle: ["Bank Account"],
		InformationOptions: ["Manual", "Online Banking"],
		defaultPaymentType: "Bank Account",
		defaultAccountType: "Business",
		AccountSelection: ["Any", "Online", "Manual"],
		Language: ["English", "French"],
		isBusiness: true,
		isDeposit: false,
		isCredit: false,
		isDual: false,
		country: "Canada",
		currency: "CAD",
		currencyName: "Canadian Dollar",
		currency2: "CAD",
		isInteracEtransfer: false,
		countryID: 2,
		stageNumber: 1,
		ClientAccountsEnabled: false,
		stageName: "Account Details",
		defaultType: "Deposit",
		amount: "10,000.00",
		max: "0",
		isNew: false,
		isShowingModal: false,
		beneficiaries: null,
		FlinksURL: "",
		isoCountry: "CA",
		isVisaDirect: false,
		isSendPaylinkEmail: true,
		currencies2: _vopay_appConstants['default'].currencies,
		currencies4: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			},
			USD: {
				name: 'United States Dollar',
				iso3: 'USD'
			}
		},
		currencies5: {
			CAD: {
				name: 'Canadian Dollar',
				iso3: 'CAD'
			}
		},
		agreedToTerms: false,
		// Avoiding wrong entry data
		watchReceiverName: _ember['default'].observer('receiverName', function () {
			this.set('receiverName', this.get('entryUtil').onlyNameCharacters(this.get('receiverName')));
		}),
		watchIsName: _ember['default'].observer("isAgree", function () {
			if (this.get("isAgree") === true) {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
			}
		}),
		watchUserName: _ember['default'].observer('userName', function () {

			this.set('userName', this.get('entryUtil').onlyCharacters(this.get('userName')));
		}),

		watchfInstitutionNumber: _ember['default'].observer('financialInstitutionNumber', function () {

			this.set('financialInstitutionNumber', this.get('entryUtil').onlyNumbers(this.get('financialInstitutionNumber')));
		}),

		watchAccountNumber: _ember['default'].observer('accountNumber', function () {

			this.set('accountNumber', this.get('entryUtil').onlyNumbers(this.get('accountNumber')));
		}),

		watchBranchTransitNumber: _ember['default'].observer('branchTransitNumber', function () {

			this.set('branchTransitNumber', this.get('entryUtil').onlyNumbers(this.get('branchTransitNumber')));
		}),
		watchPhoneNumber: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		watchDualPhoneNumber: _ember['default'].observer('DualPhoneNumber', function () {

			this.set('DualPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('DualPhoneNumber')));
		}),
		watchSignatoryPhoneNumber: _ember['default'].observer('SignatoryPhoneNumber', function () {

			this.set('SignatoryPhoneNumber', this.get('entryUtil').onlyNumbers(this.get('SignatoryPhoneNumber')));
		}),
		watchPhoneNumberRequest: _ember['default'].observer('PhoneNumber', function () {

			this.set('PhoneNumber', this.get('entryUtil').onlyNumbers(this.get('PhoneNumber')));
		}),
		watchFirstName: _ember['default'].observer('firstname', function () {
			this.set('firstname', this.get('entryUtil').onlyNameCharacters(this.get('firstname')));
		}),

		watchLastName: _ember['default'].observer('lastname', function () {
			this.set('lastname', this.get('entryUtil').onlyNameCharacters(this.get('lastname')));
		}),
		watchCompanyName: _ember['default'].observer('companyName', function () {
			this.set('companyName', this.get('entryUtil').onlyNameCharacters(this.get('companyName')));
		}),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			this.set("myAccount", this.get("myAccount"));

			if (localStorage.getItem("GCMEnabled") === "1") {} else if (localStorage.getItem("USDEFTCollectEnabled") === "1" || localStorage.getItem("USDEFTSendEnabled") === "1") {

				this.set("currencies2", this.get("currencies4"));
			} else {
				this.set("currencies2", this.get("currencies5"));
			}
			if (localStorage.getItem("ClientAccountsEnabled") === "1") {
				this.set("ClientAccountsEnabled", true);
			}
			if (sessionStorage.getItem("currency")) {
				this.set("currency2", sessionStorage.getItem("currency"));
				var currencyList = this.get("currencies2");
				this.set("currencyName", currencyList[sessionStorage.getItem("currency")].name);
				sessionStorage.removeItem("currency");
			}
			var currencies2 = this.get("currencies2");
			var currencies = this.get("currencies.CurrenciesList");
			var currencies3 = {};
			for (var key in currencies) {
				if (currencies2[currencies[key]]) {
					currencies3[currencies[key]] = {};
					currencies3[currencies[key]]["name"] = currencies2[currencies[key]]["name"];
					currencies3[currencies[key]]["iso3"] = currencies2[currencies[key]]["iso3"];
				}
			}
			if (localStorage.getItem("isPayment") === "true") {
				this.set("isPayment", true);
				localStorage.removeItem("isPayment");
			} else {
				this.set("isPayment", false);
				localStorage.removeItem("isPayment");
			}

			this.set("currencies3", currencies3);

			this.get('api').getCountries().then(function (data) {
				var canada = {
					"CountryID": "1",
					"Country": "Canada",
					"Abbreviation": "CA",
					"Airtime": "0",
					"MoneyTransfer": "0",
					"BillPayment": "0",
					"Voucher": "0",
					"DefaultCurrency": "CAD",
					"DeliveryMethods": {
						"0": "bank"
					}
				};
				data.push(canada);
				_this.set('countries', data);
				_this.filterCountryList(_this.get("currency2"));
			});

			var name = this.get("userInfo.FirstName") + " " + this.get("userInfo.LastName");
			this.set("userName", name);

			var IsSendEmail = this.get("PaylinkSettings.PaylinkSettings.ReceiverNotificationsEnabled");
			if (IsSendEmail == true || IsSendEmail == "1") {
				this.set("isSendPaylinkEmail", true);
			} else {
				this.set("isSendPaylinkEmail", false);
			}
			// this.set("isDeposit",sessionStorage.getItem("isDeposit"));

			// if(this.get("isDeposit") === "true"){
			// 	this.set("defaultType", "Deposit");
			// }
			// else{
			// 	this.set("defaultType", "Withdraw");
			// }
		},
		SetIq11URL: function SetIq11URL() {
			var _this2 = this;

			var options = null;
			var AccountSelectionMethod = "any";
			if (this.get("isDeposit") !== "true") {
				AccountSelectionMethod = "manual";
			}

			if (location.hostname == "localhost") {
				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: this.get("myAccount"),
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else if (location.hostname == "bs-local.com") {

				options = {
					RedirectURL: "https://" + location.hostname + ":4200/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: this.get("myAccount"),
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			} else {
				options = {
					RedirectURL: "https://" + location.hostname + "/",
					AccountSelectionMethod: AccountSelectionMethod,
					customerName: this.get("LongName"),
					ClientControlled: this.get("myAccount"),
					ClientReferenceNumber: this.get("ClientReferenceNumber")
				};
			}
			this.get('api').getApiURL(options).then(function (data) {
				_this2.set("FlinksURL", data.EmbedURL);
			});
		},
		didInsertElement: function didInsertElement() {

			this._super.apply(this, arguments);
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});
			_ember['default'].$("#paymentForm").parsley().destroy();
			_ember['default'].$('form').parsley({
				successClass: " ",
				errorClass: "form__input--error",
				errorsWrapper: "<p></p>",
				errorTemplate: "<span class='form__message'></span>"
			});
			_ember['default'].$('#month').keypress(function () {

				if (_ember['default'].$(this).val().length > 0) {
					_ember['default'].$('#year').focus();
				}
			});

			_ember['default'].$('#financialInstitutionNumber').focusout(function () {
				_ember['default'].$(".newPaymentMethod__institution").slideDown();
			});

			_ember['default'].$("#beneficiary-address").focusout(function () {
				setTimeout(function () {
					var address = _ember['default'].$("#beneficiary-address").val();
					var city = _ember['default'].$("#beneficiary-city").val();
					var state = _ember['default'].$("#beneficiary-state").val();
					var postcode = _ember['default'].$("#beneficiary-postcode").val();
					if (address) {
						_ember['default'].$("#beneficiary-address").parsley().reset();
					}
					if (city) {
						_ember['default'].$("#beneficiary-city").parsley().reset();
					}
					if (state) {
						_ember['default'].$("#beneficiary-state").parsley().reset();
					}
					if (postcode) {
						_ember['default'].$("#beneficiary-postcode").parsley().reset();
					}
				}, 500);
			});
			_ember['default'].$("#beneficiary-address").keyup(function () {
				if (!_ember['default'].$("#beneficiary-address").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-city").keyup(function () {
				if (!_ember['default'].$("#beneficiary-city").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			_ember['default'].$("#beneficiary-postcode").keyup(function () {
				if (!_ember['default'].$("#beneficiary-postcode").val()) {
					_ember['default'].$("#beneficiary-city").val("");
					_ember['default'].$("#beneficiary-postcode").val("");
					_ember['default'].$("#beneficiary-address").val("");
					_ember['default'].$("#beneficiary-state").val("");
				}
			});
			new ClipboardJS('.copy');
			var self = this;
			_ember['default'].$("#beneficiary-address").on('input', function () {

				_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
			});
			_ember['default'].$("#beneficiary-city").on('input', function () {

				_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
			});
			_ember['default'].$("#beneficiary-postcode").on('input', function () {

				_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
			});
			window.addEventListener('message', function (e) {
				if (e.data.step == "ACCOUNT_SELECTED") {
					localStorage.setItem("accountId", e.data.accountId);
				}
				if (e.data.step == "REDIRECT") {
					localStorage.setItem("loginId", e.data.loginId);
					localStorage.setItem("institution", e.data.institution);
					//location.href = e.data.url;
				}
				if (e.data.Step == "LINK") {
					localStorage.setItem("institution", e.data.Institution);
					localStorage.setItem("AccountNumber", e.data.AccountNumber);
					localStorage.setItem("BankId", e.data.BankId);
					localStorage.setItem("InstitutionNumber", e.data.InstitutionNumber);

					localStorage.setItem("FlinksToken", e.data.Token);
					localStorage.setItem("CreditCardBrand", e.data.CreditCardBrand);
					localStorage.setItem("CreditCardExpiryMonth", e.data.CreditCardExpiryMonth);
					localStorage.setItem("CreditCardExpiryYear", e.data.CreditCardExpiryYear);
					localStorage.setItem("CreditCardNumber", e.data.CreditCardNumber);
					localStorage.setItem("CreditCardHolderName", e.data.CreditCardHolderName);
				}
			});
		},
		MoveToOverview: function MoveToOverview(accountId, institution, loginId) {},
		initialiseAutoAddress: function initialiseAutoAddress() {
			//let countries = Constants.countries;
			/** 
   let fields = [{
   	element: "beneficiary-address",
   	field: "Line1"
   }, {
   	element: "beneficiary-state",
   	field: "ProvinceCode",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-city",
   	field: "City",
   	mode: window.pca.fieldMode.POPULATE
   }, {
   	element: "beneficiary-postcode",
   	field: "PostalCode",
   	mode: window.pca.fieldMode.POPULATE
   },
   { element: "Country", field: "CountryName", mode: window.pca.fieldMode.COUNTRY }],
   options = {
   	key: "fp61-gw79-xc88-xy19"
   },
     control = new window.pca.Address(fields, options); // jshint ignore:line
   **/
			window.initializeLocationIQ("beneficiary-address", "beneficiary-address2", "Country", "beneficiary-state", "beneficiary-city", "beneficiary-postcode", this.get("isoCountry"), "Address1");
		},
		validateForm: function validateForm(formId) {

			var form = _ember['default'].$(formId);

			form.parsley({
				excluded: 'input[type=button], input[type=submit], input[type=reset]',
				inputs: 'input, textarea, select, input[type=hidden], :hidden'
			}).validate();

			return form.parsley().isValid();
		},
		filterCountryList: function filterCountryList(currency) {
			//var filteredCountires = {};

			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === currency.toUpperCase();
			});

			if (localStorage.getItem("GCMEnabled") === "0") {
				ctr = [];
			}
			if (currency == "USD" && (localStorage.getItem("USDEFTCollectEnabled") === "1" || localStorage.getItem("USDEFTSendEnabled") === "1")) {
				var obj = {
					Abbreviation: "CA",
					Airtime: "0",
					BillPayment: "0",
					Country: "Canada",
					CountryID: "1",
					DefaultCurrency: "CAD"
				};

				ctr.unshift(obj);
			}
			this.set("countries2", ctr);
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		addPaymentMethod: function addPaymentMethod(formObj) {

			var arrLength = formObj.length;
			var payment = {};

			for (var i = 0; i < arrLength; i++) {
				payment[formObj[i].name] = formObj[i].value;
			}

			delete payment.InstitutionName;

			return payment;
		},
		getIsoCountry: function getIsoCountry(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Abbreviation;
			});
			return ctr[0];
		},
		getCurrency: function getCurrency(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.DefaultCurrency;
			});
			return ctr[0];
		},
		getCountryID: function getCountryID(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.Country.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.CountryID;
			});
			return ctr[0];
		},
		getCurrencyName: function getCurrencyName(name) {
			var ctr = this.get('currencies2');
			for (var key in ctr) {
				if (ctr[key].iso3 === name) {
					return ctr[key].name;
				}
			}
		},

		checkIsAllowed: function checkIsAllowed() {

			if (localStorage.getItem("InteracMoneyRequestEnabled") === "0" && localStorage.getItem("InteracBulkPayoutEnabled ") === "0" && this.get("isInteracEtransfer")) {
				this.set("isShowingModal", true);
				return false;
			} else if (this.get("currency2") !== "CAD" && this.get("isInteracEtransfer")) {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("PortalViewOnly") === "1") {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("CreditCardEnabled") === "0" && this.get("isCreditCard")) {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("VisaDirectEnabled") === "0" && this.get("isVisaDirect")) {
				this.set("isShowingModal", true);
				return false;
			} else if (localStorage.getItem("EFTCollectEnabled") === "0" && localStorage.getItem("EFTSendEnabled") === "0" && !this.get("isInteracEtransfer") && !this.get("isCreditCard") && !this.get("isVisaDirect")) {
				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isRequest")) {
				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isoCountry") !== "CA" && localStorage.getItem("GCMEnabled") === "0") {
				this.set("isShowingModal", true);
				return false;
			} else if (this.get("isoCountry") === "CA" && this.get("currency2") == "USD" && localStorage.getItem("USDEFTCollectEnabled") === "0" && localStorage.getItem("USDEFTSendEnabled") === "0") {
				this.set("isShowingModal", true);
				return false;
			} else {
				return true;
			}
		},
		getCountryName: function getCountryName(name) {
			var ctr = this.get('countries').filter(function (f) {
				return f.DefaultCurrency.toUpperCase() === name.toUpperCase();
			}).map(function (c) {
				return c.Country;
			});
			if (ctr[0] == null) {
				return "Canada";
			} else {
				return ctr[0];
			}
		},
		actions: {
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			openModal: function openModal() {
				this.set("isShowingModal", true);
				return false;
			},
			closeModalFlinks: function closeModalFlinks() {
				this.set("isFlinks", false);
				clearInterval(window.flinksInterval);
			},

			recipientCountryChanged: function recipientCountryChanged(country) {
				this.set("showRecipient", false);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
			},
			recipientCurrencyChanged: function recipientCurrencyChanged(currency) {
				var country = this.getCountryName(currency);
				this.set('country', country);
				this.set("isoCountry", this.getIsoCountry(country));
				this.set('countryID', this.getCountryID(country));
				this.set("currency2", currency);
				if (currency == "USD" && (localStorage.getItem("USDEFTCollectEnabled") === "1" || localStorage.getItem("USDEFTSendEnabled") === "1")) {
					this.set('country', "Canada");
					this.set("isoCountry", this.getIsoCountry("Canada"));
				}
				this.set("isInteracEtransfer", false);
				this.set("isCredit", true);
				this.set("isCredit", false);
				this.set("isRequest", false);
				this.filterCountryList(currency);

				this.set("currencyName", this.getCurrencyName(currency));
			},

			moveToBank: function moveToBank() {
				var _this3 = this;

				if (this.validateForm('#paymentForm')) {
					(function () {
						/**
       		this.set("isBank", true);
      Ember.$("#Overview").hide();
      this.set("isOverview", false);
        	Ember.$("#paymentInfo").hide();
        	Ember.$("#benifeciaryInfo").hide();
        	Ember.$("#bankInfo").show();
        	this.set("stageNumber", 3);
        	this.set("stageName", "Bank Details");
        	localStorage.removeItem("accountId")
            localStorage.removeItem("institution")
      localStorage.removeItem("loginId") **/
						_this3.SetIq11URL();
						_this3.set("isFlinks", true);
						var self = _this3;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								self.set("isOverview", true);
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);

								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								localStorage.removeItem("AccountNumber");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				}
			},
			moveToPayment: function moveToPayment() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#paymentInfo").show();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#etransfer").hide();
				_ember['default'].$("#request").hide();
				_ember['default'].$("#RequestInfo").hide();

				this.set("isBank", false);
				this.set("stageNumber", 1);
				this.set("stageName", "Account Details");

				localStorage.removeItem("accountId");
				localStorage.removeItem("institution");
				localStorage.removeItem("loginId");
			},
			moveToEtransfer: function moveToEtransfer() {
				if (this.checkIsAllowed()) {

					_ember['default'].$("#Overview").hide();
					this.set("isOverview", false);
					_ember['default'].$("#paymentInfo").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#etransfer").show();
					this.set("stageNumber", 2);
					this.set("stageName", "Account Holder Details");
				}
			},
			moveToRequest: function moveToRequest() {
				_ember['default'].$("#Overview").hide();
				this.set("isOverview", false);
				_ember['default'].$("#benifeciaryInfo").hide();
				_ember['default'].$("#accountInfo").hide();
				_ember['default'].$("#paymentInfo").hide();
				_ember['default'].$("#bankInfo").hide();
				_ember['default'].$("#etransfer").hide();
				_ember['default'].$("#request").show();
				this.set("stageNumber", 2);
				this.set("stageName", "Account Holder Details");
			},
			moveToBenifeciary: function moveToBenifeciary() {
				this.set("isRequestInfo", false);
				var self = this;
				var myVar = setTimeout(function () {
					_ember['default'].$("#beneficiary-address").focusout(function () {

						setTimeout(function () {
							var address = _ember['default'].$("#beneficiary-address").val();
							var city = _ember['default'].$("#beneficiary-city").val();
							var state = _ember['default'].$("#beneficiary-state").val();
							var postcode = _ember['default'].$("#beneficiary-postcode").val();

							if (address) {
								_ember['default'].$("#beneficiary-address").parsley().reset();
							}
							if (city) {
								_ember['default'].$("#beneficiary-city").parsley().reset();
							}
							if (state) {
								_ember['default'].$("#beneficiary-state").parsley().reset();
							}
							if (postcode) {
								_ember['default'].$("#beneficiary-postcode").parsley().reset();
							}
						}, 500);
					});
					_ember['default'].$("#beneficiary-address").keyup(function () {
						if (!_ember['default'].$("#beneficiary-address").val()) {
							_ember['default'].$("#beneficiary-city").val("");
							_ember['default'].$("#beneficiary-postcode").val("");
							_ember['default'].$("#beneficiary-address").val("");
							_ember['default'].$("#beneficiary-state").val("");
						}
					});
					_ember['default'].$("#beneficiary-city").keyup(function () {
						if (!_ember['default'].$("#beneficiary-city").val()) {
							_ember['default'].$("#beneficiary-city").val("");
							_ember['default'].$("#beneficiary-postcode").val("");
							_ember['default'].$("#beneficiary-address").val("");
							_ember['default'].$("#beneficiary-state").val("");
						}
					});
					_ember['default'].$("#beneficiary-postcode").keyup(function () {
						if (!_ember['default'].$("#beneficiary-postcode").val()) {
							_ember['default'].$("#beneficiary-city").val("");
							_ember['default'].$("#beneficiary-postcode").val("");
							_ember['default'].$("#beneficiary-address").val("");
							_ember['default'].$("#beneficiary-state").val("");
						}
					});

					_ember['default'].$("#beneficiary-address").on('input', function () {

						_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
					});
					_ember['default'].$("#beneficiary-city").on('input', function () {

						_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
					});
					_ember['default'].$("#beneficiary-postcode").on('input', function () {

						_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
					});
					if (self.checkIsAllowed()) {

						_ember['default'].$("#Overview").hide();
						self.set("isOverview", false);
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#benifeciaryInfo").show();
						_ember['default'].$("#bankInfo").hide();

						_ember['default'].$("#request").hide();
						self.set("isBank", false);
						self.set("isFlinks2", false);
						self.set("stageNumber", 2);
						if (self.get("isDeposit")) {
							self.set("stageName", "Account Holder Details");
						} else if (self.get("isCollect")) {
							self.set("stageName", "Account Holder Details");
						} else {
							self.set("stageName", "Account Holder Details");
						}
						localStorage.removeItem("accountId");
						localStorage.removeItem("institution");
						localStorage.removeItem("loginId");
						self.initialiseAutoAddress();
					}
				}, 500);
			},
			handleAccountType: function handleAccountType(value) {
				if (value === "Business") {
					this.set("isBusiness", true);
				} else {
					this.set("isBusiness", false);
				}
			},
			handlePaymentType: function handlePaymentType(value) {
				if (value === "Interac E-Transfer") {
					this.set("isInteracEtransfer", true);
				} else {
					this.set("isInteracEtransfer", false);
				}
				if (value === "Money Request") {
					this.set("isRequest", true);
				} else {
					this.set("isRequest", false);
				}
				if (value === "Visa Direct") {
					this.set("isVisaDirect", true);
				} else {
					this.set("isVisaDirect", false);
				}
				if (value === "Credit Card") {

					this.set("isCreditCard", true);
				} else {
					this.set("isCreditCard", false);
				}
			},
			updateInstitutionName: function updateInstitutionName() {

				var finNumber = this.get('financialInstitutionNumber');
				this.set('financialInstitutionNumber', (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));

				var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
					return parseInt(b.code) === parseInt(finNumber);
				});

				var eClass = _ember['default'].$(".form__bankFlagIcon").attr("class").match(/b[0-9]{3}/);

				if (eClass) {
					_ember['default'].$(".form__bankFlagIcon").removeClass('' + eClass[0]);
				}

				if (bank[0]) {

					_ember['default'].$(".form__bankFlagIcon").addClass('b' + (0, _vopay_appHelpersCompleteWithZeros.completeWithZeros)(["l", finNumber, 3]));
					this.set('institutionName', bank[0].name);
					_ember['default'].$("#institutionName").parsley().reset();
					_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					_ember['default'].$(".newPaymentMethod__institution").slideDown();
				} else {
					_ember['default'].$("#paymentForm").parsley().destroy();
					_ember['default'].$('form').parsley({
						successClass: " ",
						errorClass: "form__input--error",
						errorsWrapper: "<p></p>",
						errorTemplate: "<span class='form__message'></span>"
					});
					_ember['default'].$(".newPaymentMethod__institution").slideUp();
					_ember['default'].$(".form__bankFlagIcon").addClass('b0');
					this.set('financialInstitutionNumber', '');
					this.set('institutionName', '');
					_ember['default'].$("#financialInstitutionNumber").val('');
					if (!finNumber) {
						_ember['default'].$("#financialInstitutionNumber").parsley().reset();
					} else {
						_ember['default'].$("#financialInstitutionNumber").parsley().validate();
					}
					// Ember.$("#financialInstitutionNumber").focus();
				}
			},
			showFlinks: function showFlinks() {
				var _this4 = this;

				if (this.validateForm('#paymentForm')) {
					(function () {
						_this4.SetIq11URL();
						_this4.set("isFlinks", true);
						var self = _this4;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								self.set("isOverview", true);
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);

								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								localStorage.removeItem("AccountNumber");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				}
			},
			cancel: function cancel() {
				if (sessionStorage.getItem("returnUrl")) {
					var url = sessionStorage.getItem("returnUrl");
					sessionStorage.removeItem("returnUrl");
					window.location.href = url;
				} else {
					this.get("routing").transitionTo("dashboard.info");
				}
			},
			moveToRequestInfo: function moveToRequestInfo() {
				var _this5 = this;

				if (this.get("PaylinkSettings.PaylinkSettings.PayLinkSenderEmailAddress")) {
					var myVar;

					(function () {

						_this5.set("isRequestInfo", true);
						_ember['default'].$("#Overview").hide();
						_this5.set("isOverview", false);
						_ember['default'].$("#paymentInfo").hide();
						_ember['default'].$("#RequestInfo").show();
						_ember['default'].$("#bankInfo").hide();
						_this5.set("isBank", false);
						_this5.set("stageNumber", 2);
						_this5.set("stageName", "Pay Link Details");
						var self = _this5;
						myVar = setTimeout(function () {
							self.initialiseAutoAddress();
							_ember['default'].$("#beneficiary-address").focusout(function () {

								setTimeout(function () {
									var address = _ember['default'].$("#beneficiary-address").val();
									var city = _ember['default'].$("#beneficiary-city").val();
									var state = _ember['default'].$("#beneficiary-state").val();
									var postcode = _ember['default'].$("#beneficiary-postcode").val();

									if (address) {
										_ember['default'].$("#beneficiary-address").parsley().reset();
									}
									if (city) {
										_ember['default'].$("#beneficiary-city").parsley().reset();
									}
									if (state) {
										_ember['default'].$("#beneficiary-state").parsley().reset();
									}
									if (postcode) {
										_ember['default'].$("#beneficiary-postcode").parsley().reset();
									}
								}, 500);
							});
							_ember['default'].$("#beneficiary-address").keyup(function () {
								if (!_ember['default'].$("#beneficiary-address").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-city").keyup(function () {
								if (!_ember['default'].$("#beneficiary-city").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-postcode").keyup(function () {
								if (!_ember['default'].$("#beneficiary-postcode").val()) {
									_ember['default'].$("#beneficiary-city").val("");
									_ember['default'].$("#beneficiary-postcode").val("");
									_ember['default'].$("#beneficiary-address").val("");
									_ember['default'].$("#beneficiary-state").val("");
								}
							});
							_ember['default'].$("#beneficiary-address").on('input', function () {

								_ember['default'].$("#beneficiary-address").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-address").val()));
							});
							_ember['default'].$("#beneficiary-city").on('input', function () {

								_ember['default'].$("#beneficiary-city").val(self.get('entryUtil').noSpecialCharacter(_ember['default'].$("#beneficiary-city").val()));
							});
							_ember['default'].$("#beneficiary-postcode").on('input', function () {

								_ember['default'].$("#beneficiary-postcode").val(self.get('entryUtil').onlyAlphaNumeric(_ember['default'].$("#beneficiary-postcode").val()));
							});
							clearTimeout(myVar);
						}, 1000);

						_ember['default'].$("#accountInfo").hide();
					})();
				} else {
					this.errorMessage("Sender email is not setup. Please add a sender email via the Paylink settings");
				}
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			moveToOverview: function moveToOverview() {

				if (this.validateForm('#paymentForm') && this.checkIsAllowed()) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
					if (this.get("country") === "Canada") {
						this.set("paymentToAdd.Country", "CA");
					} else {
						this.set("paymentToAdd.Country", this.get("isoCountry"));
					}
					if (this.get("paymentToAdd.Iban")) {
						this.set("paymentToAdd.Iban", this.get("isoCountry") + "" + this.get("paymentToAdd.Iban"));
					}
					this.set("paymentToAdd.Currency", this.get("currency2"));
					//var newBeneficiary= this.get('store').createRecord('beneficiary', this.get("paymentToAdd"));
					//newBeneficiary.save();

					_ember['default'].$("#etransfer").hide();
					_ember['default'].$("#benifeciaryInfo").hide();
					_ember['default'].$("#bankInfo").hide();
					_ember['default'].$("#RequestInfo").hide();
					this.set("isOverview", true);
					_ember['default'].$("#Overview").show();
					_ember['default'].$("#request").hide();
				}
			},
			closeVisaDIrect: function closeVisaDIrect() {
				this.set("isVisaDirectCard", false);
				clearInterval(window.VisaDirectInterval);
			},
			MoveCreateNew: function MoveCreateNew() {
				window.location.reload();
			},
			LocationMove: function LocationMove() {
				localStorage.setItem("link", window.location.href);
				window.location.href = this.get("transLocation");
			},
			CopyLink: function CopyLink() {
				/* Get the text field */
				var copyText = document.getElementById("link");

				/* Select the text field */
				copyText.select();
				copyText.setSelectionRange(0, 99999); /*For mobile devices*/

				/* Copy the text inside the text field */
				document.execCommand("copy");
			},
			create: function create() {
				var _this6 = this;

				// Add payment
				_ember['default'].$('.complete').prop('disabled', true);

				if (this.get("isRequestInfo")) {
					var options;

					(function () {
						_this6.set("paymentToAdd.SendEmail", _this6.get("isSendPaylinkEmail"));
						var data = _this6.get("paymentToAdd");
						options = {
							RedirectURL: "https://request.vopay.com/",
							PaymentSelectionMethod: "bank",
							RedirectMethod: "JavascriptMessage",
							customerName: _this6.get("LongName"),
							Language: data.Language,
							AccountSelectionMethod: data.AccountSelectionMethod,
							TermsAndConditions: data.TermsAndConditions,
							ClientReferenceNumber: _this6.get("paymentToAdd.ClientReferenceNumber")
						};

						_this6.get('api').getApiURL(options).then(function (iframe) {
							data.IframeKey = iframe.IframeKey;

							_this6.get("api").RequestAccountInfo(data).then(function (data3) {
								_this6.set("transLocation", "#/dashboard/paylink-accounts/details/Beneficiary/" + data3.PaylinkRequestID + "/");
								_this6.set("link", data3.Link);
								_ember['default'].$("#Overview").hide();
								_ember['default'].$("#email").show();
								_this6.set("isEmail", true);
							}, function (error) {});
						}, function (error2) {
							_this6.errorMessage(error2);
							_ember['default'].$('.complete').removeAttr('disabled');
						});
						_this6.set("BeneficiaryData.ReceiverEmailAddress", _this6.get("BeneficiaryData.ReceiverEmailAddress"));
						_this6.set("BeneficiaryData.FirstName", _this6.get("BeneficiaryData.ReceiverName").split(" ")[0]);
						_this6.set("BeneficiaryData.LastName", _this6.get("BeneficiaryData.ReceiverName").split(" ")[1]);
					})();
				} else if (this.get("isDeposit") === "true") {
					if (this.get("isFlinks2")) {
						var data = this.get("paymentToAdd");

						data.Type = "Bank Account";
						data.OuthType = "iQ11";
						this.get("firebase").createRecordUser("linkedAccount", data);
						this.get("routing").transitionTo("dashboard.accounts");
					} else {
						var data = this.get("paymentToAdd");
						if (this.get("isCreditCard")) {
							data.Type = "Credit Card";
						} else if (this.get("isInteracEtransfer")) {
							data.Type = "Interac E-Transfer";
						} else if (this.get("isRequest")) {
							data.Type = "Money Request";
						} else if (this.get("isVisaDirect")) {
							data.Type = "Visa Direct";
						} else {
							data.Type = "Bank Account";
						}

						var id = this.get("firebase").createRecordUser("accounts", data);
						this.get("routing").transitionTo("dashboard.accounts");
						var input = document.getElementById('uploadFile');
						for (var i = 0; i < input.files.length; i++) {
							var file = input.files[i];
							this.get("firebase").addFileUser("accounts", id, file);
						}
					}
				} else {

					var data = this.get("paymentToAdd");
					if (this.get("isCreditCard")) {
						data.Type = "Credit Card";
					} else if (this.get("isInteracEtransfer")) {
						data.Type = "Interac E-Transfer";
					} else if (this.get("isRequest")) {
						data.Type = "Money Request";
					} else if (this.get("isVisaDirect")) {
						data.Type = "Visa Direct";
					} else {
						data.Type = "Bank Account";
					}

					this.get("firebase").createRecordUser("beneficiary", data);
					if (localStorage.getItem('location') != null) {
						var location = localStorage.getItem('location');
						localStorage.removeItem('location');
						window.location.href = location;
					} else {
						this.get("routing").transitionTo("dashboard.beneficiaries");
					}
				}
			},
			showTerms: function showTerms() {
				_ember['default'].$("#termsConditions").show();
				_ember['default'].$("#overlay").show();
				this.set("isAgree", false);
			},
			backTerms: function backTerms() {
				_ember['default'].$("#termsConditions").hide();
				_ember['default'].$("#overlay").hide();
				this.set("isAgree", false);
			},
			AgreeToTerms: function AgreeToTerms() {
				_ember['default'].$("#overlay").hide();
				_ember['default'].$("#termsConditions").hide();
				this.set("agreedToTerms", true);
				this.set("isAgree", true);
			},
			closeCreditCard: function closeCreditCard() {
				this.set("isCreditCardFrame", false);
				clearInterval(window.CreditCardInterval);
			},
			enterCreditCard: function enterCreditCard() {
				var _this7 = this;

				if (this.checkIsAllowed()) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
					if (this.get("country") === "Canada") {
						this.set("paymentToAdd.Country", "CA");
					} else {
						this.set("paymentToAdd.Country", this.get("isoCountry"));
					}
					var options = null;

					if (location.hostname == "localhost") {
						options = {
							RedirectURL: "https://" + location.hostname + ":4200/",
							ClientControlled: this.get("myAccount")
						};
					} else if (location.hostname == "bs-local.com") {

						options = {
							RedirectURL: "https://" + location.hostname + ":4200/",
							ClientControlled: this.get("myAccount")
						};
					} else {
						options = {
							RedirectURL: "https://" + location.hostname + "/",
							ClientControlled: this.get("myAccount")
						};
					}
					this.get('api').GenerateCreditCardURL(options).then(function (data2) {
						_this7.set("CreditCardURL", data2.EmbedURL);
						_this7.set("isCreditCardFrame", true);
						var self = _this7;
						window.CreditCardInterval = setInterval(function () {

							if (localStorage.getItem("FlinksToken") != null) {
								var _paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();

								self.set('paymentToAdd', self.addPaymentMethod(_paymentFormArr));
								self.set('paymentToAdd.DollarAmount', self.get("amount").replace(/,/g, ''));
								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Country", "CA");

								//self.set("paymentToAdd.FullName", data.Name);
								//self.set("paymentToAdd.CardNumber", data.LastFourDigits);
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								self.set("isOverview", true);
								self.set("isCreditCardFrame", false);

								self.set("paymentToAdd.CreditCardToken", localStorage.getItem("FlinksToken"));

								self.set("paymentToAdd.CreditCardBrand", localStorage.getItem("CreditCardBrand"));
								self.set("paymentToAdd.CreditCardExpiryMonth", localStorage.getItem("CreditCardExpiryMonth"));
								self.set("paymentToAdd.CreditCardExpiryYear", localStorage.getItem("CreditCardExpiryYear"));
								self.set("paymentToAdd.CreditCardNumber", (0, _vopay_appHelpersMaskAccount.maskAccount)([localStorage.getItem("CreditCardNumber")]));
								self.set("paymentToAdd.CreditCardHolderName", localStorage.getItem("CreditCardHolderName"));

								localStorage.removeItem("CreditCardBrand");
								localStorage.removeItem("CreditCardExpiryMonth");
								localStorage.removeItem("CreditCardExpiryYear");
								localStorage.removeItem("CreditCardNumber");
								localStorage.removeItem("CreditCardHolderName");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.CreditCardInterval);
							}
						}, 100);
					}, function (error) {
						_this7.errorMessage(error);
					});
				}
			},
			enterVisaDirect: function enterVisaDirect() {
				var _this8 = this;

				if (this.checkIsAllowed()) {
					var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
					this.set('paymentToAdd', this.addPaymentMethod(paymentFormArr));
					this.set('paymentToAdd.DollarAmount', this.get("amount").replace(/,/g, '').replace(/[\u00a0]/g, "").replace(/[\u202f]/g, ""));
					if (this.get("country") === "Canada") {
						this.set("paymentToAdd.Country", "CA");
					} else {
						this.set("paymentToAdd.Country", this.get("isoCountry"));
					}
					var RedirectURL = "";
					if (location.hostname == "localhost") {

						RedirectURL = "https://" + location.hostname + ":4200/";
					} else if (location.hostname == "bs-local.com") {

						RedirectURL = "https://" + location.hostname + ":4200/";
					} else {

						RedirectURL = "https://" + location.hostname + "/";
					}
					this.set('paymentToAdd.RedirectURL', RedirectURL);
					var options = this.get("paymentToAdd");
					var self = this;
					this.get("api").VisaDirectURL(options).then(function (data) {
						_this8.set("VisaDirectURL", data.EmbedURL);
						_this8.set("isVisaDirectCard", true);

						window.VisaDirectInterval = setInterval(function () {

							if (localStorage.getItem("FlinksToken") != null) {
								var _paymentFormArr2 = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(_paymentFormArr2));
								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Country", "CA");
								var Options = {
									Token: localStorage.getItem("FlinksToken")
								};
								self.get("api").GetCreditCardIfo(Options).then(function (data) {
									self.set("paymentToAdd.FullName", data.Name);
									self.set("paymentToAdd.CardNumber", data.LastFourDigits);
									_ember['default'].$("#paymentInfo").hide();
									_ember['default'].$("#benifeciaryInfo").hide();
									_ember['default'].$("#bankInfo").hide();
									_ember['default'].$("#Overview").show();
									_ember['default'].$("#etransfer").hide();
									self.set("isOverview", true);
									self.set("isVisaDirectCard", false);
								}, function (error) {});
								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.VisaDirectInterval);
							}
						}, 100);
					}, function (error) {
						_this8.errorMessage(error);
					});
				}
			},
			handleIfFlinks: function handleIfFlinks(value) {
				var _this9 = this;

				if (value === "Online Banking") {
					(function () {
						_this9.SetIq11URL();
						_this9.set("isFlinks", true);
						var self = _this9;
						window.flinksInterval = setInterval(function () {
							if (localStorage.getItem("FlinksToken") != null) {
								var paymentFormArr = _ember['default'].$('#paymentForm').serializeArray();
								self.set('paymentToAdd', self.addPaymentMethod(paymentFormArr));
								if (localStorage.getItem("institution") && localStorage.getItem("institution") != "undefined") {
									self.set('paymentToAdd.InstitutionName', localStorage.getItem("institution"));
									self.set("paymentToAdd.FinancialInstitutionNumber", "");
								} else {
									var finNumber = localStorage.getItem("InstitutionNumber");
									var bank = _vopay_appConstants['default'].canadaBanks.filter(function (b) {
										return parseInt(b.code) === parseInt(finNumber);
									});

									if (bank[0]) {
										self.set('paymentToAdd.InstitutionName', bank[0].name);
									} else {
										self.set('paymentToAdd.InstitutionName', "");
									}
									self.set("paymentToAdd.FinancialInstitutionNumber", finNumber);
								}
								if (self.get("country") === "Canada") {
									self.set("paymentToAdd.Country", "CA");
								} else {
									self.set("paymentToAdd.Country", self.get("isoCountry"));
								}
								if (self.get("paymentToAdd.Iban")) {
									self.set("paymentToAdd.Iban", self.get("isoCountry") + "" + self.get("paymentToAdd.Iban"));
								}

								self.set("paymentToAdd.Currency", self.get("currency2"));
								self.set("paymentToAdd.Notes", _ember['default'].$("#Notes").val());
								_ember['default'].$("#paymentInfo").hide();
								_ember['default'].$("#benifeciaryInfo").hide();
								_ember['default'].$("#bankInfo").hide();
								self.set("isOverview", true);
								_ember['default'].$("#Overview").show();
								_ember['default'].$("#etransfer").hide();
								self.set("isFlinks2", true);
								self.set("isFlinks", false);

								self.set("paymentToAdd.Token", localStorage.getItem("FlinksToken"));
								if (localStorage.getItem("AccountNumber")) {
									self.set('paymentToAdd.MaskedAccount', localStorage.getItem("AccountNumber"));
								} else {
									self.set("paymentToAdd.MaskedAccount", localStorage.getItem("MaskedAccount"));
								}
								if (localStorage.getItem("BankId")) {
									self.set('paymentToAdd.BankId', localStorage.getItem("BankId"));
								}
								localStorage.removeItem("BankId");
								localStorage.removeItem("InstitutionNumber");
								self.set("paymentToAdd.BranchTransitNumber", "");
								self.set("paymentToAdd.AccountNumber", "");
								localStorage.removeItem("AccountNumber");
								self.set("paymentToAdd.FullName", localStorage.getItem("FullName"));
								localStorage.removeItem("TokenLocation");
								localStorage.removeItem("FlinksToken");
								localStorage.removeItem("MaskedAccount");
								localStorage.removeItem("FullName");
								localStorage.removeItem("accountId");
								localStorage.removeItem("loginId");
								localStorage.removeItem("institution");
								clearInterval(window.flinksInterval);
							}
						}, 100);
					})();
				} else {
					this.set("isFlinks", false);
					clearInterval(window.flinksInterval);
				}
			}

		}
	});
});