define('vopay_app/services/user-permissions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        routing: _ember['default'].inject.service('-routing'),
        SetPermissions: function SetPermissions(UserPermissionsList) {
            var userPermissionsObject = {
                ViewSubaccounts: false,
                AddSubaccounts: false,
                CreatePayments: false,
                CreateCollections: false,
                ViewBeneficiaries: false,
                AddBeneficiaries: false,
                ViewBulkFileUpload: false,
                CreateBulkFileUpload: false,
                ViewScheduledTransactions: false,
                ViewTransactionHistory: false,
                ViewSettings: false,
                AddSettings: false,
                ViewUsers: false,
                AddUsers: false,
                ApproveTransactions: false,
                ManageUserPermissions: false,
                CreateAccountTransfer: false,
                ViewClientAccounts: false,
                AddClientAccounts: false,
                CreateClientAccountTransfers: false,
                CreateConversions: false,
                ManageDefaultPayments: false,
                CreateSupportTickets: false,
                ViewSupportTickets: false,
                ViewReconciliation: false,
                ViewAccountingBillsAndInvoices: false,
                ProcessAccountingBillsAndInvoices: false,
                TransactionCancellation: false
            };
            for (var property in UserPermissionsList) {
                userPermissionsObject[UserPermissionsList[property]] = true;
            }
            localStorage.setItem("UserPermissions", JSON.stringify(userPermissionsObject));
        },
        GetPermissions: function GetPermissions() {
            return JSON.parse(localStorage.getItem("UserPermissions"));
        },
        HasPermission: function HasPermission(key) {
            var Permissions = this.GetPermissions();
            return Permissions[key];
        },
        HasPermissionRoute: function HasPermissionRoute(key) {
            if (!this.HasPermission(key)) {
                this.get("routing").transitionTo('dashboard.info');
                this.UpdateMessage(true);
            }
        },
        showUnauthorizedMessage: false,
        UpdateMessage: function UpdateMessage(show) {
            this.set("showUnauthorizedMessage", show);
        }
    });
});