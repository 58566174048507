define('vopay_app/components/convert-pay-transactions', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		options: ["Update", "Tracking"],
		types: ["Type", "Debit", "Credit"],
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,
		keyword: null,

		init: function init() {

			this._super.apply(this, arguments);
			var count = 0;
			var transactions = this.get("transactions");

			if (transactions) {
				var transactions2 = {};
				for (var k in transactions) {
					if (transactions.hasOwnProperty(k)) {
						++count;
					}
				}
				for (var j in transactions) {
					if (transactions.hasOwnProperty(j)) {
						var next = count - 1;
						transactions2[next] = transactions[j];
						--count;
					}
				}
				this.set("transactions", transactions2);
			}
			this.getTransactions(1);
		},
		didInsertElement: function didInsertElement() {
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				ranges: {
					'Today': [window.moment(), window.moment()],
					'Yesterday': [window.moment().subtract(1, 'days'), window.moment().subtract(1, 'days')],
					'Last 7 Days': [window.moment().subtract(6, 'days'), window.moment()],
					'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
					'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
					'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});
			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {

				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY'));
			});
		},
		getTransactions: function getTransactions(pageNumber) {
			var pageSize = this.get("pageSize");
			var transactions = this.get("transactions");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
					var next = i + 1;
					filteredList[next] = transactions[i];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(searchTerms, transactions) {
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);

				for (var i = 0; i < keys.length; i++) {

					if (typeof transactions[i] !== 'undefined') {

						if (transactions[i].hasOwnProperty("recipient") && transactions[i].recipient.hasOwnProperty("Nickname") && transactions[i].recipient.Nickname) {
							searchTerms = searchTerms.toLowerCase().replace(/\s|_/g, "");
							if (transactions[i].recipient.Nickname.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
								var next = i + 1;
								filteredList[next] = transactions[i];
							}
						} else if (transactions[i].hasOwnProperty("recipient") && transactions[i].recipient.hasOwnProperty("FirstName") && transactions[i].recipient.FirstName && transactions[i].recipient.hasOwnProperty("LastName") && transactions[i].recipient.LastName) {
							var compare = transactions[i].recipient.FirstName.toLowerCase() + " " + transactions[i].recipient.LastName.toLowerCase();
							if (compare.includes(searchTerms)) {
								var next = i + 1;
								filteredList[next] = transactions[i];
							}
						} else if (transactions[i].hasOwnProperty("recipient") && transactions[i].recipient.hasOwnProperty("FirstName") && transactions[i].recipient.FirstName) {
							if (transactions[i].recipient.FirstName.toLowerCase().includes(searchTerms)) {
								var next = i + 1;
								filteredList[next] = transactions[i];
							}
						} else if (transactions[i].hasOwnProperty("recipient") && transactions[i].recipient.hasOwnProperty("LastName") && transactions[i].recipient.LastName) {
							if (transactions[i].recipient.LastName.toLowerCase().includes(searchTerms)) {
								var next = i + 1;
								filteredList[next] = transactions[i];
							}
						} else if (transactions[i].hasOwnProperty("RemittanceRecipient")) {
							searchTerms = searchTerms.toLowerCase().replace(/\s|_/g, "");
							if (transactions[i].RemittanceRecipient.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
								var next = i + 1;
								filteredList[next] = transactions[i];
							}
						}
					}
				}
				_ember['default'].$('#searchTransactions').addClass('searchHide');
				this.set("filteredList", filteredList);
			}
		},

		actions: {
			getPage: function getPage(pageNumber) {
				this.getTransactions(pageNumber);
			},
			moveToTransfer: function moveToTransfer() {
				this.get("routing").transitionTo("transfer");
			},
			moveToNewRecipients: function moveToNewRecipients() {
				this.get("routing").transitionTo("dashboard.recipients.new");
			},
			moveToNewBankAccount: function moveToNewBankAccount() {
				this.get("routing").transitionTo("dashboard.account.payment.new");
			},
			filterKeyword: function filterKeyword() {},
			filterType: function filterType(type) {},
			filterRange: function filterRange(range) {},
			searchTransactions: function searchTransactions() {
				var transactions = this.get("transactions");
				var searchTerms = _ember['default'].$("input[name='keyword']").val().toLowerCase();
				this.filterTransactions(searchTerms, transactions);
			},
			resetTransactions: function resetTransactions() {
				var transactions = this.get("transactions");
				this.filterTransactions('', transactions);
				_ember['default'].$("input[name='keyword']").val('');
				_ember['default'].$('#searchTransactions').addClass('searchHide');
			},
			updateRemmittance: function updateRemmittance(id) {
				window.location = "/#/dashboard/transactions/" + id + "/recipient";
			},
			updatePayment: function updatePayment(id) {
				window.location = "/#/dashboard/transactions/" + id + "/payment";
			},
			selectionPayment: function selectionPayment(selection, id) {
				if (selection === "Update") {
					window.location = "/#/dashboard/transactions/" + id + "/payment";
				} else {
					window.location = "/#/dashboard/transactions/" + id + "/";
				}
			},
			selectionRemittance: function selectionRemittance(selection, id) {
				if (selection === "Update") {
					window.location = "/#/dashboard/transactions/" + id + "/recipient";
				} else {
					window.location = "/#/dashboard/transactions/" + id + "/";
				}
			}
		}

	});
});