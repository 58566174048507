define("vopay_app/helpers/account-mask-short", ["exports", "ember"], function (exports, _ember) {
  exports.accountMaskShort = accountMaskShort;

  function accountMaskShort(params /*, hash*/) {
    var LastFour = params[0].substring(params[0].length - 4, params[0].length);
    var value = "x-" + LastFour;
    return value;
  }

  exports["default"] = _ember["default"].Helper.helper(accountMaskShort);
});