define("vopay_app/components/general-button-dropdown", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Component.extend({
		actions: {
			selectItem: function selectItem(option) {
				this.sendAction("button2", option, this.get("idnum"));
			},
			primaryBtn: function primaryBtn() {
				if (this.get("type")) {
					this.sendAction("button1", this.get("idnum"), this.get("type"));
				} else {
					this.sendAction("button1", this.get("idnum"));
				}
			}
		}
	});
});