define('vopay_app/models/beneficiary', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    CompanyName: _emberData['default'].attr('string'),
    FirstName: _emberData['default'].attr('string'),
    LastName: _emberData['default'].attr('string'),
    Address1: _emberData['default'].attr('string'),
    Address2: _emberData['default'].attr('string'),
    Province: _emberData['default'].attr('string'),
    City: _emberData['default'].attr('string'),
    PostalCode: _emberData['default'].attr('string'),
    Country: _emberData['default'].attr('string'),
    ClienReferenceNumber: _emberData['default'].attr('string'),
    BranchTransitNumber: _emberData['default'].attr('number'),
    FinancialInstitutionNumber: _emberData['default'].attr('number'),
    InstitutionName: _emberData['default'].attr('string'),
    AccountNumber: _emberData['default'].attr('number'),
    Iban: _emberData['default'].attr('string'),
    Aba: _emberData['default'].attr('number'),
    SortCode: _emberData['default'].attr('number'),
    IsFavourite: _emberData['default'].attr("boolean")
  });
});