define('vopay_app/components/recipient-country-currency', ['exports', 'ember', 'vopay_app/utils/entry-filter'], function (exports, _ember, _vopay_appUtilsEntryFilter) {
  exports['default'] = _ember['default'].Component.extend({
    menuActive: false,
    dropDownClass: "form__dropdownToggle--grey",
    dropDownSelClass: "form__selDropdown--grey",
    dropDownStateClass: "",
    selDropDownStateClass: "",
    filteredData: null,
    currentSelection: null,
    hasherrorID: null,
    restrictions: null,
    selected: false,
    entryUtil: _vopay_appUtilsEntryFilter['default'].create(),
    watchCurrentSelection: _ember['default'].observer('currentSelection', function () {
      var currentSelection = this.get('currentSelection');
      if (this.get("restrict") === 'characters') {
        this.set('currentSelection', this.get('entryUtil').onlyCharacters(currentSelection));
      } else if (this.get("restrict") === 'numbers') {
        this.set('currentSelection', this.get('entryUtil').onlyNumbers(currentSelection));
      } else {}
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var data = this.get("data");
      var filteredData = {};
      var keys = Object.keys(data);
      var length = 500;
      var hasherrorID = "#" + this.get("errorID") + "SelectError";
      this.hasherrorID = hasherrorID;
      if (keys.length < 500) {
        length = keys.length;
      }
      for (var i = 0; i < length; i++) {

        filteredData[keys[i]] = data[keys[i]];
      }
      this.set("filteredData", filteredData);

      if (this.get("selected")) {
        this.set("currentSelection", this.get("default"));
        this.set('dropDownClass', '');
        this.set('dropDownSelClass', '');
        this.set('default', "Choose Bank");
      }
      if (window.innerWidth <= 670) {
        this.set("desktop", false);
        this.set("mobile", true);
      } else {
        this.set("desktop", true);
        this.set("mobile", false);
      }
      this.set("ErrorID", Math.floor(Math.random() * 100000000 + 1));
    },
    handleResize: function handleResize(e) {
      if (window.innerWidth <= 670) {
        this.set("desktop", false);
        this.set("mobile", true);
      } else {

        this.set("desktop", true);
        this.set("mobile", false);
      }
      /**
      let self = this;
      setTimeout(function(){
          $(".loaded").removeClass("loaded").removeAttr("data-ll-status");
          self.get("lazy").update();
      },100);
      **/
    },
    toggleMenu: function toggleMenu() {

      if (!this.get('menuActive')) {
        this.set('menuActive', true);
        this.set('dropDownStateClass', 'form__dropdownToggle--active');
        this.set('selDropDownStateClass', 'form__selDropdown--active');
      } else {
        this.set('menuActive', false);
        this.set('dropDownStateClass', '');
        this.set('selDropDownStateClass', '');
      }
    },
    willDestroyElement: function willDestroyElement() {

      clearInterval(window.item);
    },
    didInsertElement: function didInsertElement() {

      this._super.apply(this, arguments);
      this.$(".dropdowncontainer").focusout(function () {
        _ember['default'].$(this).removeClass("open");
      });
      this.$("input").focusin(function () {
        _ember['default'].$(this).val("");
        var self2 = this;
        setTimeout(function () {
          _ember['default'].$(".open").removeClass("open");
          _ember['default'].$(self2).parent().addClass("open");
        }, 100);
      });

      this.$("input").click(function () {
        _ember['default'].$(".open").removeClass("open");
        _ember['default'].$(this).parent().addClass("open");

        return false;
      });
      this.$('li a').on('mousedown',
      /** @param {!jQuery.Event} event */

      function (event) {
        _ember['default'].$(':focus').blur();
        event.preventDefault();
      });
      $(window).on('resize', this.handleResize.bind(this));
      var filteredData = this.get("filteredData");
      var data = this.get("data");
      var page = 2;
      var keys = Object.keys(data);
      var length = 500;
      var self = this;

      var interval = setInterval(function () {
        length = 500 * page;
        if (keys.length < length) {
          length = keys.length;
          clearInterval(window.item);
        }
        for (var i = (page - 1) * 500; i < length; i++) {

          filteredData[keys[i]] = data[keys[i]];
        }
        page = page + 1;
        self.set("filteredData", $.extend(true, {}, {}, filteredData));
      }, 100);
      window.item = interval;
    },
    actions: {
      /***
      * toggleMenu
      *
      * Toggles the dropdown menu
      */
      activateMenu: function activateMenu() {
        this.toggleMenu();
      },
      /***
      * delevry dropdown
      * @params item (String)
      * Sets current selected item the dropdown
      ***/
      selectItem: function selectItem(item) {
        this.set('dropDownSelClass', '');
        this.sendAction('value', item);
        var name = "";
        if (item === "New") {
          name = "New";
        } else {
          var data = this.get("data");
          var chosen = data[item];
          if (chosen.AccountHolderName) {
            name = chosen.AccountHolderName;
          } else if (chosen.RecipientName) {
            name = chosen.RecipientName;
          } else if (chosen.FullName && chosen.FullName != "null") {
            name = chosen.FullName;
          } else {
            name = chosen.FirstName + " " + chosen.LastName;
          }
        }
        this.set('currentSelection', name);
        $(this.element).find("input").first().parsley().reset();
        $(this.element).find("input").last().parsley().reset();
        this.toggleMenu();
        $(this.element).find("input").first().parsley().reset();
        clearInterval(window.item);
        this.set('selected', true);
      },
      filter: function filter() {
        var _this = this;

        var data = this.get("data");
        clearInterval(window.item);
        if (this.get("currentSelection") && !this.get("selected")) {
          var filterTerm;
          var key;
          var name;
          var chosen;
          var i;

          (function () {
            filterTerm = _this.get("currentSelection").toLowerCase();

            var newData = {};
            for (key in data) {
              name = "";
              chosen = data[key];

              if (chosen.CompanyName) {
                name = chosen.CompanyName.toLowerCase();
              } else if (chosen.RecipientName) {
                name = chosen.RecipientName.toLowerCase();
              } else if (chosen.FullName && chosen.FullName != "null") {
                name = chosen.FullName.toLowerCase();
              } else if (chosen.AccountHolderName) {
                name = chosen.AccountHolderName.toLowerCase();
              } else if (chosen.CreditCardHolderName) {
                name = chosen.CreditCardHolderName.toLowerCase();
              } else if (chosen.FirstName) {
                name = chosen.FirstName.toLowerCase() + " " + chosen.LastName.toLowerCase();
              }
              if (name.includes(filterTerm)) {
                newData[key] = data[key];
              }
            }
            _this.set("selected", false);

            var filteredData = {};
            var keys = Object.keys(newData);
            var length = 500;
            if (keys.length < 500) {
              length = keys.length;
            }
            for (i = 0; i < length; i++) {

              filteredData[keys[i]] = newData[keys[i]];
            }
            _this.set("filteredData", filteredData);
            var page = 2;
            var self = _this;
            window.item = setInterval(function () {
              length = 500 * page;
              if (keys.length < length) {
                length = keys.length;
                clearInterval(window.item);
              }

              for (var i = (page - 1) * 500; i < length; i++) {

                filteredData[keys[i]] = newData[keys[i]];
              }
              page = page + 1;

              self.set("filteredData", $.extend(true, {}, {}, filteredData));
            }, 100);
          })();
        } else {
          var i;

          (function () {
            _this.set("selected", false);
            var filteredData = {};
            var keys = Object.keys(data);
            var length = 500;
            if (keys.length < 500) {
              length = keys.length;
            }
            for (i = 0; i < length; i++) {

              filteredData[keys[i]] = data[keys[i]];
            }
            _this.set("filteredData", filteredData);
            var page = 2;
            var self = _this;
            window.item = setInterval(function () {
              length = 500 * page;
              if (keys.length < length) {
                length = keys.length;
                clearInterval(window.item);
              }

              for (var i = (page - 1) * 500; i < length; i++) {

                filteredData[keys[i]] = data[keys[i]];
              }
              page = page + 1;

              self.set("filteredData", $.extend(true, {}, {}, filteredData));
            }, 100);
          })();
        }
      },
      resetFeild: function resetFeild() {
        if (!this.get("selected")) {
          this.set('currentSelection', '');
          this.sendAction('value', "");
        }
      }

    }
  });
});