define('vopay_app/components/account-transactions', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		routing: _ember['default'].inject.service('-routing'),
		options: ["Cancel"],
		api: _ember['default'].inject.service('api'),
		types: ["All"],
		statuses: ["All", "Requested", "Pending", "In Progress", "Sent", "Successful", "Settled", "Failed", "Cancelled", "Cancellation Requested", "Waiting"],
		Flagged: ["All", "Yes", "No"],
		pageSize: 20,
		pageNumber: 1,
		recordCount: null,
		keyword: "",
		currency: "CAD",
		type: null,
		date: null,
		status: "all",
		startDate: "",
		endDate: "",
		flag: "All",
		isShowingModal: false,
		loader: _ember['default'].inject.service('loader'),
		watchdate: _ember['default'].observer('date', function () {
			var start = localStorage.getItem("startDate");
			var end = localStorage.getItem("endDate");
			if (start && end && start != "" && end != "") {
				this.set("startDate", start);
				this.set("endDate", end);

				localStorage.setItem("startDate", "");
				localStorage.setItem("endDate", "");
				this.refreshTransactions(start, end, this.get("currency"));
			}
		}),

		transactionType: ["All", "EFT Withdrawal", "EFT Funding", "VoPayInstant Withdraw", "Refund", "Currency Conversion", "Interac Money Request", "Interac Bulk Payout", "Inbound e-Transfer", "Visa Direct Push", "Credit Card", "Account Transfer", "Reversal", "Virtual Account Funding", "Virtual Account Withdraw", "Virtual Account Wire", "Other Funding", "Other Withdrawal"],
		transactionTypeUSA: ["All", "ACH Withdrawal", "ACH Funding", "Credit Card", "Refund", "Reversal", "Other Funding", "Other Withdrawal"],
		ClientAccountsEnabled: false,
		init: function init() {

			this._super.apply(this, arguments);
			var currencies = this.get("currencies.CurrenciesList");
			this.set("currencies2", currencies);
			var count = 0;
			var transactions = this.get("transactions");

			this.set("Country", localStorage.getItem("Country"));
			if (this.get("Country") == "US") {
				this.set("currency", "USD");
			}
			if (localStorage.getItem("ClientAccountsEnabled") === "1") {
				this.set("ClientAccountsEnabled", true);
			}

			if (transactions) {
				for (var transaction in transactions) {
					if (_ember['default'].$.inArray(transactions[transaction].TransactionType, this.get("types")) === -1) {
						this.get("types").push(transactions[transaction].TransactionType);
					}
				}
				for (var k in transactions) {
					if (transactions.hasOwnProperty(k)) {
						++count;
					}
				}
				if (count === 0) {
					this.set("isShowingModal", true);
				} else {
					this.set("isShowingModal", false);
				}
			}
			this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
		},
		didInsertElement: function didInsertElement() {
			this.set("startDate", window.moment().utc().subtract(6, 'days').format('YYYY-MM-DD'));
			this.set("endDate", window.moment().utc().format('YYYY-MM-DD'));
			_ember['default'].$('input[name="daterange"]').daterangepicker({
				autoUpdateInput: false,
				locale: {
					cancelLabel: 'Clear'
				},
				startDate: window.moment().subtract(6, 'days'),
				endDate: window.moment(),
				ranges: {
					'Today': [window.moment().utc(), window.moment().utc()],
					'Yesterday': [window.moment().utc().subtract(1, 'days'), window.moment().utc().subtract(1, 'days')],
					'Last 7 Days': [window.moment().utc().subtract(6, 'days'), window.moment().utc()],
					'Last 30 Days': [window.moment().utc().subtract(29, 'days'), window.moment().utc()],
					'This Month': [window.moment().utc().startOf('month'), window.moment().utc().endOf('month')],
					'Last Month': [window.moment().utc().subtract(1, 'month').startOf('month'), window.moment().utc().subtract(1, 'month').endOf('month')]
				},
				alwaysShowCalendars: true
			});

			localStorage.setItem("startDate", "");
			localStorage.setItem("endDate", "");
			_ember['default'].$('input[name="daterange"]').val(window.moment().utc().subtract(6, 'days').format('MM/DD/YYYY') + " - " + window.moment().utc().format('MM/DD/YYYY'));
			_ember['default'].$('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
				localStorage.setItem("startDate", picker.startDate.format('YYYY-MM-DD'));
				localStorage.setItem("endDate", picker.endDate.format('YYYY-MM-DD'));
				_ember['default'].$('input[name="daterange"]').val(picker.startDate.format('MM/DD/YYYY') + " - " + picker.endDate.format('MM/DD/YYYY'));
				_ember['default'].$('input[name="daterange"]').trigger("change");
			});
		},
		pad: function pad(n, width, z) {
			z = z || '0';

			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		},
		refreshTransactions: function refreshTransactions(startDate, endDate, currency) {
			var _this = this;

			var options = {};
			var StartDateParts = startDate.split("-");
			var date3 = new Date(StartDateParts[0], StartDateParts[1] - 1, StartDateParts[2]);
			date3.setHours(0);
			date3.setMinutes(0);
			date3.setSeconds(0);

			var year3 = date3.getFullYear();
			var month3 = date3.getMonth() + 1;
			var day3 = date3.getDate();
			var hour3 = date3.getHours();
			var minutes3 = date3.getMinutes();;
			var seconds3 = date3.getSeconds();

			startDate = year3 + "-" + this.pad(month3, 2, "0") + "-" + this.pad(day3, 2, "0") + " " + this.pad(hour3, 2, "0") + ":" + this.pad(minutes3, 2, "0") + ":" + this.pad(seconds3, 2, "0");
			options.StartDateTime = startDate;

			var currentDate = new Date();
			var EndDateParts = endDate.split("-");
			var date = new Date(EndDateParts[0], EndDateParts[1] - 1, EndDateParts[2]);
			date.setHours(23);
			date.setMinutes(59);
			date.setSeconds(59);

			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			var hour = date.getHours();
			var minutes = date.getMinutes();
			var seconds = date.getSeconds();

			endDate = year + "-" + this.pad(month, 2, "0") + "-" + this.pad(day, 2, "0") + " " + this.pad(hour, 2, "0") + ":" + this.pad(minutes, 2, "0") + ":" + this.pad(seconds, 2, "0");
			options.EndDateTime = endDate;
			options.Currency = currency;
			if (this.get("BearerToken")) {
				options.BearerToken = this.get("BearerToken");
			}
			this.get("loader").UpdateLoading(true);
			this.get("api").getAccountTransactions(options).then(function (data) {
				var count = 0;
				var transactions = data.Transactions;

				if (transactions) {
					//let transactions2 = {};
					for (var transaction in transactions) {
						if (_ember['default'].$.inArray(transactions[transaction].TransactionType, _this.get("types")) === -1) {
							_this.get("types").push(transactions[transaction].TransactionType);
						}
					}
					for (var k in transactions) {
						if (transactions.hasOwnProperty(k)) {
							++count;
						}
					}
				}
				_this.get("loader").UpdateLoading(false);
				_this.set("transactions", transactions);
				_this.filterTransactions(_this.get("keyword"), _this.get("type"), _this.get("status"), _this.get("transactions"), _this.get("flag"), 1);
			}, function (errors) {
				_this.get("loader").UpdateLoading(false);
				_this.set("transactions", {});
			});
		},
		getTransactions: function getTransactions(pageNumber, transactions) {
			var pageSize = this.get("pageSize");
			var filteredList = {};
			if (transactions) {
				var keys = Object.keys(transactions);
				for (var i = (pageNumber - 1) * pageSize; i < keys.length && i < pageNumber * pageSize; i++) {
					var next = i + 1;
					filteredList[next] = transactions[i];
				}
				this.set("recordCount", keys.length);
				this.set("pageNumber", pageNumber);
				this.set("filteredList", filteredList);
			} else {
				this.set("recordCount", 0);
				this.set("pageNumber", 1);
			}
		},
		filterTransactions: function filterTransactions(searchTerms, type, status, transactions, flag, page) {
			var filteredList = {};
			searchTerms = searchTerms.toLowerCase();
			searchTerms = searchTerms.replace(/\$/g, '');
			searchTerms = searchTerms.replace(/\,/g, '');
			if (transactions) {
				var keys = Object.keys(transactions);
				var next = -1;
				for (var i = 0; i < keys.length; i++) {
					if (this.get("Country") == "US") {
						if (transactions[i].TransactionType == "EFT Withdrawal") {
							this.set("transactions." + i + ".TransactionType", "ACH Withdrawal");
						} else if (transactions[i].TransactionType == "EFT Funding") {
							this.set("transactions." + i + ".TransactionType", "ACH Funding");
						}
					}
					if (typeof transactions[i] !== 'undefined') {
						if ((type === null || type === "" || type === "All" || type === transactions[i].TransactionType || type === "Refund" && transactions[i].IsRefund === "1") && (status === null || status === "" || status === "all" || status === transactions[i].TransactionStatus || status === "successful" && transactions[i].TransactionType == "Inbound e-Transfer")) {
							if (flag === "All" || flag === "Yes" && !transactions[i].ConfirmedByClient && Object.keys(transactions[i].TransactionFlag).length > 0 || flag == "No" && (transactions[i].ConfirmedByClient || Object.keys(transactions[i].TransactionFlag).length === 0)) {
								if (transactions[i].TransactionID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].FullName !== null && transactions[i].FullName.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].ClientAccountID !== null && transactions[i].ClientAccountID.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].ClientReferenceNumber !== null && transactions[i].ClientReferenceNumber.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].HoldAmount !== null && transactions[i].HoldAmount.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].DebitAmount !== null && transactions[i].DebitAmount.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (transactions[i].CreditAmount !== null && transactions[i].CreditAmount.toLowerCase().replace(/\s|_/g, "").includes(searchTerms)) {
									next = next + 1;
									filteredList[next] = transactions[i];
								} else if (searchTerms === null || searchTerms === "") {
									next = next + 1;
									filteredList[next] = transactions[i];
								}
							}
						}
					}
				}
				_ember['default'].$('#searchTransactions').addClass('searchHide');
				this.set("filteredList", filteredList);
				this.getTransactions(page, this.get("filteredList"));
			}
		},
		errorMessage: function errorMessage(Message) {
			this.set("ErrorMessage", Message);
			this.set("isError", true);
		},
		cancelTransaction: function cancelTransaction(id) {
			var _this2 = this;

			var options = {};
			options.TransactionID = id;
			this.get("api").cancelAccountTransactions(options).then(function (data) {
				var start = _this2.get("startDate");
				var end = _this2.get("endDate");
				_this2.refreshTransactions(start, end, _this2.get("currency"));
			}, function (err) {
				_this2.errorMessage(err);
			});
		},
		downloadURI: function downloadURI(uri, name) {
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			link = null;
		},
		actions: {
			ConfirmTransaction: function ConfirmTransaction(id) {
				var _this3 = this;

				var options = {
					TransactionID: id
				};
				this.get("loader").UpdateLoading(true);
				this.get("api").ConfirmTransaction(options).then(function (data) {
					var start = _this3.get("startDate");
					var end = _this3.get("endDate");
					_this3.refreshTransactions(start, end, _this3.get("currency"));
				}, function (error) {
					_this3.get("loader").UpdateLoading(false);
					_this3.errorMessage(error);
				});
			},
			MoveToTransactionDetails: function MoveToTransactionDetails(id, type) {
				window.location = "/#/dashboard/transaction/" + type + "/" + id + "/";
			},
			handleSelection: function handleSelection(name, id) {
				if (name === "Cancel") {
					this.cancelTransaction(id);
				}
			},
			closeModal: function closeModal() {
				this.set("isShowingModal", false);
				return false;
			},
			closeModalError: function closeModalError() {
				this.set("isError", false);
			},
			getPage: function getPage(pageNumber) {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), pageNumber);
			},
			moveToTransfer: function moveToTransfer() {
				this.get("routing").transitionTo("transfer");
			},
			moveToNewRecipients: function moveToNewRecipients() {
				this.get("routing").transitionTo("dashboard.recipients.new");
			},
			moveToNewBankAccount: function moveToNewBankAccount() {
				this.get("routing").transitionTo("dashboard.account.payment.new");
			},
			filterKeyword: function filterKeyword() {
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
			},
			filterType: function filterType(type) {
				this.set("type", type);
				this.filterTransactions(this.get("keyword"), type, this.get("status"), this.get("transactions"), this.get("flag"), 1);
			},
			filterStatus: function filterStatus(status) {
				this.set("status", status.toLowerCase());
				this.filterTransactions(this.get("keyword"), this.get("type"), status.toLowerCase(), this.get("transactions"), this.get("flag"), 1);
			},
			filterCurrency: function filterCurrency(currency) {
				this.set("currency", currency);
				var start = this.get("startDate");
				var end = this.get("endDate");
				this.refreshTransactions(start, end, this.get("currency"));
			},
			filterFlagged: function filterFlagged(flag) {
				this.set("flag", flag);
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
			},
			pdf: function pdf() {
				var _this4 = this;

				var startDate = this.get("startDate");
				var endDate = this.get("endDate");
				var currency = this.get("currency");
				var options = {};
				var StartDateParts = startDate.split("-");
				var date3 = new Date(StartDateParts[0], StartDateParts[1] - 1, StartDateParts[2]);
				date3.setHours(0);
				date3.setMinutes(0);
				date3.setSeconds(0);

				var year3 = date3.getFullYear();
				var month3 = date3.getMonth() + 1;
				var day3 = date3.getDate();
				var hour3 = date3.getHours();
				var minutes3 = date3.getMinutes();;
				var seconds3 = date3.getSeconds();

				startDate = year3 + "-" + this.pad(month3, 2, "0") + "-" + this.pad(day3, 2, "0") + " " + this.pad(hour3, 2, "0") + ":" + this.pad(minutes3, 2, "0") + ":" + this.pad(seconds3, 2, "0");
				options.StartDateTime = startDate;

				var currentDate = new Date();
				var EndDateParts = endDate.split("-");
				var date = new Date(EndDateParts[0], EndDateParts[1] - 1, EndDateParts[2]);
				date.setHours(23);
				date.setMinutes(59);
				date.setSeconds(59);

				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hour = date.getHours();
				var minutes = date.getMinutes();
				var seconds = date.getSeconds();

				endDate = year + "-" + this.pad(month, 2, "0") + "-" + this.pad(day, 2, "0") + " " + this.pad(hour, 2, "0") + ":" + this.pad(minutes, 2, "0") + ":" + this.pad(seconds, 2, "0");
				options.EndDateTime = endDate;
				options.Currency = currency;
				if (this.get("type") != "All") options.TransactionType = this.get("type");
				options.ExportPDF = true;
				if (this.get("BearerToken")) {
					options.BearerToken = this.get("BearerToken");
				}
				this.get("loader").UpdateLoading(true);
				this.get("api").getAccountTransactions(options).then(function (data) {
					_this4.get("loader").UpdateLoading(false);
					_this4.downloadURI(data.PDFFile, "Transactions.pdf");
				});
			},
			csv: function csv() {
				var _this5 = this;

				var startDate = this.get("startDate");
				var endDate = this.get("endDate");
				var currency = this.get("currency");
				var options = {};
				var StartDateParts = startDate.split("-");
				var date3 = new Date(StartDateParts[0], StartDateParts[1] - 1, StartDateParts[2]);
				date3.setHours(0);
				date3.setMinutes(0);
				date3.setSeconds(0);

				var year3 = date3.getFullYear();
				var month3 = date3.getMonth() + 1;
				var day3 = date3.getDate();
				var hour3 = date3.getHours();
				var minutes3 = date3.getMinutes();;
				var seconds3 = date3.getSeconds();

				startDate = year3 + "-" + this.pad(month3, 2, "0") + "-" + this.pad(day3, 2, "0") + " " + this.pad(hour3, 2, "0") + ":" + this.pad(minutes3, 2, "0") + ":" + this.pad(seconds3, 2, "0");
				options.StartDateTime = startDate;

				var currentDate = new Date();
				var EndDateParts = endDate.split("-");
				var date = new Date(EndDateParts[0], EndDateParts[1] - 1, EndDateParts[2]);
				date.setHours(23);
				date.setMinutes(59);
				date.setSeconds(59);

				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hour = date.getHours();
				var minutes = date.getMinutes();
				var seconds = date.getSeconds();

				endDate = year + "-" + this.pad(month, 2, "0") + "-" + this.pad(day, 2, "0") + " " + this.pad(hour, 2, "0") + ":" + this.pad(minutes, 2, "0") + ":" + this.pad(seconds, 2, "0");
				options.EndDateTime = endDate;
				options.Currency = currency;
				if (this.get("type") != "All") options.TransactionType = this.get("type");
				options.ExportCSV = true;
				if (this.get("BearerToken")) {
					options.BearerToken = this.get("BearerToken");
				}
				this.get("loader").UpdateLoading(true);
				this.get("api").getAccountTransactions(options).then(function (data) {
					_this5.get("loader").UpdateLoading(false);
					_this5.downloadURI(data.CSVFile, "Transactions.csv");
				});
			},
			xls: function xls() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xlsx', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
				}, 200);
				return false;
			},
			doc: function doc() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'word', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
				}, 200);
				return false;
			},
			text: function text() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'txt', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
					_ember['default'].$('#tableID').css("display", "");
				}, 200);
				return false;
			},
			json: function json() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').css("display", "block !important");
					_ember['default'].$('#tableID').tableExport({ type: 'json', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
				}, 200);
				return false;
			},
			xml: function xml() {
				this.set("pageSize", this.get("recordCount"));
				var pageNumberCurrent = this.get("pageNumber");
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;
				setTimeout(function () {
					//your code to be executed after 1 second
					_ember['default'].$('#tableID').addClass("importantShow");
					_ember['default'].$('#tableID').tableExport({ type: 'xml', escape: 'false', consoleLog: 'true' });
					_ember['default'].$('#tableID').removeClass("importantShow");
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
				}, 200);
				return false;
			},
			printPage: function printPage() {

				var pageNumberCurrent = this.get("pageNumber");
				this.set("pageSize", this.get("recordCount"));
				this.filterTransactions(this.get("keyword"), this.get("type"), this.get("status"), this.get("transactions"), this.get("flag"), 1);
				var self = this;

				setTimeout(function () {
					window.print();
					self.set("pageSize", 20);
					self.filterTransactions(self.get("keyword"), self.get("type"), self.get("status"), self.get("transactions"), self.get("flag"), pageNumberCurrent);
				}, 200);
			}

		}

	});
});