define('vopay_app/routes/dashboard/bulk-collect/edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		api: _ember['default'].inject.service('api'),
		userPermissions: _ember['default'].inject.service('user-permissions'),
		model: function model(id) {
			this.get("userPermissions").HasPermissionRoute("ManageTransactionGroups");
			var authed = true;
			if (authed) {

				return _ember['default'].RSVP.hash({
					key: id["template"],
					templates: this.get("api").getGroups()
				});
			} else {
				this.transitionTo('login');
			}
		}
	});
});